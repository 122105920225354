

@media (max-width: 1550px) {
    
    .rtl .elementLine4 {
		left: 412px;
	}

	.rtl .globalElement4{
		left: 135px;
	}
	.rtl .globalElement5{
		left: 65px;
	}
	
	@keyframes haElem4Ar {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			left: 412px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 120px;
			left: 1460px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 120px;
			left: 1380px;
			border-radius: 50%;
		}
	}






/* 
	@keyframes ahElem4 {
		0% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 120px;
			right: 1380px;
			border-radius: 50%;
		}
		70% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			right: 155px;
			border-radius: 54px;
		}
		100% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			right: 172px;
			border-radius: 54px;
		}
	}
 */
	/*************Home to Services************/

	@keyframes hsElem2Ar {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 750px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1400px;
			top: 60px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1370px;
			top: 60px;
			transform: rotate(135deg);
			
		}
	}
	
	@keyframes hsElem4Ar {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			left: 412px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 36px;
			left: -125px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 36px;
			left: -95px;
			border-radius: 50%;
		}
	}

	/*************Home to Team************/
	
	@keyframes htElem2Ar {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 750px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1400px;
			top: 40px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1380px;
			top: 40px;
			transform: rotate(135deg);
			
		}
	}
	@keyframes htElem4Ar {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			left: 412px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 445px;
			left: 1380px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 430px;
			left: 1350px;
			border-radius: 50%;
		}
	}

	/*************Home to Portfolio************/


	@keyframes hpElem2Ar {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 750px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1400px;
			top: -20px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1370px;
			top: 0px;
			transform: rotate(135deg);
			
		}
	}

	@keyframes hpElem4Ar {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			left: 412px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: -10px;
			left: 1430px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 10px;
			left: 1400px;
			border-radius: 50%;
		}
	}



	/* body.rtl.home-to-portfolio .globalElement2{
		animation: hpElem2 3s forwards ease-in-out;
	}
	
	@keyframes hpElem2 {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 750px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1400px;
			top: -20px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1370px;
			top: 0px;
			transform: rotate(135deg);
			
		}
	}
	
	body.rtl.home-to-portfolio .elementLine4{
		animation: hpElem4 3s forwards ease-in-out;
	}
	@keyframes hpElem4 {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			right: 172px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: -10px;
			right: 1430px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 10px;
			right: 1400px;
			border-radius: 50%;
		}
	} */


	

	/* 
	body.rtl.home-to-services .elementLine4{
		animation: hsElem4 3s forwards ease-in-out;
	}
	@keyframes hsElem4 {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			right: 185px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 36px;
			right: -125px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 36px;
			right: -95px;
			border-radius: 50%;
		}
	}

	body.rtl.home-to-services .elementLine4:before{
		animation: hsElem4Before 3s forwards ease-in-out;
	}
	@keyframes hsElem4Before {
		0% { 
			z-index: 4;
			width: 172px;
			height: 172px;
			top: -14px;
			left: -14px;
			border-radius: 63px;
		}
		70% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
	}


	body.rtl.home-to-services .elementLine4:after{
		animation: hsElem4After 3s forwards ease-in-out;
	}
	@keyframes hsElem4After {
		0% { 
			width: 197px;
			height: 196px;
			top: -26px;
			right: -26px;
			border-radius: 70px;
		}
		70% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
		}
	}
	 */

	

	/******************* Animate Home To Clients **********************/

	
	@keyframes hcGlobElem4Ar {
		0% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 135px;
			top: 350px;
			transform: rotate(60deg);
		}
		70% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 810px;
			top: 450px;
			transform: rotate(-60deg);
			z-index: -1;
		}
		100% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 800px;
			top: 430px;
			transform: rotate(-60deg);
			z-index: -1;
		}
	}
	
	@keyframes hcElem2Ar {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 750px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 250px;
			height: 250px;
			right: 575px;
			top: 525px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 250px;
			height: 250px;
			right: 575px;
			top: 495px;
			transform: rotate(135deg);
			
		}
	}
	@keyframes hcElem4Ar {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			left: 412px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 500px;
			left: 610px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 470px;
			left: 600px;
			border-radius: 50%;
		}
	}
	@keyframes hcGlobElem5Ar {
		0% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			left: 65px;
			top: 30px;
			transform: rotate(60deg);
		}
		70% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			left: 65px;
			top: 90px;
			transform: rotate(60deg);
		}
		100% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			left: 65px;
			top: 80px;
			transform: rotate(60deg);
		}
	}



	/*************Home to Testimonials************/
		
	@keyframes htesElem2Ar {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 750px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1400px;
			top: 40px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1380px;
			top: 40px;
			transform: rotate(135deg);
			
		}
	}
	@keyframes htesElem4Ar {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			left: 412px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 445px;
			left: 1380px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 430px;
			left: 1350px;
			border-radius: 50%;
		}
	}

	/*************Home to Contact Us************/

	@keyframes hCtElem2Ar {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 750px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1400px;
			top: -20px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1370px;
			top: 0px;
			transform: rotate(135deg);
			
		}
	}
	@keyframes hCtElem4Ar {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			left: 412px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			left: 1430px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			left: 1400px;
			border-radius: 50%;
		}
	}

	/*************Home to FAQs************/

	@keyframes hfElem2Ar {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 750px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1400px;
			top: -20px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1370px;
			top: 0px;
			transform: rotate(135deg);
			
		}
	}
	@keyframes hfElem4Ar {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			left: 412px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			left: 1430px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			left: 1400px;
			border-radius: 50%;
		}
	}

}
	

@media (max-width: 1499px) {
	/*************Home to Services************/
	@keyframes hsElem2Ar {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 750px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1410px;
			top: 60px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1360px;
			top: 60px;
			transform: rotate(135deg);
			
		}
	}
	
	

	/******************* Animate Home To Clients **********************/

	@keyframes hcGlobElem4Ar {
		0% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 135px;
			top: 350px;
			transform: rotate(60deg);
		}
		70% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 810px;
			top: 450px;
			transform: rotate(-60deg);
			z-index: -1;
		}
		100% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 800px;
			top: 430px;
			transform: rotate(-60deg);
			z-index: -1;
		}
	}
	
	@keyframes hcElem2Ar {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 750px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 250px;
			height: 250px;
			right: 565px;
			top: 525px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 250px;
			height: 250px;
			right: 565px;
			top: 495px;
			transform: rotate(135deg);
			
		}
	}
	
}

@media (max-width: 1440px) {
    .rtl .elementLine4 {
		left: 392px;
	}
	.rtl .globalElement4{
		left: 85px;
	}
	.rtl .globalElement5{
		left: 50px;
	}

	@keyframes haElem2Ar {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 750px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 55px;
			width: 150px;
			height: 150px;
			right: 1350px;
			top: 30px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 55px;
			width: 150px;
			height: 150px;
			right: 1330px;
			top: 30px;
			transform: rotate(135deg);
			
		}
	}

	
	@keyframes haElem4Ar {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			left: 392px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 120px;
			left: 1350px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 120px;
			left: 1300px;
			border-radius: 50%;
		}
	}






/* 
	@keyframes ahElem4 {
		0% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 120px;
			right: 1380px;
			border-radius: 50%;
		}
		70% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			right: 135px;
			border-radius: 54px;
		}
		100% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			right: 152px;
			border-radius: 54px;
		}
	}
 */
	/*************Home to Services************/
	

	@keyframes hsElem2Ar {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 750px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1350px;
			top: 60px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1300px;
			top: 60px;
			transform: rotate(135deg);
			
		}
	}
	@keyframes hsElem4Ar {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			left: 392px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 36px;
			left: -125px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 36px;
			left: -95px;
			border-radius: 50%;
		}
	}




	/*************Home to Team************/
	
	@keyframes htElem2Ar {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 750px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1350px;
			top: 40px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1330px;
			top: 40px;
			transform: rotate(135deg);
			
		}
	}
	@keyframes htElem4Ar {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			left: 392px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 445px;
			left: 1330px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 430px;
			left: 1300px;
			border-radius: 50%;
		}
	}

	/*************Home to Portfolio************/

	
	@keyframes hpElem2Ar {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 750px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1350px;
			top: -20px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1320px;
			top: 0px;
			transform: rotate(135deg);
			
		}
	}

	@keyframes hpElem4Ar {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			left: 392px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: -10px;
			left: 1380px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 10px;
			left: 1350px;
			border-radius: 50%;
		}
	}


	/******************* Animate Home To Clients **********************/

	@keyframes hcGlobElem4Ar {
		0% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 85px;
			top: 350px;
			transform: rotate(60deg);
		}
		70% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 810px;
			top: 450px;
			transform: rotate(-60deg);
			z-index: -1;
		}
		100% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 800px;
			top: 430px;
			transform: rotate(-60deg);
			z-index: -1;
		}
	}

	@keyframes hcElem2Ar {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 750px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 250px;
			height: 250px;
			right: 505px;
			top: 525px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 250px;
			height: 250px;
			right: 505px;
			top: 495px;
			transform: rotate(135deg);
			
		}
	}
	@keyframes hcElem4Ar {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			left: 392px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 500px;
			left: 610px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 470px;
			left: 600px;
			border-radius: 50%;
		}
	}
	@keyframes hcGlobElem5Ar {
		0% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			left: 50px;
			top: 30px;
			transform: rotate(60deg);
		}
		70% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			left: 50px;
			top: 90px;
			transform: rotate(60deg);
		}
		100% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			left: 50px;
			top: 80px;
			transform: rotate(60deg);
		}
	}

	/*************Home to Testimonials************/
	
	@keyframes htesElem2Ar {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 750px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1350px;
			top: 40px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1330px;
			top: 40px;
			transform: rotate(135deg);
			
		}
	}
	@keyframes htesElem4Ar {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			left: 412px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 445px;
			left: 1330px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 430px;
			left: 1300px;
			border-radius: 50%;
		}
	}


	/*************Home to Contact Us************/

	@keyframes hCtElem2Ar {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 750px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1350px;
			top: -20px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1320px;
			top: 0px;
			transform: rotate(135deg);
			
		}
	}
	@keyframes hCtElem4Ar {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			left: 392px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			left: 1380px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			left: 1350px;
			border-radius: 50%;
		}
	}

	/*************Home to FAQs************/

	@keyframes hfElem2Ar {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 750px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1350px;
			top: -20px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1320px;
			top: 0px;
			transform: rotate(135deg);
			
		}
	}
	@keyframes hfElem4Ar {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			left: 392px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			left: 1380px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			left: 1350px;
			border-radius: 50%;
		}
	}


	/*********************Get A Quote*******************/
	.rtl .getQuoteMain{
		left: 170px;
	}
	.rtl .modalQuoEle1{
		left: 150px;
	}
	.rtl .modalQuoEle2{
		left: 335px;
	}
	.rtl .modalQuoEle3{
		left: 375px;
	}
	.rtl .modalQuoEle4{
		left: 160px;
	}

}
@media (max-width: 1399px) {
    .rtl .elementLine4 {
		left: 382px;
	}
	.rtl .globalElement2 {
		right: 650px;
	}
	// .rtl .globalElement4{
	// 	left: 755px;
	// }
	@keyframes haElem2Ar {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 650px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 55px;
			width: 150px;
			height: 150px;
			right: 1350px;
			top: 30px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 55px;
			width: 150px;
			height: 150px;
			right: 1330px;
			top: 30px;
			transform: rotate(135deg);
			
		}
	}
	.rtl .globalElement5{
		left: 40px;
	}
	@keyframes haElem4Ar {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			left: 382px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 120px;
			left: 1350px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 120px;
			left: 1300px;
			border-radius: 50%;
		}
	}

	/* 
	@keyframes haElem4 {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			right: 142px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 120px;
			right: 1300px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 120px;
			right: 1260px;
			border-radius: 50%;
		}
	}
 */



/* 
	@keyframes ahElem4 {
		0% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 120px;
			right: 1380px;
			border-radius: 50%;
		}
		70% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			right: 130px;
			border-radius: 54px;
		}
		100% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			right: 142px;
			border-radius: 54px;
		}
	}
 */
	/*************Home to Services************/

	@keyframes hsElem2Ar {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 650px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1310px;
			top: 60px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1260px;
			top: 60px;
			transform: rotate(135deg);
			
		}
	}
	@keyframes hsElem4Ar {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			left: 382px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 36px;
			left: -125px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 36px;
			left: -95px;
			border-radius: 50%;
		}
	}

	/*************Home to Team************/
	
	@keyframes htElem2Ar {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 650px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1300px;
			top: 40px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1270px;
			top: 40px;
			transform: rotate(135deg);
			
		}
	}
	@keyframes htElem4Ar {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			left: 382px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 445px;
			left: 1280px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 430px;
			left: 1250px;
			border-radius: 50%;
		}
	}

	/*************Home to Portfolio************/

	
	@keyframes hpElem2Ar {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 650px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1300px;
			top: -20px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1270px;
			top: 0px;
			transform: rotate(135deg);
			
		}
	}

	@keyframes hpElem4Ar {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			left: 382px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: -10px;
			left: 1330px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 10px;
			left: 1300px;
			border-radius: 50%;
		}
	}


	/******************* Animate Home To Clients **********************/

	@keyframes hcGlobElem4Ar {
		0% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 85px;
			top: 350px;
			transform: rotate(60deg);
		}
		70% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 810px;
			top: 450px;
			transform: rotate(-60deg);
			z-index: -1;
		}
		100% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 800px;
			top: 430px;
			transform: rotate(-60deg);
			z-index: -1;
		}
	}

	@keyframes hcElem2Ar {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 650px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 250px;
			height: 250px;
			right: 465px;
			top: 525px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 250px;
			height: 250px;
			right: 465px;
			top: 495px;
			transform: rotate(135deg);
			
		}
	}
	@keyframes hcElem4Ar {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			left: 382px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 500px;
			left: 610px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 470px;
			left: 600px;
			border-radius: 50%;
		}
	}
	@keyframes hcGlobElem5Ar {
		0% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			left: 40px;
			top: 30px;
			transform: rotate(60deg);
		}
		70% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			left: 40px;
			top: 90px;
			transform: rotate(60deg);
		}
		100% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			left: 40px;
			top: 80px;
			transform: rotate(60deg);
		}
	}

	/*************Home to Testimonials************/
	
	@keyframes htesElem2Ar {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 650px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1300px;
			top: 40px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1270px;
			top: 40px;
			transform: rotate(135deg);
			
		}
	}
	@keyframes htesElem4Ar {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			left: 382px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 445px;
			left: 1280px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 430px;
			left: 1250px;
			border-radius: 50%;
		}
	}


	/*************Home to Contact Us************/

	@keyframes hCtElem2Ar {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 650px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1300px;
			top: -20px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1270px;
			top: 0px;
			transform: rotate(135deg);
			
		}
	}
	@keyframes hCtElem4Ar {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			left: 382px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			left: 1330px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			left: 1300px;
			border-radius: 50%;
		}
	}

	/*************Home to FAQs************/

	@keyframes hfElem2Ar {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 650px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1300px;
			top: -20px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1270px;
			top: 0px;
			transform: rotate(135deg);
			
		}
	}
	@keyframes hfElem4Ar {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			left: 382px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			left: 1330px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			left: 1300px;
			border-radius: 50%;
		}
	}

}

@media (max-width: 1350px) {
    .rtl .elementLine4 {
		left: 368px;
	}
	.rtl .globalElement2 {
		right: 550px;
	}
	.rtl .globalElement4{
		left: 85px;
	}
	.rtl .globalElement5{
		left: 25px;
	}
	
	@keyframes haElem4Ar {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			left: 368px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 120px;
			left: 1260px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 120px;
			left: 1200px;
			border-radius: 50%;
		}
	}
/* 
	@keyframes ahElem4 {
		0% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 120px;
			right: 1380px;
			border-radius: 50%;
		}
		70% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			right: 110px;
			border-radius: 54px;
		}
		100% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			right: 130px;
			border-radius: 54px;
		}
	}
 */
	@keyframes haElem2Ar {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 550px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 55px;
			width: 150px;
			height: 150px;
			right: 1250px;
			top: 30px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 55px;
			width: 150px;
			height: 150px;
			right: 1220px;
			top: 30px;
			transform: rotate(135deg);
			
		}
	}

	/* 
	@keyframes ahElem2 {
		0% { 
			border-radius: 55px;
			width: 150px;
			height: 150px;
			left: 1220px;
			top: 30px;
			transform: rotate(135deg);
		}
		70% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 520px;
			top: 50px;
			transform: rotate(70deg);
		}
		100% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 550px;
			top: 50px;
			transform: rotate(70deg);
		}
	} */

	/*************Home to Services************/
	@keyframes hsElem2Ar {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 550px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1250px;
			top: 60px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1200px;
			top: 60px;
			transform: rotate(135deg);
			
		}
	}
	@keyframes hsElem4Ar {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			left: 368px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 36px;
			left: -125px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 36px;
			left: -95px;
			border-radius: 50%;
		}
	}

	/*************Home to Team************/
	
	@keyframes htElem2Ar {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 550px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1250px;
			top: 40px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1220px;
			top: 40px;
			transform: rotate(135deg);
			
		}
	}
	@keyframes htElem4Ar {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			left: 368px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 445px;
			left: 1230px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 430px;
			left: 1200px;
			border-radius: 50%;
		}
	}


	/*************Home to Portfolio************/

	@keyframes hpElem2Ar {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 550px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1250px;
			top: -20px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1220px;
			top: 0px;
			transform: rotate(135deg);
			
		}
	}

	@keyframes hpElem4Ar {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			left: 368px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: -10px;
			left: 1280px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 10px;
			left: 1250px;
			border-radius: 50%;
		}
	}

	/******************* Animate Home To Clients **********************/

	@keyframes hcGlobElem4Ar {
		0% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 85px;
			top: 350px;
			transform: rotate(60deg);
		}
		70% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 810px;
			top: 450px;
			transform: rotate(-60deg);
			z-index: -1;
		}
		100% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 800px;
			top: 430px;
			transform: rotate(-60deg);
			z-index: -1;
		}
	}

	@keyframes hcElem2Ar {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 550px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 250px;
			height: 250px;
			right: 405px;
			top: 525px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 250px;
			height: 250px;
			right: 405px;
			top: 495px;
			transform: rotate(135deg);
			
		}
	}
	@keyframes hcElem4Ar {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			left: 368px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 500px;
			left: 610px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 470px;
			left: 600px;
			border-radius: 50%;
		}
	}
	@keyframes hcGlobElem5Ar {
		0% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			left: 25px;
			top: 30px;
			transform: rotate(60deg);
		}
		70% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			left: 25px;
			top: 90px;
			transform: rotate(60deg);
		}
		100% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			left: 25px;
			top: 80px;
			transform: rotate(60deg);
		}
	}

	/*************Home to Testimonials************/
	
	@keyframes htesElem2Ar {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 550px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1250px;
			top: 40px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1220px;
			top: 40px;
			transform: rotate(135deg);
			
		}
	}
	@keyframes htesElem4Ar {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			left: 368px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 445px;
			left: 1230px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 430px;
			left: 1200px;
			border-radius: 50%;
		}
	}


	/*************Home to Contact Us************/

	@keyframes hCtElem2Ar {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 550px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1250px;
			top: -20px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1220px;
			top: 0px;
			transform: rotate(135deg);
			
		}
	}
	@keyframes hCtElem4Ar {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			left: 368px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			left: 1280px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			left: 1250px;
			border-radius: 50%;
		}
	}

	/*************Home to FAQs************/

	@keyframes hfElem2Ar {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 550px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1250px;
			top: -20px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1220px;
			top: 0px;
			transform: rotate(135deg);
			
		}
	}
	@keyframes hfElem4Ar {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			left: 368px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			left: 1280px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			left: 1250px;
			border-radius: 50%;
		}
	}

	/*********************Get A Quote*******************/
	/* .getQuoteMain{
		left: 150px;
	}
	.modalQuoEle1{
		left: 130px;
	}
	.modalQuoEle2{
		left: 320px;
	}
	.modalQuoEle3{
		left: 350px;
	}
	.modalQuoEle4{
		left: 140px;
	} */
}

@media (max-width: 1299px) {
    .rtl .elementLine4 {
		left: 358px;
	}
	.rtl .globalElement4{
		left: 85px;
	}
	.rtl .globalElement5{
		left: 65px;
	}
	
	
	@keyframes haElem4Ar {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			left: 358px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 120px;
			left: 1190px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 120px;
			left: 1150px;
			border-radius: 50%;
		}
	}
	/* 
		@keyframes ahElem4 {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1150px;
				border-radius: 50%;
			}
			70% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				right: 110px;
				border-radius: 54px;
			}
			100% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				right: 116px;
				border-radius: 54px;
			}
		}
	*/
	/*************Home to Services************/
	@keyframes hsElem2Ar {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 550px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1200px;
			top: 60px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1150px;
			top: 60px;
			transform: rotate(135deg);
			
		}
	}
	@keyframes hsElem4Ar {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			left: 358px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 36px;
			left: -125px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 36px;
			left: -95px;
			border-radius: 50%;
		}
	}

	/*************Home to Team************/
	
	@keyframes htElem2Ar {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 550px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1200px;
			top: 40px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1170px;
			top: 40px;
			transform: rotate(135deg);
			
		}
	}
	@keyframes htElem4Ar {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			left: 358px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 445px;
			left: 1180px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 430px;
			left: 1150px;
			border-radius: 50%;
		}
	}

	/*************Home to Portfolio************/

	@keyframes hpElem2Ar {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 550px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1200px;
			top: -20px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1170px;
			top: 0px;
			transform: rotate(135deg);
			
		}
	}

	@keyframes hpElem4Ar {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			left: 358px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: -10px;
			left: 1230px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 10px;
			left: 1200px;
			border-radius: 50%;
		}
	}


	/******************* Animate Home To Clients **********************/

	@keyframes hcGlobElem4Ar {
		0% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 85px;
			top: 350px;
			transform: rotate(60deg);
		}
		70% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 660px;
			top: 450px;
			transform: rotate(-60deg);
			z-index: -1;
		}
		100% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 650px;
			top: 430px;
			transform: rotate(-60deg);
			z-index: -1;
		}
	}

	@keyframes hcElem2Ar {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 550px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 250px;
			height: 250px;
			right: 505px;
			top: 525px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 250px;
			height: 250px;
			right: 505px;
			top: 495px;
			transform: rotate(135deg);
			
		}
	}
	@keyframes hcElem4Ar {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			left: 358px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 500px;
			left: 460px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 470px;
			left: 450px;
			border-radius: 50%;
		}
	}
	@keyframes hcGlobElem5Ar {
		0% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			left: 65px;
			top: 30px;
			transform: rotate(60deg);
		}
		70% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			left: 65px;
			top: 90px;
			transform: rotate(60deg);
		}
		100% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			left: 65px;
			top: 80px;
			transform: rotate(60deg);
		}
	}

	/*************Home to Testimonials************/
	
	@keyframes htesElem2Ar {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 550px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1200px;
			top: 40px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1170px;
			top: 40px;
			transform: rotate(135deg);
			
		}
	}
	@keyframes htesElem4Ar {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			left: 358px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 445px;
			left: 1180px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 430px;
			left: 1150px;
			border-radius: 50%;
		}
	}


	/*************Home to Contact Us************/

	@keyframes hCtElem2Ar {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 550px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1200px;
			top: -20px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1170px;
			top: 0px;
			transform: rotate(135deg);
			
		}
	}
	@keyframes hCtElem4Ar {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			left: 358px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			left: 1230px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			left: 1200px;
			border-radius: 50%;
		}
	}

	/*************Home to FAQs************/

	@keyframes hfElem2Ar {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 550px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1200px;
			top: -20px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1170px;
			top: 0px;
			transform: rotate(135deg);
			
		}
	}
	@keyframes hfElem4Ar {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			left: 358px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			left: 1230px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			left: 1200px;
			border-radius: 50%;
		}
	}

}

@media (max-width: 1250px) {
    .rtl .elementLine4 {
		left: 345px;
	}
	.rtl .globalElement4{
		left: 85px;
	}
	.rtl .globalElement5{
		left: 49px;
	}
	
	
	@keyframes haElem4Ar {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			left: 345px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 120px;
			left: 1140px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 120px;
			left: 1100px;
			border-radius: 50%;
		}
	}
/* 
	@keyframes ahElem4 {
		0% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 120px;
			right: 1100px;
			border-radius: 50%;
		}
		70% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			right: 90px;
			border-radius: 54px;
		}
		100% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			right: 104px;
			border-radius: 54px;
		}
	}
 */
	@keyframes haElem2Ar {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 550px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 55px;
			width: 150px;
			height: 150px;
			right: 1150px;
			top: 30px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 55px;
			width: 150px;
			height: 150px;
			right: 1120px;
			top: 30px;
			transform: rotate(135deg);
			
		}
	}
	/* 
	@keyframes ahElem2 {
		0% { 
			border-radius: 55px;
			width: 150px;
			height: 150px;
			left: 1120px;
			top: 30px;
			transform: rotate(135deg);
		}
		70% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 520px;
			top: 50px;
			transform: rotate(70deg);
		}
		100% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 550px;
			top: 50px;
			transform: rotate(70deg);
		}
	}
 */
	/*************Home to Services************/
	@keyframes hsElem2Ar {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 550px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1150px;
			top: 60px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1100px;
			top: 60px;
			transform: rotate(135deg);
			
		}
	}
	@keyframes hsElem4Ar {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			left: 345px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 36px;
			left: -125px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 36px;
			left: -95px;
			border-radius: 50%;
		}
	}

	/*************Home to Team************/
	
	@keyframes htElem2Ar {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 550px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1150px;
			top: 40px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1120px;
			top: 40px;
			transform: rotate(135deg);
			
		}
	}
	@keyframes htElem4Ar {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			left: 345px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 445px;
			left: 1130px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 430px;
			left: 1100px;
			border-radius: 50%;
		}
	}

	/*************Home to Portfolio************/

	@keyframes hpElem2Ar {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 550px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1150px;
			top: -20px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1120px;
			top: 0px;
			transform: rotate(135deg);
			
		}
	}

	@keyframes hpElem4Ar {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			left: 345px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: -10px;
			left: 1180px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 10px;
			left: 1150px;
			border-radius: 50%;
		}
	}


	/******************* Animate Home To Clients **********************/

	@keyframes hcGlobElem4Ar {
		0% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 85px;
			top: 350px;
			transform: rotate(60deg);
		}
		70% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 610px;
			top: 450px;
			transform: rotate(-60deg);
			z-index: -1;
		}
		100% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 610px;
			top: 430px;
			transform: rotate(-60deg);
			z-index: -1;
		}
	}

	@keyframes hcElem2Ar {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 550px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 250px;
			height: 250px;
			right: 505px;
			top: 525px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 250px;
			height: 250px;
			right: 505px;
			top: 495px;
			transform: rotate(135deg);
			
		}
	}
	@keyframes hcElem4Ar {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			left: 345px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 500px;
			left: 410px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 470px;
			left: 400px;
			border-radius: 50%;
		}
	}
	@keyframes hcGlobElem5Ar {
		0% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			left: 49px;
			top: 30px;
			transform: rotate(60deg);
		}
		70% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			left: 49px;
			top: 90px;
			transform: rotate(60deg);
		}
		100% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			left: 49px;
			top: 80px;
			transform: rotate(60deg);
		}
	}

	/*************Home to Testimonials************/
		
	@keyframes htesElem2Ar {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 550px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1150px;
			top: 40px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1120px;
			top: 40px;
			transform: rotate(135deg);
			
		}
	}
	@keyframes htesElem4Ar {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			left: 345px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 445px;
			left: 1130px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 430px;
			left: 1100px;
			border-radius: 50%;
		}
	}


	/*************Home to Contact Us************/

	@keyframes hCtElem2Ar {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 550px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1150px;
			top: -20px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1120px;
			top: 0px;
			transform: rotate(135deg);
			
		}
	}
	@keyframes hCtElem4Ar {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			left: 345px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			left: 1180px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			left: 1150px;
			border-radius: 50%;
		}
	}

	/*************Home to FAQs************/

	@keyframes hfElem2Ar {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 550px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1150px;
			top: -20px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1120px;
			top: 0px;
			transform: rotate(135deg);
			
		}
	}
	@keyframes hfElem4Ar {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			left: 345px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			left: 1180px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			left: 1150px;
			border-radius: 50%;
		}
	}


	/*********************Get A Quote*******************/
	
	.rtl .getQuoteMain{
		left: 100px;
	}
	.rtl .modalQuoEle1{
		left: 80px;
	}
	.rtl .modalQuoEle2{
		left: 270px;
	}
	.rtl .modalQuoEle3{
		left: 300px;
	}
	.rtl .modalQuoEle4{
		left: 90px;
	}

}

@media (max-width: 1199px) {
    .rtl .elementLine4 {
		left: 335px;
	}
	.rtl .globalElement2{
		right: 500px;
	}
	.rtl .globalElement4{
		left: 85px;
	}
	.rtl .globalElement5{
		left: 79px;
	}
	
	
	@keyframes haElem4Ar {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			left: 335px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 120px;
			left: 1070px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 120px;
			left: 1040px;
			border-radius: 50%;
		}
	}
/* 
	@keyframes ahElem4 {
		0% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 120px;
			right: 1040px;
			border-radius: 50%;
		}
		70% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			right: 60px;
			border-radius: 54px;
		}
		100% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			right: 74px;
			border-radius: 54px;
		}
	}
 */
 
	@keyframes haElem2Ar {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 500px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 55px;
			width: 150px;
			height: 150px;
			right: 1050px;
			top: 30px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 55px;
			width: 150px;
			height: 150px;
			right: 1020px;
			top: 30px;
			transform: rotate(135deg);
			
		}
	}

	/* 
		@keyframes ahElem2 {
			0% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				left: 1050px;
				top: 30px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				left: 520px;
				top: 50px;
				transform: rotate(70deg);
			}
			100% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				left: 550px;
				top: 50px;
				transform: rotate(70deg);
			}
		}
	*/
 /* 
	@keyframes haElem1 {
		0% { 
			border-radius: 17px;
			width: 40px;
			height: 40px;
			left: 200px;
			top: 0px;
			transform: rotate(70deg);
			background: linear-gradient(120deg, rgba(255, 255, 255, .8) 0%, rgba(255, 255, 255, 0) 100%);
		}
		50% { 
			left: 300px;
			top: 330px;
			width: 160px;
			height: 160px;
			border-radius: 47px;
			transform: rotate(120deg);
			backdrop-filter: blur(10px);
			-webkit-backdrop-filter: blur(10px);
			z-index: 5;
		}
		100% { 
			left: 270px;
			top: 295px;
			width: 160px;
			height: 160px;
			border-radius: 47px;
			transform: rotate(120deg);
			backdrop-filter: blur(10px);
			-webkit-backdrop-filter: blur(10px);
			z-index: 5;
			
		}
	} */
	
	/* @keyframes ahElem1 {
		0% { 
			left: 270px;
			top: 295px;
			width: 160px;
			height: 160px;
			border-radius: 47px;
			transform: rotate(120deg);
			backdrop-filter: blur(10px);
			-webkit-backdrop-filter: blur(10px);
			z-index: 5;
		}
		70% { 
			border-radius: 17px;
			width: 40px;
			height: 40px;
			left: 180px;
			top: -30px;
			transform: rotate(70deg);
			background: linear-gradient(120deg, rgba(255, 255, 255, .8) 0%, rgba(255, 255, 255, 0) 100%);
		}
		100% { 
			border-radius: 17px;
			width: 40px;
			height: 40px;
			left: 200px;
			top: 0px;
			transform: rotate(70deg);
			background: linear-gradient(120deg, rgba(255, 255, 255, .8) 0%, rgba(255, 255, 255, 0) 100%);
			// backdrop-filter: blur(10px);
			// -webkit-backdrop-filter: blur(10px);
			// transition: all 2s ease;
			
		}
	} */


	/*************Home to Services************/
	@keyframes hsElem2Ar {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 500px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1100px;
			top: 60px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1050px;
			top: 60px;
			transform: rotate(135deg);
			
		}
	}
	@keyframes hsElem4Ar {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			left: 335px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 36px;
			left: -125px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 36px;
			left: -95px;
			border-radius: 50%;
		}
	}

	/*************Home to Team************/
	
	@keyframes htElem2Ar {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 500px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1100px;
			top: 40px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1070px;
			top: 40px;
			transform: rotate(135deg);
			
		}
	}
	@keyframes htElem4Ar {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			left: 335px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 445px;
			left: 1080px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 430px;
			left: 1050px;
			border-radius: 50%;
		}
	}


	/*************Home to Portfolio************/

	@keyframes hpElem2Ar {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 500px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1100px;
			top: -20px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1070px;
			top: 0px;
			transform: rotate(135deg);
			
		}
	}

	@keyframes hpElem4Ar {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			left: 335px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: -10px;
			left: 1130px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 10px;
			left: 1100px;
			border-radius: 50%;
		}
	}


	/******************* Animate Home To Clients **********************/

	@keyframes hcGlobElem4Ar {
		0% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 85px;
			top: 350px;
			transform: rotate(60deg);
		}
		70% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 610px;
			top: 450px;
			transform: rotate(-60deg);
			z-index: -1;
		}
		100% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 610px;
			top: 430px;
			transform: rotate(-60deg);
			z-index: -1;
		}
	}

	@keyframes hcElem2Ar {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 500px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 250px;
			height: 250px;
			right: 455px;
			top: 525px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 250px;
			height: 250px;
			right: 455px;
			top: 495px;
			transform: rotate(135deg);
			
		}
	}
	@keyframes hcElem4Ar {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			left: 335px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 500px;
			left: 410px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 470px;
			left: 400px;
			border-radius: 50%;
		}
	}
	@keyframes hcGlobElem5Ar {
		0% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			left: 79px;
			top: 30px;
			transform: rotate(60deg);
		}
		70% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			left: 79px;
			top: 90px;
			transform: rotate(60deg);
		}
		100% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			left: 79px;
			top: 80px;
			transform: rotate(60deg);
		}
	}


	/*************Home to Testimonials************/
	
	@keyframes htesElem2Ar {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 500px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1100px;
			top: 40px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1070px;
			top: 40px;
			transform: rotate(135deg);
			
		}
	}
	@keyframes htesElem4Ar {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			left: 335px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 445px;
			left: 1080px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 430px;
			left: 1050px;
			border-radius: 50%;
		}
	}


	/*************Home to Contact Us************/

	@keyframes hCtElem2Ar {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 500px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1100px;
			top: -20px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1070px;
			top: 0px;
			transform: rotate(135deg);
			
		}
	}
	@keyframes hCtElem4Ar {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			left: 335px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			left: 1130px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			left: 1100px;
			border-radius: 50%;
		}
	}


	/*************Home to FAQs************/

	@keyframes hfElem2Ar {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 500px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1100px;
			top: -20px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1070px;
			top: 0px;
			transform: rotate(135deg);
			
		}
	}
	@keyframes hfElem4Ar {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			left: 335px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			left: 1130px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			left: 1100px;
			border-radius: 50%;
		}
	}

}

@media (max-width: 1150px) {
    .rtl .elementLine4 {
		left: 325px;
		top: 291px;
	}
	.rtl .globalElement4{
		left: 85px;
	}
	.rtl .globalElement5{
		left: 69px;
	}
	
	
	@keyframes haElem4Ar {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			left: 325px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 120px;
			left: 1040px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 120px;
			left: 1000px;
			border-radius: 50%;
		}
	}
/* 
	@keyframes ahElem4 {
		0% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 120px;
			right: 1000px;
			border-radius: 50%;
		}
		70% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 291px;
			right: 45px;
			border-radius: 54px;
		}
		100% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 291px;
			right: 60px;
			border-radius: 54px;
		}
	}
 */
	@keyframes haElem1 {
		0% { 
			border-radius: 17px;
			width: 40px;
			height: 40px;
			left: 200px;
			top: 0px;
			transform: rotate(70deg);
			background: linear-gradient(120deg, rgba(255, 255, 255, .8) 0%, rgba(255, 255, 255, 0) 100%);
		}
		50% { 
			left: 300px;
			top: 330px;
			width: 140px;
			height: 140px;
			border-radius: 47px;
			transform: rotate(120deg);
			/* Add the blur effect */
			backdrop-filter: blur(10px);
			-webkit-backdrop-filter: blur(10px);
			z-index: 5;
		}
		100% { 
			left: 270px;
			top: 295px;
			width: 140px;
			height: 140px;
			border-radius: 47px;
			transform: rotate(120deg);
			/* Add the blur effect */
			backdrop-filter: blur(10px);
			-webkit-backdrop-filter: blur(10px);
			z-index: 5;
			
		}
	}
	/* 
	@keyframes ahElem1 {
		0% { 
			left: 270px;
			top: 295px;
			width: 140px;
			height: 140px;
			border-radius: 47px;
			transform: rotate(120deg);
			backdrop-filter: blur(10px);
			-webkit-backdrop-filter: blur(10px);
			z-index: 5;
		}
		70% { 
			border-radius: 17px;
			width: 40px;
			height: 40px;
			left: 180px;
			top: -30px;
			transform: rotate(70deg);
			background: linear-gradient(120deg, rgba(255, 255, 255, .8) 0%, rgba(255, 255, 255, 0) 100%);
		}
		100% { 
			border-radius: 17px;
			width: 40px;
			height: 40px;
			left: 200px;
			top: 0px;
			transform: rotate(70deg);
			background: linear-gradient(120deg, rgba(255, 255, 255, .8) 0%, rgba(255, 255, 255, 0) 100%);
			// backdrop-filter: blur(10px);
			// -webkit-backdrop-filter: blur(10px);
			// transition: all 2s ease;
			
		}
	}
 	*/

	/*************Home to Services************/
	@keyframes hsElem2Ar {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 500px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1050px;
			top: 60px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1000px;
			top: 60px;
			transform: rotate(135deg);
			
		}
	}
	@keyframes hsElem4Ar {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 291px;
			left: 325px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 36px;
			left: -125px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 36px;
			left: -95px;
			border-radius: 50%;
		}
	}

	/*************Home to Team************/
	
	@keyframes htElem2Ar {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 500px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1050px;
			top: 40px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1020px;
			top: 40px;
			transform: rotate(135deg);
			
		}
	}
	@keyframes htElem4Ar {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 291px;
			left: 325px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 445px;
			left: 1030px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 430px;
			left: 1000px;
			border-radius: 50%;
		}
	}


	/*************Home to Portfolio************/

	@keyframes hpElem2Ar {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 500px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1050px;
			top: -20px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1020px;
			top: 0px;
			transform: rotate(135deg);
			
		}
	}

	@keyframes hpElem4Ar {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 291px;
			left: 325px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: -10px;
			left: 1080px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 10px;
			left: 1050px;
			border-radius: 50%;
		}
	}


	/******************* Animate Home To Clients **********************/

	@keyframes hcGlobElem4Ar {
		0% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 85px;
			top: 350px;
			transform: rotate(60deg);
		}
		70% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 550px;
			top: 450px;
			transform: rotate(-60deg);
			z-index: -1;
		}
		100% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 550px;
			top: 430px;
			transform: rotate(-60deg);
			z-index: -1;
		}
	}

	@keyframes hcElem2Ar {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 500px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 250px;
			height: 250px;
			right: 455px;
			top: 525px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 250px;
			height: 250px;
			right: 455px;
			top: 495px;
			transform: rotate(135deg);
			
		}
	}
	@keyframes hcElem4Ar {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 291px;
			left: 325px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 500px;
			left: 360px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 470px;
			left: 350px;
			border-radius: 50%;
		}
	}
	@keyframes hcGlobElem5Ar {
		0% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			left: 69px;
			top: 30px;
			transform: rotate(60deg);
		}
		70% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			left: 69px;
			top: 90px;
			transform: rotate(60deg);
		}
		100% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			left: 69px;
			top: 80px;
			transform: rotate(60deg);
		}
	}

	/*************Home to Testimonials************/
		
	@keyframes htesElem2Ar {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 500px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1050px;
			top: 40px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1020px;
			top: 40px;
			transform: rotate(135deg);
			
		}
	}
	@keyframes htesElem4Ar {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 291px;
			left: 325px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 445px;
			left: 1030px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 430px;
			left: 1000px;
			border-radius: 50%;
		}
	}


	/*************Home to Contact Us************/

	@keyframes hCtElem2Ar {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 500px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1100px;
			top: -20px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1070px;
			top: 0px;
			transform: rotate(135deg);
			
		}
	}
	@keyframes hCtElem4Ar {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 291px;
			left: 325px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			left: 1080px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			left: 1050px;
			border-radius: 50%;
		}
	}

	/*************Home to FAQs************/

	@keyframes hfElem2Ar {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 500px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1100px;
			top: -20px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1070px;
			top: 0px;
			transform: rotate(135deg);
			
		}
	}
	@keyframes hfElem4Ar {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 291px;
			left: 325px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			left: 1080px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			left: 1050px;
			border-radius: 50%;
		}
	}

	/*********************Get A Quote*******************/
	/* .getQuoteMain{
		left: 75px;
	}
	.modalQuoEle1{
		left: 60px;
	}
	.modalQuoEle2{
		left: 240px;
	}
	.modalQuoEle3{
		left: 280px;
	}
	.modalQuoEle4{
		left: 70px;
	} */
	.rtl .rightColContentQuote {
		padding: 10px 80px 0 10px;
	}

}

@media (max-width: 1099px) {
	
    .rtl .elementLine4 {
		left: 315px;
	}
	.rtl .globalElement4{
		left: 65px;
	}
	.rtl .globalElement5{
		left: 55px;
	}
	.rtl .globalElement2 {
		right: 450px;
	}
	
	@keyframes haElem2Ar {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 450px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 55px;
			width: 150px;
			height: 150px;
			right: 1050px;
			top: 30px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 55px;
			width: 150px;
			height: 150px;
			right: 1020px;
			top: 30px;
			transform: rotate(135deg);
			
		}
	}
	@keyframes haElem4Ar {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			left: 315px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 120px;
			left: 1000px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 120px;
			left: 940px;
			border-radius: 50%;
		}
	}


	@keyframes haElem3Ar {
		0% { 
			border-radius: 80px;
			width: 250px;
			height: 250px;
			right: 95%;
			bottom: -100px;
			left: unset;
			transform: rotate(70deg);
			background: linear-gradient(120deg, rgba(255, 255, 255, .8) 0%, rgba(255, 255, 255, 0) 100%);
			// transition: all 2s ease;
		}
		70% { 
			border-radius: 80px;
			width: 300px;
			height: 300px;
			left: unset;
			right: 0px;
			bottom: 60px;
			transform: rotate(70deg);
			background: var(--bgGradient);
		}
		100% { 
			border-radius: 80px;
			width: 284px;
			height: 284px;
			left: unset;
			right: 30px;
			bottom: 87px;
			transform: rotate(66deg);
			background: var(--bgGradient);
			
		}
	}


	

/* 
	@keyframes ahElem4 {
		0% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 120px;
			right: 1000px;
			border-radius: 50%;
		}
		70% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 291px;
			right: 45px;
			border-radius: 54px;
		}
		100% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 291px;
			right: 50px;
			border-radius: 54px;
		}
	}
 */
 /* 
	@keyframes haElem1 {
		0% { 
			border-radius: 17px;
			width: 40px;
			height: 40px;
			left: 200px;
			top: 0px;
			transform: rotate(70deg);
			background: linear-gradient(120deg, rgba(255, 255, 255, .8) 0%, rgba(255, 255, 255, 0) 100%);
		}
		50% { 
			left: 280px;
			top: 360px;
			width: 130px;
			height: 130px;
			border-radius: 47px;
			transform: rotate(120deg);
			backdrop-filter: blur(10px);
			-webkit-backdrop-filter: blur(10px);
			z-index: 5;
		}
		100% { 
			left: 250px;
			top: 320px;
			width: 130px;
			height: 130px;
			border-radius: 47px;
			transform: rotate(120deg);
			backdrop-filter: blur(10px);
			-webkit-backdrop-filter: blur(10px);
			z-index: 5;
			
		}
	}
 */


	/* 
	@keyframes ahElem1 {
		0% { 
			left: 250px;
			top: 320px;
			width: 130px;
			height: 130px;
			border-radius: 47px;
			transform: rotate(120deg);
			backdrop-filter: blur(10px);
			-webkit-backdrop-filter: blur(10px);
			z-index: 5;
		}
		70% { 
			border-radius: 17px;
			width: 40px;
			height: 40px;
			left: 180px;
			top: -30px;
			transform: rotate(70deg);
			background: linear-gradient(120deg, rgba(255, 255, 255, .8) 0%, rgba(255, 255, 255, 0) 100%);
		}
		100% { 
			border-radius: 17px;
			width: 40px;
			height: 40px;
			left: 200px;
			top: 0px;
			transform: rotate(70deg);
			background: linear-gradient(120deg, rgba(255, 255, 255, .8) 0%, rgba(255, 255, 255, 0) 100%);
			// backdrop-filter: blur(10px);
			// -webkit-backdrop-filter: blur(10px);
			// transition: all 2s ease;
			
		}
	}
 	*/
	/*************Home to Services************/
	
	@keyframes hsElem2Ar {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 450px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1000px;
			top: 60px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 950px;
			top: 60px;
			transform: rotate(135deg);
			
		}
	}
	@keyframes hsElem4Ar {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 291px;
			left: 315px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 36px;
			left: -125px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 36px;
			left: -95px;
			border-radius: 50%;
		}
	}


	/*************Home to Portfolio************/

	@keyframes hpElem2Ar {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 450px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1000px;
			top: -20px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 970px;
			top: 0px;
			transform: rotate(135deg);
			
		}
	}

	@keyframes hpElem4Ar {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 291px;
			left: 315px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: -10px;
			left: 1030px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 10px;
			left: 1000px;
			border-radius: 50%;
		}
	}


	/*************Home to Team************/
	
	@keyframes htElem2Ar {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 450px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1000px;
			top: 40px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 970px;
			top: 40px;
			transform: rotate(135deg);
			
		}
	}
	@keyframes htElem4Ar {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 291px;
			left: 315px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 445px;
			left: 980px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 430px;
			left: 950px;
			border-radius: 50%;
		}
	}


	/*************Home to Testimonials************/
	
	@keyframes htesElem2Ar {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 450px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1000px;
			top: 40px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 970px;
			top: 40px;
			transform: rotate(135deg);
			
		}
	}
	@keyframes htesElem4Ar {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 291px;
			left: 315px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 445px;
			left: 980px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 430px;
			left: 950px;
			border-radius: 50%;
		}
	}


	/******************* Animate Home To Clients **********************/

	@keyframes hcGlobElem4Ar {
		0% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 85px;
			top: 350px;
			transform: rotate(60deg);
		}
		70% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 550px;
			top: 450px;
			transform: rotate(-60deg);
			z-index: -1;
		}
		100% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 550px;
			top: 430px;
			transform: rotate(-60deg);
			z-index: -1;
		}
	}

	@keyframes hcElem2Ar {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 450px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 250px;
			height: 250px;
			right: 405px;
			top: 525px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 250px;
			height: 250px;
			right: 405px;
			top: 495px;
			transform: rotate(135deg);
			
		}
	}
	@keyframes hcElem4Ar {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 291px;
			left: 315px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 500px;
			left: 360px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 470px;
			left: 350px;
			border-radius: 50%;
		}
	}
	@keyframes hcGlobElem5Ar {
		0% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			left: 55px;
			top: 30px;
			transform: rotate(60deg);
		}
		70% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			left: 55px;
			top: 90px;
			transform: rotate(60deg);
		}
		100% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			left: 55px;
			top: 80px;
			transform: rotate(60deg);
		}
	}


	/*************Home to Contact Us************/

	@keyframes hCtElem2Ar {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 450px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1050px;
			top: -20px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1020px;
			top: 0px;
			transform: rotate(135deg);
			
		}
	}
	@keyframes hCtElem4Ar {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 291px;
			left: 315px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			left: 1030px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			left: 1000px;
			border-radius: 50%;
		}
	}

	/*************Home to FAQs************/

	@keyframes hfElem2Ar {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 450px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1050px;
			top: -20px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1020px;
			top: 0px;
			transform: rotate(135deg);
			
		}
	}
	@keyframes hfElem4Ar {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 291px;
			left: 315px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			left: 1030px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			left: 1000px;
			border-radius: 50%;
		}
	}

	/*********************Get A Quote*******************/
	.rtl .getQuoteMain{
		left: 100px;
	}
	.rtl .modalQuoEle1{
		left: 80px;
	}
	.rtl .modalQuoEle2{
		left: 260px;
	}
	.rtl .modalQuoEle3{
		left: 300px;
	}
	.rtl .modalQuoEle4{
		left: 80px;
	}
	.rtl .rightColContentQuote {
		padding: 10px 50px 0 10px;
	}

}

@media (max-width: 1050px) {
    .rtl .elementLine4 {
		top: 291px;
		left: 300px;
	}
	// .rtl .globalElement2{
	// 	right: 450px;
	// }
	.rtl .globalElement4{
		left: 55px;
		top: 364px;
	}
	.rtl .globalElement5{
		left: 42px;
		top: 46px;
	}
	
	
	@keyframes haElem4Ar {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 291px;
			left: 300px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 120px;
			left: 950px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 120px;
			left: 900px;
			border-radius: 50%;
		}
	}

	@keyframes haElem2Ar {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 450px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 55px;
			width: 150px;
			height: 150px;
			right: 930px;
			top: 30px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 55px;
			width: 150px;
			height: 150px;
			right: 900px;
			top: 30px;
			transform: rotate(135deg);
			
		}
	}

	/* 
	@keyframes ahElem4 {
		0% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 120px;
			right: 900px;
			border-radius: 50%;
		}
		70% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 315px;
			right: 20px;
			border-radius: 54px;
		}
		100% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 305px;
			right: 36px;
			border-radius: 54px;
		}
	}
 */
	/* @keyframes ahElem3 {
		0% { 
			border-radius: 80px;
			width: 284px;
			height: 284px;
			left: 50px;
			bottom: 72px;
			transform: rotate(66deg);
			background: var(--bgGradient);
		}
		70% { 
			border-radius: 80px;
			width: 250px;
			height: 250px;
			left: 96%;
			bottom: -120px;
			transform: rotate(70deg);
			// background: linear-gradient(120deg, rgba(255, 255, 255, .8) 0%, rgba(255, 255, 255, 0) 100%);
		}
		100% { 
			border-radius: 80px;
			width: 250px;
			height: 250px;
			left: 95%;
			bottom: -100px;
			transform: rotate(70deg);
			background: linear-gradient(120deg, rgba(255, 255, 255, .8) 0%, rgba(255, 255, 255, 0) 100%);
			
			
		}
	} */
	/* 
	@keyframes haElem3 {
		0% { 
			border-radius: 80px;
			width: 250px;
			height: 250px;
			left: 95%;
			bottom: -100px;
			transform: rotate(70deg);
			background: linear-gradient(120deg, rgba(255, 255, 255, .8) 0%, rgba(255, 255, 255, 0) 100%);
			// transition: all 2s ease;
		}
		70% { 
			border-radius: 80px;
			width: 300px;
			height: 300px;
			left: 20px;
			bottom: 50px;
			transform: rotate(70deg);
			background: var(--bgGradient);
		}
		100% { 
			border-radius: 80px;
			width: 284px;
			height: 284px;
			left: 50px;
			bottom: 72px;
			transform: rotate(66deg);
			background: var(--bgGradient);
			
		}
	}
 */
	
	/* @keyframes ahElem2 {
		0% { 
			border-radius: 55px;
			width: 150px;
			height: 150px;
			left: 980px;
			top: 30px;
			transform: rotate(135deg);
		}
		70% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 480px;
			top: 50px;
			transform: rotate(70deg);
		}
		100% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 500px;
			top: 50px;
			transform: rotate(70deg);
		}
	} */
/* 
	@keyframes haElem1 {
		0% { 
			border-radius: 17px;
			width: 40px;
			height: 40px;
			left: 200px;
			top: 0px;
			transform: rotate(70deg);
			background: linear-gradient(120deg, rgba(255, 255, 255, .8) 0%, rgba(255, 255, 255, 0) 100%);
		}
		50% { 
			left: 280px;
			top: 360px;
			width: 130px;
			height: 130px;
			border-radius: 47px;
			transform: rotate(120deg);
			backdrop-filter: blur(10px);
			-webkit-backdrop-filter: blur(10px);
			z-index: 5;
		}
		100% { 
			left: 240px;
			top: 320px;
			width: 130px;
			height: 130px;
			border-radius: 47px;
			transform: rotate(120deg);
			backdrop-filter: blur(10px);
			-webkit-backdrop-filter: blur(10px);
			z-index: 5;
			
		}
	}
 */

	/* @keyframes ahElem1 {
		0% { 
			left: 240px;
			top: 320px;
			width: 130px;
			height: 130px;
			border-radius: 47px;
			transform: rotate(120deg);
			backdrop-filter: blur(10px);
			-webkit-backdrop-filter: blur(10px);
			z-index: 5;
		}
		70% { 
			border-radius: 17px;
			width: 40px;
			height: 40px;
			left: 180px;
			top: -30px;
			transform: rotate(70deg);
			background: linear-gradient(120deg, rgba(255, 255, 255, .8) 0%, rgba(255, 255, 255, 0) 100%);
		}
		100% { 
			border-radius: 17px;
			width: 40px;
			height: 40px;
			left: 200px;
			top: 0px;
			transform: rotate(70deg);
			background: linear-gradient(120deg, rgba(255, 255, 255, .8) 0%, rgba(255, 255, 255, 0) 100%);
			// backdrop-filter: blur(10px);
			// -webkit-backdrop-filter: blur(10px);
			// transition: all 2s ease;
			
		}
	} */

	/*************Home to Services************/
	
	@keyframes hsElem2Ar {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 450px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 950px;
			top: 60px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 900px;
			top: 60px;
			transform: rotate(135deg);
			
		}
	}
	@keyframes hsElem4Ar {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 291px;
			left: 300px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 36px;
			left: -125px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 36px;
			left: -95px;
			border-radius: 50%;
		}
	}

	/*************Home to Team************/
	
	@keyframes htElem2Ar {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 450px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 950px;
			top: 40px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 920px;
			top: 40px;
			transform: rotate(135deg);
			
		}
	}
	@keyframes htElem4Ar {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 291px;
			left: 300px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 445px;
			left: 930px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 430px;
			left: 900px;
			border-radius: 50%;
		}
	}


	/*************Home to Portfolio************/

	@keyframes hpElem2Ar {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 450px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 950px;
			top: -20px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 920px;
			top: 0px;
			transform: rotate(135deg);
			
		}
	}

	@keyframes hpElem4Ar {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 291px;
			left: 300px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: -10px;
			left: 980px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 10px;
			left: 950px;
			border-radius: 50%;
		}
	}


	/*************Home to Testimonials************/
	
	@keyframes htesElem2Ar {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 450px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 950px;
			top: 40px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 920px;
			top: 40px;
			transform: rotate(135deg);
			
		}
	}
	@keyframes htesElem4Ar {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 291px;
			left: 300px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 445px;
			left: 930px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 430px;
			left: 900px;
			border-radius: 50%;
		}
	}


	/******************* Animate Home To Clients **********************/

	@keyframes hcGlobElem4Ar {
		0% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 85px;
			top: 350px;
			transform: rotate(60deg);
		}
		70% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 550px;
			top: 450px;
			transform: rotate(-60deg);
			z-index: -1;
		}
		100% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 550px;
			top: 430px;
			transform: rotate(-60deg);
			z-index: -1;
		}
	}
	@keyframes hcElem2Ar {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 450px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 250px;
			height: 250px;
			right: 355px;
			top: 525px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 250px;
			height: 250px;
			right: 355px;
			top: 495px;
			transform: rotate(135deg);
			
		}
	}
	@keyframes hcElem4Ar {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 291px;
			left: 300px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 500px;
			left: 360px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 470px;
			left: 350px;
			border-radius: 50%;
		}
	}
	@keyframes hcGlobElem5Ar {
		0% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			left: 42px;
			top: 46px;
			transform: rotate(60deg);
		}
		70% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			left: 42px;
			top: 90px;
			transform: rotate(60deg);
		}
		100% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			left: 42px;
			top: 80px;
			transform: rotate(60deg);
		}
	}




	/*************Home to Contact Us************/

	@keyframes hCtElem2Ar {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 450px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1000px;
			top: -20px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 970px;
			top: 0px;
			transform: rotate(135deg);
			
		}
	}
	@keyframes hCtElem4Ar {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 291px;
			left: 300px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			left: 980px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			left: 950px;
			border-radius: 50%;
		}
	}

	/*************Home to FAQs************/

	@keyframes hfElem2Ar {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 450px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1000px;
			top: -20px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 970px;
			top: 0px;
			transform: rotate(135deg);
			
		}
	}
	@keyframes hfElem4Ar {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 291px;
			left: 300px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			left: 980px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			left: 950px;
			border-radius: 50%;
		}
	}

	/*********************Get A Quote*******************/
	/* .getQuoteMain{
		left: 55px;
	}
	.modalQuoEle1{
		left: 40px;
	}
	.modalQuoEle2{
		left: 220px;
	}
	.modalQuoEle3{
		left: 260px;
	}
	.modalQuoEle4{
		left: 50px;
	} */
	.rtl .rightColContentQuote {
		padding: 10px 70px 0 10px;
	}

}




/******************* Animate About to All **********************/



	/******************* Animate About to Home **********************/
	
	@media (max-width: 1550px) {
		
		@keyframes ahElem2Ar {
			0% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				right: 1420px;
				left: unset;
				top: 30px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 720px;
				left: unset;
				top: 50px;
				transform: rotate(70deg);
			}
			100% { 
				
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 750px;
				left: unset;
				top: 50px;
				transform: rotate(70deg);
			}
		}
		
		@keyframes ahElem3Ar {
			0% { 
				border-radius: 80px;
				width: 284px;
				height: 284px;
				left: unset;
				right: 50px;
				bottom: 67px;
				transform: rotate(66deg);
				background: var(--bgGradient);
				
			}
			70% { 
				border-radius: 80px;
				width: 250px;
				height: 250px;
				right: 96%;
				bottom: -110px;
				left: unset;
				transform: rotate(70deg);
				background: linear-gradient(120deg, rgba(255, 255, 255, .8) 0%, rgba(255, 255, 255, 0) 100%);
				// transition: all 2s ease;
			}
			100% { 
				border-radius: 80px;
				width: 250px;
				height: 250px;
				right: 95%;
				bottom: -100px;
				left: unset;
				transform: rotate(70deg);
				background: linear-gradient(120deg, rgba(255, 255, 255, .8) 0%, rgba(255, 255, 255, 0) 100%);
				// transition: all 2s ease;
				
			}
		}
		@keyframes ahElem4Ar {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				left: 1420px;
				border-radius: 50%;
			}
			70% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 310px;
				left: 395px;
				border-radius: 54px;
			}
			100% { 
				
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 412px;
				border-radius: 54px;
			}
		}
	}
	@media (max-width: 1440px) {
		
		@keyframes ahElem2Ar {
			0% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				right: 1330px;
				top: 30px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 730px;
				top: 50px;
				transform: rotate(70deg);
			}
			100% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 750px;
				top: 50px;
				transform: rotate(70deg);
				
			}
		}
		
		@keyframes ahElem4Ar {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				left: 1300px;
				border-radius: 50%;
			}
			70% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 372px;
				border-radius: 54px;
			}
			100% { 
				
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 392px;
				border-radius: 54px;
			}
		}
	}
	@media (max-width: 1399px) {
		@keyframes ahElem2Ar {
			0% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				right: 1330px;
				top: 30px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 630px;
				top: 50px;
				transform: rotate(70deg);
			}
			100% { 
				
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 650px;
				top: 50px;
				transform: rotate(70deg);
			}
		}
		@keyframes ahElem4Ar {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				left: 1300px;
				border-radius: 50%;
			}
			70% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 362px;
				border-radius: 54px;
			}
			100% { 
				
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 382px;
				border-radius: 54px;
			}
		}
	}
	@media (max-width: 1350px) {
		@keyframes ahElem2Ar {
			0% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				right: 1220px;
				top: 30px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 530px;
				top: 50px;
				transform: rotate(70deg);
			}
			100% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 550px;
				top: 50px;
				transform: rotate(70deg);
				
			}
		}
		@keyframes ahElem4Ar {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				left: 1200px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 348px;
				border-radius: 54px;
			}
			100% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 368px;
				border-radius: 54px;
			}
		}
	}
	@media (max-width: 1299px) {
		@keyframes ahElem2Ar {
			0% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				right: 1220px;
				top: 30px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 530px;
				top: 50px;
				transform: rotate(70deg);
			}
			100% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 550px;
				top: 50px;
				transform: rotate(70deg);
				
			}
		}
		@keyframes ahElem4Ar {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				left: 1150px;
				border-radius: 50%;
			}
			70% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 338px;
				border-radius: 54px;
			}
			100% { 
				
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 358px;
				border-radius: 54px;
			}
		}
	}
	@media (max-width: 1250px) {
		@keyframes ahElem2Ar {
			0% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				right: 1120px;
				top: 30px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 530px;
				top: 50px;
				transform: rotate(70deg);
			}
			100% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 550px;
				top: 50px;
				transform: rotate(70deg);
				
			}
		}
		@keyframes ahElem4Ar {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				left: 1100px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 325px;
				border-radius: 54px;
			}
			100% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 345px;
				border-radius: 54px;
			}
		}
	}
	@media (max-width: 1199px) {
		@keyframes ahElem2Ar {
			0% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				right: 1020px;
				top: 30px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 480px;
				top: 50px;
				transform: rotate(70deg);
			}
			100% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 500px;
				top: 50px;
				transform: rotate(70deg);
				
			}
		}
		@keyframes ahElem4Ar {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				left: 1040px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 315px;
				border-radius: 54px;
			}
			100% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 335px;
				border-radius: 54px;
			}
		}
	}
	@media (max-width: 1150px) {
		/* 
		@keyframes ahElem2Ar {
			0% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				right: 1020px;
				top: 30px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 480px;
				top: 50px;
				transform: rotate(70deg);
			}
			100% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 500px;
				top: 50px;
				transform: rotate(70deg);
				
			}
		}
 		*/
		@keyframes ahElem4Ar {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				left: 1000px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 305px;
				border-radius: 54px;
			}
			100% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 325px;
				border-radius: 54px;
			}
		}
	}
	@media (max-width: 1099px) {
		@keyframes ahElem2Ar {
			0% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				right: 1020px;
				top: 30px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 430px;
				top: 50px;
				transform: rotate(70deg);
			}
			100% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 450px;
				top: 50px;
				transform: rotate(70deg);
				
			}
		}
		
		@keyframes ahElem4Ar {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				left: 940px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 295px;
				border-radius: 54px;
			}
			100% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 315px;
				border-radius: 54px;
			}
		}
	}
	@media (max-width: 1050px) {
		@keyframes ahElem2Ar {
			0% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				right: 900px;
				top: 30px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 430px;
				top: 50px;
				transform: rotate(70deg);
			}
			100% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 450px;
				top: 50px;
				transform: rotate(70deg);
				
			}
		}
		
		@keyframes ahElem4Ar {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				left: 900px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 291px;
				left: 280px;
				border-radius: 54px;
			}
			100% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 291px;
				left: 300px;
				border-radius: 54px;
			}
		}
		
	}

	/******************* Animate About to Services **********************/

	@media (max-width: 1550px) {
		body.rtl.about-to-services .globalElement2{
			animation: asElem2Ar 3s forwards ease-in-out;
		}
		
		@keyframes asElem2Ar {
			0% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				right: 1420px;
				left: unset;
				top: 30px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1370px;
				top: 60px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.about-to-services .elementLine4{
			animation: asElem4Ar 3s forwards ease-in-out;
		}
		@keyframes asElem4Ar {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				left: 1420px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				left: -125px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				left: -95px;
				border-radius: 50%;
			}
		}
	}
	@media (max-width: 1499px) {
		body.rtl.about-to-services .globalElement2{
			animation: asElem2Ar 3s forwards ease-in-out;
		}
		
		@keyframes asElem2Ar {
			0% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				right: 1410px;
				left: unset;
				top: 30px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1360px;
				top: 60px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.about-to-services .elementLine4{
			animation: asElem4Ar 3s forwards ease-in-out;
		}
		@keyframes asElem4Ar {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				left: 1420px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				left: -125px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				left: -95px;
				border-radius: 50%;
			}
		}
	}
	@media (max-width: 1440px) {
		body.rtl.about-to-services .globalElement2{
			animation: asElem2Ar 3s forwards ease-in-out;
		}
		
		@keyframes asElem2Ar {
			0% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				right: 1330px;
				top: 30px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1300px;
				top: 80px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1300px;
				top: 60px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.about-to-services .elementLine4{
			animation: asElem4Ar 3s forwards ease-in-out;
		}
		@keyframes asElem4Ar {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				left: 1300px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				left: -115px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				left: -95px;
				border-radius: 50%;
			}
		}
	}
	@media (max-width: 1399px) {
		body.rtl.about-to-services .globalElement2{
			animation: asElem2Ar 3s forwards ease-in-out;
		}
		
		@keyframes asElem2Ar {
			0% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				right: 1330px;
				top: 30px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1290px;
				top: 60px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1260px;
				top: 60px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.about-to-services .elementLine4{
			animation: asElem4Ar 3s forwards ease-in-out;
		}
		@keyframes asElem4Ar {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				left: 1300px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				left: -115px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				left: -95px;
				border-radius: 50%;
			}
		}
	}
	@media (max-width: 1350px) {
		body.rtl.about-to-services .globalElement2{
			animation: asElem2Ar 3s forwards ease-in-out;
		}
		
		@keyframes asElem2Ar {
			0% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				right: 1220px;
				top: 30px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1200px;
				top: 80px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1200px;
				top: 60px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.about-to-services .elementLine4{
			animation: asElem4Ar 3s forwards ease-in-out;
		}
		@keyframes asElem4Ar {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				left: 1200px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				left: -115px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				left: -95px;
				border-radius: 50%;
			}
		}
	}
	@media (max-width: 1299px) {
		body.rtl.about-to-services .globalElement2{
			animation: asElem2Ar 3s forwards ease-in-out;
		}
		
		@keyframes asElem2Ar {
			0% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				right: 1220px;
				top: 30px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1150px;
				top: 80px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1150px;
				top: 60px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.about-to-services .elementLine4{
			animation: asElem4Ar 3s forwards ease-in-out;
		}
		@keyframes asElem4Ar {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				left: 1150px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				left: -115px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				left: -95px;
				border-radius: 50%;
			}
		}
	}
	@media (max-width: 1250px) {
		body.rtl.about-to-services .globalElement2{
			animation: asElem2Ar 3s forwards ease-in-out;
		}
		
		@keyframes asElem2Ar {
			0% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				right: 1120px;
				top: 30px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1100px;
				top: 80px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1100px;
				top: 60px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.about-to-services .elementLine4{
			animation: asElem4Ar 3s forwards ease-in-out;
		}
		@keyframes asElem4Ar {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				left: 1100px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				left: -115px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				left: -95px;
				border-radius: 50%;
			}
		}
	}

	/******************* Animate About to Portfolio **********************/

	@media (max-width: 1550px) {
		body.rtl.about-to-portfolio .globalElement2{
			animation: apElem2Ar 3s forwards ease-in-out;
		}
		
		@keyframes apElem2Ar {
			0% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				right: 1420px;
				left: unset;
				top: 30px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1370px;
				top: -20px;
				transform: rotate(135deg);
				
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1370px;
				top: 0px;
				transform: rotate(135deg);
				
			}
		}
		
		body.rtl.about-to-portfolio .elementLine4{
			animation: apElem4Ar 3s forwards ease-in-out;
		}
		@keyframes apElem4Ar {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				left: 1380px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: -10px;
				left: 1380px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				left: 1380px;
				border-radius: 50%;
			}
		}
	}
/* 
	@media (max-width: 1499px) {
		body.rtl.about-to-services .globalElement2{
			animation: asElem2Ar 3s forwards ease-in-out;
		}
		
		@keyframes asElem2Ar {
			0% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				right: 1410px;
				left: unset;
				top: 30px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1320px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1320px;
				top: 0px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.about-to-services .elementLine4{
			animation: asElem4Ar 3s forwards ease-in-out;
		}
		@keyframes asElem4Ar {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				left: 1420px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: -10px;
				left: 1420px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				left: 1420px;
				border-radius: 50%;
			}
		}
	}
	 */
	@media (max-width: 1440px) {
		body.rtl.about-to-portfolio .globalElement2{
			animation: apElem2Ar 3s forwards ease-in-out;
		}
		
		@keyframes apElem2Ar {
			0% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				right: 1330px;
				top: 30px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1320px;
				top: 0px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1320px;
				top: 0px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.about-to-portfolio .elementLine4{
			animation: apElem4Ar 3s forwards ease-in-out;
		}
		@keyframes apElem4Ar {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				left: 1300px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: -10px;
				left: 1350px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				left: 1350px;
				border-radius: 50%;
			}
		}
	}
	@media (max-width: 1399px) {
		body.rtl.about-to-portfolio .globalElement2{
			animation: apElem2Ar 3s forwards ease-in-out;
		}
		
		@keyframes apElem2Ar {
			0% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				right: 1330px;
				top: 30px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1270px;
				top: -10px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1270px;
				top: 0px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.about-to-portfolio .elementLine4{
			animation: apElem4Ar 3s forwards ease-in-out;
		}
		@keyframes apElem4Ar {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				left: 1300px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				left: 1300px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				left: 1300px;
				border-radius: 50%;
			}
		}
	}
	@media (max-width: 1350px) {
		body.rtl.about-to-portfolio .globalElement2{
			animation: apElem2Ar 3s forwards ease-in-out;
		}
		
		@keyframes apElem2Ar {
			0% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				right: 1220px;
				top: 30px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1220px;
				top: -10px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1220px;
				top: 0px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.about-to-portfolio .elementLine4{
			animation: apElem4Ar 3s forwards ease-in-out;
		}
		@keyframes apElem4Ar {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				left: 1200px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: -10px;
				left: 1250px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				left: 1250px;
				border-radius: 50%;
			}
		}
	}
	@media (max-width: 1299px) {
		body.rtl.about-to-portfolio .globalElement2{
			animation: apElem2Ar 3s forwards ease-in-out;
		}
		
		@keyframes apElem2Ar {
			0% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				right: 1220px;
				top: 30px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1170px;
				top: -10px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1170px;
				top: 0px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.about-to-portfolio .elementLine4{
			animation: apElem4Ar 3s forwards ease-in-out;
		}
		@keyframes apElem4Ar {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				left: 1150px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: -10px;
				left: 1200px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				left: 1200px;
				border-radius: 50%;
			}
		}
	}
	@media (max-width: 1250px) {
		body.rtl.about-to-portfolio .globalElement2{
			animation: apElem2Ar 3s forwards ease-in-out;
		}
		
		@keyframes apElem2Ar {
			0% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				right: 1120px;
				top: 30px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1120px;
				top: -10px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1120px;
				top: 0px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.about-to-portfolio .elementLine4{
			animation: apElem4Ar 3s forwards ease-in-out;
		}
		@keyframes apElem4Ar {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				left: 1100px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: -10px;
				left: 1150px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				left: 1150px;
				border-radius: 50%;
			}
		}
	}


	/******************* Animate About to Team **********************/

	@media (max-width: 1550px) {
		body.rtl.about-to-team .globalElement2{
			animation: atElem2Ar 3s forwards ease-in-out;
		}
		
		@keyframes atElem2Ar {
			0% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				right: 1420px;
				left: unset;
				top: 30px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1380px;
				top: 40px;
				transform: rotate(135deg);
				
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1380px;
				top: 40px;
				transform: rotate(135deg);
				
			}
		}
		
		body.rtl.about-to-team .elementLine4{
			animation: atElem4Ar 3s forwards ease-in-out;
		}
		@keyframes atElem4Ar {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				left: 1380px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 450px;
				left: 1350px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				left: 1350px;
				border-radius: 50%;
			}
		}
	}

	@media (max-width: 1440px) {
		body.rtl.about-to-team .globalElement2{
			animation: atElem2Ar 3s forwards ease-in-out;
		}
		
		@keyframes atElem2Ar {
			0% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				right: 1330px;
				top: 30px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1330px;
				top: 40px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1330px;
				top: 40px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.about-to-team .elementLine4{
			animation: atElem4Ar 3s forwards ease-in-out;
		}
		@keyframes atElem4Ar {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				left: 1300px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 450px;
				left: 1300px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				left: 1300px;
				border-radius: 50%;
			}
		}
	}
	@media (max-width: 1399px) {
		body.rtl.about-to-team .globalElement2{
			animation: atElem2Ar 3s forwards ease-in-out;
		}
		
		@keyframes atElem2Ar {
			0% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				right: 1330px;
				top: 30px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1270px;
				top: 50px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1270px;
				top: 40px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.about-to-team .elementLine4{
			animation: atElem4Ar 3s forwards ease-in-out;
		}
		@keyframes atElem4Ar {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				left: 1300px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 450px;
				left: 1250px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				left: 1250px;
				border-radius: 50%;
			}
		}
	}
	@media (max-width: 1350px) {
		body.rtl.about-to-team .globalElement2{
			animation: atElem2Ar 3s forwards ease-in-out;
		}
		
		@keyframes atElem2Ar {
			0% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				right: 1220px;
				top: 30px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1220px;
				top: 50px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1220px;
				top: 40px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.about-to-team .elementLine4{
			animation: atElem4Ar 3s forwards ease-in-out;
		}
		@keyframes atElem4Ar {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				left: 1200px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				left: 1200px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				left: 1200px;
				border-radius: 50%;
			}
		}
	}
	@media (max-width: 1299px) {
		body.rtl.about-to-team .globalElement2{
			animation: atElem2Ar 3s forwards ease-in-out;
		}
		
		@keyframes atElem2Ar {
			0% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				right: 1220px;
				top: 30px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1170px;
				top: 40px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1170px;
				top: 40px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.about-to-team .elementLine4{
			animation: atElem4Ar 3s forwards ease-in-out;
		}
		@keyframes atElem4Ar {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				left: 1150px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				left: 1150px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				left: 1150px;
				border-radius: 50%;
			}
		}
	}
	@media (max-width: 1250px) {
		body.rtl.about-to-team .globalElement2{
			animation: atElem2Ar 3s forwards ease-in-out;
		}
		
		@keyframes atElem2Ar {
			0% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				right: 1120px;
				top: 30px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1120px;
				top: 40px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1120px;
				top: 40px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.about-to-team .elementLine4{
			animation: atElem4Ar 3s forwards ease-in-out;
		}
		@keyframes atElem4Ar {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				left: 1100px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				left: 1100px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				left: 1100px;
				border-radius: 50%;
			}
		}
	}


	/******************* Animate About to Testimonials **********************/

	@media (max-width: 1550px) {
		body.rtl.about-to-testimonials .globalElement2{
			animation: atesElem2Ar 3s forwards ease-in-out;
		}
		
		@keyframes atesElem2Ar {
			0% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				right: 1420px;
				left: unset;
				top: 30px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1380px;
				top: 40px;
				transform: rotate(135deg);
				
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1380px;
				top: 40px;
				transform: rotate(135deg);
				
			}
		}
		
		body.rtl.about-to-testimonials .elementLine4{
			animation: atesElem4Ar 3s forwards ease-in-out;
		}
		@keyframes atesElem4Ar {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				left: 1380px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 450px;
				left: 1350px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				left: 1350px;
				border-radius: 50%;
			}
		}
	}

	@media (max-width: 1440px) {
		body.rtl.about-to-testimonials .globalElement2{
			animation: atesElem2Ar 3s forwards ease-in-out;
		}
		
		@keyframes atesElem2Ar {
			0% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				right: 1330px;
				top: 30px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1330px;
				top: 40px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1330px;
				top: 40px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.about-to-testimonials .elementLine4{
			animation: atesElem4Ar 3s forwards ease-in-out;
		}
		@keyframes atesElem4Ar {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				left: 1300px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 450px;
				left: 1300px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				left: 1300px;
				border-radius: 50%;
			}
		}
	}
	@media (max-width: 1399px) {
		body.rtl.about-to-testimonials .globalElement2{
			animation: atesElem2Ar 3s forwards ease-in-out;
		}
		
		@keyframes atesElem2Ar {
			0% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				right: 1330px;
				top: 30px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1270px;
				top: 50px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1270px;
				top: 40px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.about-to-testimonials .elementLine4{
			animation: atesElem4Ar 3s forwards ease-in-out;
		}
		@keyframes atesElem4Ar {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				left: 1300px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 450px;
				left: 1250px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				left: 1250px;
				border-radius: 50%;
			}
		}
	}
	@media (max-width: 1350px) {
		body.rtl.about-to-testimonials .globalElement2{
			animation: atesElem2Ar 3s forwards ease-in-out;
		}
		
		@keyframes atesElem2Ar {
			0% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				right: 1220px;
				top: 30px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1220px;
				top: 50px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1220px;
				top: 40px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.about-to-testimonials .elementLine4{
			animation: atesElem4Ar 3s forwards ease-in-out;
		}
		@keyframes atesElem4Ar {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				left: 1200px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				left: 1200px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				left: 1200px;
				border-radius: 50%;
			}
		}
	}
	@media (max-width: 1299px) {
		body.rtl.about-to-testimonials .globalElement2{
			animation: atesElem2Ar 3s forwards ease-in-out;
		}
		
		@keyframes atesElem2Ar {
			0% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				right: 1220px;
				top: 30px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1170px;
				top: 40px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1170px;
				top: 40px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.about-to-testimonials .elementLine4{
			animation: atesElem4Ar 3s forwards ease-in-out;
		}
		@keyframes atesElem4Ar {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				left: 1150px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				left: 1150px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				left: 1150px;
				border-radius: 50%;
			}
		}
	}
	@media (max-width: 1250px) {
		body.rtl.about-to-testimonials .globalElement2{
			animation: atesElem2Ar 3s forwards ease-in-out;
		}
		
		@keyframes atesElem2Ar {
			0% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				right: 1120px;
				top: 30px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1120px;
				top: 40px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1120px;
				top: 40px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.about-to-testimonials .elementLine4{
			animation: atesElem4Ar 3s forwards ease-in-out;
		}
		@keyframes atesElem4Ar {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				left: 1100px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				left: 1100px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				left: 1100px;
				border-radius: 50%;
			}
		}
	}

	/******************* Animate About to Contact **********************/

	@media (max-width: 1550px) {
		body.rtl.about-to-contact .globalElement2{
			animation: aCtElem2Ar 3s forwards ease-in-out;
		}
		
		@keyframes aCtElem2Ar {
			0% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				right: 1420px;
				left: unset;
				top: 30px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1370px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1370px;
				top: 0px;
				transform: rotate(135deg);
				
			}
		}
		
		body.rtl.about-to-contact .elementLine4{
			animation: aCtElem4Ar 3s forwards ease-in-out;
		}
		@keyframes aCtElem4Ar {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				left: 1380px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				left: 1400px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				left: 1400px;
				border-radius: 50%;
			}
		}
	}

	@media (max-width: 1440px) {
		body.rtl.about-to-contact .globalElement2{
			animation: aCtElem2Ar 3s forwards ease-in-out;
		}
		
		@keyframes aCtElem2Ar {
			0% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				right: 1330px;
				top: 30px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1320px;
				top: 0px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1320px;
				top: 0px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.about-to-contact .elementLine4{
			animation: aCtElem4Ar 3s forwards ease-in-out;
		}
		@keyframes aCtElem4Ar {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				left: 1300px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				left: 1350px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				left: 1350px;
				border-radius: 50%;
			}
		}
	}
	@media (max-width: 1399px) {
		body.rtl.about-to-contact .globalElement2{
			animation: aCtElem2Ar 3s forwards ease-in-out;
		}
		
		@keyframes aCtElem2Ar {
			0% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				right: 1330px;
				top: 30px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1270px;
				top: 0px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1270px;
				top: 0px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.about-to-contact .elementLine4{
			animation: aCtElem4Ar 3s forwards ease-in-out;
		}
		@keyframes aCtElem4Ar {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				left: 1300px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				left: 1300px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				left: 1300px;
				border-radius: 50%;
			}
		}
	}
	@media (max-width: 1350px) {
		body.rtl.about-to-contact .globalElement2{
			animation: aCtElem2Ar 3s forwards ease-in-out;
		}
		
		@keyframes aCtElem2Ar {
			0% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				right: 1220px;
				top: 30px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1220px;
				top: 0px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1220px;
				top: 0px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.about-to-contact .elementLine4{
			animation: aCtElem4Ar 3s forwards ease-in-out;
		}
		@keyframes aCtElem4Ar {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				left: 1200px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				left: 1250px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				left: 1250px;
				border-radius: 50%;
			}
		}
	}
	@media (max-width: 1299px) {
		body.rtl.about-to-contact .globalElement2{
			animation: aCtElem2Ar 3s forwards ease-in-out;
		}
		
		@keyframes aCtElem2Ar {
			0% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				right: 1220px;
				top: 30px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1170px;
				top: 0px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1170px;
				top: 0px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.about-to-contact .elementLine4{
			animation: aCtElem4Ar 3s forwards ease-in-out;
		}
		@keyframes aCtElem4Ar {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				left: 1150px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				left: 1200px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				left: 1200px;
				border-radius: 50%;
			}
		}
	}
	@media (max-width: 1250px) {
		body.rtl.about-to-contact .globalElement2{
			animation: aCtElem2Ar 3s forwards ease-in-out;
		}
		
		@keyframes aCtElem2Ar {
			0% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				right: 1120px;
				top: 30px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1120px;
				top: 0px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1120px;
				top: 0px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.about-to-contact .elementLine4{
			animation: aCtElem4Ar 3s forwards ease-in-out;
		}
		@keyframes aCtElem4Ar {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				left: 1100px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				left: 1150px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				left: 1150px;
				border-radius: 50%;
			}
		}
	}



/******************* Animate Services To ALL **********************/

	/******************* Animate Services To Home **********************/
	

	@media (max-width: 1550px) {
		
		body.rtl.services-to-home .globalElement2{
			animation: shElem2Ar 3s forwards ease-in-out;
		}
		@keyframes shElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1370px;
				top: 60px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 730px;
				top: 50px;
				transform: rotate(70deg);
			}
			100% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 750px;
				top: 50px;
				transform: rotate(70deg);
				
			}
		}
	
		body.rtl.services-to-home .elementLine4{
			animation: shElem4Ar 3s forwards ease-in-out;
		}
		@keyframes shElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				left: -95px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 432px;
				border-radius: 54px;
			}
			100% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 412px;
				border-radius: 54px;
			}
		}
	}


	@media (max-width: 1499px) {
		
		body.rtl.services-to-home .globalElement2{
			animation: shElem2Ar 3s forwards ease-in-out;
		}
		@keyframes shElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1360px;
				top: 60px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 730px;
				top: 50px;
				transform: rotate(70deg);
			}
			100% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 750px;
				top: 50px;
				transform: rotate(70deg);
				
			}
		}
	
	}

	@media (max-width: 1440px) {
		
		body.rtl.services-to-home .globalElement2{
			animation: shElem2Ar 3s forwards ease-in-out;
		}
		@keyframes shElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1300px;
				top: 60px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 730px;
				top: 50px;
				transform: rotate(70deg);
			}
			100% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 750px;
				top: 50px;
				transform: rotate(70deg);
				
			}
		}

		body.rtl.services-to-home .elementLine4{
			animation: shElem4Ar 3s forwards ease-in-out;
		}
		@keyframes shElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				left: -95px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 412px;
				border-radius: 54px;
			}
			100% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 392px;
				border-radius: 54px;
			}
		}
		
	}

	@media (max-width: 1399px) {
		
		body.rtl.services-to-home .globalElement2{
			animation: shElem2Ar 3s forwards ease-in-out;
		}
		@keyframes shElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1260px;
				top: 60px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 630px;
				top: 50px;
				transform: rotate(70deg);
			}
			100% { 
				
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 650px;
				top: 50px;
				transform: rotate(70deg);
				
			}
		}

		body.rtl.services-to-home .elementLine4{
			animation: shElem4Ar 3s forwards ease-in-out;
		}
		@keyframes shElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				left: -95px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 402px;
				border-radius: 54px;
			}
			100% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 382px;
				border-radius: 54px;
			}
		}

	}

	@media (max-width: 1350px) {
		
		body.rtl.services-to-home .globalElement2{
			animation: shElem2Ar 3s forwards ease-in-out;
		}
		@keyframes shElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1200px;
				top: 60px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 530px;
				top: 50px;
				transform: rotate(70deg);
			}
			100% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 550px;
				top: 50px;
				transform: rotate(70deg);
				
			}
		}

		body.rtl.services-to-home .elementLine4{
			animation: shElem4Ar 3s forwards ease-in-out;
		}
		@keyframes shElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				left: -95px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 388px;
				border-radius: 54px;
			}
			100% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 368px;
				border-radius: 54px;
			}
		}

	}

	@media (max-width: 1299px) {
		
		body.rtl.services-to-home .globalElement2{
			animation: shElem2Ar 3s forwards ease-in-out;
		}
		@keyframes shElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1150px;
				top: 60px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 530px;
				top: 50px;
				transform: rotate(70deg);
			}
			100% { 
				
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 550px;
				top: 50px;
				transform: rotate(70deg);
			}
		}

		body.rtl.services-to-home .elementLine4{
			animation: shElem4Ar 3s forwards ease-in-out;
		}
		@keyframes shElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				left: -95px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 378px;
				border-radius: 54px;
			}
			100% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 358px;
				border-radius: 54px;
			}
		}

	}

	@media (max-width: 1250px) {
		
		body.rtl.services-to-home .globalElement2{
			animation: shElem2Ar 3s forwards ease-in-out;
		}
		@keyframes shElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1100px;
				top: 60px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 530px;
				top: 50px;
				transform: rotate(70deg);
			}
			100% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 550px;
				top: 50px;
				transform: rotate(70deg);
				
			}
		}

		body.rtl.services-to-home .elementLine4{
			animation: shElem4Ar 3s forwards ease-in-out;
		}
		@keyframes shElem4Ar {
			
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				left: -95px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 365px;
				border-radius: 54px;
			}
			100% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 345px;
				border-radius: 54px;
			}
		}

	}

	@media (max-width: 1199px) {
		
		body.rtl.services-to-home .globalElement2{
			animation: shElem2Ar 3s forwards ease-in-out;
		}
		@keyframes shElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1050px;
				top: 60px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 480px;
				top: 50px;
				transform: rotate(70deg);
			}
			100% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 500px;
				top: 50px;
				transform: rotate(70deg);
				
			}
		}

		body.rtl.services-to-home .elementLine4{
			animation: shElem4Ar 3s forwards ease-in-out;
		}
		@keyframes shElem4Ar {
			
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				left: -95px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 355px;
				border-radius: 54px;
			}
			100% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 335px;
				border-radius: 54px;
			}
		}

	}

	@media (max-width: 1150px) {
		
		body.rtl.services-to-home .globalElement2{
			animation: shElem2Ar 3s forwards ease-in-out;
		}
		@keyframes shElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1000px;
				top: 60px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 480px;
				top: 50px;
				transform: rotate(70deg);
			}
			100% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 500px;
				top: 50px;
				transform: rotate(70deg);
				
			}
		}

		body.rtl.services-to-home .elementLine4{
			animation: shElem4Ar 3s forwards ease-in-out;
		}
		@keyframes shElem4Ar {
			
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				left: -95px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 291px;
				left: 345px;
				border-radius: 54px;
			}
			100% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 291px;
				left: 325px;
				border-radius: 54px;
			}
		}

	}
	
	@media (max-width: 1099px) {
		
		body.rtl.services-to-home .globalElement2{
			animation: shElem2Ar 3s forwards ease-in-out;
		}
		@keyframes shElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 950px;
				top: 60px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 430px;
				top: 50px;
				transform: rotate(70deg);
			}
			100% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 450px;
				top: 50px;
				transform: rotate(70deg);
				
			}
		}

		body.rtl.services-to-home .elementLine4{
			animation: shElem4Ar 3s forwards ease-in-out;
		}
		@keyframes shElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				left: -95px;
				border-radius: 50%;
			}
			70% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 291px;
				left: 325px;
				border-radius: 54px;
			}
			100% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 291px;
				left: 315px;
				border-radius: 54px;
				
			}
		}

	}

	@media (max-width: 1050px) {
		
		body.rtl.services-to-home .globalElement2{
			animation: shElem2Ar 3s forwards ease-in-out;
		}
		@keyframes shElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 900px;
				top: 60px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 430px;
				top: 50px;
				transform: rotate(70deg);
			}
			100% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 450px;
				top: 50px;
				transform: rotate(70deg);
				
			}
		}

		body.rtl.services-to-home .elementLine4{
			animation: shElem4Ar 3s forwards ease-in-out;
		}
		@keyframes shElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				left: -95px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 291px;
				left: 320px;
				border-radius: 54px;
			}
			100% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 291px;
				left: 300px;
				border-radius: 54px;
			}
		}

	}

	/******************* Animate Services To About **********************/

	@media (max-width: 1550px) {
		
		body.rtl.services-to-about .globalElement2{
			animation: saElem2Ar 3s forwards ease-in-out;
		}
		@keyframes saElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1420px;
				top: 60px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				right: 1420px;
				left: unset;
				top: 30px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				right: 1420px;
				left: unset;
				top: 30px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.services-to-about .elementLine4{
			animation: saElem4Ar 3s forwards ease-in-out;
		}
		@keyframes saElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				left: -95px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				left: 1380px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				left: 1380px;
				border-radius: 50%;
			}
		}
		
	}

	@media (max-width: 1440px) {
		
		body.rtl.services-to-about .globalElement2{
			animation: saElem2Ar 3s forwards ease-in-out;
		}
		@keyframes saElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1300px;
				top: 60px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				right: 1330px;
				top: 30px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				right: 1330px;
				top: 30px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.services-to-about .elementLine4{
			animation: saElem4Ar 3s forwards ease-in-out;
		}
		@keyframes saElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				left: -95px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				left: 1300px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				left: 1300px;
				border-radius: 50%;
			}
		}
		
	}

	@media (max-width: 1399px) {
		
		body.rtl.services-to-about .globalElement2{
			animation: saElem2Ar 3s forwards ease-in-out;
		}
		@keyframes saElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1260px;
				top: 60px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				right: 1330px;
				top: 30px;
				transform: rotate(135deg);
			}
			100% { 
				
				border-radius: 55px;
				width: 150px;
				height: 150px;
				right: 1330px;
				top: 30px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.services-to-about .elementLine4{
			animation: saElem4Ar 3s forwards ease-in-out;
		}
		@keyframes saElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				left: -95px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				left: 1300px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				left: 1300px;
				border-radius: 50%;
			}
		}

	}

	@media (max-width: 1350px) {
		
		body.rtl.services-to-about .globalElement2{
			animation: saElem2Ar 3s forwards ease-in-out;
		}
		@keyframes saElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1200px;
				top: 60px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 530px;
				top: 50px;
				transform: rotate(70deg);
			}
			100% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				right: 1220px;
				top: 30px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.services-to-about .elementLine4{
			animation: saElem4Ar 3s forwards ease-in-out;
		}
		@keyframes saElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				left: -95px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				left: 1200px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				left: 1200px;
				border-radius: 50%;
			}
		}

	}

	@media (max-width: 1299px) {
		
		body.rtl.services-to-about .globalElement2{
			animation: saElem2Ar 3s forwards ease-in-out;
		}
		@keyframes saElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1150px;
				top: 60px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				right: 1220px;
				top: 30px;
				transform: rotate(135deg);
			}
			100% { 
				
				border-radius: 55px;
				width: 150px;
				height: 150px;
				right: 1220px;
				top: 30px;
				transform: rotate(135deg);
			}
		}

		body.rtl.services-to-about .elementLine4{
			animation: saElem4Ar 3s forwards ease-in-out;
		}
		@keyframes saElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				left: -95px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				left: 1150px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				left: 1150px;
				border-radius: 50%;
			}
		}

	}

	@media (max-width: 1250px) {
		
		body.rtl.services-to-about .globalElement2{
			animation: saElem2Ar 3s forwards ease-in-out;
		}
		@keyframes saElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1100px;
				top: 60px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				right: 1120px;
				top: 30px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				right: 1120px;
				top: 30px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.services-to-about .elementLine4{
			animation: saElem4Ar 3s forwards ease-in-out;
		}
		@keyframes saElem4Ar {
			
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				left: -95px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				left: 1100px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				left: 1100px;
				border-radius: 50%;
			}
		}

	}


	/******************* Animate Services To Portfolio **********************/
		

	@media (max-width: 1550px) {
			
		body.rtl.services-to-portfolio .globalElement2{
			animation: spElem2Ar 3s forwards ease-in-out;
		}
		@keyframes spElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1370px;
				top: 60px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1370px;
				top: 0px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1370px;
				top: 0px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.services-to-portfolio .elementLine4{
			animation: spElem4Ar 3s forwards ease-in-out;
		}
		@keyframes spElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				left: -95px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				left: 1400px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				left: 1400px;
				border-radius: 50%;
			}
		}
	}

	@media (max-width: 1440px) {
		
		body.rtl.services-to-portfolio .globalElement2{
			animation: spElem2Ar 3s forwards ease-in-out;
		}
		@keyframes spElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1300px;
				top: 60px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1320px;
				top: 0px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1320px;
				top: 0px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.services-to-portfolio .elementLine4{
			animation: spElem4Ar 3s forwards ease-in-out;
		}
		@keyframes spElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				left: -95px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				left: 1350px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				left: 1350px;
				border-radius: 50%;
			}
		}
		
	}

	@media (max-width: 1399px) {
		
		body.rtl.services-to-portfolio .globalElement2{
			animation: spElem2Ar 3s forwards ease-in-out;
		}
		@keyframes spElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1260px;
				top: 60px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1270px;
				top: 0px;
				transform: rotate(135deg);
			}
			100% { 
				
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1270px;
				top: 0px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.services-to-portfolio .elementLine4{
			animation: spElem4Ar 3s forwards ease-in-out;
		}
		@keyframes spElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				left: -95px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				left: 1300px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				left: 1300px;
				border-radius: 50%;
			}
		}

	}

	@media (max-width: 1350px) {
		
		body.rtl.services-to-portfolio .globalElement2{
			animation: spElem2Ar 3s forwards ease-in-out;
		}
		@keyframes spElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1200px;
				top: 60px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1220px;
				top: 0px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1220px;
				top: 0px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.services-to-portfolio .elementLine4{
			animation: spElem4Ar 3s forwards ease-in-out;
		}
		@keyframes spElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				left: -95px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				left: 1250px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				left: 1250px;
				border-radius: 50%;
			}
		}

	}

	@media (max-width: 1299px) {
		
		body.rtl.services-to-portfolio .globalElement2{
			animation: spElem2Ar 3s forwards ease-in-out;
		}
		@keyframes spElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1150px;
				top: 60px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1170px;
				top: 0px;
				transform: rotate(135deg);
			}
			100% { 
				
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1170px;
				top: 0px;
				transform: rotate(135deg);
			}
		}

		body.rtl.services-to-portfolio .elementLine4{
			animation: spElem4Ar 3s forwards ease-in-out;
		}
		@keyframes spElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				left: -95px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				left: 1200px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				left: 1200px;
				border-radius: 50%;
			}
		}

	}

	@media (max-width: 1250px) {
		
		body.rtl.services-to-portfolio .globalElement2{
			animation: spElem2Ar 3s forwards ease-in-out;
		}
		@keyframes spElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1100px;
				top: 60px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1120px;
				top: 0px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1120px;
				top: 0px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.services-to-portfolio .elementLine4{
			animation: spElem4Ar 3s forwards ease-in-out;
		}
		@keyframes spElem4Ar {
			
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				left: -95px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				left: 1150px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				left: 1150px;
				border-radius: 50%;
			}
		}

	}


	/******************* Animate Services To Team **********************/
	

	@media (max-width: 1550px) {
		
		body.rtl.services-to-team .globalElement2{
			animation: stElem2Ar 3s forwards ease-in-out;
		}
		@keyframes stElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1370px;
				top: 60px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1380px;
				top: 40px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1380px;
				top: 40px;
				transform: rotate(135deg);
				
			}
		}
	
		body.rtl.services-to-team .elementLine4{
			animation: stElem4Ar 3s forwards ease-in-out;
		}
		@keyframes stElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				left: -95px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				left: 1380px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				left: 1350px;
				border-radius: 50%;
			}
		}
	}

	/* 
	@media (max-width: 1499px) {
		
		body.rtl.services-to-team .globalElement2{
			animation: stElem2Ar 3s forwards ease-in-out;
		}
		@keyframes stElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1360px;
				top: 60px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 730px;
				top: 50px;
				transform: rotate(70deg);
			}
			100% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 750px;
				top: 50px;
				transform: rotate(70deg);
				
			}
		}
	
	}
 	*/
	@media (max-width: 1440px) {
		
		body.rtl.services-to-team .globalElement2{
			animation: stElem2Ar 3s forwards ease-in-out;
		}
		@keyframes stElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1300px;
				top: 60px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1360px;
				top: 40px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1330px;
				top: 40px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.services-to-team .elementLine4{
			animation: stElem4Ar 3s forwards ease-in-out;
		}
		@keyframes stElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				left: -95px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				left: 1330px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				left: 1300px;
				border-radius: 50%;
			}
		}
		
	}

	@media (max-width: 1399px) {
		
		body.rtl.services-to-team .globalElement2{
			animation: stElem2Ar 3s forwards ease-in-out;
		}
		@keyframes stElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1260px;
				top: 60px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1300px;
				top: 40px;
				transform: rotate(135deg);
			}
			100% { 
				
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1270px;
				top: 40px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.services-to-team .elementLine4{
			animation: stElem4Ar 3s forwards ease-in-out;
		}
		@keyframes stElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				left: -95px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				left: 1280px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				left: 1250px;
				border-radius: 50%;
			}
		}

	}

	@media (max-width: 1350px) {
		
		body.rtl.services-to-team .globalElement2{
			animation: stElem2Ar 3s forwards ease-in-out;
		}
		@keyframes stElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1200px;
				top: 60px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1250px;
				top: 40px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1220px;
				top: 40px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.services-to-team .elementLine4{
			animation: stElem4Ar 3s forwards ease-in-out;
		}
		@keyframes stElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				left: -95px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				left: 1230px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				left: 1200px;
				border-radius: 50%;
			}
		}

	}

	@media (max-width: 1299px) {
		
		body.rtl.services-to-team .globalElement2{
			animation: stElem2Ar 3s forwards ease-in-out;
		}
		@keyframes stElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1150px;
				top: 60px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1200px;
				top: 40px;
				transform: rotate(135deg);
			}
			100% { 
				
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1170px;
				top: 40px;
				transform: rotate(135deg);
			}
		}

		body.rtl.services-to-team .elementLine4{
			animation: stElem4Ar 3s forwards ease-in-out;
		}
		@keyframes stElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				left: -95px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				left: 1180px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				left: 1150px;
				border-radius: 50%;
			}
		}

	}

	@media (max-width: 1250px) {
		
		body.rtl.services-to-team .globalElement2{
			animation: stElem2Ar 3s forwards ease-in-out;
		}
		@keyframes shElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1100px;
				top: 60px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1150px;
				top: 40px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1120px;
				top: 40px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.services-to-team .elementLine4{
			animation: stElem4Ar 3s forwards ease-in-out;
		}
		@keyframes stElem4Ar {
			
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				left: -95px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				left: 1130px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				left: 1100px;
				border-radius: 50%;
			}
		}

	}


	/******************* Animate Services To Testimonials **********************/
	

	@media (max-width: 1550px) {
		
		body.rtl.services-to-testimonials .globalElement2{
			animation: stesElem2Ar 3s forwards ease-in-out;
		}
		@keyframes stesElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1370px;
				top: 60px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1380px;
				top: 40px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1380px;
				top: 40px;
				transform: rotate(135deg);
				
			}
		}
	
		body.rtl.services-to-testimonials .elementLine4{
			animation: stesElem4Ar 3s forwards ease-in-out;
		}
		@keyframes stesElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				left: -95px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				left: 1380px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				left: 1350px;
				border-radius: 50%;
			}
		}
	}

	
	@media (max-width: 1440px) {
		
		body.rtl.services-to-testimonials .globalElement2{
			animation: stesElem2Ar 3s forwards ease-in-out;
		}
		@keyframes stesElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1300px;
				top: 60px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1360px;
				top: 40px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1330px;
				top: 40px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.services-to-testimonials .elementLine4{
			animation: stesElem4Ar 3s forwards ease-in-out;
		}
		@keyframes stesElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				left: -95px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				left: 1330px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				left: 1300px;
				border-radius: 50%;
			}
		}
		
	}

	@media (max-width: 1399px) {
		
		body.rtl.services-to-testimonials .globalElement2{
			animation: stesElem2Ar 3s forwards ease-in-out;
		}
		@keyframes stesElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1260px;
				top: 60px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1300px;
				top: 40px;
				transform: rotate(135deg);
			}
			100% { 
				
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1270px;
				top: 40px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.services-to-testimonials .elementLine4{
			animation: stesElem4Ar 3s forwards ease-in-out;
		}
		@keyframes stesElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				left: -95px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				left: 1280px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				left: 1250px;
				border-radius: 50%;
			}
		}

	}

	@media (max-width: 1350px) {
		
		body.rtl.services-to-testimonials .globalElement2{
			animation: stesElem2Ar 3s forwards ease-in-out;
		}
		@keyframes stesElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1200px;
				top: 60px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1250px;
				top: 40px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1220px;
				top: 40px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.services-to-testimonials .elementLine4{
			animation: stesElem4Ar 3s forwards ease-in-out;
		}
		@keyframes stesElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				left: -95px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				left: 1230px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				left: 1200px;
				border-radius: 50%;
			}
		}

	}

	@media (max-width: 1299px) {
		
		body.rtl.services-to-testimonials .globalElement2{
			animation: stesElem2Ar 3s forwards ease-in-out;
		}
		@keyframes stesElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1150px;
				top: 60px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1200px;
				top: 40px;
				transform: rotate(135deg);
			}
			100% { 
				
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1170px;
				top: 40px;
				transform: rotate(135deg);
			}
		}

		body.rtl.services-to-testimonials .elementLine4{
			animation: stesElem4Ar 3s forwards ease-in-out;
		}
		@keyframes stesElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				left: -95px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				left: 1180px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				left: 1150px;
				border-radius: 50%;
			}
		}

	}

	@media (max-width: 1250px) {
		
		body.rtl.services-to-testimonials .globalElement2{
			animation: stesElem2Ar 3s forwards ease-in-out;
		}
		@keyframes stesElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1100px;
				top: 60px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1150px;
				top: 40px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1120px;
				top: 40px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.services-to-testimonials .elementLine4{
			animation: stesElem4Ar 3s forwards ease-in-out;
		}
		@keyframes stesElem4Ar {
			
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				left: -95px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				left: 1130px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				left: 1100px;
				border-radius: 50%;
			}
		}

	}


	/******************* Animate Services To Clients **********************/
	/* 
	@media (max-width: 1550px) {
		
		body.rtl.services-to-clients .globalElement2{
			animation: scElem2Ar 3s forwards ease-in-out;
		}
		@keyframes scElem2Ar {
			0% { 
				
				
			}
			70% { 
				border-radius: 50%;
				width: 250px;
				height: 250px;
				right: 575px;
				top: 495px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 50%;
				width: 250px;
				height: 250px;
				right: 575px;
				top: 495px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.services-to-clients .globalElement4{
			animation: scGlobElem4Ar 3s forwards ease-in-out;
		}
		@keyframes scGlobElem4Ar {
			0% { 
				
				
			}
			70% { 
				border-radius: 21px;
				width: 48px;
				height: 48px;
				left: 800px;
				top: 430px;
				transform: rotate(-60deg);
				z-index: -1;
			}
			100% { 
				border-radius: 21px;
				width: 48px;
				height: 48px;
				left: 800px;
				top: 430px;
				transform: rotate(-60deg);
				z-index: -1;
			}
		}

		body.rtl.services-to-clients .globalElement5{
			animation: scGlobElem5Ar 3s forwards ease-in-out;
		}
		@keyframes scGlobElem5Ar {
			0% { 
				
				
			}
			70% { 
				border-radius: 18px;
				width: 40px;
				height: 40px;
				left: 65px;
				top: 80px;
				transform: rotate(60deg);
			}
			100% { 
				border-radius: 18px;
				width: 40px;
				height: 40px;
				left: 65px;
				top: 80px;
				transform: rotate(60deg);
			}
		}

		body.rtl.services-to-clients .elementLine4{
			animation: scElem4Ar 3s forwards ease-in-out;
		}
		@keyframes scElem4Ar {
			0% { 
				
				
			}
			70% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 470px;
				left: 600px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 470px;
				left: 600px;
				border-radius: 50%;
			}
		}
		
	}

	@media (max-width: 1499px) {
		
		body.rtl.services-to-clients .globalElement2{
			animation: scElem2Ar 3s forwards ease-in-out;
		}
		@keyframes scElem2Ar {
			0% { 
				
				
			}
			70% { 
				border-radius: 50%;
				width: 250px;
				height: 250px;
				right: 565px;
				top: 495px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 50%;
				width: 250px;
				height: 250px;
				right: 565px;
				top: 495px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.services-to-clients .globalElement4{
			animation: scGlobElem4Ar 3s forwards ease-in-out;
		}
		@keyframes scGlobElem4Ar {
			0% { 
				
				
			}
			70% { 
				border-radius: 21px;
				width: 48px;
				height: 48px;
				left: 800px;
				top: 430px;
				transform: rotate(-60deg);
				z-index: -1;
			}
			100% { 
				border-radius: 21px;
				width: 48px;
				height: 48px;
				left: 800px;
				top: 430px;
				transform: rotate(-60deg);
				z-index: -1;
			}
		}
		
	}

	
	@media (max-width: 1440px) {
		
		body.rtl.services-to-clients .globalElement2{
			animation: scElem2Ar 3s forwards ease-in-out;
		}
		@keyframes scElem2Ar {
			0% { 
				
				
			}
			70% { 
				border-radius: 50%;
				width: 250px;
				height: 250px;
				right: 505px;
				top: 495px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 50%;
				width: 250px;
				height: 250px;
				right: 505px;
				top: 495px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.services-to-clients .globalElement4{
			animation: scGlobElem4Ar 3s forwards ease-in-out;
		}
		@keyframes scGlobElem4Ar {
			0% { 
				
				
			}
			70% { 
				border-radius: 21px;
				width: 48px;
				height: 48px;
				left: 800px;
				top: 430px;
				transform: rotate(-60deg);
				z-index: -1;
			}
			100% { 
				border-radius: 21px;
				width: 48px;
				height: 48px;
				left: 800px;
				top: 430px;
				transform: rotate(-60deg);
				z-index: -1;
			}
		}

		body.rtl.services-to-clients .globalElement5{
			animation: scGlobElem5Ar 3s forwards ease-in-out;
		}
		@keyframes scGlobElem5Ar {
			0% { 
				
				
			}
			70% { 
				border-radius: 18px;
				width: 40px;
				height: 40px;
				left: 50px;
				top: 80px;
				transform: rotate(60deg);
			}
			100% { 
				border-radius: 18px;
				width: 40px;
				height: 40px;
				left: 50px;
				top: 80px;
				transform: rotate(60deg);
			}
		}

		body.rtl.services-to-clients .elementLine4{
			animation: scElem4Ar 3s forwards ease-in-out;
		}
		@keyframes scElem4Ar {
			0% { 
				
				
			}
			70% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 470px;
				left: 600px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 470px;
				left: 600px;
				border-radius: 50%;
			}
		}
		
	}

	@media (max-width: 1399px) {
		
		body.rtl.services-to-clients .globalElement2{
			animation: scElem2Ar 3s forwards ease-in-out;
		}
		@keyframes scElem2Ar {
			0% { 
				
				
			}
			70% { 
				border-radius: 50%;
				width: 250px;
				height: 250px;
				right: 465px;
				top: 495px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 50%;
				width: 250px;
				height: 250px;
				right: 465px;
				top: 495px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.services-to-clients .globalElement4{
			animation: scGlobElem4Ar 3s forwards ease-in-out;
		}
		@keyframes scGlobElem4Ar {
			0% { 
				
				
			}
			70% { 
				border-radius: 21px;
				width: 48px;
				height: 48px;
				left: 800px;
				top: 430px;
				transform: rotate(-60deg);
				z-index: -1;
			}
			100% { 
				border-radius: 21px;
				width: 48px;
				height: 48px;
				left: 800px;
				top: 430px;
				transform: rotate(-60deg);
				z-index: -1;
			}
		}

		body.rtl.services-to-clients .globalElement5{
			animation: scGlobElem5Ar 3s forwards ease-in-out;
		}
		@keyframes scGlobElem5Ar {
			0% { 
				
				
			}
			70% { 
				border-radius: 18px;
				width: 40px;
				height: 40px;
				left: 40px;
				top: 80px;
				transform: rotate(60deg);
			}
			100% { 
				border-radius: 18px;
				width: 40px;
				height: 40px;
				left: 40px;
				top: 80px;
				transform: rotate(60deg);
			}
		}

		body.rtl.services-to-clients .elementLine4{
			animation: scElem4Ar 3s forwards ease-in-out;
		}
		@keyframes scElem4Ar {
			0% { 
				
				
			}
			70% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 470px;
				left: 600px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 470px;
				left: 600px;
				border-radius: 50%;
			}
		}
		
	}
	
	@media (max-width: 1350px) {
		
		body.rtl.services-to-clients .globalElement2{
			animation: scElem2Ar 3s forwards ease-in-out;
		}
		@keyframes scElem2Ar {
			0% { 
				
				
			}
			70% { 
				border-radius: 50%;
				width: 250px;
				height: 250px;
				right: 405px;
				top: 495px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 50%;
				width: 250px;
				height: 250px;
				right: 405px;
				top: 495px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.services-to-clients .globalElement4{
			animation: scGlobElem4Ar 3s forwards ease-in-out;
		}
		@keyframes scGlobElem4Ar {
			0% { 
				
				
			}
			70% { 
				border-radius: 21px;
				width: 48px;
				height: 48px;
				left: 800px;
				top: 430px;
				transform: rotate(-60deg);
				z-index: -1;
			}
			100% { 
				border-radius: 21px;
				width: 48px;
				height: 48px;
				left: 800px;
				top: 430px;
				transform: rotate(-60deg);
				z-index: -1;
			}
		}

		body.rtl.services-to-clients .globalElement5{
			animation: scGlobElem5Ar 3s forwards ease-in-out;
		}
		@keyframes scGlobElem5Ar {
			0% { 
				
				
			}
			70% { 
				border-radius: 18px;
				width: 40px;
				height: 40px;
				left: 25px;
				top: 80px;
				transform: rotate(60deg);
			}
			100% { 
				border-radius: 18px;
				width: 40px;
				height: 40px;
				left: 25px;
				top: 80px;
				transform: rotate(60deg);
			}
		}

		body.rtl.services-to-clients .elementLine4{
			animation: scElem4Ar 3s forwards ease-in-out;
		}
		@keyframes scElem4Ar {
			0% { 
				
				
			}
			70% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 470px;
				left: 600px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 470px;
				left: 600px;
				border-radius: 50%;
			}
		}
		
	}

	@media (max-width: 1299px) {
		
		body.rtl.services-to-clients .globalElement2{
			animation: scElem2Ar 3s forwards ease-in-out;
		}
		@keyframes scElem2Ar {
			0% { 
				
				
			}
			70% { 
				border-radius: 50%;
				width: 250px;
				height: 250px;
				right: 505px;
				top: 495px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 50%;
				width: 250px;
				height: 250px;
				right: 505px;
				top: 495px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.services-to-clients .globalElement4{
			animation: scGlobElem4Ar 3s forwards ease-in-out;
		}
		@keyframes scGlobElem4Ar {
			0% { 
				
				
			}
			70% { 
				border-radius: 21px;
				width: 48px;
				height: 48px;
				left: 650px;
				top: 430px;
				transform: rotate(-60deg);
				z-index: -1;
			}
			100% { 
				border-radius: 21px;
				width: 48px;
				height: 48px;
				left: 650px;
				top: 430px;
				transform: rotate(-60deg);
				z-index: -1;
			}
		}

		body.rtl.services-to-clients .globalElement5{
			animation: scGlobElem5Ar 3s forwards ease-in-out;
		}
		@keyframes scGlobElem5Ar {
			0% { 
				
				
			}
			70% { 
				border-radius: 18px;
				width: 40px;
				height: 40px;
				left: 65px;
				top: 80px;
				transform: rotate(60deg);
			}
			100% { 
				border-radius: 18px;
				width: 40px;
				height: 40px;
				left: 65px;
				top: 80px;
				transform: rotate(60deg);
			}
		}

		body.rtl.services-to-clients .elementLine4{
			animation: scElem4Ar 3s forwards ease-in-out;
		}
		@keyframes scElem4Ar {
			0% { 
				
				
			}
			70% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 470px;
				left: 450px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 470px;
				left: 450px;
				border-radius: 50%;
			}
		}
		
	}

	@media (max-width: 1250px) {
		
		body.rtl.services-to-clients .globalElement2{
			animation: scElem2Ar 3s forwards ease-in-out;
		}
		@keyframes scElem2Ar {
			0% { 
				
				
			}
			70% { 
				border-radius: 50%;
				width: 250px;
				height: 250px;
				right: 505px;
				top: 495px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 50%;
				width: 250px;
				height: 250px;
				right: 505px;
				top: 495px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.services-to-clients .globalElement4{
			animation: scGlobElem4Ar 3s forwards ease-in-out;
		}
		@keyframes scGlobElem4Ar {
			0% { 
				
				
			}
			70% { 
				border-radius: 21px;
				width: 48px;
				height: 48px;
				left: 610px;
				top: 430px;
				transform: rotate(-60deg);
				z-index: -1;
			}
			100% { 
				border-radius: 21px;
				width: 48px;
				height: 48px;
				left: 610px;
				top: 430px;
				transform: rotate(-60deg);
				z-index: -1;
			}
		}

		body.rtl.services-to-clients .globalElement5{
			animation: scGlobElem5Ar 3s forwards ease-in-out;
		}
		@keyframes scGlobElem5Ar {
			0% { 
				
			}
			70% { 
				border-radius: 18px;
				width: 40px;
				height: 40px;
				left: 49px;
				top: 30px;
				transform: rotate(60deg);
			}
			100% { 
				border-radius: 18px;
				width: 40px;
				height: 40px;
				left: 49px;
				top: 30px;
				transform: rotate(60deg);
			}
		}

		body.rtl.services-to-clients .elementLine4{
			animation: scElem4Ar 3s forwards ease-in-out;
		}
		@keyframes scElem4Ar {
			0% { 
				
				
			}
			70% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 470px;
				left: 400px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 470px;
				left: 400px;
				border-radius: 50%;
			}
		}
		
	}
 	*/


	/******************* Animate Services To Contact **********************/
	

	@media (max-width: 1550px) {
		
		body.rtl.services-to-contact .globalElement2{
			animation: sCtElem2Ar 3s forwards ease-in-out;
		}
		@keyframes sCtElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1370px;
				top: 60px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1370px;
				top: 0px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1370px;
				top: 0px;
				transform: rotate(135deg);
				
			}
		}
	
		body.rtl.services-to-contact .elementLine4{
			animation: sCtElem4Ar 3s forwards ease-in-out;
		}
		@keyframes sCtElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				left: -95px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				left: 1400px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				left: 1400px;
				border-radius: 50%;
			}
		}
	}

	
	@media (max-width: 1440px) {
		
		body.rtl.services-to-contact .globalElement2{
			animation: sCtElem2Ar 3s forwards ease-in-out;
		}
		@keyframes sCtElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1300px;
				top: 60px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1320px;
				top: 0px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1320px;
				top: 0px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.services-to-contact .elementLine4{
			animation: sCtElem4Ar 3s forwards ease-in-out;
		}
		@keyframes sCtElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				left: -95px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				left: 1350px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				left: 1350px;
				border-radius: 50%;
			}
		}
		
	}

	@media (max-width: 1399px) {
		
		body.rtl.services-to-contact .globalElement2{
			animation: sCtElem2Ar 3s forwards ease-in-out;
		}
		@keyframes sCtElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1260px;
				top: 60px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1270px;
				top: 0px;
				transform: rotate(135deg);
			}
			100% { 
				
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1270px;
				top: 0px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.services-to-contact .elementLine4{
			animation: sCtElem4Ar 3s forwards ease-in-out;
		}
		@keyframes sCtElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				left: -95px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				left: 1330px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				left: 1300px;
				border-radius: 50%;
			}
		}

	}

	@media (max-width: 1350px) {
		
		body.rtl.services-to-contact .globalElement2{
			animation: sCtElem2Ar 3s forwards ease-in-out;
		}
		@keyframes sCtElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1200px;
				top: 60px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1220px;
				top: 0px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1220px;
				top: 0px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.services-to-contact .elementLine4{
			animation: sCtElem4Ar 3s forwards ease-in-out;
		}
		@keyframes sCtElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				left: -95px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				left: 1250px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				left: 1250px;
				border-radius: 50%;
			}
		}

	}

	@media (max-width: 1299px) {
		
		body.rtl.services-to-contact .globalElement2{
			animation: sCtElem2Ar 3s forwards ease-in-out;
		}
		@keyframes sCtElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1150px;
				top: 60px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1170px;
				top: 0px;
				transform: rotate(135deg);
			}
			100% { 
				
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1170px;
				top: 0px;
				transform: rotate(135deg);
			}
		}

		body.rtl.services-to-contact .elementLine4{
			animation: sCtElem4Ar 3s forwards ease-in-out;
		}
		@keyframes sCtElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				left: -95px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				left: 1230px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				left: 1200px;
				border-radius: 50%;
			}
		}

	}

	@media (max-width: 1250px) {
		
		body.rtl.services-to-contact .globalElement2{
			animation: sCtElem2Ar 3s forwards ease-in-out;
		}
		@keyframes sCtElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1100px;
				top: 60px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1120px;
				top: 0px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1120px;
				top: 0px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.services-to-contact .elementLine4{
			animation: sCtElem4Ar 3s forwards ease-in-out;
		}
		@keyframes sCtElem4Ar {
			
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				left: -95px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				left: 1180px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				left: 1150px;
				border-radius: 50%;
			}
		}

	}


	/******************* Animate Services To FAQs **********************/
	

	@media (max-width: 1550px) {
		
		body.rtl.services-to-faqs .globalElement2{
			animation: sfElem2Ar 3s forwards ease-in-out;
		}
		@keyframes sfElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1370px;
				top: 60px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1370px;
				top: 0px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1370px;
				top: 0px;
				transform: rotate(135deg);
				
			}
		}
	
		body.rtl.services-to-faqs .elementLine4{
			animation: sfElem4Ar 3s forwards ease-in-out;
		}
		@keyframes sfElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				left: -95px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				left: 1400px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				left: 1400px;
				border-radius: 50%;
			}
		}
	}

	
	@media (max-width: 1440px) {
		
		body.rtl.services-to-faqs .globalElement2{
			animation: sfElem2Ar 3s forwards ease-in-out;
		}
		@keyframes sfElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1300px;
				top: 60px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1320px;
				top: 0px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1320px;
				top: 0px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.services-to-faqs .elementLine4{
			animation: sfElem4Ar 3s forwards ease-in-out;
		}
		@keyframes sfElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				left: -95px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				left: 1350px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				left: 1350px;
				border-radius: 50%;
			}
		}
		
	}

	@media (max-width: 1399px) {
		
		body.rtl.services-to-faqs .globalElement2{
			animation: sfElem2Ar 3s forwards ease-in-out;
		}
		@keyframes sfElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1260px;
				top: 60px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1270px;
				top: 0px;
				transform: rotate(135deg);
			}
			100% { 
				
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1270px;
				top: 0px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.services-to-faqs .elementLine4{
			animation: sfElem4Ar 3s forwards ease-in-out;
		}
		@keyframes sfElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				left: -95px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				left: 1330px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				left: 1300px;
				border-radius: 50%;
			}
		}

	}

	@media (max-width: 1350px) {
		
		body.rtl.services-to-faqs .globalElement2{
			animation: sfElem2Ar 3s forwards ease-in-out;
		}
		@keyframes sfElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1200px;
				top: 60px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1220px;
				top: 0px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1220px;
				top: 0px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.services-to-faqs .elementLine4{
			animation: sfElem4Ar 3s forwards ease-in-out;
		}
		@keyframes sfElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				left: -95px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				left: 1250px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				left: 1250px;
				border-radius: 50%;
			}
		}

	}

	@media (max-width: 1299px) {
		
		body.rtl.services-to-faqs .globalElement2{
			animation: sfElem2Ar 3s forwards ease-in-out;
		}
		@keyframes sfElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1150px;
				top: 60px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1170px;
				top: 0px;
				transform: rotate(135deg);
			}
			100% { 
				
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1170px;
				top: 0px;
				transform: rotate(135deg);
			}
		}

		body.rtl.services-to-faqs .elementLine4{
			animation: sfElem4Ar 3s forwards ease-in-out;
		}
		@keyframes sfElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				left: -95px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				left: 1230px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				left: 1200px;
				border-radius: 50%;
			}
		}

	}

	@media (max-width: 1250px) {
		
		body.rtl.services-to-faqs .globalElement2{
			animation: sfElem2Ar 3s forwards ease-in-out;
		}
		@keyframes sfElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1100px;
				top: 60px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1120px;
				top: 0px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1120px;
				top: 0px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.services-to-faqs .elementLine4{
			animation: sfElem4Ar 3s forwards ease-in-out;
		}
		@keyframes sfElem4Ar {
			
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				left: -95px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				left: 1180px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				left: 1150px;
				border-radius: 50%;
			}
		}

	}




/******************* Animate Portfolio To ALL **********************/

	/******************* Animate Portfolio To Home **********************/

		
	@media (max-width: 1550px) {
		body.rtl.portfolio-to-home .globalElement2{
			animation: phElem2Ar 3s forwards ease-in-out;
		}
		@keyframes phElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1370px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 730px;
				top: 50px;
				transform: rotate(70deg);
			}
			100% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 750px;
				top: 50px;
				transform: rotate(70deg);
			}
		}

		body.rtl.portfolio-to-home .elementLine4{
			animation: phElem4Ar 3s forwards ease-in-out;
		}
		@keyframes phElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				left: 1400px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 392px;
				border-radius: 54px;
			}
			100% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 412px;
				border-radius: 54px;
			}
		}
	}
	
	@media (max-width: 1440px) {
		body.rtl.portfolio-to-home .globalElement2{
			animation: phElem2Ar 3s forwards ease-in-out;
		}
		@keyframes phElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1320px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 730px;
				top: 50px;
				transform: rotate(70deg);
			}
			100% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 750px;
				top: 50px;
				transform: rotate(70deg);
				
			}
		}

		body.rtl.portfolio-to-home .elementLine4{
			animation: phElem4Ar 3s forwards ease-in-out;
		}
		@keyframes phElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				left: 1350px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 372px;
				border-radius: 54px;
			}
			100% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 392px;
				border-radius: 54px;
			}
		}
	}
	
	@media (max-width: 1399px) {
		body.rtl.portfolio-to-home .globalElement2{
			animation: phElem2Ar 3s forwards ease-in-out;
		}
		@keyframes phElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1270px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 630px;
				top: 50px;
				transform: rotate(70deg);
			}
			100% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 650px;
				top: 50px;
				transform: rotate(70deg);
				
			}
		}

		body.rtl.portfolio-to-home .elementLine4{
			animation: phElem4Ar 3s forwards ease-in-out;
		}
		@keyframes phElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				left: 1300px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 362px;
				border-radius: 54px;
			}
			100% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 382px;
				border-radius: 54px;
			}
		}
	}

	@media (max-width: 1350px) {
		body.rtl.portfolio-to-home .globalElement2{
			animation: phElem2Ar 3s forwards ease-in-out;
		}
		@keyframes phElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1220px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 530px;
				top: 50px;
				transform: rotate(70deg);
			}
			100% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 550px;
				top: 50px;
				transform: rotate(70deg);
				
			}
		}

		body.rtl.portfolio-to-home .elementLine4{
			animation: phElem4Ar 3s forwards ease-in-out;
		}
		@keyframes phElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				left: 1250px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 348px;
				border-radius: 54px;
			}
			100% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 368px;
				border-radius: 54px;
			}
		}
	}
	
	@media (max-width: 1299px) {
		body.rtl.portfolio-to-home .globalElement2{
			animation: phElem2Ar 3s forwards ease-in-out;
		}
		@keyframes phElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1170px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 530px;
				top: 50px;
				transform: rotate(70deg);
			}
			100% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 550px;
				top: 50px;
				transform: rotate(70deg);
				
			}
		}

		body.rtl.portfolio-to-home .elementLine4{
			animation: phElem4Ar 3s forwards ease-in-out;
		}
		@keyframes phElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				left: 1200px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 338px;
				border-radius: 54px;
			}
			100% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 358px;
				border-radius: 54px;
			}
		}
	}

	@media (max-width: 1250px) {
		body.rtl.portfolio-to-home .globalElement2{
			animation: phElem2Ar 3s forwards ease-in-out;
		}
		@keyframes phElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1120px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 530px;
				top: 50px;
				transform: rotate(70deg);
			}
			100% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 550px;
				top: 50px;
				transform: rotate(70deg);
				
			}
		}

		body.rtl.portfolio-to-home .elementLine4{
			animation: phElem4Ar 3s forwards ease-in-out;
		}
		@keyframes phElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				left: 1150px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 325px;
				border-radius: 54px;
			}
			100% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 345px;
				border-radius: 54px;
			}
		}
	}

	@media (max-width: 1199px) {
		body.rtl.portfolio-to-home .globalElement2{
			animation: phElem2Ar 3s forwards ease-in-out;
		}
		@keyframes phElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1070px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 480px;
				top: 50px;
				transform: rotate(70deg);
			}
			100% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 500px;
				top: 50px;
				transform: rotate(70deg);
				
			}
		}

		body.rtl.portfolio-to-home .elementLine4{
			animation: phElem4Ar 3s forwards ease-in-out;
		}
		@keyframes phElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				left: 1100px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 315px;
				border-radius: 54px;
			}
			100% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 335px;
				border-radius: 54px;
			}
		}
	}

	@media (max-width: 1150px) {
		body.rtl.portfolio-to-home .globalElement2{
			animation: phElem2Ar 3s forwards ease-in-out;
		}
		@keyframes phElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1020px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 480px;
				top: 50px;
				transform: rotate(70deg);
			}
			100% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 500px;
				top: 50px;
				transform: rotate(70deg);
				
			}
		}

		body.rtl.portfolio-to-home .elementLine4{
			animation: phElem4Ar 3s forwards ease-in-out;
		}
		@keyframes phElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				left: 1050px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 291px;
				left: 305px;
				border-radius: 54px;
			}
			100% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 291px;
				left: 325px;
				border-radius: 54px;
			}
		}
	}
	
	@media (max-width: 1099px) {
		body.rtl.portfolio-to-home .globalElement2{
			animation: phElem2Ar 3s forwards ease-in-out;
		}
		@keyframes phElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 970px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 430px;
				top: 50px;
				transform: rotate(70deg);
			}
			100% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 450px;
				top: 50px;
				transform: rotate(70deg);
				
			}
		}

		body.rtl.portfolio-to-home .elementLine4{
			animation: phElem4Ar 3s forwards ease-in-out;
		}
		@keyframes phElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				left: 1000px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 291px;
				left: 295px;
				border-radius: 54px;
			}
			100% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 291px;
				left: 315px;
				border-radius: 54px;
			}
		}
	}

	@media (max-width: 1050px) {
		body.rtl.portfolio-to-home .globalElement2{
			animation: phElem2Ar 3s forwards ease-in-out;
		}
		@keyframes phElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 920px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 430px;
				top: 50px;
				transform: rotate(70deg);
			}
			100% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 450px;
				top: 50px;
				transform: rotate(70deg);
				
			}
		}

		body.rtl.portfolio-to-home .elementLine4{
			animation: phElem4Ar 3s forwards ease-in-out;
		}
		@keyframes phElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				left: 950px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 291px;
				left: 280px;
				border-radius: 54px;
			}
			100% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 291px;
				left: 300px;
				border-radius: 54px;
			}
		}
	}


	/******************* Animate Portfolio To About **********************/

	@media (max-width: 1550px) {
			
		body.rtl.portfolio-to-about .globalElement2{
			animation: paElem2Ar 3s forwards ease-in-out;
		}
		@keyframes paElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1370px;
				top: 0px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				right: 1420px;
				left: unset;
				top: 30px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				right: 1420px;
				left: unset;
				top: 30px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.portfolio-to-about .elementLine4{
			animation: paElem4Ar 3s forwards ease-in-out;
		}
		@keyframes paElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				left: 1400px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				left: 1380px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				left: 1380px;
				border-radius: 50%;
			}
		}
		
	}

	@media (max-width: 1440px) {
		
		body.rtl.portfolio-to-about .globalElement2{
			animation: paElem2Ar 3s forwards ease-in-out;
		}
		@keyframes paElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1320px;
				top: 0px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				right: 1330px;
				top: 30px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				right: 1330px;
				top: 30px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.portfolio-to-about .elementLine4{
			animation: paElem4Ar 3s forwards ease-in-out;
		}
		@keyframes paElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				left: 1350px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				left: 1300px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				left: 1300px;
				border-radius: 50%;
			}
		}
		
	}

	@media (max-width: 1399px) {
		
		body.rtl.portfolio-to-about .globalElement2{
			animation: paElem2Ar 3s forwards ease-in-out;
		}
		@keyframes paElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1270px;
				top: 0px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				right: 1330px;
				top: 30px;
				transform: rotate(135deg);
			}
			100% { 
				
				border-radius: 55px;
				width: 150px;
				height: 150px;
				right: 1330px;
				top: 30px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.portfolio-to-about .elementLine4{
			animation: paElem4Ar 3s forwards ease-in-out;
		}
		@keyframes paElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				left: 1300px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				left: 1300px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				left: 1300px;
				border-radius: 50%;
			}
		}

	}

	@media (max-width: 1350px) {
		
		body.rtl.portfolio-to-about .globalElement2{
			animation: paElem2Ar 3s forwards ease-in-out;
		}
		@keyframes paElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1220px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 530px;
				top: 50px;
				transform: rotate(70deg);
			}
			100% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				right: 1220px;
				top: 30px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.portfolio-to-about .elementLine4{
			animation: paElem4Ar 3s forwards ease-in-out;
		}
		@keyframes paElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				left: 1250px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				left: 1200px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				left: 1200px;
				border-radius: 50%;
			}
		}

	}

	@media (max-width: 1299px) {
		
		body.rtl.portfolio-to-about .globalElement2{
			animation: paElem2Ar 3s forwards ease-in-out;
		}
		@keyframes paElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1170px;
				top: 0px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				right: 1220px;
				top: 30px;
				transform: rotate(135deg);
			}
			100% { 
				
				border-radius: 55px;
				width: 150px;
				height: 150px;
				right: 1220px;
				top: 30px;
				transform: rotate(135deg);
			}
		}

		body.rtl.portfolio-to-about .elementLine4{
			animation: paElem4Ar 3s forwards ease-in-out;
		}
		@keyframes paElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				left: 1200px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				left: 1150px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				left: 1150px;
				border-radius: 50%;
			}
		}

	}

	@media (max-width: 1250px) {
		
		body.rtl.portfolio-to-about .globalElement2{
			animation: paElem2Ar 3s forwards ease-in-out;
		}
		@keyframes paElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1120px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				right: 1120px;
				top: 30px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				right: 1120px;
				top: 30px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.portfolio-to-about .elementLine4{
			animation: paElem4Ar 3s forwards ease-in-out;
		}
		@keyframes paElem4Ar {
			
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				left: 1150px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				left: 1100px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				left: 1100px;
				border-radius: 50%;
			}
		}

	}


	/******************* Animate Portfolio To Services **********************/

		
	@media (max-width: 1550px) {
		body.rtl.portfolio-to-services .globalElement2{
			animation: psElem2Ar 3s forwards ease-in-out;
		}
		@keyframes psElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1370px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1370px;
				top: 60px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1370px;
				top: 60px;
				transform: rotate(135deg);
			}
		}

		body.rtl.portfolio-to-services .elementLine4{
			animation: psElem4Ar 3s forwards ease-in-out;
		}
		@keyframes psElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				left: 1400px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				left: -95px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				left: -95px;
				border-radius: 50%;
			}
		}
	}
	
	@media (max-width: 1440px) {
		body.rtl.portfolio-to-services .globalElement2{
			animation: psElem2Ar 3s forwards ease-in-out;
		}
		@keyframes psElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1320px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1300px;
				top: 60px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1300px;
				top: 60px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.portfolio-to-services .elementLine4{
			animation: psElem4Ar 3s forwards ease-in-out;
		}
		@keyframes psElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				left: 1350px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				left: -95px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				left: -95px;
				border-radius: 50%;
			}
		}
	}
	
	@media (max-width: 1399px) {
		body.rtl.portfolio-to-services .globalElement2{
			animation: psElem2Ar 3s forwards ease-in-out;
		}
		@keyframes psElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1270px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1260px;
				top: 60px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1260px;
				top: 60px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.portfolio-to-services .elementLine4{
			animation: psElem4Ar 3s forwards ease-in-out;
		}
		@keyframes psElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				left: 1300px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				left: -95px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				left: -95px;
				border-radius: 50%;
			}
		}
	}

	@media (max-width: 1350px) {
		body.rtl.portfolio-to-services .globalElement2{
			animation: psElem2Ar 3s forwards ease-in-out;
		}
		@keyframes psElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1220px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1200px;
				top: 60px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1200px;
				top: 60px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.portfolio-to-services .elementLine4{
			animation: psElem4Ar 3s forwards ease-in-out;
		}
		@keyframes psElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				left: 1250px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				left: -95px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				left: -95px;
				border-radius: 50%;
			}
		}
	}
	
	@media (max-width: 1299px) {
		body.rtl.portfolio-to-services .globalElement2{
			animation: psElem2Ar 3s forwards ease-in-out;
		}
		@keyframes psElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1170px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1150px;
				top: 60px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1150px;
				top: 60px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.portfolio-to-services .elementLine4{
			animation: psElem4Ar 3s forwards ease-in-out;
		}
		@keyframes psElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				left: 1200px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 338px;
				border-radius: 54px;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				left: -95px;
				border-radius: 50%;
			}
		}
	}

	@media (max-width: 1250px) {
		body.rtl.portfolio-to-services .globalElement2{
			animation: psElem2Ar 3s forwards ease-in-out;
		}
		@keyframes psElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1120px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1100px;
				top: 60px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1100px;
				top: 60px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.portfolio-to-services .elementLine4{
			animation: phElem4Ar 3s forwards ease-in-out;
		}
		@keyframes psElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				left: 1150px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				left: -95px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				left: -95px;
				border-radius: 50%;
			}
		}
	}


	/******************* Animate Portfolio To Team **********************/

	
	@media (max-width: 1550px) {
		body.rtl.portfolio-to-team .globalElement2{
			animation: ptElem2Ar 3s forwards ease-in-out;
		}
		@keyframes ptElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1370px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1380px;
				top: 40px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1380px;
				top: 40px;
				transform: rotate(135deg);
			}
		}

		body.rtl.portfolio-to-team .elementLine4{
			animation: ptElem4Ar 3s forwards ease-in-out;
		}
		@keyframes ptElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				left: 1400px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				left: 1350px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				left: 1350px;
				border-radius: 50%;
			}
		}
	}
	
	@media (max-width: 1440px) {
		body.rtl.portfolio-to-team .globalElement2{
			animation: ptElem2Ar 3s forwards ease-in-out;
		}
		@keyframes ptElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1320px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1330px;
				top: 40px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1330px;
				top: 40px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.portfolio-to-team .elementLine4{
			animation: ptElem4Ar 3s forwards ease-in-out;
		}
		@keyframes ptElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				left: 1350px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				left: 1300px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				left: 1300px;
				border-radius: 50%;
			}
		}
	}
	
	@media (max-width: 1399px) {
		body.rtl.portfolio-to-team .globalElement2{
			animation: ptElem2Ar 3s forwards ease-in-out;
		}
		@keyframes ptElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1270px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1300px;
				top: 40px;
				transform: rotate(135deg);
			}
			100% { 
				
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1270px;
				top: 40px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.portfolio-to-team .elementLine4{
			animation: ptElem4Ar 3s forwards ease-in-out;
		}
		@keyframes ptElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				left: 1300px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				left: 1280px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				left: 1250px;
				border-radius: 50%;
			}
		}
	}

	@media (max-width: 1350px) {
		body.rtl.portfolio-to-team .globalElement2{
			animation: ptElem2Ar 3s forwards ease-in-out;
		}
		@keyframes ptElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1220px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1250px;
				top: 40px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1220px;
				top: 40px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.portfolio-to-team .elementLine4{
			animation: ptElem4Ar 3s forwards ease-in-out;
		}
		@keyframes ptElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				left: 1250px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				left: 1230px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				left: 1200px;
				border-radius: 50%;
			}
		}
	}
	
	@media (max-width: 1299px) {
		body.rtl.portfolio-to-team .globalElement2{
			animation: ptElem2Ar 3s forwards ease-in-out;
		}
		@keyframes ptElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1170px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1200px;
				top: 40px;
				transform: rotate(135deg);
			}
			100% { 
				
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1170px;
				top: 40px;
				transform: rotate(135deg);
			}
		}

		body.rtl.portfolio-to-team .elementLine4{
			animation: ptElem4Ar 3s forwards ease-in-out;
		}
		@keyframes ptElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				left: 1200px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				left: 1180px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				left: 1150px;
				border-radius: 50%;
			}
		}
	}

	@media (max-width: 1250px) {
		body.rtl.portfolio-to-team .globalElement2{
			animation: ptElem2Ar 3s forwards ease-in-out;
		}
		@keyframes ptElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1120px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1150px;
				top: 40px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1120px;
				top: 40px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.portfolio-to-team .elementLine4{
			animation: ptElem4Ar 3s forwards ease-in-out;
		}
		@keyframes ptElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				left: 1150px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				left: 1130px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				left: 1100px;
				border-radius: 50%;
			}
		}
	}


	/******************* Animate Portfolio To Testimonials **********************/

	
	@media (max-width: 1550px) {
		body.rtl.portfolio-to-testimonials .globalElement2{
			animation: ptesElem2Ar 3s forwards ease-in-out;
		}
		@keyframes ptesElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1370px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1380px;
				top: 40px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1380px;
				top: 40px;
				transform: rotate(135deg);
			}
		}

		body.rtl.portfolio-to-testimonials .elementLine4{
			animation: ptesElem4Ar 3s forwards ease-in-out;
		}
		@keyframes ptesElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				left: 1400px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				left: 1350px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				left: 1350px;
				border-radius: 50%;
			}
		}
	}
	
	@media (max-width: 1440px) {
		body.rtl.portfolio-to-testimonials .globalElement2{
			animation: ptesElem2Ar 3s forwards ease-in-out;
		}
		@keyframes ptesElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1320px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1330px;
				top: 40px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1330px;
				top: 40px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.portfolio-to-testimonials .elementLine4{
			animation: ptesElem4Ar 3s forwards ease-in-out;
		}
		@keyframes ptesElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				left: 1350px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				left: 1300px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				left: 1300px;
				border-radius: 50%;
			}
		}
	}
	
	@media (max-width: 1399px) {
		body.rtl.portfolio-to-testimonials .globalElement2{
			animation: ptesElem2Ar 3s forwards ease-in-out;
		}
		@keyframes ptesElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1270px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1300px;
				top: 40px;
				transform: rotate(135deg);
			}
			100% { 
				
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1270px;
				top: 40px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.portfolio-to-testimonials .elementLine4{
			animation: ptesElem4Ar 3s forwards ease-in-out;
		}
		@keyframes ptesElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				left: 1300px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				left: 1280px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				left: 1250px;
				border-radius: 50%;
			}
		}
	}

	@media (max-width: 1350px) {
		body.rtl.portfolio-to-testimonials .globalElement2{
			animation: ptesElem2Ar 3s forwards ease-in-out;
		}
		@keyframes ptesElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1220px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1250px;
				top: 40px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1220px;
				top: 40px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.portfolio-to-testimonials .elementLine4{
			animation: ptesElem4Ar 3s forwards ease-in-out;
		}
		@keyframes ptesElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				left: 1250px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				left: 1230px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				left: 1200px;
				border-radius: 50%;
			}
		}
	}
	
	@media (max-width: 1299px) {
		body.rtl.portfolio-to-testimonials .globalElement2{
			animation: ptesElem2Ar 3s forwards ease-in-out;
		}
		@keyframes ptesElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1170px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1200px;
				top: 40px;
				transform: rotate(135deg);
			}
			100% { 
				
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1170px;
				top: 40px;
				transform: rotate(135deg);
			}
		}

		body.rtl.portfolio-to-testimonials .elementLine4{
			animation: ptesElem4Ar 3s forwards ease-in-out;
		}
		@keyframes ptesElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				left: 1200px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				left: 1180px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				left: 1150px;
				border-radius: 50%;
			}
		}
	}

	@media (max-width: 1250px) {
		body.rtl.portfolio-to-testimonials .globalElement2{
			animation: ptesElem2Ar 3s forwards ease-in-out;
		}
		@keyframes ptesElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1120px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1150px;
				top: 40px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1120px;
				top: 40px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.portfolio-to-testimonials .elementLine4{
			animation: ptesElem4Ar 3s forwards ease-in-out;
		}
		@keyframes ptesElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				left: 1150px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				left: 1130px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				left: 1100px;
				border-radius: 50%;
			}
		}
	}


	/******************* Animate Portfolio To Contact **********************/

	
	@media (max-width: 1550px) {
		body.rtl.portfolio-to-contact .globalElement2{
			animation: pCtElem2Ar 3s forwards ease-in-out;
		}
		@keyframes pCtElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1370px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1370px;
				top: 0px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1370px;
				top: 0px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.portfolio-to-contact .elementLine4{
			animation: pCtElem4Ar 3s forwards ease-in-out;
		}
		@keyframes pCtElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				left: 1400px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				left: 1400px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				left: 1400px;
				border-radius: 50%;
			}
		}
	}
	
	@media (max-width: 1440px) {
		body.rtl.portfolio-to-contact .globalElement2{
			animation: pCtElem2Ar 3s forwards ease-in-out;
		}
		@keyframes pCtElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1320px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1320px;
				top: 0px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1320px;
				top: 0px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.portfolio-to-contact .elementLine4{
			animation: pCtElem4Ar 3s forwards ease-in-out;
		}
		@keyframes pCtElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				left: 1350px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				left: 1350px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				left: 1350px;
				border-radius: 50%;
			}
		}
	}
	
	@media (max-width: 1399px) {
		body.rtl.portfolio-to-contact .globalElement2{
			animation: pCtElem2Ar 3s forwards ease-in-out;
		}
		@keyframes pCtElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1270px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1270px;
				top: 0px;
				transform: rotate(135deg);
			}
			100% { 
				
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1270px;
				top: 0px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.portfolio-to-contact .elementLine4{
			animation: pCtElem4Ar 3s forwards ease-in-out;
		}
		@keyframes pCtElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				left: 1300px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				left: 1330px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				left: 1300px;
				border-radius: 50%;
			}
		}
	}

	@media (max-width: 1350px) {
		body.rtl.portfolio-to-contact .globalElement2{
			animation: pCtElem2Ar 3s forwards ease-in-out;
		}
		@keyframes pCtElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1220px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1220px;
				top: 0px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1220px;
				top: 0px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.portfolio-to-contact .elementLine4{
			animation: pCtElem4Ar 3s forwards ease-in-out;
		}
		@keyframes pCtElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				left: 1250px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				left: 1250px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				left: 1250px;
				border-radius: 50%;
			}
		}
	}
	
	@media (max-width: 1299px) {
		body.rtl.portfolio-to-contact .globalElement2{
			animation: pCtElem2Ar 3s forwards ease-in-out;
		}
		@keyframes pCtElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1170px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1170px;
				top: 0px;
				transform: rotate(135deg);
			}
			100% { 
				
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1170px;
				top: 0px;
				transform: rotate(135deg);
			}
		}

		body.rtl.portfolio-to-contact .elementLine4{
			animation: pCtElem4Ar 3s forwards ease-in-out;
		}
		@keyframes pCtElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				left: 1200px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				left: 1230px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				left: 1200px;
				border-radius: 50%;
			}
		}
	}

	@media (max-width: 1250px) {
		body.rtl.portfolio-to-contact .globalElement2{
			animation: pCtElem2Ar 3s forwards ease-in-out;
		}
		@keyframes pCtElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1120px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1120px;
				top: 0px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1120px;
				top: 0px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.portfolio-to-contact .elementLine4{
			animation: pCtElem4Ar 3s forwards ease-in-out;
		}
		@keyframes pCtElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				left: 1150px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				left: 1180px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				left: 1150px;
				border-radius: 50%;
			}
		}
	}


	/******************* Animate Portfolio To FAQs **********************/

	
	@media (max-width: 1550px) {
		body.rtl.portfolio-to-faqs .globalElement2{
			animation: pfElem2Ar 3s forwards ease-in-out;
		}
		@keyframes pfElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1370px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1370px;
				top: 0px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1370px;
				top: 0px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.portfolio-to-faqs .elementLine4{
			animation: pfElem4Ar 3s forwards ease-in-out;
		}
		@keyframes pfElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				left: 1400px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				left: 1400px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				left: 1400px;
				border-radius: 50%;
			}
		}
	}
	
	@media (max-width: 1440px) {
		body.rtl.portfolio-to-faqs .globalElement2{
			animation: pfElem2Ar 3s forwards ease-in-out;
		}
		@keyframes pfElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1320px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1320px;
				top: 0px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1320px;
				top: 0px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.portfolio-to-faqs .elementLine4{
			animation: pfElem4Ar 3s forwards ease-in-out;
		}
		@keyframes pfElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				left: 1350px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				left: 1350px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				left: 1350px;
				border-radius: 50%;
			}
		}
	}
	
	@media (max-width: 1399px) {
		body.rtl.portfolio-to-faqs .globalElement2{
			animation: pfElem2Ar 3s forwards ease-in-out;
		}
		@keyframes pfElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1270px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1270px;
				top: 0px;
				transform: rotate(135deg);
			}
			100% { 
				
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1270px;
				top: 0px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.portfolio-to-faqs .elementLine4{
			animation: pfElem4Ar 3s forwards ease-in-out;
		}
		@keyframes pfElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				left: 1300px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				left: 1330px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				left: 1300px;
				border-radius: 50%;
			}
		}
	}

	@media (max-width: 1350px) {
		body.rtl.portfolio-to-faqs .globalElement2{
			animation: pfElem2Ar 3s forwards ease-in-out;
		}
		@keyframes pfElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1220px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1220px;
				top: 0px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1220px;
				top: 0px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.portfolio-to-faqs .elementLine4{
			animation: pfElem4Ar 3s forwards ease-in-out;
		}
		@keyframes pfElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				left: 1250px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				left: 1250px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				left: 1250px;
				border-radius: 50%;
			}
		}
	}
	
	@media (max-width: 1299px) {
		body.rtl.portfolio-to-faqs .globalElement2{
			animation: pfElem2Ar 3s forwards ease-in-out;
		}
		@keyframes pfElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1170px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1170px;
				top: 0px;
				transform: rotate(135deg);
			}
			100% { 
				
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1170px;
				top: 0px;
				transform: rotate(135deg);
			}
		}

		body.rtl.portfolio-to-faqs .elementLine4{
			animation: pfElem4Ar 3s forwards ease-in-out;
		}
		@keyframes pfElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				left: 1200px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				left: 1230px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				left: 1200px;
				border-radius: 50%;
			}
		}
	}

	@media (max-width: 1250px) {
		body.rtl.portfolio-to-faqs .globalElement2{
			animation: pfElem2Ar 3s forwards ease-in-out;
		}
		@keyframes pfElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1120px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1120px;
				top: 0px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1120px;
				top: 0px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.portfolio-to-faqs .elementLine4{
			animation: pfElem4Ar 3s forwards ease-in-out;
		}
		@keyframes pfElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				left: 1150px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				left: 1180px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				left: 1150px;
				border-radius: 50%;
			}
		}
	}





	
/******************* Animate Team To ALL **********************/

	/******************* Animate Team To Home **********************/

	@media (max-width: 1550px) {
		body.rtl.team-to-home .globalElement2{
			animation: thElem2Ar 3s forwards ease-in-out;
		}
		@keyframes thElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1380px;
				top: 40px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 730px;
				top: 50px;
				transform: rotate(70deg);
			}
			100% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 750px;
				top: 50px;
				transform: rotate(70deg);
				
			}
		}

		body.rtl.team-to-home .elementLine4{
			animation: thElem4Ar 3s forwards ease-in-out;
		}
		@keyframes thElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				left: 1350px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 392px;
				border-radius: 54px;
			}
			100% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 412px;
				border-radius: 54px;
			}
		}
	}

	@media (max-width: 1440px) {
		body.rtl.team-to-home .globalElement2{
			animation: thElem2Ar 3s forwards ease-in-out;
		}
		@keyframes thElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1330px;
				top: 40px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 730px;
				top: 50px;
				transform: rotate(70deg);
			}
			100% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 750px;
				top: 50px;
				transform: rotate(70deg);
				
			}
		}

		body.rtl.team-to-home .elementLine4{
			animation: thElem4Ar 3s forwards ease-in-out;
		}
		@keyframes thElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				left: 1300px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 372px;
				border-radius: 54px;
			}
			100% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 392px;
				border-radius: 54px;
			}
		}
	}

	@media (max-width: 1399px) {
		body.rtl.team-to-home .globalElement2{
			animation: thElem2Ar 3s forwards ease-in-out;
		}
		@keyframes thElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1270px;
				top: 40px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 630px;
				top: 50px;
				transform: rotate(70deg);
			}
			100% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 650px;
				top: 50px;
				transform: rotate(70deg);
				
			}
		}

		body.rtl.team-to-home .elementLine4{
			animation: thElem4Ar 3s forwards ease-in-out;
		}
		@keyframes thElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				left: 1250px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 362px;
				border-radius: 54px;
			}
			100% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 382px;
				border-radius: 54px;
			}
		}
	}

	@media (max-width: 1350px) {
		body.rtl.team-to-home .globalElement2{
			animation: thElem2Ar 3s forwards ease-in-out;
		}
		@keyframes thElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1220px;
				top: 40px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 530px;
				top: 50px;
				transform: rotate(70deg);
			}
			100% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 550px;
				top: 50px;
				transform: rotate(70deg);
			}
		}

		body.rtl.team-to-home .elementLine4{
			animation: thElem4Ar 3s forwards ease-in-out;
		}
		@keyframes thElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				left: 1200px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 348px;
				border-radius: 54px;
			}
			100% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 368px;
				border-radius: 54px;
			}
		}
	}

	@media (max-width: 1299px) {
		body.rtl.team-to-home .globalElement2{
			animation: thElem2Ar 3s forwards ease-in-out;
		}
		@keyframes thElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1170px;
				top: 40px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 530px;
				top: 50px;
				transform: rotate(70deg);
			}
			100% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 550px;
				top: 50px;
				transform: rotate(70deg);
				
			}
		}

		body.rtl.team-to-home .elementLine4{
			animation: thElem4Ar 3s forwards ease-in-out;
		}
		@keyframes thElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				left: 1150px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 338px;
				border-radius: 54px;
			}
			100% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 358px;
				border-radius: 54px;
			}
		}
	}
	
	@media (max-width: 1250px) {
		body.rtl.team-to-home .globalElement2{
			animation: thElem2Ar 3s forwards ease-in-out;
		}
		@keyframes thElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1120px;
				top: 40px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 530px;
				top: 50px;
				transform: rotate(70deg);
			}
			100% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 550px;
				top: 50px;
				transform: rotate(70deg);
				
			}
		}

		body.rtl.team-to-home .elementLine4{
			animation: thElem4Ar 3s forwards ease-in-out;
		}
		@keyframes thElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				left: 1100px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 325px;
				border-radius: 54px;
			}
			100% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 345px;
				border-radius: 54px;
			}
		}
	}

	@media (max-width: 1199px) {
		body.rtl.team-to-home .globalElement2{
			animation: thElem2Ar 3s forwards ease-in-out;
		}
		@keyframes thElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1070px;
				top: 40px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 480px;
				top: 50px;
				transform: rotate(70deg);
			}
			100% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 500px;
				top: 50px;
				transform: rotate(70deg);
				
			}
		}

		body.rtl.team-to-home .elementLine4{
			animation: thElem4Ar 3s forwards ease-in-out;
		}
		@keyframes thElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				left: 1050px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 315px;
				border-radius: 54px;
			}
			100% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 335px;
				border-radius: 54px;
			}
		}
	}

	@media (max-width: 1150px) {
		body.rtl.team-to-home .globalElement2{
			animation: thElem2Ar 3s forwards ease-in-out;
		}
		@keyframes thElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1020px;
				top: 40px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 480px;
				top: 50px;
				transform: rotate(70deg);
			}
			100% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 500px;
				top: 50px;
				transform: rotate(70deg);
				
			}
		}

		body.rtl.team-to-home .elementLine4{
			animation: thElem4Ar 3s forwards ease-in-out;
		}
		@keyframes thElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				left: 1000px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 291px;
				left: 305px;
				border-radius: 54px;
			}
			100% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 291px;
				left: 325px;
				border-radius: 54px;
			}
		}
	}

	@media (max-width: 1099px) {
		body.rtl.team-to-home .globalElement2{
			animation: thElem2Ar 3s forwards ease-in-out;
		}
		@keyframes thElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 970px;
				top: 40px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 430px;
				top: 50px;
				transform: rotate(70deg);
			}
			100% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 450px;
				top: 50px;
				transform: rotate(70deg);
				
			}
		}

		body.rtl.team-to-home .elementLine4{
			animation: thElem4Ar 3s forwards ease-in-out;
		}
		@keyframes thElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				left: 950px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 291px;
				left: 295px;
				border-radius: 54px;
			}
			100% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 291px;
				left: 315px;
				border-radius: 54px;
			}
		}
	}

	@media (max-width: 1050px) {
		body.rtl.team-to-home .globalElement2{
			animation: thElem2Ar 3s forwards ease-in-out;
		}
		@keyframes thElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 920px;
				top: 40px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 430px;
				top: 50px;
				transform: rotate(70deg);
			}
			100% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 450px;
				top: 50px;
				transform: rotate(70deg);
				
			}
		}

		body.rtl.team-to-home .elementLine4{
			animation: thElem4Ar 3s forwards ease-in-out;
		}
		@keyframes thElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				left: 900px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 291px;
				left: 280px;
				border-radius: 54px;
			}
			100% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 291px;
				left: 300px;
				border-radius: 54px;
			}
		}
	}


	/******************* Animate Team To About **********p to A************/

	@media (max-width: 1550px) {
				
		body.rtl.team-to-about .globalElement2{
			animation: taElem2Ar 3s forwards ease-in-out;
		}
		@keyframes taElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1380px;
				top: 40px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				right: 1420px;
				left: unset;
				top: 30px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				right: 1420px;
				left: unset;
				top: 30px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.team-to-about .elementLine4{
			animation: taElem4Ar 3s forwards ease-in-out;
		}
		@keyframes taElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				left: 1350px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				left: 1380px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				left: 1380px;
				border-radius: 50%;
			}
		}
		
	}

	@media (max-width: 1440px) {
		
		body.rtl.team-to-about .globalElement2{
			animation: taElem2Ar 3s forwards ease-in-out;
		}
		@keyframes taElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1330px;
				top: 40px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				right: 1330px;
				top: 30px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				right: 1330px;
				top: 30px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.team-to-about .elementLine4{
			animation: taElem4Ar 3s forwards ease-in-out;
		}
		@keyframes taElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				left: 1300px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				left: 1300px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				left: 1300px;
				border-radius: 50%;
			}
		}
		
	}

	@media (max-width: 1399px) {
		
		body.rtl.team-to-about .globalElement2{
			animation: taElem2Ar 3s forwards ease-in-out;
		}
		@keyframes taElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1270px;
				top: 40px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				right: 1330px;
				top: 30px;
				transform: rotate(135deg);
			}
			100% { 
				
				border-radius: 55px;
				width: 150px;
				height: 150px;
				right: 1330px;
				top: 30px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.team-to-about .elementLine4{
			animation: taElem4Ar 3s forwards ease-in-out;
		}
		@keyframes taElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				left: 1250px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				left: 1300px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				left: 1300px;
				border-radius: 50%;
			}
		}

	}

	@media (max-width: 1350px) {
		
		body.rtl.team-to-about .globalElement2{
			animation: taElem2Ar 3s forwards ease-in-out;
		}
		@keyframes taElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1220px;
				top: 40px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 530px;
				top: 50px;
				transform: rotate(70deg);
			}
			100% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				right: 1220px;
				top: 30px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.team-to-about .elementLine4{
			animation: taElem4Ar 3s forwards ease-in-out;
		}
		@keyframes taElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				left: 1200px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				left: 1200px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				left: 1200px;
				border-radius: 50%;
			}
		}

	}

	@media (max-width: 1299px) {
		
		body.rtl.team-to-about .globalElement2{
			animation: taElem2Ar 3s forwards ease-in-out;
		}
		@keyframes taElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1170px;
				top: 40px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				right: 1220px;
				top: 30px;
				transform: rotate(135deg);
			}
			100% { 
				
				border-radius: 55px;
				width: 150px;
				height: 150px;
				right: 1220px;
				top: 30px;
				transform: rotate(135deg);
			}
		}

		body.rtl.team-to-about .elementLine4{
			animation: taElem4Ar 3s forwards ease-in-out;
		}
		@keyframes taElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				left: 1150px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				left: 1150px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				left: 1150px;
				border-radius: 50%;
			}
		}

	}

	@media (max-width: 1250px) {
		
		body.rtl.team-to-about .globalElement2{
			animation: taElem2Ar 3s forwards ease-in-out;
		}
		@keyframes taElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1120px;
				top: 40px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				right: 1120px;
				top: 30px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				right: 1120px;
				top: 30px;
				transform: rotate(135deg);
				
			}
		}

		body.rtl.team-to-about .elementLine4{
			animation: taElem4Ar 3s forwards ease-in-out;
		}
		@keyframes taElem4Ar {
			
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				left: 1100px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				left: 1100px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				left: 1100px;
				border-radius: 50%;
			}
		}

	}



	
/******************** Animate Team To ALL **********************/

	/******************* Animate Team To Home **********************/
/*
	@media (max-width: 1550px) {
		body.rtl.testimonials-to-home .globalElement2{
			animation: teshElem2Ar 3s forwards ease-in-out;
		}
		@keyframes teshElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1380px;
				top: 40px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 730px;
				top: 50px;
				transform: rotate(70deg);
			}
			100% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 750px;
				top: 50px;
				transform: rotate(70deg);
				
			}
		}

		body.rtl.testimonials-to-home .elementLine4{
			animation: teshElem4Ar 3s forwards ease-in-out;
		}
		@keyframes teshElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				left: 1350px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 392px;
				border-radius: 54px;
			}
			100% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 412px;
				border-radius: 54px;
			}
		}
	}

	@media (max-width: 1440px) {
		body.rtl.testimonials-to-home .globalElement2{
			animation: teshElem2Ar 3s forwards ease-in-out;
		}
		@keyframes teshElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1330px;
				top: 40px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 730px;
				top: 50px;
				transform: rotate(70deg);
			}
			100% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 750px;
				top: 50px;
				transform: rotate(70deg);
				
			}
		}

		body.rtl.testimonials-to-home .elementLine4{
			animation: teshElem4Ar 3s forwards ease-in-out;
		}
		@keyframes teshElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				left: 1300px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 372px;
				border-radius: 54px;
			}
			100% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 392px;
				border-radius: 54px;
			}
		}
	}

	@media (max-width: 1399px) {
		body.rtl.testimonials-to-home .globalElement2{
			animation: teshElem2Ar 3s forwards ease-in-out;
		}
		@keyframes teshElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1270px;
				top: 40px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 630px;
				top: 50px;
				transform: rotate(70deg);
			}
			100% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 650px;
				top: 50px;
				transform: rotate(70deg);
				
			}
		}

		body.rtl.testimonials-to-home .elementLine4{
			animation: teshElem4Ar 3s forwards ease-in-out;
		}
		@keyframes teshElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				left: 1250px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 362px;
				border-radius: 54px;
			}
			100% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 382px;
				border-radius: 54px;
			}
		}
	}

	@media (max-width: 1350px) {
		body.rtl.testimonials-to-home .globalElement2{
			animation: teshElem2Ar 3s forwards ease-in-out;
		}
		@keyframes teshElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1220px;
				top: 40px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 530px;
				top: 50px;
				transform: rotate(70deg);
			}
			100% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 550px;
				top: 50px;
				transform: rotate(70deg);
			}
		}

		body.rtl.testimonials-to-home .elementLine4{
			animation: teshElem4Ar 3s forwards ease-in-out;
		}
		@keyframes teshElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				left: 1200px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 348px;
				border-radius: 54px;
			}
			100% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 368px;
				border-radius: 54px;
			}
		}
	}

	@media (max-width: 1299px) {
		body.rtl.testimonials-to-home .globalElement2{
			animation: teshElem2Ar 3s forwards ease-in-out;
		}
		@keyframes teshElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1170px;
				top: 40px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 530px;
				top: 50px;
				transform: rotate(70deg);
			}
			100% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 550px;
				top: 50px;
				transform: rotate(70deg);
				
			}
		}

		body.rtl.testimonials-to-home .elementLine4{
			animation: teshElem4Ar 3s forwards ease-in-out;
		}
		@keyframes teshElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				left: 1150px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 338px;
				border-radius: 54px;
			}
			100% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 358px;
				border-radius: 54px;
			}
		}
	}
	
	@media (max-width: 1250px) {
		body.rtl.testimonials-to-home .globalElement2{
			animation: teshElem2Ar 3s forwards ease-in-out;
		}
		@keyframes teshElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1120px;
				top: 40px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 530px;
				top: 50px;
				transform: rotate(70deg);
			}
			100% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 550px;
				top: 50px;
				transform: rotate(70deg);
				
			}
		}

		body.rtl.testimonials-to-home .elementLine4{
			animation: teshElem4Ar 3s forwards ease-in-out;
		}
		@keyframes teshElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				left: 1100px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 325px;
				border-radius: 54px;
			}
			100% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 345px;
				border-radius: 54px;
			}
		}
	}

	@media (max-width: 1199px) {
		body.rtl.testimonials-to-home .globalElement2{
			animation: teshElem2Ar 3s forwards ease-in-out;
		}
		@keyframes teshElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1070px;
				top: 40px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 480px;
				top: 50px;
				transform: rotate(70deg);
			}
			100% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 500px;
				top: 50px;
				transform: rotate(70deg);
				
			}
		}

		body.rtl.testimonials-to-home .elementLine4{
			animation: teshElem4Ar 3s forwards ease-in-out;
		}
		@keyframes teshElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				left: 1050px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 315px;
				border-radius: 54px;
			}
			100% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 335px;
				border-radius: 54px;
			}
		}
	}

	@media (max-width: 1150px) {
		body.rtl.testimonials-to-home .globalElement2{
			animation: teshElem2Ar 3s forwards ease-in-out;
		}
		@keyframes teshElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1020px;
				top: 40px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 480px;
				top: 50px;
				transform: rotate(70deg);
			}
			100% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 500px;
				top: 50px;
				transform: rotate(70deg);
				
			}
		}

		body.rtl.testimonials-to-home .elementLine4{
			animation: teshElem4Ar 3s forwards ease-in-out;
		}
		@keyframes teshElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				left: 1000px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 291px;
				left: 305px;
				border-radius: 54px;
			}
			100% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 291px;
				left: 325px;
				border-radius: 54px;
			}
		}
	}

	@media (max-width: 1099px) {
		body.rtl.testimonials-to-home .globalElement2{
			animation: teshElem2Ar 3s forwards ease-in-out;
		}
		@keyframes teshElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 970px;
				top: 40px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 430px;
				top: 50px;
				transform: rotate(70deg);
			}
			100% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 450px;
				top: 50px;
				transform: rotate(70deg);
				
			}
		}

		body.rtl.testimonials-to-home .elementLine4{
			animation: teshElem4Ar 3s forwards ease-in-out;
		}
		@keyframes teshElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				left: 950px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 291px;
				left: 295px;
				border-radius: 54px;
			}
			100% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 291px;
				left: 315px;
				border-radius: 54px;
			}
		}
	}

	@media (max-width: 1050px) {
		body.rtl.testimonials-to-home .globalElement2{
			animation: teshElem2Ar 3s forwards ease-in-out;
		}
		@keyframes teshElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 920px;
				top: 40px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 430px;
				top: 50px;
				transform: rotate(70deg);
			}
			100% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 450px;
				top: 50px;
				transform: rotate(70deg);
				
			}
		}

		body.rtl.testimonials-to-home .elementLine4{
			animation: teshElem4Ar 3s forwards ease-in-out;
		}
		@keyframes teshElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				left: 900px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 291px;
				left: 280px;
				border-radius: 54px;
			}
			100% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 291px;
				left: 300px;
				border-radius: 54px;
			}
		}
	}
*/

/******************** Animate Clients To ALL **********************/

	/******************* Animate Clients To Home **********************/

	@media (max-width: 1550px) {
		
		body.rtl.clients-to-home .globalElement2{
			animation: chElem2Ar 3s forwards ease-in-out;
		}
		@keyframes chElem2Ar {
			0% { 
				border-radius: 50%;
				width: 250px;
				height: 250px;
				right: 575px;
				top: 495px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 750px;
				top: 30px;
				transform: rotate(70deg);
			}
			100% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 750px;
				top: 50px;
				transform: rotate(70deg);
				
			}
		}

		body.rtl.clients-to-home .globalElement4{
			animation: chGlobElem4Ar 3s forwards ease-in-out;
		}
		@keyframes chGlobElem4Ar {
			0% { 
				border-radius: 21px;
				width: 48px;
				height: 48px;
				left: 800px;
				top: 430px;
				transform: rotate(-60deg);
				z-index: -1;
				
			}
			70% { 
				border-radius: 21px;
				width: 48px;
				height: 48px;
				left: 115px;
				top: 340px;
				transform: rotate(60deg);
			}
			100% { 
				border-radius: 21px;
				width: 48px;
				height: 48px;
				left: 135px;
				top: 350px;
				transform: rotate(60deg);
			}
		}

		body.rtl.clients-to-home .globalElement5{
			animation: chGlobElem5Ar 3s forwards ease-in-out;
		}
		@keyframes chGlobElem5Ar {
			0% { 
				border-radius: 18px;
				width: 40px;
				height: 40px;
				left: 65px;
				top: 80px;
				transform: rotate(60deg);
				
			}
			70% { 
				border-radius: 18px;
				width: 40px;
				height: 40px;
				left: 65px;
				top: 30px;
				transform: rotate(60deg);
			}
			100% { 
				border-radius: 18px;
				width: 40px;
				height: 40px;
				left: 65px;
				top: 30px;
				transform: rotate(60deg);
			}
		}

		body.rtl.clients-to-home .elementLine4{
			animation: chElem4Ar 3s forwards ease-in-out;
		}
		@keyframes chElem4Ar {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 470px;
				left: 600px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 283px;
				left: 402px;
				border-radius: 54px;
			}
			100% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 412px;
				border-radius: 54px;
			}
		}
		
	}

	@media (max-width: 1499px) {
		
		body.rtl.clients-to-home .globalElement2{
			animation: chElem2Ar 3s forwards ease-in-out;
		}
		@keyframes chElem2Ar {
			0% { 
				border-radius: 50%;
				width: 250px;
				height: 250px;
				right: 565px;
				top: 495px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 750px;
				top: 30px;
				transform: rotate(70deg);
			}
			100% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 750px;
				top: 50px;
				transform: rotate(70deg);
				
			}
		}

		body.rtl.clients-to-home .globalElement4{
			animation: chGlobElem4Ar 3s forwards ease-in-out;
		}
		@keyframes chGlobElem4Ar {
			0% { 
				border-radius: 21px;
				width: 48px;
				height: 48px;
				left: 800px;
				top: 430px;
				transform: rotate(-60deg);
				z-index: -1;
				
			}
			70% { 
				border-radius: 21px;
				width: 48px;
				height: 48px;
				left: 115px;
				top: 340px;
				transform: rotate(60deg);
			}
			100% { 
				border-radius: 21px;
				width: 48px;
				height: 48px;
				left: 135px;
				top: 350px;
				transform: rotate(60deg);
			}
		}
		
	}

	
	@media (max-width: 1440px) {
		
		body.rtl.clients-to-home .globalElement2{
			animation: chElem2Ar 3s forwards ease-in-out;
		}
		@keyframes chElem2Ar {
			0% { 
				border-radius: 50%;
				width: 250px;
				height: 250px;
				right: 505px;
				top: 495px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 750px;
				top: 30px;
				transform: rotate(70deg);
			}
			100% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 750px;
				top: 50px;
				transform: rotate(70deg);
				
			}
		}

		body.rtl.clients-to-home .globalElement4{
			animation: chGlobElem4Ar 3s forwards ease-in-out;
		}
		@keyframes chGlobElem4Ar {
			0% { 
				border-radius: 21px;
				width: 48px;
				height: 48px;
				left: 800px;
				top: 430px;
				transform: rotate(-60deg);
				z-index: -1;
				
			}
			70% { 
				border-radius: 21px;
				width: 48px;
				height: 48px;
				left: 75px;
				top: 340px;
				transform: rotate(60deg);
			}
			100% { 
				border-radius: 21px;
				width: 48px;
				height: 48px;
				left: 85px;
				top: 350px;
				transform: rotate(60deg);
			}
		}

		body.rtl.clients-to-home .globalElement5{
			animation: chGlobElem5Ar 3s forwards ease-in-out;
		}
		@keyframes chGlobElem5Ar {
			0% { 
				border-radius: 18px;
				width: 40px;
				height: 40px;
				left: 50px;
				top: 80px;
				transform: rotate(60deg);
				
			}
			70% { 
				border-radius: 18px;
				width: 40px;
				height: 40px;
				left: 50px;
				top: 30px;
				transform: rotate(60deg);
			}
			100% { 
				border-radius: 18px;
				width: 40px;
				height: 40px;
				left: 50px;
				top: 30px;
				transform: rotate(60deg);
			}
		}

		body.rtl.clients-to-home .elementLine4{
			animation: chElem4Ar 3s forwards ease-in-out;
		}
		@keyframes chElem4Ar {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 470px;
				left: 600px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 283px;
				left: 382px;
				border-radius: 54px;
			}
			100% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 392px;
				border-radius: 54px;
			}
		}
		
	}

	@media (max-width: 1399px) {
		
		body.rtl.clients-to-home .globalElement2{
			animation: chElem2Ar 3s forwards ease-in-out;
		}
		@keyframes chElem2Ar {
			0% { 
				border-radius: 50%;
				width: 250px;
				height: 250px;
				right: 465px;
				top: 495px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 650px;
				top: 30px;
				transform: rotate(70deg);
			}
			100% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 650px;
				top: 50px;
				transform: rotate(70deg);
				
			}
		}

		body.rtl.clients-to-home .globalElement4{
			animation: chGlobElem4Ar 3s forwards ease-in-out;
		}
		@keyframes chGlobElem4Ar {
			0% { 
				border-radius: 21px;
				width: 48px;
				height: 48px;
				left: 800px;
				top: 430px;
				transform: rotate(-60deg);
				z-index: -1;
				
			}
			70% { 
				border-radius: 21px;
				width: 48px;
				height: 48px;
				left: 75px;
				top: 340px;
				transform: rotate(60deg);
			}
			100% { 
				border-radius: 21px;
				width: 48px;
				height: 48px;
				left: 85px;
				top: 350px;
				transform: rotate(60deg);
			}
		}

		body.rtl.clients-to-home .globalElement5{
			animation: chGlobElem5Ar 3s forwards ease-in-out;
		}
		@keyframes chGlobElem5Ar {
			0% { 
				border-radius: 18px;
				width: 40px;
				height: 40px;
				left: 40px;
				top: 80px;
				transform: rotate(60deg);
				
			}
			70% { 
				border-radius: 18px;
				width: 40px;
				height: 40px;
				left: 40px;
				top: 30px;
				transform: rotate(60deg);
			}
			100% { 
				border-radius: 18px;
				width: 40px;
				height: 40px;
				left: 40px;
				top: 30px;
				transform: rotate(60deg);
			}
		}

		body.rtl.clients-to-home .elementLine4{
			animation: chElem4Ar 3s forwards ease-in-out;
		}
		@keyframes chElem4Ar {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 470px;
				left: 600px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 283px;
				left: 372px;
				border-radius: 54px;
			}
			100% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 382px;
				border-radius: 54px;
			}
		}
		
	}
	
	@media (max-width: 1350px) {
		
		body.rtl.clients-to-home .globalElement2{
			animation: chElem2Ar 3s forwards ease-in-out;
		}
		@keyframes chElem2Ar {
			0% { 
				border-radius: 50%;
				width: 250px;
				height: 250px;
				right: 405px;
				top: 495px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 550px;
				top: 30px;
				transform: rotate(70deg);
			}
			100% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 550px;
				top: 50px;
				transform: rotate(70deg);
				
			}
		}

		body.rtl.clients-to-home .globalElement4{
			animation: chGlobElem4Ar 3s forwards ease-in-out;
		}
		@keyframes chGlobElem4Ar {
			0% { 
				border-radius: 21px;
				width: 48px;
				height: 48px;
				left: 800px;
				top: 430px;
				transform: rotate(-60deg);
				z-index: -1;
				
			}
			70% { 
				border-radius: 21px;
				width: 48px;
				height: 48px;
				left: 75px;
				top: 340px;
				transform: rotate(60deg);
			}
			100% { 
				border-radius: 21px;
				width: 48px;
				height: 48px;
				left: 85px;
				top: 350px;
				transform: rotate(60deg);
			}
		}

		body.rtl.clients-to-home .globalElement5{
			animation: chGlobElem5Ar 3s forwards ease-in-out;
		}
		@keyframes chGlobElem5Ar {
			0% { 
				border-radius: 18px;
				width: 40px;
				height: 40px;
				left: 25px;
				top: 80px;
				transform: rotate(60deg);
				
			}
			70% { 
				border-radius: 18px;
				width: 40px;
				height: 40px;
				left: 25px;
				top: 30px;
				transform: rotate(60deg);
			}
			100% { 
				border-radius: 18px;
				width: 40px;
				height: 40px;
				left: 25px;
				top: 30px;
				transform: rotate(60deg);
			}
		}

		body.rtl.clients-to-home .elementLine4{
			animation: chElem4Ar 3s forwards ease-in-out;
		}
		@keyframes chElem4Ar {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 470px;
				left: 600px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 283px;
				left: 358px;
				border-radius: 54px;
			}
			100% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 368px;
				border-radius: 54px;
			}
		}
		
	}

	@media (max-width: 1299px) {
		
		body.rtl.clients-to-home .globalElement2{
			animation: chElem2Ar 3s forwards ease-in-out;
		}
		@keyframes chElem2Ar {
			0% { 
				border-radius: 50%;
				width: 250px;
				height: 250px;
				right: 505px;
				top: 495px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 550px;
				top: 30px;
				transform: rotate(70deg);
			}
			100% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 550px;
				top: 50px;
				transform: rotate(70deg);
				
			}
		}

		body.rtl.clients-to-home .globalElement4{
			animation: chGlobElem4Ar 3s forwards ease-in-out;
		}
		@keyframes chGlobElem4Ar {
			0% { 
				border-radius: 21px;
				width: 48px;
				height: 48px;
				left: 650px;
				top: 430px;
				transform: rotate(-60deg);
				z-index: -1;
				
			}
			70% { 
				border-radius: 21px;
				width: 48px;
				height: 48px;
				left: 75px;
				top: 340px;
				transform: rotate(60deg);
			}
			100% { 
				border-radius: 21px;
				width: 48px;
				height: 48px;
				left: 85px;
				top: 350px;
				transform: rotate(60deg);
			}
		}

		body.rtl.clients-to-home .globalElement5{
			animation: chGlobElem5Ar 3s forwards ease-in-out;
		}
		@keyframes chGlobElem5Ar {
			0% { 
				border-radius: 18px;
				width: 40px;
				height: 40px;
				left: 65px;
				top: 80px;
				transform: rotate(60deg);
				
			}
			70% { 
				border-radius: 18px;
				width: 40px;
				height: 40px;
				left: 65px;
				top: 30px;
				transform: rotate(60deg);
			}
			100% { 
				border-radius: 18px;
				width: 40px;
				height: 40px;
				left: 65px;
				top: 30px;
				transform: rotate(60deg);
			}
		}

		body.rtl.clients-to-home .elementLine4{
			animation: chElem4Ar 3s forwards ease-in-out;
		}
		@keyframes chElem4Ar {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 470px;
				left: 450px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 283px;
				left: 348px;
				border-radius: 54px;
			}
			100% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 358px;
				border-radius: 54px;
			}
		}
		
	}

	@media (max-width: 1250px) {
		
		body.rtl.clients-to-home .globalElement2{
			animation: chElem2Ar 3s forwards ease-in-out;
		}
		@keyframes chElem2Ar {
			0% { 
				border-radius: 50%;
				width: 250px;
				height: 250px;
				right: 505px;
				top: 495px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 550px;
				top: 30px;
				transform: rotate(70deg);
			}
			100% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 550px;
				top: 50px;
				transform: rotate(70deg);
				
			}
		}

		body.rtl.clients-to-home .globalElement4{
			animation: chGlobElem4Ar 3s forwards ease-in-out;
		}
		@keyframes chGlobElem4Ar {
			0% { 
				border-radius: 21px;
				width: 48px;
				height: 48px;
				left: 610px;
				top: 430px;
				transform: rotate(-60deg);
				z-index: -1;
				
			}
			70% { 
				border-radius: 21px;
				width: 48px;
				height: 48px;
				left: 75px;
				top: 340px;
				transform: rotate(60deg);
			}
			100% { 
				border-radius: 21px;
				width: 48px;
				height: 48px;
				left: 85px;
				top: 350px;
				transform: rotate(60deg);
			}
		}

		body.rtl.clients-to-home .globalElement5{
			animation: chGlobElem5Ar 3s forwards ease-in-out;
		}
		@keyframes chGlobElem5Ar {
			0% { 
				border-radius: 18px;
				width: 40px;
				height: 40px;
				left: 49px;
				top: 30px;
				transform: rotate(60deg);
			}
			70% { 
				border-radius: 18px;
				width: 40px;
				height: 40px;
				left: 49px;
				top: 90px;
				transform: rotate(60deg);
			}
			100% { 
				border-radius: 18px;
				width: 40px;
				height: 40px;
				left: 49px;
				top: 80px;
				transform: rotate(60deg);
			}
		}

		body.rtl.clients-to-home .elementLine4{
			animation: chElem4Ar 3s forwards ease-in-out;
		}
		@keyframes chElem4Ar {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 470px;
				left: 400px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 283px;
				left: 335px;
				border-radius: 54px;
			}
			100% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 345px;
				border-radius: 54px;
			}
		}
		
	}

	@media (max-width: 1199px) {
		
		body.rtl.clients-to-home .globalElement2{
			animation: chElem2Ar 3s forwards ease-in-out;
		}
		@keyframes chElem2Ar {
			0% { 
				border-radius: 50%;
				width: 250px;
				height: 250px;
				right: 455px;
				top: 495px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 500px;
				top: 30px;
				transform: rotate(70deg);
			}
			100% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 500px;
				top: 50px;
				transform: rotate(70deg);
				
			}
		}

		body.rtl.clients-to-home .globalElement4{
			animation: chGlobElem4Ar 3s forwards ease-in-out;
		}
		@keyframes chGlobElem4Ar {
			0% { 
				border-radius: 21px;
				width: 48px;
				height: 48px;
				left: 610px;
				top: 430px;
				transform: rotate(-60deg);
				z-index: -1;
				
			}
			70% { 
				border-radius: 21px;
				width: 48px;
				height: 48px;
				left: 75px;
				top: 340px;
				transform: rotate(60deg);
			}
			100% { 
				border-radius: 21px;
				width: 48px;
				height: 48px;
				left: 85px;
				top: 350px;
				transform: rotate(60deg);
			}
		}

		body.rtl.clients-to-home .globalElement5{
			animation: chGlobElem5Ar 3s forwards ease-in-out;
		}
		@keyframes chGlobElem5Ar {
			0% { 
				border-radius: 18px;
				width: 40px;
				height: 40px;
				left: 79px;
				top: 80px;
				transform: rotate(60deg);
				
			}
			70% { 
				border-radius: 18px;
				width: 40px;
				height: 40px;
				left: 79px;
				top: 30px;
				transform: rotate(60deg);
			}
			100% { 
				border-radius: 18px;
				width: 40px;
				height: 40px;
				left: 79px;
				top: 30px;
				transform: rotate(60deg);
			}
		}

		body.rtl.clients-to-home .elementLine4{
			animation: chElem4Ar 3s forwards ease-in-out;
		}
		@keyframes chElem4Ar {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 470px;
				left: 400px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 283px;
				left: 325px;
				border-radius: 54px;
			}
			100% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 335px;
				border-radius: 54px;
			}
		}
		
	}

	@media (max-width: 1150px) {
		
		body.rtl.clients-to-home .globalElement2{
			animation: chElem2Ar 3s forwards ease-in-out;
		}
		@keyframes chElem2Ar {
			0% { 
				border-radius: 50%;
				width: 250px;
				height: 250px;
				right: 455px;
				top: 495px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 500px;
				top: 30px;
				transform: rotate(70deg);
			}
			100% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 500px;
				top: 50px;
				transform: rotate(70deg);
				
			}
		}

		body.rtl.clients-to-home .globalElement4{
			animation: chGlobElem4Ar 3s forwards ease-in-out;
		}
		@keyframes chGlobElem4Ar {
			0% { 
				border-radius: 21px;
				width: 48px;
				height: 48px;
				left: 550px;
				top: 430px;
				transform: rotate(-60deg);
				z-index: -1;
				
			}
			70% { 
				border-radius: 21px;
				width: 48px;
				height: 48px;
				left: 75px;
				top: 340px;
				transform: rotate(60deg);
			}
			100% { 
				border-radius: 21px;
				width: 48px;
				height: 48px;
				left: 85px;
				top: 350px;
				transform: rotate(60deg);
			}
		}

		body.rtl.clients-to-home .globalElement5{
			animation: chGlobElem5Ar 3s forwards ease-in-out;
		}
		@keyframes chGlobElem5Ar {
			0% { 
				border-radius: 18px;
				width: 40px;
				height: 40px;
				left: 69px;
				top: 80px;
				transform: rotate(60deg);
				
			}
			70% { 
				border-radius: 18px;
				width: 40px;
				height: 40px;
				left: 69px;
				top: 30px;
				transform: rotate(60deg);
			}
			100% { 
				border-radius: 18px;
				width: 40px;
				height: 40px;
				left: 69px;
				top: 30px;
				transform: rotate(60deg);
			}
		}

		body.rtl.clients-to-home .elementLine4{
			animation: chElem4Ar 3s forwards ease-in-out;
		}
		@keyframes chElem4Ar {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 470px;
				left: 350px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 281px;
				left: 315px;
				border-radius: 54px;
			}
			100% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 291px;
				left: 325px;
				border-radius: 54px;
			}
		}
		
	}

	@media (max-width: 1099px) {
		
		body.rtl.clients-to-home .globalElement2{
			animation: chElem2Ar 3s forwards ease-in-out;
		}
		@keyframes chElem2Ar {
			0% { 
				border-radius: 50%;
				width: 250px;
				height: 250px;
				right: 405px;
				top: 495px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 450px;
				top: 30px;
				transform: rotate(70deg);
			}
			100% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 450px;
				top: 50px;
				transform: rotate(70deg);
				
			}
		}

		body.rtl.clients-to-home .globalElement4{
			animation: chGlobElem4Ar 3s forwards ease-in-out;
		}
		@keyframes chGlobElem4Ar {
			0% { 
				border-radius: 21px;
				width: 48px;
				height: 48px;
				left: 550px;
				top: 430px;
				transform: rotate(-60deg);
				z-index: -1;
				
			}
			70% { 
				border-radius: 21px;
				width: 48px;
				height: 48px;
				left: 75px;
				top: 340px;
				transform: rotate(60deg);
			}
			100% { 
				border-radius: 21px;
				width: 48px;
				height: 48px;
				left: 85px;
				top: 350px;
				transform: rotate(60deg);
			}
		}

		body.rtl.clients-to-home .globalElement5{
			animation: chGlobElem5Ar 3s forwards ease-in-out;
		}
		@keyframes chGlobElem5Ar {
			0% { 
				border-radius: 18px;
				width: 40px;
				height: 40px;
				left: 55px;
				top: 80px;
				transform: rotate(60deg);
				
			}
			70% { 
				border-radius: 18px;
				width: 40px;
				height: 40px;
				left: 55px;
				top: 30px;
				transform: rotate(60deg);
			}
			100% { 
				border-radius: 18px;
				width: 40px;
				height: 40px;
				left: 55px;
				top: 30px;
				transform: rotate(60deg);
			}
		}

		body.rtl.clients-to-home .elementLine4{
			animation: chElem4Ar 3s forwards ease-in-out;
		}
		@keyframes chElem4Ar {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 470px;
				left: 350px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 281px;
				left: 305px;
				border-radius: 54px;
			}
			100% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 291px;
				left: 315px;
				border-radius: 54px;
			}
		}
		
	}

	@media (max-width: 1050px) {
		
		body.rtl.clients-to-home .globalElement2{
			animation: chElem2Ar 3s forwards ease-in-out;
		}
		@keyframes chElem2Ar {
			0% { 
				border-radius: 50%;
				width: 250px;
				height: 250px;
				right: 355px;
				top: 495px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 450px;
				top: 30px;
				transform: rotate(70deg);
			}
			100% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 450px;
				top: 50px;
				transform: rotate(70deg);
				
			}
		}

		body.rtl.clients-to-home .globalElement4{
			animation: chGlobElem4Ar 3s forwards ease-in-out;
		}
		@keyframes chGlobElem4Ar {
			0% { 
				border-radius: 21px;
				width: 48px;
				height: 48px;
				left: 550px;
				top: 430px;
				transform: rotate(-60deg);
				z-index: -1;
				
			}
			70% { 
				border-radius: 21px;
				width: 48px;
				height: 48px;
				left: 75px;
				top: 340px;
				transform: rotate(60deg);
			}
			100% { 
				border-radius: 21px;
				width: 48px;
				height: 48px;
				left: 85px;
				top: 350px;
				transform: rotate(60deg);
			}
		}

		body.rtl.clients-to-home .globalElement5{
			animation: chGlobElem5Ar 3s forwards ease-in-out;
		}
		@keyframes chGlobElem5Ar {
			0% { 
				border-radius: 18px;
				width: 40px;
				height: 40px;
				left: 42px;
				top: 80px;
				transform: rotate(60deg);
				
			}
			70% { 
				border-radius: 18px;
				width: 40px;
				height: 40px;
				left: 42px;
				top: 46px;
				transform: rotate(60deg);
			}
			100% { 
				border-radius: 18px;
				width: 40px;
				height: 40px;
				left: 42px;
				top: 46px;
				transform: rotate(60deg);
			}
		}

		body.rtl.clients-to-home .elementLine4{
			animation: chElem4Ar 3s forwards ease-in-out;
		}
		@keyframes chElem4Ar {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 470px;
				left: 350px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 281px;
				left: 290px;
				border-radius: 54px;
			}
			100% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 291px;
				left: 300px;
				border-radius: 54px;
			}
		}
		
	}


/******************** Animate Contact Us To ALL **********************/

	/******************* Animate Contact Us To Home **********************/

	@media (max-width: 1550px) {

		body.rtl.contact-to-home .globalElement2{
			animation: CthElem2Ar 3s forwards ease-in-out;
		}
		@keyframes CthElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1370px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 730px;
				top: 50px;
				transform: rotate(70deg);
			}
			100% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 750px;
				top: 50px;
				transform: rotate(70deg);
				
			}
		}

		body.rtl.contact-to-home .elementLine4{
			animation: CthElem4Ar 3s forwards ease-in-out;
		}
		@keyframes CthElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				left: 1400px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 392px;
				border-radius: 54px;
			}
			100% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 412px;
				border-radius: 54px;
			}
		}

	}

	@media (max-width: 1440px) {

		body.rtl.contact-to-home .globalElement2{
			animation: CthElem2Ar 3s forwards ease-in-out;
		}
		@keyframes CthElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1320px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 730px;
				top: 50px;
				transform: rotate(70deg);
			}
			100% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 750px;
				top: 50px;
				transform: rotate(70deg);
				
			}
		}

		body.rtl.contact-to-home .elementLine4{
			animation: CthElem4Ar 3s forwards ease-in-out;
		}
		@keyframes CthElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				left: 1350px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 372px;
				border-radius: 54px;
			}
			100% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 392px;
				border-radius: 54px;
			}
		}

	}

	@media (max-width: 1399px) {

		body.rtl.contact-to-home .globalElement2{
			animation: CthElem2Ar 3s forwards ease-in-out;
		}
		@keyframes CthElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1270px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 630px;
				top: 50px;
				transform: rotate(70deg);
			}
			100% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 650px;
				top: 50px;
				transform: rotate(70deg);
				
			}
		}

		body.rtl.contact-to-home .elementLine4{
			animation: CthElem4Ar 3s forwards ease-in-out;
		}
		@keyframes CthElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				left: 1300px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 362px;
				border-radius: 54px;
			}
			100% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 382px;
				border-radius: 54px;
			}
		}

	}
	
	@media (max-width: 1350px) {

		body.rtl.contact-to-home .globalElement2{
			animation: CthElem2Ar 3s forwards ease-in-out;
		}
		@keyframes CthElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1220px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 530px;
				top: 50px;
				transform: rotate(70deg);
			}
			100% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 550px;
				top: 50px;
				transform: rotate(70deg);
				
			}
		}

		body.rtl.contact-to-home .elementLine4{
			animation: CthElem4Ar 3s forwards ease-in-out;
		}
		@keyframes CthElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				left: 1250px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 348px;
				border-radius: 54px;
			}
			100% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 368px;
				border-radius: 54px;
			}
		}

	}
	
	@media (max-width: 1299px) {

		body.rtl.contact-to-home .globalElement2{
			animation: CthElem2Ar 3s forwards ease-in-out;
		}
		@keyframes CthElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1170px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 530px;
				top: 50px;
				transform: rotate(70deg);
			}
			100% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 550px;
				top: 50px;
				transform: rotate(70deg);
				
			}
		}

		body.rtl.contact-to-home .elementLine4{
			animation: CthElem4Ar 3s forwards ease-in-out;
		}
		@keyframes CthElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				left: 1200px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 338px;
				border-radius: 54px;
			}
			100% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 358px;
				border-radius: 54px;
			}
		}

	}

	@media (max-width: 1250px) {

		body.rtl.contact-to-home .globalElement2{
			animation: CthElem2Ar 3s forwards ease-in-out;
		}
		@keyframes CthElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1120px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 530px;
				top: 50px;
				transform: rotate(70deg);
			}
			100% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 550px;
				top: 50px;
				transform: rotate(70deg);
				
			}
		}

		body.rtl.contact-to-home .elementLine4{
			animation: CthElem4Ar 3s forwards ease-in-out;
		}
		@keyframes CthElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				left: 1150px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 325px;
				border-radius: 54px;
			}
			100% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 345px;
				border-radius: 54px;
			}
		}

	}
	
	@media (max-width: 1199px) {

		body.rtl.contact-to-home .globalElement2{
			animation: CthElem2Ar 3s forwards ease-in-out;
		}
		@keyframes CthElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1070px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 480px;
				top: 50px;
				transform: rotate(70deg);
			}
			100% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 500px;
				top: 50px;
				transform: rotate(70deg);
				
			}
		}

		body.rtl.contact-to-home .elementLine4{
			animation: CthElem4Ar 3s forwards ease-in-out;
		}
		@keyframes CthElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				left: 1100px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 315px;
				border-radius: 54px;
			}
			100% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				left: 335px;
				border-radius: 54px;
			}
		}

	}
	
	@media (max-width: 1150px) {

		body.rtl.contact-to-home .globalElement2{
			animation: CthElem2Ar 3s forwards ease-in-out;
		}
		@keyframes CthElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1070px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 480px;
				top: 50px;
				transform: rotate(70deg);
			}
			100% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 500px;
				top: 50px;
				transform: rotate(70deg);
				
			}
		}

		body.rtl.contact-to-home .elementLine4{
			animation: CthElem4Ar 3s forwards ease-in-out;
		}
		@keyframes CthElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				left: 1050px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 291px;
				left: 305px;
				border-radius: 54px;
			}
			100% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 291px;
				left: 325px;
				border-radius: 54px;
			}
		}

	}

	@media (max-width: 1099px) {

		body.rtl.contact-to-home .globalElement2{
			animation: CthElem2Ar 3s forwards ease-in-out;
		}
		@keyframes CthElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 1020px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 430px;
				top: 50px;
				transform: rotate(70deg);
			}
			100% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 450px;
				top: 50px;
				transform: rotate(70deg);
				
			}
		}

		body.rtl.contact-to-home .elementLine4{
			animation: CthElem4Ar 3s forwards ease-in-out;
		}
		@keyframes CthElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				left: 1000px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 291px;
				left: 295px;
				border-radius: 54px;
			}
			100% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 291px;
				left: 315px;
				border-radius: 54px;
			}
		}

	}

	@media (max-width: 1050px) {

		body.rtl.contact-to-home .globalElement2{
			animation: CthElem2Ar 3s forwards ease-in-out;
		}
		@keyframes CthElem2Ar {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				right: 970px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 430px;
				top: 50px;
				transform: rotate(70deg);
			}
			100% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				right: 450px;
				top: 50px;
				transform: rotate(70deg);
				
			}
		}

		body.rtl.contact-to-home .elementLine4{
			animation: CthElem4Ar 3s forwards ease-in-out;
		}
		@keyframes CthElem4Ar {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				left: 950px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 291px;
				left: 280px;
				border-radius: 54px;
			}
			100% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 291px;
				left: 300px;
				border-radius: 54px;
			}
		}

	}
	

	