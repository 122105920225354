.rtl{
	.globalElement1{
		border-radius: 17px;
		width: 40px;
		height: 40px;
		right: 200px;
		top: 0px;
		transform: rotate(70deg);
		background: linear-gradient(120deg, rgba(255, 255, 255, .8) 0%, rgba(255, 255, 255, 0) 100%);
		// backdrop-filter: blur(10px);
		// -webkit-backdrop-filter: blur(10px);
		
	}
	.globalElement2{
		border-radius: 35px;
		width: 100px;
		height: 100px;
		right: 750px;
		top: 50px;
		transform: rotate(70deg);
		background: linear-gradient(120deg, rgba(255, 255, 255, .8) 0%, rgba(255, 255, 255, 0) 100%);
	}
	.globalElement3{
		border-radius: 80px;
		width: 250px;
		height: 250px;
		right: 95%;
		bottom: -100px;
		transform: rotate(70deg);
		background: linear-gradient(120deg, rgba(255, 255, 255, .8) 0%, rgba(255, 255, 255, 0) 100%);
	}
	.globalElement4{
		border-radius: 21px;
		width: 48px;
		height: 48px;
		left: 135px;
		top: 350px;
		transform: rotate(60deg);
		background: linear-gradient(120deg, rgba(255, 255, 255, .8) 0%, rgba(255, 255, 255, 0) 100%);
	}
	.globalElement5{
		border-radius: 18px;
		width: 40px;
		height: 40px;
		left: 80px;
		top: 30px;
		transform: rotate(60deg);
		background: linear-gradient(120deg, rgba(255, 255, 255, .8) 0%, rgba(255, 255, 255, 0) 100%);
	}


	.elementLine4{
		z-index: 0;
		width: 144px;
		height: 144px;
		top: 293px;
		left: 425px;
		border-radius: 54px;
		transform: rotate(27deg);
		background: transparent;
		border: 1px solid var(--bluePurple);
		opacity: .7;
	}
	.elementLine4:before{
		content: "";
		position: absolute;
		z-index: 4;
		width: 172px;
		height: 172px;
		top: -14px;
		right: -14px;
		border-radius: 63px;
		transform: rotate(0deg);
		background: transparent;
		border: 1px solid var(--bluePurple);
	}
	.elementLine4:after{
		content: "";
		position: absolute;
		z-index: 4;
		width: 197px;
		height: 196px;
		top: -26px;
		right: -26px;
		border-radius: 70px;
		transform: rotate(0deg);
		background: transparent;
		border: 1px solid var(--bluePurple);
	}

	.elementLine5{
		z-index: 0;
		transform: rotate(27deg);
		background: transparent;
		border: 1px solid var(--bluePurple);
		width: 284px;
		height: 284px;
		top: -6px;
		right: -137px;
		border-radius: 50%;
		opacity: 0;
		// display: none;
	}
}
/******************* Animate Home To About **********************/

body.rtl.home-to-about .globalElement4{
	animation: haGlobElem4Ar 1s forwards ease-in-out;
}
@keyframes haGlobElem4Ar {
	0% { 
		opacity: 1;
	}
	
	100% { 
		opacity: 0;
	}
}
body.rtl.home-to-about .globalElement5{
	animation: haGlobElem5Ar 1s forwards ease-in-out;
}
@keyframes haGlobElem5Ar {
	0% { 
		opacity: 1;
	}
	
	100% { 
		opacity: 0;
	}
}

body.rtl.home-to-about .about-img{
	animation: aboutImg 2.5s forwards ease-in-out;
}
@keyframes aboutImg {
	0% { 
		bottom: -600px;
		left: 100px;
		opacity: 0;
	}
	70% { 
		bottom: 10px;
		left: -10px;
		opacity: 1;
	}
	100% { 
		bottom: 0;
		left: 0;
		opacity: 1;
	}
}

body.rtl.about-to-home .about-img{
	animation: aboutImgOut 2.5s forwards ease-in-out;
}
@keyframes aboutImgOut {
	0% { 
		bottom: 0;
		left: 0;
		opacity: 1;
		
	}
	100% { 
		bottom: -600px;
		left: 100px;
		opacity: 0;
	}
}

body.rtl.home-to-about .globalElement1{
	animation: haElem1Ar 3s forwards ease-in-out;
}
@keyframes haElem1Ar {
	0% { 
		border-radius: 17px;
		width: 40px;
		height: 40px;
		left: 200px;
		top: 0px;
		transform: rotate(70deg);
		background: linear-gradient(120deg, rgba(255, 255, 255, .8) 0%, rgba(255, 255, 255, 0) 100%);
	}
	50% { 
		left: 340px;
		top: 350px;
		width: 160px;
		height: 160px;
		border-radius: 47px;
		transform: rotate(120deg);
		/* Add the blur effect */
		backdrop-filter: blur(10px);
		-webkit-backdrop-filter: blur(10px);
		z-index: 5;
	}
	100% { 
		left: 290px;
		top: 325px;
		width: 160px;
		height: 160px;
		border-radius: 47px;
		transform: rotate(120deg);
		/* Add the blur effect */
		backdrop-filter: blur(10px);
		-webkit-backdrop-filter: blur(10px);
		z-index: 5;
		
	}
}
body.rtl.home-to-about .globalElement2{
	animation: haElem2Ar 3s forwards ease-in-out;
}

@keyframes haElem2Ar {
	0% { 
		border-radius: 35px;
		width: 100px;
		height: 100px;
		right: 750px;
		left: unset;
		top: 50px;
		transform: rotate(70deg);
	}
	70% { 
		border-radius: 55px;
		width: 150px;
		height: 150px;
		right: 1450px;
		left: unset;
		top: 30px;
		transform: rotate(140deg);
	}
	100% { 
		border-radius: 55px;
		width: 150px;
		height: 150px;
		right: 1420px;
		left: unset;
		top: 30px;
		transform: rotate(135deg);
		
	}
}

body.rtl.home-to-about .globalElement3{
	animation: haElem3Ar 3s forwards ease-in-out;
	
}

@keyframes haElem3Ar {
	0% { 
		border-radius: 80px;
		width: 250px;
		height: 250px;
		right: 95%;
		bottom: -100px;
		left: unset;
		transform: rotate(70deg);
		background: linear-gradient(120deg, rgba(255, 255, 255, .8) 0%, rgba(255, 255, 255, 0) 100%);
		// transition: all 2s ease;
	}
	70% { 
		border-radius: 80px;
		width: 300px;
		height: 300px;
		left: unset;
		right: 20px;
		bottom: 50px;
		transform: rotate(70deg);
		background: var(--bgGradient);
	}
	100% { 
		border-radius: 80px;
		width: 284px;
		height: 284px;
		left: unset;
		right: 50px;
		bottom: 67px;
		transform: rotate(66deg);
		background: var(--bgGradient);
		
	}
}


body.rtl.home-to-about .elementLine4{
	animation: haElem4Ar 3s forwards ease-in-out;
}
@keyframes haElem4Ar {
	0% { 
		z-index: 0;
		width: 144px;
		height: 144px;
		top: 293px;
		left: 425px;
		border-radius: 54px;
	}
	70% { 
		z-index: -1;
		width: 300px;
		height: 300px;
		top: 120px;
		left: 1460px;
		border-radius: 50%;
	}
	100% { 
		z-index: -1;
		width: 300px;
		height: 300px;
		top: 120px;
		left: 1420px;
		border-radius: 50%;
	}
}

body.rtl.home-to-about .elementLine4:before{
	animation: haElem4BeforeAr 3s forwards ease-in-out;
}
@keyframes haElem4BeforeAr {
	0% { 
		z-index: 4;
		width: 172px;
		height: 172px;
		top: -14px;
		right: -14px;
		border-radius: 63px;
	}
	70% { 
		z-index: -1;
		width: 342px;
		height: 342px;
		top: -22px;
		right: -22px;
		border-radius: 50%;
	}
	100% { 
		z-index: -1;
		width: 342px;
		height: 342px;
		top: -22px;
		right: -22px;
		border-radius: 50%;
	}
}


body.rtl.home-to-about .elementLine4:after{
	animation: haElem4AfterAr 3s forwards ease-in-out;
}
@keyframes haElem4AfterAr {
	0% { 
		width: 197px;
		height: 196px;
		top: -26px;
		left: -26px;
		border-radius: 70px;
	}
	70% { 
		z-index: -1;
		width: 384px;
		height: 384px;
		top: -43px;
		right: -43px;
		border-radius: 50%;
	}
	100% { 
		z-index: -1;
		width: 384px;
		height: 384px;
		top: -43px;
		right: -43px;
		border-radius: 50%;
	}
}





/******************* Animate Home To Services **********************/

body.rtl.home-to-services .globalElement4{
	animation: hsGlobElem4Ar 1s forwards ease-in-out;
}
@keyframes hsGlobElem4Ar {
	0% { 
		opacity: 1;
	}
	
	100% { 
		opacity: 0;
	}
}
body.rtl.home-to-services .globalElement5{
	animation: hsGlobElem5Ar 1s forwards ease-in-out;
}
@keyframes hsGlobElem5Ar {
	0% { 
		opacity: 1;
	}
	
	100% { 
		opacity: 0;
	}
}

body.rtl.home-to-services .globalElement1{
	animation: hsElem1Ar 1s forwards ease-in-out;
}
@keyframes hsElem1Ar {
	0% { 
		opacity: 1;
	}
	
	100% { 
		opacity: 0;
	}
}
body.rtl.home-to-services .globalElement2{
	animation: hsElem2Ar 3s forwards ease-in-out;
}

@keyframes hsElem2Ar {
	0% { 
		border-radius: 35px;
		width: 100px;
		height: 100px;
		right: 750px;
		top: 50px;
		transform: rotate(70deg);
	}
	70% { 
		border-radius: 50%;
		width: 150px;
		height: 150px;
		right: 1450px;
		top: 60px;
		transform: rotate(140deg);
	}
	100% { 
		border-radius: 50%;
		width: 150px;
		height: 150px;
		right: 1420px;
		top: 60px;
		transform: rotate(135deg);
		
	}
}

body.rtl.home-to-services .globalElement3{
	animation: hsElem3Ar 1s forwards ease-in-out;
	
}

@keyframes hsElem3Ar {
	0% { 
		opacity: 1;
	}
	
	100% { 
		opacity: 0;
	}
}


body.rtl.home-to-services .elementLine4{
	animation: hsElem4Ar 3s forwards ease-in-out;
}
@keyframes hsElem4Ar {
	0% { 
		z-index: 0;
		width: 144px;
		height: 144px;
		top: 293px;
		left: 425px;
		border-radius: 54px;
	}
	70% { 
		z-index: -1;
		width: 200px;
		height: 200px;
		top: 36px;
		left: -125px;
		border-radius: 50%;
	}
	100% { 
		z-index: -1;
		width: 200px;
		height: 200px;
		top: 36px;
		left: -95px;
		border-radius: 50%;
	}
}

body.rtl.home-to-services .elementLine4:before{
	animation: hsElem4BeforeAr 3s forwards ease-in-out;
}
@keyframes hsElem4BeforeAr {
	0% { 
		z-index: 4;
		width: 172px;
		height: 172px;
		top: -14px;
		right: -14px;
		border-radius: 63px;
	}
	70% { 
		z-index: -1;
		width: 242px;
		height: 242px;
		top: -22px;
		right: -22px;
		border-radius: 50%;
	}
	100% { 
		z-index: -1;
		width: 242px;
		height: 242px;
		top: -22px;
		right: -22px;
		border-radius: 50%;
	}
}


body.rtl.home-to-services .elementLine4:after{
	animation: hsElem4AfterAr 3s forwards ease-in-out;
}
@keyframes hsElem4AfterAr {
	0% { 
		width: 197px;
		height: 196px;
		top: -26px;
		right: -26px;
		border-radius: 70px;
	}
	70% { 
		z-index: -1;
		width: 284px;
		height: 284px;
		top: -43px;
		right: -43px;
		border-radius: 50%;
	}
	100% { 
		z-index: -1;
		width: 284px;
		height: 284px;
		top: -43px;
		right: -43px;
		border-radius: 50%;
	}
}



body.rtl.home-to-services.modal-open .elementLine5{
	animation: hsElem5 3s forwards ease-in-out;
	
	// display: none;
}
@keyframes hsElem5 {
	0% { 
		z-index: 1100;
		width: 284px;
		height: 284px;
		top: -6px;
		right: -137px;
		border-radius: 50%;
		opacity: 0;
		transform: rotate(27deg);
	}
	70% { 
		transform: rotate(-114deg);
		z-index: 1057;
		width: 325px;
		height: 325px;
		top: 60px;
		right: 1060px;
		border-radius: 100px;
		opacity: 1;
	}
	99% { 
		transform: rotate(-114deg);
		z-index: 1056;
		width: 325px;
		height: 325px;
		top: 60px;
		right: 1034px;
		border-radius: 100px;
		opacity: 1;
	}
	100% { 
		transform: rotate(-114deg);
		z-index: 1056;
		width: 325px;
		height: 325px;
		top: 60px;
		right: 1034px;
		border-radius: 100px;
		opacity: 0;
	}
}



/******************* Animate Home To Team **********************/

body.rtl.home-to-team .globalElement4{
	animation: htGlobElem4Ar 1s forwards ease-in-out;
}
@keyframes htGlobElem4Ar {
	0% { 
		opacity: 1;
	}
	
	100% { 
		opacity: 0;
	}
}
body.rtl.home-to-team .globalElement5{
	animation: htGlobElem5Ar 1s forwards ease-in-out;
}
@keyframes htGlobElem5Ar {
	0% { 
		opacity: 1;
	}
	
	100% { 
		opacity: 0;
	}
}

body.rtl.home-to-team .globalElement1{
	animation: htElem1Ar 1s forwards ease-in-out;
}
@keyframes htElem1Ar {
	0% { 
		opacity: 1;
	}
	
	100% { 
		opacity: 0;
	}
}
body.rtl.home-to-team .globalElement2{
	animation: htElem2Ar 3s forwards ease-in-out;
}

@keyframes htElem2Ar {
	0% { 
		border-radius: 35px;
		width: 100px;
		height: 100px;
		right: 750px;
		top: 50px;
		transform: rotate(70deg);
	}
	70% { 
		border-radius: 50%;
		width: 150px;
		height: 150px;
		right: 1450px;
		top: 40px;
		transform: rotate(140deg);
	}
	100% { 
		border-radius: 50%;
		width: 150px;
		height: 150px;
		right: 1420px;
		top: 40px;
		transform: rotate(135deg);
		
	}
}

body.rtl.home-to-team .globalElement3{
	animation: htElem3Ar 1s forwards ease-in-out;
	
}

@keyframes htElem3Ar {
	0% { 
		opacity: 1;
	}
	
	100% { 
		opacity: 0;
	}
}


body.rtl.home-to-team .elementLine4{
	animation: htElem4Ar 3s forwards ease-in-out;
}
@keyframes htElem4Ar {
	0% { 
		z-index: 0;
		width: 144px;
		height: 144px;
		top: 293px;
		left: 425px;
		border-radius: 54px;
	}
	70% { 
		z-index: -1;
		width: 200px;
		height: 200px;
		top: 445px;
		left: 1430px;
		border-radius: 50%;
	}
	100% { 
		z-index: -1;
		width: 200px;
		height: 200px;
		top: 430px;
		left: 1400px;
		border-radius: 50%;
	}
}

body.rtl.home-to-team .elementLine4:before{
	animation: htElem4BeforeAr 3s forwards ease-in-out;
}
@keyframes htElem4BeforeAr {
	0% { 
		z-index: 4;
		width: 172px;
		height: 172px;
		top: -14px;
		right: -14px;
		border-radius: 63px;
	}
	70% { 
		z-index: -1;
		width: 242px;
		height: 242px;
		top: -22px;
		right: -22px;
		border-radius: 50%;
	}
	100% { 
		z-index: -1;
		width: 242px;
		height: 242px;
		top: -22px;
		right: -22px;
		border-radius: 50%;
	}
}


body.rtl.home-to-team .elementLine4:after{
	animation: htElem4AfterAr 3s forwards ease-in-out;
}
@keyframes htElem4AfterAr {
	0% { 
		width: 197px;
		height: 196px;
		top: -26px;
		left: -26px;
		border-radius: 70px;
	}
	70% { 
		z-index: -1;
		width: 284px;
		height: 284px;
		top: -43px;
		right: -43px;
		border-radius: 50%;
	}
	100% { 
		z-index: -1;
		width: 284px;
		height: 284px;
		top: -43px;
		right: -43px;
		border-radius: 50%;
	}
}




/******************* Animate Home To Portfolio **********************/

body.rtl.home-to-portfolio .globalElement4{
	animation: hpGlobElem4Ar 1s forwards ease-in-out;
}
@keyframes hpGlobElem4Ar {
	0% { 
		opacity: 1;
	}
	
	100% { 
		opacity: 0;
	}
}
body.rtl.home-to-portfolio .globalElement5{
	animation: hpGlobElem5Ar 1s forwards ease-in-out;
}
@keyframes hpGlobElem5Ar {
	0% { 
		opacity: 1;
	}
	
	100% { 
		opacity: 0;
	}
}

body.rtl.home-to-portfolio .globalElement1{
	animation: hpElem1Ar 1s forwards ease-in-out;
}
@keyframes hpElem1Ar {
	0% { 
		opacity: 1;
	}
	
	100% { 
		opacity: 0;
	}
}
body.rtl.home-to-portfolio .globalElement2{
	animation: hpElem2Ar 3s forwards ease-in-out;
}

@keyframes hpElem2Ar {
	0% { 
		border-radius: 35px;
		width: 100px;
		height: 100px;
		right: 750px;
		top: 50px;
		transform: rotate(70deg);
	}
	70% { 
		border-radius: 50%;
		width: 150px;
		height: 150px;
		right: 1450px;
		top: -20px;
		transform: rotate(140deg);
	}
	100% { 
		border-radius: 50%;
		width: 150px;
		height: 150px;
		right: 1420px;
		top: 0px;
		transform: rotate(135deg);
		
	}
}

body.rtl.home-to-portfolio .globalElement3{
	animation: hpElem3Ar 1s forwards ease-in-out;
	
}

@keyframes hpElem3Ar {
	0% { 
		opacity: 1;
	}
	
	100% { 
		opacity: 0;
	}
}


body.rtl.home-to-portfolio .elementLine4{
	animation: hpElem4Ar 3s forwards ease-in-out;
}
@keyframes hpElem4Ar {
	0% { 
		z-index: 0;
		width: 144px;
		height: 144px;
		top: 293px;
		left: 425px;
		border-radius: 54px;
	}
	70% { 
		z-index: -1;
		width: 200px;
		height: 200px;
		top: -10px;
		left: 1480px;
		border-radius: 50%;
	}
	100% { 
		z-index: -1;
		width: 200px;
		height: 200px;
		top: 10px;
		left: 1450px;
		border-radius: 50%;
	}
}

body.rtl.home-to-portfolio .elementLine4:before{
	animation: hpElem4BeforeAr 3s forwards ease-in-out;
}
@keyframes hpElem4BeforeAr {
	0% { 
		z-index: 4;
		width: 172px;
		height: 172px;
		top: -14px;
		right: -14px;
		border-radius: 63px;
	}
	70% { 
		z-index: -1;
		width: 242px;
		height: 242px;
		top: -22px;
		right: -22px;
		border-radius: 50%;
	}
	100% { 
		z-index: -1;
		width: 242px;
		height: 242px;
		top: -22px;
		right: -22px;
		border-radius: 50%;
	}
}


body.rtl.home-to-portfolio .elementLine4:after{
	animation: hpElem4AfterAr 3s forwards ease-in-out;
}
@keyframes hpElem4AfterAr {
	0% { 
		width: 197px;
		height: 196px;
		top: -26px;
		left: -26px;
		border-radius: 70px;
	}
	70% { 
		z-index: -1;
		width: 284px;
		height: 284px;
		top: -43px;
		right: -43px;
		border-radius: 50%;
	}
	100% { 
		z-index: -1;
		width: 284px;
		height: 284px;
		top: -43px;
		right: -43px;
		border-radius: 50%;
	}
}



/******************* Animate Home To Clients **********************/

body.rtl.home-to-clients .globalElement3{
	left: 95%;
	right: unset;
}
body.rtl.home-to-clients .globalElement4{
	animation: hcGlobElem4Ar 3s forwards ease-in-out;
}
@keyframes hcGlobElem4Ar {
	0% { 
		border-radius: 21px;
		width: 48px;
		height: 48px;
		left: 135px;
		top: 350px;
		transform: rotate(60deg);
	}
	70% { 
		border-radius: 21px;
		width: 48px;
		height: 48px;
		left: 810px;
		top: 450px;
		transform: rotate(-60deg);
		z-index: -1;
	}
	100% { 
		border-radius: 21px;
		width: 48px;
		height: 48px;
		left: 800px;
		top: 430px;
		transform: rotate(-60deg);
		z-index: -1;
	}
}
body.rtl.home-to-clients .globalElement5{
	animation: hcGlobElem5Ar 3s forwards ease-in-out;
}
@keyframes hcGlobElem5Ar {
	0% { 
		border-radius: 18px;
		width: 40px;
		height: 40px;
		left: 80px;
		top: 30px;
		transform: rotate(60deg);
	}
	70% { 
		border-radius: 18px;
		width: 40px;
		height: 40px;
		left: 80px;
		top: 90px;
		transform: rotate(60deg);
	}
	100% { 
		border-radius: 18px;
		width: 40px;
		height: 40px;
		left: 80px;
		top: 80px;
		transform: rotate(60deg);
	}
}

body.rtl.home-to-clients .globalElement1{
	animation: hcElem1Ar 3s forwards ease-in-out;
}
@keyframes hcElem1Ar {
	0% { 
		border-radius: 17px;
		width: 40px;
		height: 40px;
		right: 200px;
		top: 0px;
		transform: rotate(70deg);
	}
	70% { 
		border-radius: 35px;
		width: 100px;
		height: 100px;
		right: 90px;
		top: -25px;
		transform: rotate(-70deg);
	}
	100% { 
		border-radius: 35px;
		width: 100px;
		height: 100px;
		right: 100px;
		top: -20px;
		transform: rotate(-70deg);
	}
}
body.rtl.home-to-clients .globalElement2{
	animation: hcElem2Ar 3s forwards ease-in-out;
}

@keyframes hcElem2Ar {
	0% { 
		border-radius: 35px;
		width: 100px;
		height: 100px;
		right: 750px;
		top: 50px;
		transform: rotate(70deg);
	}
	70% { 
		border-radius: 50%;
		width: 250px;
		height: 250px;
		right: 625px;
		top: 525px;
		transform: rotate(140deg);
	}
	100% { 
		border-radius: 50%;
		width: 250px;
		height: 250px;
		right: 625px;
		top: 495px;
		transform: rotate(135deg);
		
	}
}


body.rtl.home-to-clients .elementLine4{
	animation: hcElem4Ar 3s forwards ease-in-out;
}
@keyframes hcElem4Ar {
	0% { 
		z-index: 0;
		width: 144px;
		height: 144px;
		top: 293px;
		left: 425px;
		border-radius: 54px;
	}
	70% { 
		z-index: -1;
		width: 300px;
		height: 300px;
		top: 500px;
		left: 610px;
		border-radius: 50%;
	}
	100% { 
		z-index: -1;
		width: 300px;
		height: 300px;
		top: 470px;
		left: 600px;
		border-radius: 50%;
	}
}

body.rtl.home-to-clients .elementLine4:before{
	animation: hcElem4BeforeAr 3s forwards ease-in-out;
}
@keyframes hcElem4BeforeAr {
	0% { 
		z-index: 4;
		width: 172px;
		height: 172px;
		top: -14px;
		right: -14px;
		border-radius: 63px;
	}
	70% { 
		z-index: -1;
		width: 342px;
		height: 342px;
		top: -22px;
		right: -22px;
		border-radius: 50%;
	}
	100% { 
		z-index: -1;
		width: 342px;
		height: 342px;
		top: -22px;
		right: -22px;
		border-radius: 50%;
	}
}


body.rtl.home-to-clients .elementLine4:after{
	animation: hcElem4AfterAr 3s forwards ease-in-out;
}
@keyframes hcElem4AfterAr {
	0% { 
		width: 197px;
		height: 196px;
		top: -26px;
		left: -26px;
		border-radius: 70px;
	}
	70% { 
		z-index: -1;
		width: 384px;
		height: 384px;
		top: -43px;
		right: -43px;
		border-radius: 50%;
	}
	100% { 
		z-index: -1;
		width: 384px;
		height: 384px;
		top: -43px;
		right: -43px;
		border-radius: 50%;
	}
}



body.rtl.home-to-clients.modal-open .elementLine5{
	animation: hcElem5Ar 3s forwards ease-in-out;
	
	// display: none;
}
@keyframes hcElem5Ar {
	0% { 
		z-index: 1100;
		width: 284px;
		height: 284px;
		top: -6px;
		right: -137px;
		border-radius: 50%;
		opacity: 0;
		transform: rotate(27deg);
	}
	70% { 
		transform: rotate(-114deg);
		z-index: 1057;
		width: 325px;
		height: 325px;
		top: 60px;
		right: 1060px;
		border-radius: 100px;
		opacity: 1;
	}
	99% { 
		transform: rotate(-114deg);
		z-index: 1056;
		width: 325px;
		height: 325px;
		top: 60px;
		right: 1034px;
		border-radius: 100px;
		opacity: 1;
	}
	100% { 
		transform: rotate(-114deg);
		z-index: 1056;
		width: 325px;
		height: 325px;
		top: 60px;
		right: 1034px;
		border-radius: 100px;
		opacity: 0;
	}
}




/******************* Animate Home To Contact Us **********************/

body.rtl.home-to-contact .globalElement4{
	animation: hCtGlobElem4Ar 1s forwards ease-in-out;
}
@keyframes hCtGlobElem4Ar {
	0% { 
		opacity: 1;
	}
	
	100% { 
		opacity: 0;
	}
}
body.rtl.home-to-contact .globalElement5{
	animation: hCtGlobElem5Ar 1s forwards ease-in-out;
}
@keyframes hCtGlobElem5Ar {
	0% { 
		opacity: 1;
	}
	
	100% { 
		opacity: 0;
	}
}

body.rtl.home-to-contact .globalElement1{
	animation: hCtElem1Ar 1s forwards ease-in-out;
}
@keyframes hCtElem1Ar {
	0% { 
		opacity: 1;
	}
	
	100% { 
		opacity: 0;
	}
}
body.rtl.home-to-contact .globalElement2{
	animation: hCtElem2Ar 3s forwards ease-in-out;
}

@keyframes hCtElem2Ar {
	0% { 
		border-radius: 35px;
		width: 100px;
		height: 100px;
		right: 750px;
		top: 50px;
		transform: rotate(70deg);
	}
	70% { 
		border-radius: 50%;
		width: 150px;
		height: 150px;
		right: 1450px;
		top: -20px;
		transform: rotate(140deg);
	}
	100% { 
		border-radius: 50%;
		width: 150px;
		height: 150px;
		right: 1420px;
		top: 0px;
		transform: rotate(135deg);
		
	}
}

body.rtl.home-to-contact .globalElement3{
	animation: hCtElem3Ar 1s forwards ease-in-out;
	
}

@keyframes hCtElem3Ar {
	0% { 
		opacity: 1;
	}
	
	100% { 
		opacity: 0;
	}
}


body.rtl.home-to-contact .elementLine4{
	animation: hCtElem4Ar 3s forwards ease-in-out;
}
@keyframes hCtElem4Ar {
	0% { 
		z-index: 0;
		width: 144px;
		height: 144px;
		top: 293px;
		left: 425px;
		border-radius: 54px;
	}
	70% { 
		z-index: -1;
		width: 200px;
		height: 200px;
		top: 180px;
		left: 1480px;
		border-radius: 50%;
	}
	100% { 
		z-index: -1;
		width: 200px;
		height: 200px;
		top: 180px;
		left: 1450px;
		border-radius: 50%;
	}
}

body.rtl.home-to-contact .elementLine4:before{
	animation: hCtElem4BeforeAr 3s forwards ease-in-out;
}
@keyframes hCtElem4BeforeAr {
	0% { 
		z-index: 4;
		width: 172px;
		height: 172px;
		top: -14px;
		right: -14px;
		border-radius: 63px;
	}
	70% { 
		z-index: -1;
		width: 242px;
		height: 242px;
		top: -22px;
		right: -22px;
		border-radius: 50%;
	}
	100% { 
		z-index: -1;
		width: 242px;
		height: 242px;
		top: -22px;
		right: -22px;
		border-radius: 50%;
	}
}


body.rtl.home-to-contact .elementLine4:after{
	animation: hCtElem4AfterAr 3s forwards ease-in-out;
}
@keyframes hCtElem4AfterAr {
	0% { 
		width: 197px;
		height: 196px;
		top: -26px;
		left: -26px;
		border-radius: 70px;
	}
	70% { 
		z-index: -1;
		width: 284px;
		height: 284px;
		top: -43px;
		right: -43px;
		border-radius: 50%;
	}
	100% { 
		z-index: -1;
		width: 284px;
		height: 284px;
		top: -43px;
		right: -43px;
		border-radius: 50%;
	}
}




/******************* Animate Home To FAQs **********************/

body.rtl.home-to-faqs .globalElement4{
	animation: hfGlobElem4Ar 1s forwards ease-in-out;
}
@keyframes hfGlobElem4Ar {
	0% { 
		opacity: 1;
	}
	
	100% { 
		opacity: 0;
	}
}
body.rtl.home-to-faqs .globalElement5{
	animation: hfGlobElem5Ar 1s forwards ease-in-out;
}
@keyframes hfGlobElem5Ar {
	0% { 
		opacity: 1;
	}
	
	100% { 
		opacity: 0;
	}
}

body.rtl.home-to-faqs .globalElement1{
	animation: hfElem1Ar 1s forwards ease-in-out;
}
@keyframes hfElem1Ar {
	0% { 
		opacity: 1;
	}
	
	100% { 
		opacity: 0;
	}
}
body.rtl.home-to-faqs .globalElement2{
	animation: hfElem2Ar 3s forwards ease-in-out;
}

@keyframes hfElem2Ar {
	0% { 
		border-radius: 35px;
		width: 100px;
		height: 100px;
		right: 750px;
		top: 50px;
		transform: rotate(70deg);
	}
	70% { 
		border-radius: 50%;
		width: 150px;
		height: 150px;
		right: 1450px;
		top: -20px;
		transform: rotate(140deg);
	}
	100% { 
		border-radius: 50%;
		width: 150px;
		height: 150px;
		right: 1420px;
		top: 0px;
		transform: rotate(135deg);
		
	}
}

body.rtl.home-to-faqs .globalElement3{
	animation: hfElem3Ar 1s forwards ease-in-out;
	
}

@keyframes hfElem3Ar {
	0% { 
		opacity: 1;
	}
	
	100% { 
		opacity: 0;
	}
}


body.rtl.home-to-faqs .elementLine4{
	animation: hfElem4Ar 3s forwards ease-in-out;
}
@keyframes hfElem4Ar {
	0% { 
		z-index: 0;
		width: 144px;
		height: 144px;
		top: 293px;
		left: 425px;
		border-radius: 54px;
	}
	70% { 
		z-index: -1;
		width: 200px;
		height: 200px;
		top: 180px;
		left: 1480px;
		border-radius: 50%;
	}
	100% { 
		z-index: -1;
		width: 200px;
		height: 200px;
		top: 180px;
		left: 1450px;
		border-radius: 50%;
	}
}

body.rtl.home-to-faqs .elementLine4:before{
	animation: hfElem4BeforeAr 3s forwards ease-in-out;
}
@keyframes hfElem4BeforeAr {
	0% { 
		z-index: 4;
		width: 172px;
		height: 172px;
		top: -14px;
		right: -14px;
		border-radius: 63px;
	}
	70% { 
		z-index: -1;
		width: 242px;
		height: 242px;
		top: -22px;
		right: -22px;
		border-radius: 50%;
	}
	100% { 
		z-index: -1;
		width: 242px;
		height: 242px;
		top: -22px;
		right: -22px;
		border-radius: 50%;
	}
}


body.rtl.home-to-faqs .elementLine4:after{
	animation: hfElem4AfterAr 3s forwards ease-in-out;
}
@keyframes hfElem4AfterAr {
	0% { 
		width: 197px;
		height: 196px;
		top: -26px;
		left: -26px;
		border-radius: 70px;
	}
	70% { 
		z-index: -1;
		width: 284px;
		height: 284px;
		top: -43px;
		right: -43px;
		border-radius: 50%;
	}
	100% { 
		z-index: -1;
		width: 284px;
		height: 284px;
		top: -43px;
		right: -43px;
		border-radius: 50%;
	}
}





/******************* Animate Home To Testimonial **********************/
/* 
body.rtl.home-to-testimonials .globalElement4{
	animation: htesGlobElem4 1s forwards ease-in-out;
}
@keyframes htesGlobElem4 {
	0% { 
		opacity: 1;
	}
	
	100% { 
		opacity: 0;
	}
}
body.rtl.home-to-testimonials .globalElement5{
	animation: htesGlobElem5 1s forwards ease-in-out;
}
@keyframes htesGlobElem5 {
	0% { 
		opacity: 1;
	}
	
	100% { 
		opacity: 0;
	}
}

body.rtl.home-to-testimonials .globalElement1{
	animation: htesElem1 1s forwards ease-in-out;
}
@keyframes htesElem1 {
	0% { 
		opacity: 1;
	}
	
	100% { 
		opacity: 0;
	}
}
body.rtl.home-to-testimonials .globalElement2{
	animation: htesElem2 3s forwards ease-in-out;
}

@keyframes htesElem2 {
	0% { 
		border-radius: 35px;
		width: 100px;
		height: 100px;
		left: 750px;
		top: 50px;
		transform: rotate(70deg);
	}
	70% { 
		border-radius: 50%;
		width: 150px;
		height: 150px;
		left: 1450px;
		top: 40px;
		transform: rotate(140deg);
	}
	100% { 
		border-radius: 50%;
		width: 150px;
		height: 150px;
		left: 1420px;
		top: 40px;
		transform: rotate(135deg);
		
	}
}

body.rtl.home-to-testimonials .globalElement3{
	animation: htesElem3 1s forwards ease-in-out;
	
}

@keyframes htesElem3 {
	0% { 
		opacity: 1;
	}
	
	100% { 
		opacity: 0;
	}
}


body.rtl.home-to-testimonials .elementLine4{
	animation: htesElem4 3s forwards ease-in-out;
}
@keyframes htesElem4 {
	0% { 
		z-index: 0;
		width: 144px;
		height: 144px;
		top: 293px;
		right: 185px;
		border-radius: 54px;
	}
	70% { 
		z-index: -1;
		width: 200px;
		height: 200px;
		top: 445px;
		right: 1430px;
		border-radius: 50%;
	}
	100% { 
		z-index: -1;
		width: 200px;
		height: 200px;
		top: 430px;
		right: 1400px;
		border-radius: 50%;
	}
}

body.rtl.home-to-testimonials .elementLine4:before{
	animation: htesElem4Before 3s forwards ease-in-out;
}
@keyframes htesElem4Before {
	0% { 
		z-index: 4;
		width: 172px;
		height: 172px;
		top: -14px;
		left: -14px;
		border-radius: 63px;
	}
	70% { 
		z-index: -1;
		width: 242px;
		height: 242px;
		top: -22px;
		left: -22px;
		border-radius: 50%;
	}
	100% { 
		z-index: -1;
		width: 242px;
		height: 242px;
		top: -22px;
		left: -22px;
		border-radius: 50%;
	}
}


body.rtl.home-to-testimonials .elementLine4:after{
	animation: htesElem4After 3s forwards ease-in-out;
}
@keyframes htesElem4After {
	0% { 
		width: 197px;
		height: 196px;
		top: -26px;
		right: -26px;
		border-radius: 70px;
	}
	70% { 
		z-index: -1;
		width: 284px;
		height: 284px;
		top: -43px;
		left: -43px;
		border-radius: 50%;
	}
	100% { 
		z-index: -1;
		width: 284px;
		height: 284px;
		top: -43px;
		left: -43px;
		border-radius: 50%;
	}
}
 */



 body.rtl.home-to-testimonials .globalElement4{
	animation: htesGlobElem4Ar 1s forwards ease-in-out;
}
@keyframes htGlobElem4Ar {
	0% { 
		opacity: 1;
	}
	
	100% { 
		opacity: 0;
	}
}
body.rtl.home-to-testimonials .globalElement5{
	animation: htesGlobElem5Ar 1s forwards ease-in-out;
}
@keyframes htesGlobElem5Ar {
	0% { 
		opacity: 1;
	}
	
	100% { 
		opacity: 0;
	}
}

body.rtl.home-to-testimonials .globalElement1{
	animation: htesElem1Ar 1s forwards ease-in-out;
}
@keyframes htesElem1Ar {
	0% { 
		opacity: 1;
	}
	
	100% { 
		opacity: 0;
	}
}
body.rtl.home-to-testimonials .globalElement2{
	animation: htesElem2Ar 3s forwards ease-in-out;
}

@keyframes htesElem2Ar {
	0% { 
		border-radius: 35px;
		width: 100px;
		height: 100px;
		right: 750px;
		top: 50px;
		transform: rotate(70deg);
	}
	70% { 
		border-radius: 50%;
		width: 150px;
		height: 150px;
		right: 1450px;
		top: 40px;
		transform: rotate(140deg);
	}
	100% { 
		border-radius: 50%;
		width: 150px;
		height: 150px;
		right: 1420px;
		top: 40px;
		transform: rotate(135deg);
		
	}
}

body.rtl.home-to-testimonials .globalElement3{
	animation: htesElem3Ar 1s forwards ease-in-out;
	
}

@keyframes htesElem3Ar {
	0% { 
		opacity: 1;
	}
	
	100% { 
		opacity: 0;
	}
}


body.rtl.home-to-testimonials .elementLine4{
	animation: htesElem4Ar 3s forwards ease-in-out;
}
@keyframes htesElem4Ar {
	0% { 
		z-index: 0;
		width: 144px;
		height: 144px;
		top: 293px;
		left: 425px;
		border-radius: 54px;
	}
	70% { 
		z-index: -1;
		width: 200px;
		height: 200px;
		top: 445px;
		left: 1430px;
		border-radius: 50%;
	}
	100% { 
		z-index: -1;
		width: 200px;
		height: 200px;
		top: 430px;
		left: 1400px;
		border-radius: 50%;
	}
}

body.rtl.home-to-testimonials .elementLine4:before{
	animation: htesElem4BeforeAr 3s forwards ease-in-out;
}
@keyframes htesElem4BeforeAr {
	0% { 
		z-index: 4;
		width: 172px;
		height: 172px;
		top: -14px;
		right: -14px;
		border-radius: 63px;
	}
	70% { 
		z-index: -1;
		width: 242px;
		height: 242px;
		top: -22px;
		right: -22px;
		border-radius: 50%;
	}
	100% { 
		z-index: -1;
		width: 242px;
		height: 242px;
		top: -22px;
		right: -22px;
		border-radius: 50%;
	}
}


body.rtl.home-to-testimonials .elementLine4:after{
	animation: htesElem4AfterAr 3s forwards ease-in-out;
}
@keyframes htesElem4AfterAr {
	0% { 
		width: 197px;
		height: 196px;
		top: -26px;
		left: -26px;
		border-radius: 70px;
	}
	70% { 
		z-index: -1;
		width: 284px;
		height: 284px;
		top: -43px;
		right: -43px;
		border-radius: 50%;
	}
	100% { 
		z-index: -1;
		width: 284px;
		height: 284px;
		top: -43px;
		right: -43px;
		border-radius: 50%;
	}
}




/******************* Animate Home To Get A Quote **********************/
.rtl{
	.getQuoteMain{
		position: absolute;
		z-index: 2;
		width: 220px;
		bottom: -30px;
		left: 160px;
	}
	.modalQuoEle1{
		width: 55px;
		left: 145px;
		top: 30px;
	}
	.modalQuoEle2{
		width: 40px;
		left: 325px;
		top: 45px;
	}
	.modalQuoEle3{
		width: 40px;
		left: 363px;
		top: 165px;
	}
	.modalQuoEle4{
		width: 40px;
		left: 150px;
		top: 130px;
	}

}






/******************* Animate About to All **********************/



	/******************* Animate About to Home **********************/
	
	
	body.rtl.about-to-home .about-img{
		animation: aboutImgAr 2.5s forwards ease-in-out;
	}
	@keyframes aboutImgAr {
		0% { 
			bottom: -600px;
			left: 100px;
			opacity: 0;
		}
		70% { 
			bottom: 10px;
			left: -10px;
			opacity: 1;
		}
		100% { 
			bottom: 0;
			left: 0;
			opacity: 1;
		}
	}
	
	body.rtl.about-to-home .about-img{
		animation: aboutImgOutAr 2.5s forwards ease-in-out;
	}
	@keyframes aboutImgOutAr {
		0% { 
			bottom: 0;
			left: 0;
			opacity: 1;
			
		}
		100% { 
			bottom: -600px;
			left: 100px;
			opacity: 0;
		}
	}
	
	body.rtl.about-to-home .globalElement1{
		animation: ahElem1Ar 3s forwards ease-in-out;
	}
	@keyframes ahElem1Ar {
		0% { 
			left: 290px;
			top: 325px;
			width: 160px;
			height: 160px;
			border-radius: 47px;
			transform: rotate(120deg);
			/* Add the blur effect */
			backdrop-filter: blur(10px);
			-webkit-backdrop-filter: blur(10px);
			z-index: 5;
		}
		70% { 
			border-radius: 17px;
			width: 40px;
			height: 40px;
			left: 230px;
			top: -30px;
			transform: rotate(70deg);
			background: linear-gradient(120deg, rgba(255, 255, 255, .8) 0%, rgba(255, 255, 255, 0) 100%);
		}
		100% { 
			
			border-radius: 17px;
			width: 40px;
			height: 40px;
			left: 200px;
			top: 0px;
			transform: rotate(70deg);
			background: linear-gradient(120deg, rgba(255, 255, 255, .8) 0%, rgba(255, 255, 255, 0) 100%);
			
		}
	}
	body.rtl.about-to-home .globalElement2{
		animation: ahElem2Ar 3s forwards ease-in-out;
	}
	
	@keyframes ahElem2Ar {
		0% { 
			border-radius: 55px;
			width: 150px;
			height: 150px;
			right: 1420px;
			left: unset;
			top: 30px;
			transform: rotate(135deg);
		}
		70% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 720px;
			left: unset;
			top: 50px;
			transform: rotate(70deg);
		}
		100% { 
			
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 750px;
			left: unset;
			top: 50px;
			transform: rotate(70deg);
		}
	}
	
	body.rtl.about-to-home .globalElement3{
		animation: ahElem3Ar 3s forwards ease-in-out;
		
	}
	
	@keyframes ahElem3Ar {
		0% { 
			border-radius: 80px;
			width: 284px;
			height: 284px;
			left: unset;
			right: 50px;
			bottom: 67px;
			transform: rotate(66deg);
			background: var(--bgGradient);
			
		}
		70% { 
			border-radius: 80px;
			width: 250px;
			height: 250px;
			right: 96%;
			bottom: -110px;
			left: unset;
			transform: rotate(70deg);
			background: linear-gradient(120deg, rgba(255, 255, 255, .8) 0%, rgba(255, 255, 255, 0) 100%);
			// transition: all 2s ease;
		}
		100% { 
			border-radius: 80px;
			width: 250px;
			height: 250px;
			right: 95%;
			bottom: -100px;
			left: unset;
			transform: rotate(70deg);
			background: linear-gradient(120deg, rgba(255, 255, 255, .8) 0%, rgba(255, 255, 255, 0) 100%);
			// transition: all 2s ease;
			
		}
	}
	body.rtl.about-to-home .globalElement4{
		animation: ahGlobElem4Ar 1s forwards ease-in-out;
	}
	@keyframes ahGlobElem4Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 1;
		}
	}
	body.rtl.about-to-home .globalElement5{
		animation: ahGlobElem5Ar 1s forwards ease-in-out;
	}
	@keyframes ahGlobElem5Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 1;
		}
	}
	
	body.rtl.about-to-home .elementLine4{
		animation: ahElem4Ar 3s forwards ease-in-out;
	}
	@keyframes ahElem4Ar {
		0% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 120px;
			left: 1420px;
			border-radius: 50%;
		}
		70% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 310px;
			left: 395px;
			border-radius: 54px;
		}
		100% { 
			
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			left: 425px;
			border-radius: 54px;
		}
	}
	
	body.rtl.about-to-home .elementLine4:before{
		animation: ahElem4BeforeAr 3s forwards ease-in-out;
	}
	@keyframes ahElem4BeforeAr {
		0% { 
			z-index: -1;
			width: 342px;
			height: 342px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
		70% { 
			z-index: 4;
			width: 172px;
			height: 172px;
			top: -14px;
			right: -14px;
			border-radius: 63px;
		}
		100% { 
			
			z-index: 4;
			width: 172px;
			height: 172px;
			top: -14px;
			right: -14px;
			border-radius: 63px;
		}
	}
	
	
	body.rtl.about-to-home .elementLine4:after{
		animation: ahElem4AfterAr 3s forwards ease-in-out;
	}
	@keyframes ahElem4AfterAr {
		0% { 
			z-index: -1;
			width: 384px;
			height: 384px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
		70% { 
			width: 197px;
			height: 196px;
			top: -26px;
			left: -26px;
			border-radius: 70px;
		}
		100% { 
			
			width: 197px;
			height: 196px;
			top: -26px;
			left: -26px;
			border-radius: 70px;
		}
	}

	/******************* Animate About to Services **********************/

	body.rtl.about-to-services .about-img{
		animation: aboutSrImgAr 2.5s forwards ease-in-out;
	}
	@keyframes aboutSrImgAr {
		0% { 
			bottom: -600px;
			left: 100px;
			opacity: 0;
		}
		70% { 
			bottom: 10px;
			left: -10px;
			opacity: 1;
		}
		100% { 
			bottom: 0;
			left: 0;
			opacity: 1;
		}
	}
	
	body.rtl.about-to-home .about-img{
		animation: aboutImgOutAr 2.5s forwards ease-in-out;
	}
	@keyframes aboutImgOutAr {
		0% { 
			bottom: 0;
			left: 0;
			opacity: 1;
			
		}
		100% { 
			bottom: -600px;
			left: 100px;
			opacity: 0;
		}
	}
	
	body.rtl.about-to-services .globalElement1{
		animation: asElem1Ar 3s forwards ease-in-out;
	}
	@keyframes asElem1Ar {
		0% { 
			left: 290px;
			top: 325px;
			width: 160px;
			height: 160px;
			border-radius: 47px;
			transform: rotate(120deg);
			/* Add the blur effect */
			backdrop-filter: blur(10px);
			-webkit-backdrop-filter: blur(10px);
			z-index: 5;
		}
		
		100% { 
			opacity: 0;
			left: 290px;
			top: 325px;
			width: 160px;
			height: 160px;
			border-radius: 47px;
			transform: rotate(120deg);
			/* Add the blur effect */
			backdrop-filter: blur(10px);
			-webkit-backdrop-filter: blur(10px);
			z-index: 5;
			
		}
	}
	body.rtl.about-to-services .globalElement2{
		animation: asElem2Ar 3s forwards ease-in-out;
	}
	
	@keyframes asElem2Ar {
		0% { 
			border-radius: 55px;
			width: 150px;
			height: 150px;
			right: 1420px;
			left: unset;
			top: 30px;
			transform: rotate(135deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1420px;
			top: 60px;
			transform: rotate(135deg);
			
		}
	}
	
	body.rtl.about-to-services .globalElement3{
		animation: asElem3Ar 3s forwards ease-in-out;
		
	}
	
	@keyframes asElem3Ar {
		0% { 
			border-radius: 80px;
			width: 284px;
			height: 284px;
			left: unset;
			right: 50px;
			bottom: 67px;
			transform: rotate(66deg);
			background: var(--bgGradient);
			
		}
		100% { 
			border-radius: 80px;
			width: 284px;
			height: 284px;
			left: unset;
			right: 50px;
			bottom: 67px;
			transform: rotate(66deg);
			background: var(--bgGradient);
			opacity: 0;
			
		}
	}
	body.rtl.about-to-services .globalElement4{
		animation: asGlobElem4Ar 1s forwards ease-in-out;
	}
	@keyframes asGlobElem4Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}
	body.rtl.about-to-services .globalElement5{
		animation: asGlobElem5Ar 1s forwards ease-in-out;
	}
	@keyframes asGlobElem5Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}
	
	body.rtl.about-to-services .elementLine4{
		animation: asElem4Ar 3s forwards ease-in-out;
	}
	@keyframes asElem4Ar {
		0% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 120px;
			left: 1420px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 36px;
			left: -125px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 36px;
			left: -95px;
			border-radius: 50%;
		}
	}
	
	body.rtl.about-to-services .elementLine4:before{
		animation: asElem4BeforeAr 3s forwards ease-in-out;
	}
	@keyframes asElem4BeforeAr {
		0% { 
			z-index: -1;
			width: 342px;
			height: 342px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
	}
	
	
	body.rtl.about-to-services .elementLine4:after{
		animation: asElem4AfterAr 3s forwards ease-in-out;
	}
	@keyframes asElem4AfterAr {
		0% { 
			z-index: -1;
			width: 384px;
			height: 384px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
	}
	
	/******************* Animate About to Portfolio **********************/
		
		
	body.rtl.about-to-portfolio .globalElement1{
		animation: apElem1Ar 3s forwards ease-in-out;
	}
	@keyframes apElem1Ar {
		0% { 
			left: 290px;
			top: 325px;
			width: 160px;
			height: 160px;
			border-radius: 47px;
			transform: rotate(120deg);
			/* Add the blur effect */
			backdrop-filter: blur(10px);
			-webkit-backdrop-filter: blur(10px);
			z-index: 5;
		}
		100% { 
			
			left: 290px;
			top: 325px;
			width: 160px;
			height: 160px;
			border-radius: 47px;
			transform: rotate(120deg);
			/* Add the blur effect */
			backdrop-filter: blur(10px);
			-webkit-backdrop-filter: blur(10px);
			z-index: 5;
			opacity: 0;
			
		}
	}
	body.rtl.about-to-portfolio .globalElement2{
		animation: apElem2Ar 3s forwards ease-in-out;
	}

	@keyframes apElem2Ar {
		0% { 
			border-radius: 55px;
			width: 150px;
			height: 150px;
			right: 1420px;
			left: unset;
			top: 30px;
			transform: rotate(135deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1450px;
			top: -20px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1420px;
			top: 0px;
			transform: rotate(135deg);
			
		}
	}

	body.rtl.about-to-portfolio .globalElement3{
		animation: apElem3Ar 3s forwards ease-in-out;
		
	}

	@keyframes apElem3Ar {
		0% { 
			border-radius: 80px;
			width: 284px;
			height: 284px;
			left: unset;
			right: 50px;
			bottom: 67px;
			transform: rotate(66deg);
			background: var(--bgGradient);
			
		}
		100% { 
			border-radius: 80px;
			width: 284px;
			height: 284px;
			left: unset;
			right: 50px;
			bottom: 67px;
			transform: rotate(66deg);
			background: var(--bgGradient);
			opacity: 0;
		}
	}
	
	body.rtl.about-to-portfolio .globalElement5{
		animation: apGlobElem5Ar 1s forwards ease-in-out;
	}
	@keyframes apGlobElem5Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.rtl.about-to-portfolio .elementLine4{
		animation: apElem4Ar 3s forwards ease-in-out;
	}
	@keyframes apElem4Ar {
		0% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 120px;
			left: 1420px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: -10px;
			left: 1480px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 10px;
			left: 1450px;
			border-radius: 50%;
		}
	}

	body.rtl.about-to-portfolio .elementLine4:before{
		animation: apElem4BeforeAr 3s forwards ease-in-out;
	}
	@keyframes apElem4BeforeAr {
		0% { 
			z-index: -1;
			width: 342px;
			height: 342px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
	}


	body.rtl.about-to-portfolio .elementLine4:after{
		animation: apElem4AfterAr 3s forwards ease-in-out;
	}
	@keyframes apElem4AfterAr {
		0% { 
			z-index: -1;
			width: 384px;
			height: 384px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
	}

	/******************* Animate About to Team **********************/
			
			
	body.rtl.about-to-team .globalElement1{
		animation: atElem1Ar 3s forwards ease-in-out;
	}
	@keyframes atElem1Ar {
		0% { 
			left: 290px;
			top: 325px;
			width: 160px;
			height: 160px;
			border-radius: 47px;
			transform: rotate(120deg);
			/* Add the blur effect */
			backdrop-filter: blur(10px);
			-webkit-backdrop-filter: blur(10px);
			z-index: 5;
		}
		100% { 
			
			left: 290px;
			top: 325px;
			width: 160px;
			height: 160px;
			border-radius: 47px;
			transform: rotate(120deg);
			/* Add the blur effect */
			backdrop-filter: blur(10px);
			-webkit-backdrop-filter: blur(10px);
			z-index: 5;
			opacity: 0;
			
		}
	}
	body.rtl.about-to-team .globalElement2{
		animation: atElem2Ar 3s forwards ease-in-out;
	}

	@keyframes atElem2Ar {
		0% { 
			border-radius: 55px;
			width: 150px;
			height: 150px;
			right: 1420px;
			left: unset;
			top: 30px;
			transform: rotate(135deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1450px;
			top: 40px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1420px;
			top: 40px;
			transform: rotate(135deg);
			
		}
	}

	body.rtl.about-to-team .globalElement3{
		animation: atElem3Ar 3s forwards ease-in-out;
		
	}

	@keyframes atElem3Ar {
		0% { 
			border-radius: 80px;
			width: 284px;
			height: 284px;
			left: unset;
			right: 50px;
			bottom: 67px;
			transform: rotate(66deg);
			background: var(--bgGradient);
			
		}
		100% { 
			border-radius: 80px;
			width: 284px;
			height: 284px;
			left: unset;
			right: 50px;
			bottom: 67px;
			transform: rotate(66deg);
			background: var(--bgGradient);
			opacity: 0;
		}
	}

	body.rtl.about-to-team .globalElement5{
		animation: atGlobElem5Ar 1s forwards ease-in-out;
	}
	@keyframes atGlobElem5Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.rtl.about-to-team .elementLine4{
		animation: atElem4Ar 3s forwards ease-in-out;
	}
	@keyframes atElem4Ar {
		0% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 120px;
			left: 1420px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 445px;
			left: 1430px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 430px;
			left: 1400px;
			border-radius: 50%;
		}
	}
	
	body.rtl.about-to-team .elementLine4:before{
		animation: atElem4BeforeAr 3s forwards ease-in-out;
	}
	@keyframes atElem4BeforeAr {
		0% { 
			z-index: -1;
			width: 342px;
			height: 342px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
	}


	body.rtl.about-to-team .elementLine4:after{
		animation: atElem4AfterAr 3s forwards ease-in-out;
	}
	@keyframes atElem4AfterAr {
		0% { 
			z-index: -1;
			width: 384px;
			height: 384px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
	}

	/******************* Animate About to Testimonials **********************/
				
				
	body.rtl.about-to-testimonials .globalElement1{
		animation: atesElem1Ar 3s forwards ease-in-out;
	}
	@keyframes atesElem1Ar {
		0% { 
			left: 290px;
			top: 325px;
			width: 160px;
			height: 160px;
			border-radius: 47px;
			transform: rotate(120deg);
			/* Add the blur effect */
			backdrop-filter: blur(10px);
			-webkit-backdrop-filter: blur(10px);
			z-index: 5;
		}
		100% { 
			
			left: 290px;
			top: 325px;
			width: 160px;
			height: 160px;
			border-radius: 47px;
			transform: rotate(120deg);
			/* Add the blur effect */
			backdrop-filter: blur(10px);
			-webkit-backdrop-filter: blur(10px);
			z-index: 5;
			opacity: 0;
			
		}
	}
	body.rtl.about-to-testimonials .globalElement2{
		animation: atesElem2Ar 3s forwards ease-in-out;
	}

	@keyframes atesElem2Ar {
		0% { 
			border-radius: 55px;
			width: 150px;
			height: 150px;
			right: 1420px;
			left: unset;
			top: 30px;
			transform: rotate(135deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1450px;
			top: 40px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1420px;
			top: 40px;
			transform: rotate(135deg);
			
		}
	}

	body.rtl.about-to-testimonials .globalElement3{
		animation: atesElem3Ar 3s forwards ease-in-out;
		
	}

	@keyframes atesElem3Ar {
		0% { 
			border-radius: 80px;
			width: 284px;
			height: 284px;
			left: unset;
			right: 50px;
			bottom: 67px;
			transform: rotate(66deg);
			background: var(--bgGradient);
			
		}
		100% { 
			border-radius: 80px;
			width: 284px;
			height: 284px;
			left: unset;
			right: 50px;
			bottom: 67px;
			transform: rotate(66deg);
			background: var(--bgGradient);
			opacity: 0;
		}
	}

	body.rtl.about-to-testimonials .globalElement5{
		animation: atesGlobElem5Ar 1s forwards ease-in-out;
	}
	@keyframes atesGlobElem5Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.rtl.about-to-testimonials .elementLine4{
		animation: atesElem4Ar 3s forwards ease-in-out;
	}
	@keyframes atesElem4Ar {
		0% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 120px;
			left: 1420px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 445px;
			left: 1430px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 430px;
			left: 1400px;
			border-radius: 50%;
		}
	}

	body.rtl.about-to-testimonials .elementLine4:before{
		animation: atesElem4BeforeAr 3s forwards ease-in-out;
	}
	@keyframes atesElem4BeforeAr {
		0% { 
			z-index: -1;
			width: 342px;
			height: 342px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
	}


	body.rtl.about-to-testimonials .elementLine4:after{
		animation: atesElem4AfterAr 3s forwards ease-in-out;
	}
	@keyframes atesElem4AfterAr {
		0% { 
			z-index: -1;
			width: 384px;
			height: 384px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
	}

	/******************* Animate About to Clients **********************/
					
	body.rtl.about-to-clients .globalElement3{
		left: 95%;
		right: unset;
	}
	body.rtl.about-to-clients .globalElement1{
		animation: acElem1Ar 3s forwards ease-in-out;
	}
	@keyframes acElem1Ar {
		0% { 
			left: 290px;
			top: 325px;
			width: 160px;
			height: 160px;
			border-radius: 47px;
			transform: rotate(120deg);
			/* Add the blur effect */
			backdrop-filter: blur(10px);
			-webkit-backdrop-filter: blur(10px);
			z-index: 5;
		}
		70% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 90px;
			top: -25px;
			transform: rotate(-70deg);
		}
		100% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 100px;
			top: -20px;
			transform: rotate(-70deg);
		}
	}
	body.rtl.about-to-clients .globalElement2{
		animation: acElem2Ar 3s forwards ease-in-out;
	}

	@keyframes acElem2Ar {
		0% { 
			border-radius: 55px;
			width: 150px;
			height: 150px;
			right: 1420px;
			left: unset;
			top: 30px;
			transform: rotate(135deg);
		}
		70% { 
			border-radius: 50%;
			width: 250px;
			height: 250px;
			right: 625px;
			top: 525px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 250px;
			height: 250px;
			right: 625px;
			top: 495px;
			transform: rotate(135deg);
			
		}
	}

	body.rtl.about-to-clients .globalElement3{
		animation: acElem3Ar 3s forwards ease-in-out;
		
	}

	@keyframes acElem3Ar {
		0% { 
			border-radius: 80px;
			width: 284px;
			height: 284px;
			left: unset;
			right: 50px;
			bottom: 67px;
			transform: rotate(66deg);
			background: var(--bgGradient);
			
		}
		100% { 
			border-radius: 80px;
			width: 284px;
			height: 284px;
			left: unset;
			right: 50px;
			bottom: 67px;
			transform: rotate(66deg);
			background: var(--bgGradient);
			opacity: 0;
		}
	}

	body.rtl.about-to-clients .globalElement4{
		animation: acGlobElem4Ar 3s forwards ease-in-out;
	}
	@keyframes acGlobElem4Ar {
		0% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 135px;
			top: 350px;
			transform: rotate(60deg);
			opacity: 1;
		}
		
		70% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 810px;
			top: 450px;
			transform: rotate(-60deg);
			z-index: -1;
		}
		100% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 800px;
			top: 430px;
			transform: rotate(-60deg);
			z-index: -1;
		}
	}

	body.rtl.about-to-clients .globalElement5{
		animation: acGlobElem5Ar 1s forwards ease-in-out;
	}
	@keyframes acGlobElem5Ar {
		0% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			left: 80px;
			top: 30px;
			transform: rotate(60deg);
			opacity: 0;
		}
		
		70% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			left: 80px;
			top: 90px;
			transform: rotate(60deg);
			opacity: 1;
		}
		100% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			left: 80px;
			top: 80px;
			transform: rotate(60deg);
			opacity: 1;
		}
	}

	body.rtl.about-to-clients .elementLine4{
		animation: acElem4Ar 3s forwards ease-in-out;
	}
	@keyframes acElem4Ar {
		0% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 120px;
			left: 1420px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 500px;
			left: 610px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 470px;
			left: 600px;
			border-radius: 50%;
		}
	}

	body.rtl.about-to-clients .elementLine4:before{
		animation: acElem4BeforeAr 3s forwards ease-in-out;
	}
	@keyframes acElem4BeforeAr {
		0% { 
			z-index: -1;
			width: 342px;
			height: 342px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 342px;
			height: 342px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 342px;
			height: 342px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
	}


	body.rtl.about-to-clients .elementLine4:after{
		animation: acElem4AfterAr 3s forwards ease-in-out;
	}
	@keyframes acElem4AfterAr {
		0% { 
			z-index: -1;
			width: 384px;
			height: 384px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 384px;
			height: 384px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 384px;
			height: 384px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
	}

	/******************* Animate About to Contact **********************/
						
	body.rtl.about-to-contact .globalElement1{
		animation: aCtElem1Ar 3s forwards ease-in-out;
	}
	@keyframes aCtElem1Ar {
		0% { 
			left: 290px;
			top: 325px;
			width: 160px;
			height: 160px;
			border-radius: 47px;
			transform: rotate(120deg);
			/* Add the blur effect */
			backdrop-filter: blur(10px);
			-webkit-backdrop-filter: blur(10px);
			z-index: 5;
		}
		100% { 
			
			left: 290px;
			top: 325px;
			width: 160px;
			height: 160px;
			border-radius: 47px;
			transform: rotate(120deg);
			/* Add the blur effect */
			backdrop-filter: blur(10px);
			-webkit-backdrop-filter: blur(10px);
			z-index: 5;
			opacity: 0;
			
		}
	}
	body.rtl.about-to-contact .globalElement2{
		animation: aCtElem2Ar 3s forwards ease-in-out;
	}

	@keyframes aCtElem2Ar {
		0% { 
			border-radius: 55px;
			width: 150px;
			height: 150px;
			right: 1420px;
			left: unset;
			top: 30px;
			transform: rotate(135deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1450px;
			top: -20px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1420px;
			top: 0px;
			transform: rotate(135deg);
			
		}
	}

	body.rtl.about-to-contact .globalElement3{
		animation: aCtElem3Ar 3s forwards ease-in-out;
	}
	@keyframes aCtElem3Ar {
		0% { 
			border-radius: 80px;
			width: 284px;
			height: 284px;
			left: unset;
			right: 50px;
			bottom: 67px;
			transform: rotate(66deg);
			background: var(--bgGradient);
			
		}
		100% { 
			border-radius: 80px;
			width: 284px;
			height: 284px;
			left: unset;
			right: 50px;
			bottom: 67px;
			transform: rotate(66deg);
			background: var(--bgGradient);
			opacity: 0;
		}
	}

	body.rtl.about-to-contact .globalElement5{
		animation: aCtGlobElem5Ar 1s forwards ease-in-out;
	}
	@keyframes aCtGlobElem5Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.rtl.about-to-contact .elementLine4{
		animation: aCtElem4Ar 3s forwards ease-in-out;
	}
	@keyframes aCtElem4Ar {
		0% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 120px;
			left: 1420px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			left: 1480px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			left: 1450px;
			border-radius: 50%;
		}
	}

	body.rtl.about-to-contact .elementLine4:before{
		animation: aCtElem4BeforeAr 3s forwards ease-in-out;
	}
	@keyframes aCtElem4BeforeAr {
		0% { 
			z-index: -1;
			width: 342px;
			height: 342px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
	}


	body.rtl.about-to-contact .elementLine4:after{
		animation: aCtElem4AfterAr 3s forwards ease-in-out;
	}
	@keyframes aCtElem4AfterAr {
		0% { 
			z-index: -1;
			width: 384px;
			height: 384px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
	}
	
	



	
/******************* Animate Services To ALL **********************/

	/******************* Animate Services To Home **********************/

	body.rtl.services-to-home .globalElement4{
		animation: shGlobElem4Ar 1s forwards ease-in-out;
	}
	@keyframes hsGlobElem4Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 1;
		}
	}
	body.rtl.services-to-home .globalElement5{
		animation: shGlobElem5Ar 1s forwards ease-in-out;
	}
	@keyframes shGlobElem5Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 1;
		}
	}

	body.rtl.services-to-home .globalElement1{
		animation: shElem1Ar 1s forwards ease-in-out;
	}
	@keyframes shElem1Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 1;
		}
	}
	body.rtl.services-to-home .globalElement2{
		animation: shElem2Ar 3s forwards ease-in-out;
	}

	@keyframes shElem2Ar {
		0% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1420px;
			top: 60px;
			transform: rotate(135deg);
		}
		70% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 730px;
			top: 50px;
			transform: rotate(70deg);
		}
		100% { 
			
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 750px;
			top: 50px;
			transform: rotate(70deg);
		}
	}

	body.rtl.services-to-home .globalElement3{
		animation: shElem3Ar 1s forwards ease-in-out;
		
	}

	@keyframes shElem3Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 1;
		}
	}


	body.rtl.services-to-home .elementLine4{
		animation: shElem4Ar 3s forwards ease-in-out;
	}
	@keyframes shElem4Ar {
		0% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 36px;
			left: -95px;
			border-radius: 50%;
		}
		70% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			left: 445px;
			border-radius: 54px;
		}
		100% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			left: 425px;
			border-radius: 54px;
		}
	}

	body.rtl.services-to-home .elementLine4:before{
		animation: shElem4BeforeAr 3s forwards ease-in-out;
	}
	@keyframes shElem4BeforeAr {
		0% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
		70% { 
			z-index: 4;
			width: 172px;
			height: 172px;
			top: -14px;
			right: -14px;
			border-radius: 63px;
		}
		100% { 
			
			z-index: 4;
			width: 172px;
			height: 172px;
			top: -14px;
			right: -14px;
			border-radius: 63px;
		}
	}


	body.rtl.services-to-home .elementLine4:after{
		animation: shElem4AfterAr 3s forwards ease-in-out;
	}
	@keyframes shElem4AfterAr {
		0% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
		70% { 
			width: 197px;
			height: 196px;
			top: -26px;
			left: -26px;
			border-radius: 70px;
		}
		100% { 
			
			width: 197px;
			height: 196px;
			top: -26px;
			left: -26px;
			border-radius: 70px;
		}
	}



	body.rtl.home-to-services.modal-open .elementLine5{
		animation: hsElem5 3s forwards ease-in-out;
		
		// display: none;
	}
	@keyframes hsElem5 {
		0% { 
			z-index: 1100;
			width: 284px;
			height: 284px;
			top: -6px;
			right: -137px;
			border-radius: 50%;
			opacity: 0;
			transform: rotate(27deg);
		}
		70% { 
			transform: rotate(-114deg);
			z-index: 1057;
			width: 325px;
			height: 325px;
			top: 60px;
			right: 1060px;
			border-radius: 100px;
			opacity: 1;
		}
		99% { 
			transform: rotate(-114deg);
			z-index: 1056;
			width: 325px;
			height: 325px;
			top: 60px;
			right: 1034px;
			border-radius: 100px;
			opacity: 1;
		}
		100% { 
			transform: rotate(-114deg);
			z-index: 1056;
			width: 325px;
			height: 325px;
			top: 60px;
			right: 1034px;
			border-radius: 100px;
			opacity: 0;
		}
	}

	/******************* Animate Services To About **********************/

	
	body.rtl.services-to-about .about-img{
		animation: seraboutImg 2.5s forwards ease-in-out;
	}
	@keyframes seraboutImg {
		0% { 
			bottom: -600px;
			left: 100px;
			opacity: 0;
		}
		70% { 
			bottom: 10px;
			left: -10px;
			opacity: 1;
		}
		100% { 
			bottom: 0;
			left: 0;
			opacity: 1;
		}
	}


	body.rtl.services-to-about .globalElement1{
		animation: saElem1Ar 3s forwards ease-in-out;
	}
	@keyframes saElem1Ar {
		0% { 
			border-radius: 17px;
			width: 40px;
			height: 40px;
			left: 200px;
			top: 0px;
			transform: rotate(70deg);
			background: linear-gradient(120deg, rgba(255, 255, 255, .8) 0%, rgba(255, 255, 255, 0) 100%);
		}
		50% { 
			left: 340px;
			top: 350px;
			width: 160px;
			height: 160px;
			border-radius: 47px;
			transform: rotate(120deg);
			/* Add the blur effect */
			backdrop-filter: blur(10px);
			-webkit-backdrop-filter: blur(10px);
			z-index: 5;
		}
		100% { 
			left: 290px;
			top: 325px;
			width: 160px;
			height: 160px;
			border-radius: 47px;
			transform: rotate(120deg);
			/* Add the blur effect */
			backdrop-filter: blur(10px);
			-webkit-backdrop-filter: blur(10px);
			z-index: 5;
			
		}
	}

	body.rtl.services-to-about .globalElement2{
		animation: saElem2Ar 3s forwards ease-in-out;
	}

	@keyframes saElem2Ar {
		0% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1420px;
			top: 60px;
			transform: rotate(135deg);
		}
		70% { 
			border-radius: 55px;
			width: 150px;
			height: 150px;
			right: 1450px;
			left: unset;
			top: 30px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 55px;
			width: 150px;
			height: 150px;
			right: 1420px;
			left: unset;
			top: 30px;
			transform: rotate(135deg);
			
		}
	}

	body.rtl.services-to-about .globalElement3{
		animation: saElem3Ar 3s forwards ease-in-out;
		
	}

	@keyframes saElem3Ar {
		0% { 
			border-radius: 80px;
			width: 250px;
			height: 250px;
			right: 95%;
			bottom: -100px;
			left: unset;
			transform: rotate(70deg);
			background: linear-gradient(120deg, rgba(255, 255, 255, .8) 0%, rgba(255, 255, 255, 0) 100%);
			opacity: 1;
		}
		
		70% { 
			border-radius: 80px;
			width: 300px;
			height: 300px;
			left: unset;
			right: 20px;
			bottom: 50px;
			transform: rotate(70deg);
			background: var(--bgGradient);
		}
		100% { 
			border-radius: 80px;
			width: 284px;
			height: 284px;
			left: unset;
			right: 50px;
			bottom: 67px;
			transform: rotate(66deg);
			background: var(--bgGradient);
			
		}
	}

	body.rtl.services-to-about .globalElement4{
		animation: saGlobElem4Ar 1s forwards ease-in-out;
	}
	@keyframes saGlobElem4Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}
	body.rtl.services-to-about .globalElement5{
		animation: saGlobElem5Ar 1s forwards ease-in-out;
	}
	@keyframes saGlobElem5Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}


	body.rtl.services-to-about .elementLine4{
		animation: saElem4Ar 3s forwards ease-in-out;
	}
	@keyframes saElem4Ar {
		0% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 36px;
			left: -95px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 120px;
			left: 1460px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 120px;
			left: 1420px;
			border-radius: 50%;
		}
	}

	body.rtl.services-to-about .elementLine4:before{
		animation: saElem4BeforeAr 3s forwards ease-in-out;
	}
	@keyframes saElem4BeforeAr {
		0% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 342px;
			height: 342px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 342px;
			height: 342px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
	}


	body.rtl.services-to-about .elementLine4:after{
		animation: saElem4AfterAr 3s forwards ease-in-out;
	}
	@keyframes saElem4AfterAr {
		0% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 384px;
			height: 384px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 384px;
			height: 384px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
	}

	/******************* Animate Services To Portfolio **********************/
	
	body.rtl.services-to-portfolio .globalElement1{
		animation: spElem1Ar 3s forwards ease-in-out;
	}
	@keyframes spElem1Ar {
		0% { 
			opacity: 0;
		}
		100% { 
			opacity: 0;
			
		}
	}

	body.rtl.services-to-portfolio .globalElement2{
		animation: spElem2Ar 3s forwards ease-in-out;
	}

	@keyframes spElem2Ar {
		0% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1420px;
			top: 60px;
			transform: rotate(135deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1450px;
			top: -20px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1420px;
			top: 0px;
			transform: rotate(135deg);
			
		}
	}

	body.rtl.services-to-portfolio .globalElement3{
		animation: spElem3Ar 3s forwards ease-in-out;
		
	}

	@keyframes spElem3Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
			
		}
	}

	body.rtl.services-to-portfolio .globalElement4{
		animation: spGlobElem4Ar 1s forwards ease-in-out;
	}
	@keyframes spGlobElem4Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}
	body.rtl.services-to-portfolio .globalElement5{
		animation: spGlobElem5Ar 1s forwards ease-in-out;
	}
	@keyframes spGlobElem5Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}


	body.rtl.services-to-portfolio .elementLine4{
		animation: spElem4Ar 3s forwards ease-in-out;
	}
	@keyframes spElem4Ar {
		0% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 36px;
			left: -95px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: -10px;
			left: 1480px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 10px;
			left: 1450px;
			border-radius: 50%;
		}
	}

	body.rtl.services-to-portfolio .elementLine4:before{
		animation: spElem4BeforeAr 3s forwards ease-in-out;
	}
	@keyframes spElem4BeforeAr {
		0% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
	}


	body.rtl.services-to-portfolio .elementLine4:after{
		animation: spElem4AfterAr 3s forwards ease-in-out;
	}
	@keyframes spElem4AfterAr {
		0% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
	}

	/******************* Animate Services To Team **********************/
		
	body.rtl.services-to-team .globalElement1{
		animation: stElem1Ar 3s forwards ease-in-out;
	}
	@keyframes stElem1Ar {
		0% { 
			opacity: 0;
		}
		100% { 
			opacity: 0;
			
		}
	}

	body.rtl.services-to-team .globalElement2{
		animation: stElem2Ar 3s forwards ease-in-out;
	}

	@keyframes stElem2Ar {
		0% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1420px;
			top: 60px;
			transform: rotate(135deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1450px;
			top: 40px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1420px;
			top: 40px;
			transform: rotate(135deg);
			
		}
	}

	body.rtl.services-to-team .globalElement3{
		animation: stElem3Ar 3s forwards ease-in-out;
		
	}

	@keyframes stElem3Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
			
		}
	}

	body.rtl.services-to-team .globalElement4{
		animation: stGlobElem4Ar 1s forwards ease-in-out;
	}
	@keyframes stGlobElem4Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}
	body.rtl.services-to-team .globalElement5{
		animation: stGlobElem5Ar 1s forwards ease-in-out;
	}
	@keyframes stGlobElem5Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}


	body.rtl.services-to-team .elementLine4{
		animation: stElem4Ar 3s forwards ease-in-out;
	}
	@keyframes stElem4Ar {
		0% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 36px;
			left: -95px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 445px;
			left: 1430px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 430px;
			left: 1400px;
			border-radius: 50%;
		}
	}

	body.rtl.services-to-team .elementLine4:before{
		animation: stElem4BeforeAr 3s forwards ease-in-out;
	}
	@keyframes stElem4BeforeAr {
		0% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
	}


	body.rtl.services-to-team .elementLine4:after{
		animation: stElem4AfterAr 3s forwards ease-in-out;
	}
	@keyframes stElem4AfterAr {
		0% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
	}

	/******************* Animate Services To Testimonials **********************/
		
	body.rtl.services-to-testimonials .globalElement1{
		animation: stesElem1Ar 3s forwards ease-in-out;
	}
	@keyframes stesElem1Ar {
		0% { 
			opacity: 0;
		}
		100% { 
			opacity: 0;
			
		}
	}

	body.rtl.services-to-testimonials .globalElement2{
		animation: stesElem2Ar 3s forwards ease-in-out;
	}

	@keyframes stesElem2Ar {
		0% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1420px;
			top: 60px;
			transform: rotate(135deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1450px;
			top: 40px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1420px;
			top: 40px;
			transform: rotate(135deg);
			
		}
	}

	body.rtl.services-to-testimonials .globalElement3{
		animation: stesElem3Ar 3s forwards ease-in-out;
		
	}

	@keyframes stesElem3Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
			
		}
	}

	body.rtl.services-to-testimonials .globalElement4{
		animation: stesGlobElem4Ar 1s forwards ease-in-out;
	}
	@keyframes stesGlobElem4Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}
	body.rtl.services-to-testimonials .globalElement5{
		animation: stesGlobElem5Ar 1s forwards ease-in-out;
	}
	@keyframes stesGlobElem5Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}


	body.rtl.services-to-testimonials .elementLine4{
		animation: stesElem4Ar 3s forwards ease-in-out;
	}
	@keyframes stesElem4Ar {
		0% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 36px;
			left: -95px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 445px;
			left: 1430px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 430px;
			left: 1400px;
			border-radius: 50%;
		}
	}

	body.rtl.services-to-testimonials .elementLine4:before{
		animation: stesElem4BeforeAr 3s forwards ease-in-out;
	}
	@keyframes stesElem4BeforeAr {
		0% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
	}


	body.rtl.services-to-testimonials .elementLine4:after{
		animation: stesElem4AfterAr 3s forwards ease-in-out;
	}
	@keyframes stesElem4AfterAr {
		0% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
	}
	
	/******************* Animate Services To Clients **********************/

	body.rtl.services-to-clients .globalElement3{
		left: 95%;
		right: unset;
	}
		
	body.rtl.services-to-clients .globalElement1{
		animation: scElem1Ar 3s forwards ease-in-out;
	}
	@keyframes scElem1Ar {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 100px;
			top: -20px;
			transform: rotate(-70deg);
			opacity: 0;
		}
		100% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 100px;
			top: -20px;
			transform: rotate(-70deg);
			opacity: 1;
			
		}
	}

	body.rtl.services-to-clients .globalElement2{
		animation: scElem2Ar 3s forwards ease-in-out;
	}

	@keyframes scElem2Ar {
		0% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1420px;
			top: 60px;
			transform: rotate(135deg);
		}
		70% { 
			border-radius: 50%;
			width: 250px;
			height: 250px;
			right: 625px;
			top: 525px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 250px;
			height: 250px;
			right: 625px;
			top: 495px;
			transform: rotate(135deg);
			
		}
	}

	
	body.rtl.services-to-clients .globalElement4{
		animation: scGlobElem4Ar 1s forwards ease-in-out;
	}
	@keyframes scGlobElem4Ar {
		0% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 800px;
			top: 430px;
			transform: rotate(-60deg);
			z-index: -1;
			opacity: 0;
		}
		
		100% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 800px;
			top: 430px;
			transform: rotate(-60deg);
			z-index: -1;
			opacity: 1;
		}
	}
	body.rtl.services-to-clients .globalElement5{
		animation: scGlobElem5Ar 1s forwards ease-in-out;
	}
	@keyframes scGlobElem5Ar {
		0% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			left: 80px;
			top: 80px;
			transform: rotate(60deg);
			opacity: 0;
		}
		
		100% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			left: 80px;
			top: 80px;
			transform: rotate(60deg);
			opacity: 1;
		}
	}


	body.rtl.services-to-clients .elementLine4{
		animation: scElem4Ar 3s forwards ease-in-out;
	}
	@keyframes scElem4Ar {
		0% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 36px;
			left: -95px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 500px;
			left: 610px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 470px;
			left: 600px;
			border-radius: 50%;
		}
	}

	body.rtl.services-to-clients .elementLine4:before{
		animation: scElem4BeforeAr 3s forwards ease-in-out;
	}
	@keyframes scElem4BeforeAr {
		0% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 342px;
			height: 342px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 342px;
			height: 342px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
	}


	body.rtl.services-to-clients .elementLine4:after{
		animation: scElem4AfterAr 3s forwards ease-in-out;
	}
	@keyframes scElem4AfterAr {
		0% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 384px;
			height: 384px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 384px;
			height: 384px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
	}

	/******************* Animate Services To Contact **********************/
			
	body.rtl.services-to-contact .globalElement1{
		animation: sCtElem1Ar 3s forwards ease-in-out;
	}
	@keyframes sCtElem1Ar {
		0% { 
			opacity: 0;
		}
		100% { 
			opacity: 0;
			
		}
	}

	body.rtl.services-to-contact .globalElement2{
		animation: sCtElem2Ar 3s forwards ease-in-out;
	}

	@keyframes sCtElem2Ar {
		0% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1420px;
			top: 60px;
			transform: rotate(135deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1450px;
			top: -20px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1420px;
			top: 0px;
			transform: rotate(135deg);
			
		}
	}

	body.rtl.services-to-contact .globalElement3{
		animation: sCtElem3Ar 3s forwards ease-in-out;
		
	}

	@keyframes sCtElem3Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
			
		}
	}

	body.rtl.services-to-contact .globalElement4{
		animation: sCtGlobElem4Ar 1s forwards ease-in-out;
	}
	@keyframes sCtGlobElem4Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}
	body.rtl.services-to-contact .globalElement5{
		animation: sCtGlobElem5Ar 1s forwards ease-in-out;
	}
	@keyframes sCtGlobElem5Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}


	body.rtl.services-to-contact .elementLine4{
		animation: sCtElem4Ar 3s forwards ease-in-out;
	}
	@keyframes sCtElem4Ar {
		0% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 36px;
			left: -95px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			left: 1480px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			left: 1450px;
			border-radius: 50%;
		}
	}
	
	body.rtl.services-to-contact .elementLine4:before{
		animation: sCtElem4BeforeAr 3s forwards ease-in-out;
	}
	@keyframes sCtElem4BeforeAr {
		0% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
	}


	body.rtl.services-to-contact .elementLine4:after{
		animation: sCtElem4AfterAr 3s forwards ease-in-out;
	}
	@keyframes sCtElem4AfterAr {
		0% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
	}





/******************* Animate Portfolio To ALL **********************/

	/******************* Animate Portfolio To Home **********************/

		
	body.rtl.portfolio-to-home .globalElement4{
		animation: phGlobElem4Ar 1s forwards ease-in-out;
	}
	@keyframes phGlobElem4Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 1;
		}
	}
	body.rtl.portfolio-to-home .globalElement5{
		animation: phGlobElem5Ar 1s forwards ease-in-out;
	}
	@keyframes phGlobElem5Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 1;
		}
	}

	body.rtl.portfolio-to-home .globalElement1{
		animation: phElem1Ar 1s forwards ease-in-out;
	}
	@keyframes phElem1Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 1;
		}
	}
	body.rtl.portfolio-to-home .globalElement2{
		animation: phElem2Ar 3s forwards ease-in-out;
	}

	@keyframes phElem2Ar {
		0% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1420px;
			top: 0px;
			transform: rotate(135deg);
			
		}
		70% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 730px;
			top: 50px;
			transform: rotate(70deg);
		}
		100% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 750px;
			top: 50px;
			transform: rotate(70deg);
			
		}
	}

	body.rtl.portfolio-to-home .globalElement3{
		animation: phElem3Ar 1s forwards ease-in-out;
		
	}

	@keyframes phElem3Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 1;
		}
	}


	body.rtl.portfolio-to-home .elementLine4{
		animation: phElem4Ar 3s forwards ease-in-out;
	}
	@keyframes phElem4Ar {
		0% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 10px;
			left: 1450px;
			border-radius: 50%;
		}
		70% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			left: 405px;
			border-radius: 54px;
		}
		100% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			left: 425px;
			border-radius: 54px;
			
		}
	}

	body.rtl.portfolio-to-home .elementLine4:before{
		animation: phElem4BeforeAr 3s forwards ease-in-out;
	}
	@keyframes phElem4BeforeAr {
		0% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
		70% { 
			z-index: 4;
			width: 172px;
			height: 172px;
			top: -14px;
			right: -14px;
			border-radius: 63px;
		}
		100% { 
			z-index: 4;
			width: 172px;
			height: 172px;
			top: -14px;
			right: -14px;
			border-radius: 63px;
			
		}
	}


	body.rtl.portfolio-to-home .elementLine4:after{
		animation: phElem4AfterAr 3s forwards ease-in-out;
	}
	@keyframes phElem4AfterAr {
		0% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
			
		}
		70% { 
			width: 197px;
			height: 196px;
			top: -26px;
			left: -26px;
			border-radius: 70px;
		}
		100% { 
			width: 197px;
			height: 196px;
			top: -26px;
			left: -26px;
			border-radius: 70px;
		}
	}

	/******************* Animate Portfolio To About **********************/

	body.rtl.portfolio-to-about .about-img{
		animation: poraboutImg 2.5s forwards ease-in-out;
	}
	@keyframes poraboutImg {
		0% { 
			bottom: -600px;
			left: 100px;
			opacity: 0;
		}
		70% { 
			bottom: 10px;
			left: -10px;
			opacity: 1;
		}
		100% { 
			bottom: 0;
			left: 0;
			opacity: 1;
		}
	}

	body.rtl.portfolio-to-about .globalElement1{
		animation: paElem1Ar 3s forwards ease-in-out;
	}
	@keyframes paElem1Ar {
		0% { 
			left: 290px;
			top: 325px;
			width: 160px;
			height: 160px;
			border-radius: 47px;
			transform: rotate(120deg);
			/* Add the blur effect */
			backdrop-filter: blur(10px);
			-webkit-backdrop-filter: blur(10px);
			z-index: 5;
			opacity: 0;
		}
		100% { 
			left: 290px;
			top: 325px;
			width: 160px;
			height: 160px;
			border-radius: 47px;
			transform: rotate(120deg);
			/* Add the blur effect */
			backdrop-filter: blur(10px);
			-webkit-backdrop-filter: blur(10px);
			z-index: 5;
			opacity: 1;
		}
		
	}

	body.rtl.portfolio-to-about .globalElement2{
		animation: paElem2Ar 3s forwards ease-in-out;
	}

	@keyframes paElem2Ar {
		0% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1420px;
			top: 0px;
			transform: rotate(135deg);
		}
		70% { 
			border-radius: 55px;
			width: 150px;
			height: 150px;
			right: 1450px;
			left: unset;
			top: 30px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 55px;
			width: 150px;
			height: 150px;
			right: 1420px;
			left: unset;
			top: 30px;
			transform: rotate(135deg);
			
		}
	}

	body.rtl.portfolio-to-about .globalElement3{
		animation: paElem3Ar 1s forwards ease-in-out;
		
	}

	@keyframes paElem3Ar {
		0% { 
			border-radius: 80px;
			width: 284px;
			height: 284px;
			left: unset;
			right: 50px;
			bottom: 67px;
			transform: rotate(66deg);
			background: var(--bgGradient);
			opacity: 0;
		}
		
		100% { 
			border-radius: 80px;
			width: 284px;
			height: 284px;
			left: unset;
			right: 50px;
			bottom: 67px;
			transform: rotate(66deg);
			background: var(--bgGradient);
			opacity: 1;
		}
	}

	body.rtl.portfolio-to-about .globalElement4{
		animation: paGlobElem4Ar 1s forwards ease-in-out;
	}
	@keyframes paGlobElem4Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}
	body.rtl.portfolio-to-about .globalElement5{
		animation: paGlobElem5Ar 1s forwards ease-in-out;
	}
	@keyframes paGlobElem5Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.rtl.portfolio-to-about .elementLine4{
		animation: paElem4Ar 3s forwards ease-in-out;
	}
	@keyframes paElem4Ar {
		0% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 10px;
			left: 1450px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 120px;
			left: 1460px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 120px;
			left: 1420px;
			border-radius: 50%;
		}
	}

	body.rtl.portfolio-to-about .elementLine4:before{
		animation: paElem4BeforeAr 3s forwards ease-in-out;
	}
	@keyframes paElem4BeforeAr {
		0% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 342px;
			height: 342px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 342px;
			height: 342px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
	}


	body.rtl.portfolio-to-about .elementLine4:after{
		animation: paElem4AfterAr 3s forwards ease-in-out;
	}
	@keyframes paElem4AfterAr {
		0% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
			
		}
		70% { 
			z-index: -1;
			width: 384px;
			height: 384px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 384px;
			height: 384px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
	}


	/******************* Animate Portfolio To Services **********************/

	body.rtl.portfolio-to-services .globalElement1{
		animation: psElem1Ar 1s forwards ease-in-out;
	}
	@keyframes psElem1Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.rtl.portfolio-to-services .globalElement2{
		animation: psElem2Ar 3s forwards ease-in-out;
	}
	@keyframes psElem2Ar {
		0% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1420px;
			top: 0px;
			transform: rotate(135deg);
			
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1450px;
			top: 60px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1420px;
			top: 60px;
			transform: rotate(135deg);
			
		}
	}

	body.rtl.portfolio-to-services .globalElement3{
		animation: psElem3Ar 1s forwards ease-in-out;
		
	}
	@keyframes psElem3Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.rtl.portfolio-to-services .globalElement4{
		animation: psGlobElem4Ar 1s forwards ease-in-out;
	}
	@keyframes psGlobElem4Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.rtl.portfolio-to-services .globalElement5{
		animation: psGlobElem5Ar 1s forwards ease-in-out;
	}
	@keyframes psGlobElem5Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.rtl.portfolio-to-services .elementLine4{
		animation: psElem4Ar 3s forwards ease-in-out;
	}
	@keyframes psElem4Ar {
		0% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 10px;
			left: 1450px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 36px;
			left: -125px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 36px;
			left: -95px;
			border-radius: 50%;
		}
	}

	body.rtl.portfolio-to-services .elementLine4:before{
		animation: psElem4BeforeAr 3s forwards ease-in-out;
	}
	@keyframes psElem4BeforeAr {
		0% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
	}


	body.rtl.portfolio-to-services .elementLine4:after{
		animation: psElem4AfterAr 3s forwards ease-in-out;
	}
	@keyframes psElem4AfterAr {
		0% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
			
		}
		70% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
	}


	/******************* Animate Portfolio To Team **********************/

	body.rtl.portfolio-to-team .globalElement1{
		animation: ptElem1Ar 1s forwards ease-in-out;
	}
	@keyframes ptElem1Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.rtl.portfolio-to-team .globalElement2{
		animation: ptElem2Ar 3s forwards ease-in-out;
	}
	@keyframes ptElem2Ar {
		0% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1420px;
			top: 0px;
			transform: rotate(135deg);
			
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1450px;
			top: 40px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1420px;
			top: 40px;
			transform: rotate(135deg);
			
		}
	}

	body.rtl.portfolio-to-team .globalElement3{
		animation: ptElem3Ar 1s forwards ease-in-out;
		
	}
	@keyframes ptElem3Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.rtl.portfolio-to-team .globalElement4{
		animation: ptGlobElem4Ar 1s forwards ease-in-out;
	}
	@keyframes ptGlobElem4Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.rtl.portfolio-to-team .globalElement5{
		animation: ptGlobElem5Ar 1s forwards ease-in-out;
	}
	@keyframes ptGlobElem5Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.rtl.portfolio-to-team .elementLine4{
		animation: ptElem4Ar 3s forwards ease-in-out;
	}
	@keyframes ptElem4Ar {
		0% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 10px;
			left: 1450px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 445px;
			left: 1430px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 430px;
			left: 1400px;
			border-radius: 50%;
		}
	}

	body.rtl.portfolio-to-team .elementLine4:before{
		animation: ptElem4BeforeAr 3s forwards ease-in-out;
	}
	@keyframes ptElem4BeforeAr {
		0% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
	}


	body.rtl.portfolio-to-team .elementLine4:after{
		animation: ptElem4AfterAr 3s forwards ease-in-out;
	}
	@keyframes ptElem4AfterAr {
		0% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
			
		}
		70% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
	}


	/******************* Animate Portfolio To Testimonilas **********************/

	body.rtl.portfolio-to-testimonials .globalElement1{
		animation: ptesElem1Ar 1s forwards ease-in-out;
	}
	@keyframes ptesElem1Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.rtl.portfolio-to-testimonials .globalElement2{
		animation: ptesElem2Ar 3s forwards ease-in-out;
	}
	@keyframes ptesElem2Ar {
		0% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1420px;
			top: 0px;
			transform: rotate(135deg);
			
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1450px;
			top: 40px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1420px;
			top: 40px;
			transform: rotate(135deg);
			
		}
	}

	body.rtl.portfolio-to-testimonials .globalElement3{
		animation: ptesElem3Ar 1s forwards ease-in-out;
		
	}
	@keyframes ptesElem3Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.rtl.portfolio-to-testimonials .globalElement4{
		animation: ptesGlobElem4Ar 1s forwards ease-in-out;
	}
	@keyframes ptesGlobElem4Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.rtl.portfolio-to-testimonials .globalElement5{
		animation: ptesGlobElem5Ar 1s forwards ease-in-out;
	}
	@keyframes ptesGlobElem5Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.rtl.portfolio-to-testimonials .elementLine4{
		animation: ptesElem4Ar 3s forwards ease-in-out;
	}
	@keyframes ptesElem4Ar {
		0% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 10px;
			left: 1450px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 445px;
			left: 1430px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 430px;
			left: 1400px;
			border-radius: 50%;
		}
	}

	body.rtl.portfolio-to-testimonials .elementLine4:before{
		animation: ptesElem4BeforeAr 3s forwards ease-in-out;
	}
	@keyframes ptesElem4BeforeAr {
		0% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
	}


	body.rtl.portfolio-to-testimonials .elementLine4:after{
		animation: ptesElem4AfterAr 3s forwards ease-in-out;
	}
	@keyframes ptesElem4AfterAr {
		0% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
			
		}
		70% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
	}


	/******************* Animate Portfolio To Clients **********************/

	body.rtl.portfolio-to-clients .globalElement3{
		left: 95%;
		right: unset;
	}
		
	body.rtl.portfolio-to-clients .globalElement1{
		animation: pcElem1Ar 3s forwards ease-in-out;
	}
	@keyframes pcElem1Ar {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 100px;
			top: -20px;
			transform: rotate(-70deg);
			opacity: 0;
		}
		100% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 100px;
			top: -20px;
			transform: rotate(-70deg);
			opacity: 1;
			
		}
	}

	body.rtl.portfolio-to-clients .globalElement2{
		animation: pcElem2Ar 3s forwards ease-in-out;
	}

	@keyframes pcElem2Ar {
		0% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1420px;
			top: 0px;
			transform: rotate(135deg);
		}
		70% { 
			border-radius: 50%;
			width: 250px;
			height: 250px;
			right: 625px;
			top: 525px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 250px;
			height: 250px;
			right: 625px;
			top: 495px;
			transform: rotate(135deg);
			
		}
	}


	body.rtl.portfolio-to-clients .globalElement4{
		animation: pcGlobElem4Ar 3s forwards ease-in-out;
	}
	@keyframes pcGlobElem4Ar {
		0% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 800px;
			top: 430px;
			transform: rotate(-60deg);
			z-index: -1;
			opacity: 0;
		}
		
		100% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 800px;
			top: 430px;
			transform: rotate(-60deg);
			z-index: -1;
			opacity: 1;
		}
	}
	
	body.rtl.portfolio-to-clients .globalElement5{
		animation: pcGlobElem5Ar 3s forwards ease-in-out;
	}
	@keyframes pcGlobElem5Ar {
		0% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			left: 80px;
			top: 80px;
			transform: rotate(60deg);
			opacity: 0;
		}
		
		100% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			left: 80px;
			top: 80px;
			transform: rotate(60deg);
			opacity: 1;
		}
	}


	body.rtl.portfolio-to-clients .elementLine4{
		animation: pcElem4Ar 3s forwards ease-in-out;
	}
	@keyframes pcElem4Ar {
		0% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 10px;
			left: 1450px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 500px;
			left: 610px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 470px;
			left: 600px;
			border-radius: 50%;
		}
	}

	body.rtl.portfolio-to-clients .elementLine4:before{
		animation: pcElem4BeforeAr 3s forwards ease-in-out;
	}
	@keyframes pcElem4BeforeAr {
		0% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 342px;
			height: 342px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 342px;
			height: 342px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
	}


	body.rtl.portfolio-to-clients .elementLine4:after{
		animation: pcElem4AfterAr 3s forwards ease-in-out;
	}
	@keyframes pcElem4AfterAr {
		0% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 384px;
			height: 384px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 384px;
			height: 384px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
	}



	/******************* Animate Portfolio To Contact **********************/

	body.rtl.portfolio-to-contact .globalElement1{
		animation: pCtElem1Ar 1s forwards ease-in-out;
	}
	@keyframes pCtElem1Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.rtl.portfolio-to-contact .globalElement2{
		animation: pCtElem2Ar 3s forwards ease-in-out;
	}
	@keyframes pCtElem2Ar {
		0% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1420px;
			top: 0px;
			transform: rotate(135deg);
			
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1450px;
			top: -20px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1420px;
			top: 0px;
			transform: rotate(135deg);
			
		}
	}

	body.rtl.portfolio-to-contact .globalElement3{
		animation: pCtElem3Ar 1s forwards ease-in-out;
		
	}
	@keyframes pCtElem3Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.rtl.portfolio-to-contact .globalElement4{
		animation: pCtGlobElem4Ar 1s forwards ease-in-out;
	}
	@keyframes pCtGlobElem4Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.rtl.portfolio-to-contact .globalElement5{
		animation: pCtGlobElem5Ar 1s forwards ease-in-out;
	}
	@keyframes pCtGlobElem5Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.rtl.portfolio-to-contact .elementLine4{
		animation: pCtElem4Ar 3s forwards ease-in-out;
	}
	@keyframes pCtElem4Ar {
		0% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 10px;
			left: 1450px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			left: 1480px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			left: 1450px;
			border-radius: 50%;
		}
	}

	body.rtl.portfolio-to-contact .elementLine4:before{
		animation: pCtElem4BeforeAr 3s forwards ease-in-out;
	}
	@keyframes pCtElem4BeforeAr {
		0% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
	}


	body.rtl.portfolio-to-contact .elementLine4:after{
		animation: pCtElem4AfterAr 3s forwards ease-in-out;
	}
	@keyframes pCtElem4AfterAr {
		0% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
			
		}
		70% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
	}




/******************* Animate Team To ALL **********************/

	/******************* Animate Team To Home **********************/

	body.rtl.team-to-home .globalElement4{
		animation: thGlobElem4Ar 1s forwards ease-in-out;
	}
	@keyframes thGlobElem4Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 1;
		}
	}
	body.rtl.team-to-home .globalElement5{
		animation: thGlobElem5Ar 1s forwards ease-in-out;
	}
	@keyframes thGlobElem5Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 1;
		}
	}

	body.rtl.team-to-home .globalElement1{
		animation: thElem1Ar 1s forwards ease-in-out;
	}
	@keyframes thElem1Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 1;
		}
	}
	body.rtl.team-to-home .globalElement2{
		animation: thElem2Ar 3s forwards ease-in-out;
	}

	@keyframes thElem2Ar {
		0% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1420px;
			top: 40px;
			transform: rotate(135deg);
			
		}
		70% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 730px;
			top: 50px;
			transform: rotate(70deg);
		}
		100% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 750px;
			top: 50px;
			transform: rotate(70deg);
			
		}
	}

	body.rtl.team-to-home .globalElement3{
		animation: thElem3Ar 1s forwards ease-in-out;
	}

	@keyframes thElem3Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 1;
		}
	}


	body.rtl.team-to-home .elementLine4{
		animation: thElem4Ar 3s forwards ease-in-out;
	}
	@keyframes thElem4Ar {
		0% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 430px;
			left: 1400px;
			border-radius: 50%;
			
		}
		70% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			left: 405px;
			border-radius: 54px;
		}
		100% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			left: 425px;
			border-radius: 54px;
		}
	}

	body.rtl.team-to-home .elementLine4:before{
		animation: thElem4BeforeAr 3s forwards ease-in-out;
	}
	@keyframes thElem4BeforeAr {
		0% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
			
		}
		70% { 
			z-index: 4;
			width: 172px;
			height: 172px;
			top: -14px;
			right: -14px;
			border-radius: 63px;
		}
		100% { 
			z-index: 4;
			width: 172px;
			height: 172px;
			top: -14px;
			right: -14px;
			border-radius: 63px;
		}
	}


	body.rtl.team-to-home .elementLine4:after{
		animation: thElem4AfterAr 3s forwards ease-in-out;
	}
	@keyframes thElem4AfterAr {
		0% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
			
		}
		70% { 
			width: 197px;
			height: 196px;
			top: -26px;
			left: -26px;
			border-radius: 70px;
		}
		100% { 
			width: 197px;
			height: 196px;
			top: -26px;
			left: -26px;
			border-radius: 70px;
		}
	}


	/******************* Animate Team To About **********************/

	body.rtl.team-to-about .about-img{
		animation: teaaboutImg 2.5s forwards ease-in-out;
	}
	@keyframes teaaboutImg {
		0% { 
			bottom: -600px;
			left: 100px;
			opacity: 0;
		}
		70% { 
			bottom: 10px;
			left: -10px;
			opacity: 1;
		}
		100% { 
			bottom: 0;
			left: 0;
			opacity: 1;
		}
	}

	body.rtl.team-to-about .globalElement1{
		animation: taElem1Ar 3s forwards ease-in-out;
	}
	@keyframes taElem1Ar {
		0% { 
			left: 290px;
			top: 325px;
			width: 160px;
			height: 160px;
			border-radius: 47px;
			transform: rotate(120deg);
			/* Add the blur effect */
			backdrop-filter: blur(10px);
			-webkit-backdrop-filter: blur(10px);
			z-index: 5;
			opacity: 0;
		}
		100% { 
			left: 290px;
			top: 325px;
			width: 160px;
			height: 160px;
			border-radius: 47px;
			transform: rotate(120deg);
			/* Add the blur effect */
			backdrop-filter: blur(10px);
			-webkit-backdrop-filter: blur(10px);
			z-index: 5;
			opacity: 1;
		}
		
	}

	body.rtl.team-to-about .globalElement2{
		animation: taElem2Ar 3s forwards ease-in-out;
	}

	@keyframes taElem2Ar {
		0% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1420px;
			top: 40px;
			transform: rotate(135deg);
		}
		70% { 
			border-radius: 55px;
			width: 150px;
			height: 150px;
			right: 1450px;
			left: unset;
			top: 30px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 55px;
			width: 150px;
			height: 150px;
			right: 1420px;
			left: unset;
			top: 30px;
			transform: rotate(135deg);
			
		}
	}

	body.rtl.team-to-about .globalElement3{
		animation: taElem3Ar 1s forwards ease-in-out;
		
	}

	@keyframes taElem3Ar {
		0% { 
			border-radius: 80px;
			width: 284px;
			height: 284px;
			left: unset;
			right: 50px;
			bottom: 67px;
			transform: rotate(66deg);
			background: var(--bgGradient);
			opacity: 0;
		}
		
		100% { 
			border-radius: 80px;
			width: 284px;
			height: 284px;
			left: unset;
			right: 50px;
			bottom: 67px;
			transform: rotate(66deg);
			background: var(--bgGradient);
			opacity: 1;
		}
	}

	body.rtl.team-to-about .globalElement4{
		animation: taGlobElem4Ar 1s forwards ease-in-out;
	}
	@keyframes taGlobElem4Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}
	body.rtl.team-to-about .globalElement5{
		animation: taGlobElem5Ar 1s forwards ease-in-out;
	}
	@keyframes taGlobElem5Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.rtl.team-to-about .elementLine4{
		animation: taElem4Ar 3s forwards ease-in-out;
	}
	@keyframes taElem4Ar {
		0% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 430px;
			left: 1400px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 120px;
			left: 1460px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 120px;
			left: 1420px;
			border-radius: 50%;
		}
	}

	body.rtl.team-to-about .elementLine4:before{
		animation: taElem4BeforeAr 3s forwards ease-in-out;
	}
	@keyframes taElem4BeforeAr {
		0% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 342px;
			height: 342px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 342px;
			height: 342px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
	}


	body.rtl.team-to-about .elementLine4:after{
		animation: taElem4AfterAr 3s forwards ease-in-out;
	}
	@keyframes taElem4AfterAr {
		0% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
			
		}
		70% { 
			z-index: -1;
			width: 384px;
			height: 384px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 384px;
			height: 384px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
	}


	/******************* Animate Team To Services **********************/

	body.rtl.team-to-services .globalElement1{
		animation: tsElem1Ar 1s forwards ease-in-out;
	}
	@keyframes tsElem1Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.rtl.team-to-services .globalElement2{
		animation: tsElem2Ar 3s forwards ease-in-out;
	}
	@keyframes tsElem2Ar {
		0% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1420px;
			top: 40px;
			transform: rotate(135deg);
			
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1450px;
			top: 60px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1420px;
			top: 60px;
			transform: rotate(135deg);
			
		}
	}

	body.rtl.team-to-services .globalElement3{
		animation: tsElem3Ar 1s forwards ease-in-out;
		
	}
	@keyframes tsElem3Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.rtl.team-to-services .globalElement4{
		animation: tsGlobElem4Ar 1s forwards ease-in-out;
	}
	@keyframes tsGlobElem4Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.rtl.team-to-services .globalElement5{
		animation: tsGlobElem5Ar 1s forwards ease-in-out;
	}
	@keyframes tsGlobElem5Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.rtl.team-to-services .elementLine4{
		animation: tsElem4Ar 3s forwards ease-in-out;
	}
	@keyframes tsElem4Ar {
		0% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 430px;
			left: 1400px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 36px;
			left: -125px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 36px;
			left: -95px;
			border-radius: 50%;
		}
	}

	body.rtl.team-to-services .elementLine4:before{
		animation: tsElem4BeforeAr 3s forwards ease-in-out;
	}
	@keyframes tsElem4BeforeAr {
		0% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
	}


	body.rtl.team-to-services .elementLine4:after{
		animation: tsElem4AfterAr 3s forwards ease-in-out;
	}
	@keyframes tsElem4AfterAr {
		0% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
			
		}
		70% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
	}


	/******************* Animate Team To Portfolio **********************/
		
	body.rtl.team-to-portfolio .globalElement1{
		animation: tpElem1Ar 3s forwards ease-in-out;
	}
	@keyframes tpElem1Ar {
		0% { 
			opacity: 0;
		}
		100% { 
			opacity: 0;
			
		}
	}

	body.rtl.team-to-portfolio .globalElement2{
		animation: tpElem2Ar 3s forwards ease-in-out;
	}

	@keyframes tpElem2Ar {
		0% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1420px;
			top: 40px;
			transform: rotate(135deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1450px;
			top: -20px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1420px;
			top: 0px;
			transform: rotate(135deg);
			
		}
	}

	body.rtl.team-to-portfolio .globalElement3{
		animation: tpElem3Ar 3s forwards ease-in-out;
		
	}

	@keyframes tpElem3Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
			
		}
	}

	body.rtl.team-to-portfolio .globalElement4{
		animation: tpGlobElem4Ar 1s forwards ease-in-out;
	}
	@keyframes tpGlobElem4Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}
	body.rtl.team-to-portfolio .globalElement5{
		animation: tpGlobElem5Ar 1s forwards ease-in-out;
	}
	@keyframes tpGlobElem5Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}


	body.rtl.team-to-portfolio .elementLine4{
		animation: tpElem4Ar 3s forwards ease-in-out;
	}
	@keyframes tpElem4Ar {
		0% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 430px;
			left: 1400px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: -10px;
			left: 1480px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 10px;
			left: 1450px;
			border-radius: 50%;
		}
	}

	body.rtl.team-to-portfolio .elementLine4:before{
		animation: tpElem4BeforeAr 3s forwards ease-in-out;
	}
	@keyframes tpElem4BeforeAr {
		0% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
	}


	body.rtl.team-to-portfolio .elementLine4:after{
		animation: tpElem4AfterAr 3s forwards ease-in-out;
	}
	@keyframes tpElem4AfterAr {
		0% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
	}


	/******************* Animate Team To Testimonilas **********************/

	body.rtl.team-to-testimonials .globalElement1{
		animation: ttesElem1Ar 1s forwards ease-in-out;
	}
	@keyframes ttesElem1Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.rtl.team-to-testimonials .globalElement2{
		animation: ttesElem2Ar 3s forwards ease-in-out;
	}
	@keyframes ttesElem2Ar {
		0% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1420px;
			top: 40px;
			transform: rotate(135deg);
			
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1450px;
			top: 40px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1420px;
			top: 40px;
			transform: rotate(135deg);
			
		}
	}

	body.rtl.team-to-testimonials .globalElement3{
		animation: ttesElem3Ar 1s forwards ease-in-out;
		
	}
	@keyframes ttesElem3Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.rtl.team-to-testimonials .globalElement4{
		animation: ttesGlobElem4Ar 1s forwards ease-in-out;
	}
	@keyframes ttesGlobElem4Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.rtl.team-to-testimonials .globalElement5{
		animation: ttesGlobElem5Ar 1s forwards ease-in-out;
	}
	@keyframes ttesGlobElem5Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.rtl.team-to-testimonials .elementLine4{
		animation: ttesElem4Ar 3s forwards ease-in-out;
	}
	@keyframes ttesElem4Ar {
		0% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 430px;
			left: 1400px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 445px;
			left: 1430px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 430px;
			left: 1400px;
			border-radius: 50%;
		}
	}

	body.rtl.team-to-testimonials .elementLine4:before{
		animation: ttesElem4BeforeAr 3s forwards ease-in-out;
	}
	@keyframes ttesElem4BeforeAr {
		0% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
	}


	body.rtl.team-to-testimonials .elementLine4:after{
		animation: ttesElem4AfterAr 3s forwards ease-in-out;
	}
	@keyframes ttesElem4AfterAr {
		0% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
			
		}
		70% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
	}


	/******************* Animate Team To Clients **********************/

	body.rtl.team-to-clients .globalElement3{
		left: 95%;
		right: unset;
	}
		
	body.rtl.team-to-clients .globalElement1{
		animation: tcElem1Ar 3s forwards ease-in-out;
	}
	@keyframes tcElem1Ar {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 100px;
			top: -20px;
			transform: rotate(-70deg);
			opacity: 0;
		}
		100% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 100px;
			top: -20px;
			transform: rotate(-70deg);
			opacity: 1;
			
		}
	}

	body.rtl.team-to-clients .globalElement2{
		animation: tcElem2Ar 3s forwards ease-in-out;
	}

	@keyframes tcElem2Ar {
		0% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1420px;
			top: 40px;
			transform: rotate(135deg);
		}
		70% { 
			border-radius: 50%;
			width: 250px;
			height: 250px;
			right: 625px;
			top: 525px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 250px;
			height: 250px;
			right: 625px;
			top: 495px;
			transform: rotate(135deg);
			
		}
	}


	body.rtl.team-to-clients .globalElement4{
		animation: tcGlobElem4Ar 3s forwards ease-in-out;
	}
	@keyframes tcGlobElem4Ar {
		0% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 800px;
			top: 430px;
			transform: rotate(-60deg);
			z-index: -1;
			opacity: 0;
		}
		
		100% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 800px;
			top: 430px;
			transform: rotate(-60deg);
			z-index: -1;
			opacity: 1;
		}
	}
	
	body.rtl.team-to-clients .globalElement5{
		animation: tcGlobElem5Ar 3s forwards ease-in-out;
	}
	@keyframes tcGlobElem5Ar {
		0% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			left: 80px;
			top: 80px;
			transform: rotate(60deg);
			opacity: 0;
		}
		
		100% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			left: 80px;
			top: 80px;
			transform: rotate(60deg);
			opacity: 1;
		}
	}


	body.rtl.team-to-clients .elementLine4{
		animation: tcElem4Ar 3s forwards ease-in-out;
	}
	@keyframes tcElem4Ar {
		0% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 430px;
			left: 1400px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 500px;
			left: 610px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 470px;
			left: 600px;
			border-radius: 50%;
		}
	}

	body.rtl.team-to-clients .elementLine4:before{
		animation: tcElem4BeforeAr 3s forwards ease-in-out;
	}
	@keyframes tcElem4BeforeAr {
		0% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 342px;
			height: 342px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 342px;
			height: 342px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
	}


	body.rtl.team-to-clients .elementLine4:after{
		animation: tcElem4AfterAr 3s forwards ease-in-out;
	}
	@keyframes tcElem4AfterAr {
		0% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 384px;
			height: 384px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 384px;
			height: 384px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
	}


	/******************* Animate Team To Contact **********************/

	body.rtl.team-to-contact .globalElement1{
		animation: tCtElem1Ar 1s forwards ease-in-out;
	}
	@keyframes tCtElem1Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.rtl.team-to-contact .globalElement2{
		animation: tCtElem2Ar 3s forwards ease-in-out;
	}
	@keyframes tCtElem2Ar {
		0% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1420px;
			top: 40px;
			transform: rotate(135deg);
			
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1450px;
			top: -20px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1420px;
			top: 0px;
			transform: rotate(135deg);
			
		}
	}

	body.rtl.team-to-contact .globalElement3{
		animation: tCtElem3Ar 1s forwards ease-in-out;
		
	}
	@keyframes tCtElem3Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.rtl.team-to-contact .globalElement4{
		animation: tCtGlobElem4Ar 1s forwards ease-in-out;
	}
	@keyframes tCtGlobElem4Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.rtl.team-to-contact .globalElement5{
		animation: tCtGlobElem5Ar 1s forwards ease-in-out;
	}
	@keyframes tCtGlobElem5Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.rtl.team-to-contact .elementLine4{
		animation: tCtElem4Ar 3s forwards ease-in-out;
	}
	@keyframes tCtElem4Ar {
		0% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 430px;
			left: 1400px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			left: 1480px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			left: 1450px;
			border-radius: 50%;
		}
	}

	body.rtl.team-to-contact .elementLine4:before{
		animation: tCtElem4BeforeAr 3s forwards ease-in-out;
	}
	@keyframes tCtElem4BeforeAr {
		0% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
	}


	body.rtl.team-to-contact .elementLine4:after{
		animation: tCtElem4AfterAr 3s forwards ease-in-out;
	}
	@keyframes tCtElem4AfterAr {
		0% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
			
		}
		70% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
	}









/******************* Animate Testimonials To ALL **********************/

	/******************* Animate Testimonials To Home **********************/

	body.rtl.testimonials-to-home .globalElement4{
		animation: teshGlobElem4Ar 1s forwards ease-in-out;
	}
	@keyframes teshGlobElem4Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 1;
		}
	}
	body.rtl.testimonials-to-home .globalElement5{
		animation: teshGlobElem5Ar 1s forwards ease-in-out;
	}
	@keyframes teshGlobElem5Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 1;
		}
	}

	body.rtl.testimonials-to-home .globalElement1{
		animation: teshElem1Ar 1s forwards ease-in-out;
	}
	@keyframes teshElem1Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 1;
		}
	}
	body.rtl.testimonials-to-home .globalElement2{
		animation: teshElem2Ar 3s forwards ease-in-out;
	}

	@keyframes teshElem2Ar {
		0% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1420px;
			top: 40px;
			transform: rotate(135deg);
			
		}
		70% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 730px;
			top: 50px;
			transform: rotate(70deg);
		}
		100% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 750px;
			top: 50px;
			transform: rotate(70deg);
			
		}
	}

	body.rtl.testimonials-to-home .globalElement3{
		animation: teshElem3Ar 1s forwards ease-in-out;
	}

	@keyframes teshElem3Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 1;
		}
	}


	body.rtl.testimonials-to-home .elementLine4{
		animation: teshElem4Ar 3s forwards ease-in-out;
	}
	@keyframes teshElem4Ar {
		0% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 430px;
			left: 1400px;
			border-radius: 50%;
			
		}
		70% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			left: 405px;
			border-radius: 54px;
		}
		100% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			left: 425px;
			border-radius: 54px;
		}
	}

	body.rtl.testimonials-to-home .elementLine4:before{
		animation: teshElem4BeforeAr 3s forwards ease-in-out;
	}
	@keyframes teshElem4BeforeAr {
		0% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
			
		}
		70% { 
			z-index: 4;
			width: 172px;
			height: 172px;
			top: -14px;
			right: -14px;
			border-radius: 63px;
		}
		100% { 
			z-index: 4;
			width: 172px;
			height: 172px;
			top: -14px;
			right: -14px;
			border-radius: 63px;
		}
	}


	body.rtl.testimonials-to-home .elementLine4:after{
		animation: teshElem4AfterAr 3s forwards ease-in-out;
	}
	@keyframes teshElem4AfterAr {
		0% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
			
		}
		70% { 
			width: 197px;
			height: 196px;
			top: -26px;
			left: -26px;
			border-radius: 70px;
		}
		100% { 
			width: 197px;
			height: 196px;
			top: -26px;
			left: -26px;
			border-radius: 70px;
		}
	}


	/******************* Animate Testimonials To About **********************/

	body.rtl.testimonials-to-about .about-img{
		animation: tesaboutImg 2.5s forwards ease-in-out;
	}
	@keyframes tesaboutImg {
		0% { 
			bottom: -600px;
			left: 100px;
			opacity: 0;
		}
		70% { 
			bottom: 10px;
			left: -10px;
			opacity: 1;
		}
		100% { 
			bottom: 0;
			left: 0;
			opacity: 1;
		}
	}

	body.rtl.testimonials-to-about .globalElement1{
		animation: tesaElem1Ar 3s forwards ease-in-out;
	}
	@keyframes tesaElem1Ar {
		0% { 
			left: 290px;
			top: 325px;
			width: 160px;
			height: 160px;
			border-radius: 47px;
			transform: rotate(120deg);
			/* Add the blur effect */
			backdrop-filter: blur(10px);
			-webkit-backdrop-filter: blur(10px);
			z-index: 5;
			opacity: 0;
		}
		100% { 
			left: 290px;
			top: 325px;
			width: 160px;
			height: 160px;
			border-radius: 47px;
			transform: rotate(120deg);
			/* Add the blur effect */
			backdrop-filter: blur(10px);
			-webkit-backdrop-filter: blur(10px);
			z-index: 5;
			opacity: 1;
		}
		
	}

	body.rtl.testimonials-to-about .globalElement2{
		animation: tesaElem2Ar 3s forwards ease-in-out;
	}

	@keyframes tesaElem2Ar {
		0% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1420px;
			top: 40px;
			transform: rotate(135deg);
		}
		70% { 
			border-radius: 55px;
			width: 150px;
			height: 150px;
			right: 1450px;
			left: unset;
			top: 30px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 55px;
			width: 150px;
			height: 150px;
			right: 1420px;
			left: unset;
			top: 30px;
			transform: rotate(135deg);
			
		}
	}

	body.rtl.testimonials-to-about .globalElement3{
		animation: tesaElem3Ar 1s forwards ease-in-out;
		
	}

	@keyframes tesaElem3Ar {
		0% { 
			border-radius: 80px;
			width: 284px;
			height: 284px;
			left: unset;
			right: 50px;
			bottom: 67px;
			transform: rotate(66deg);
			background: var(--bgGradient);
			opacity: 0;
		}
		
		100% { 
			border-radius: 80px;
			width: 284px;
			height: 284px;
			left: unset;
			right: 50px;
			bottom: 67px;
			transform: rotate(66deg);
			background: var(--bgGradient);
			opacity: 1;
		}
	}

	body.rtl.testimonials-to-about .globalElement4{
		animation: tesaGlobElem4Ar 1s forwards ease-in-out;
	}
	@keyframes tesaGlobElem4Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}
	body.rtl.testimonials-to-about .globalElement5{
		animation: tesaGlobElem5Ar 1s forwards ease-in-out;
	}
	@keyframes tesaGlobElem5Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.rtl.testimonials-to-about .elementLine4{
		animation: tesaElem4Ar 3s forwards ease-in-out;
	}
	@keyframes tesaElem4Ar {
		0% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 430px;
			left: 1400px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 120px;
			left: 1460px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 120px;
			left: 1420px;
			border-radius: 50%;
		}
	}

	body.rtl.testimonials-to-about .elementLine4:before{
		animation: tesaElem4BeforeAr 3s forwards ease-in-out;
	}
	@keyframes tesaElem4BeforeAr {
		0% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 342px;
			height: 342px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 342px;
			height: 342px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
	}


	body.rtl.testimonials-to-about .elementLine4:after{
		animation: tesaElem4AfterAr 3s forwards ease-in-out;
	}
	@keyframes tesaElem4AfterAr {
		0% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
			
		}
		70% { 
			z-index: -1;
			width: 384px;
			height: 384px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 384px;
			height: 384px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
	}


	/******************* Animate Testimonials To Services **********************/

	body.rtl.testimonials-to-services .globalElement1{
		animation: tessElem1Ar 1s forwards ease-in-out;
	}
	@keyframes tessElem1Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.rtl.testimonials-to-services .globalElement2{
		animation: tessElem2Ar 3s forwards ease-in-out;
	}
	@keyframes tsElem2Ar {
		0% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1420px;
			top: 40px;
			transform: rotate(135deg);
			
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1450px;
			top: 60px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1420px;
			top: 60px;
			transform: rotate(135deg);
			
		}
	}

	body.rtl.testimonials-to-services .globalElement3{
		animation: tessElem3Ar 1s forwards ease-in-out;
		
	}
	@keyframes tessElem3Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.rtl.testimonials-to-services .globalElement4{
		animation: tessGlobElem4Ar 1s forwards ease-in-out;
	}
	@keyframes tessGlobElem4Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.rtl.testimonials-to-services .globalElement5{
		animation: tessGlobElem5Ar 1s forwards ease-in-out;
	}
	@keyframes tessGlobElem5Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.rtl.testimonials-to-services .elementLine4{
		animation: tessElem4Ar 3s forwards ease-in-out;
	}
	@keyframes tessElem4Ar {
		0% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 430px;
			left: 1400px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 36px;
			left: -125px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 36px;
			left: -95px;
			border-radius: 50%;
		}
	}

	body.rtl.testimonials-to-services .elementLine4:before{
		animation: tessElem4BeforeAr 3s forwards ease-in-out;
	}
	@keyframes tessElem4BeforeAr {
		0% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
	}


	body.rtl.testimonials-to-services .elementLine4:after{
		animation: tessElem4AfterAr 3s forwards ease-in-out;
	}
	@keyframes tessElem4AfterAr {
		0% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
			
		}
		70% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
	}


	/******************* Animate Testimonials To Portfolio **********************/
		
	body.rtl.testimonials-to-portfolio .globalElement1{
		animation: tespElem1Ar 3s forwards ease-in-out;
	}
	@keyframes tespElem1Ar {
		0% { 
			opacity: 0;
		}
		100% { 
			opacity: 0;
			
		}
	}

	body.rtl.testimonials-to-portfolio .globalElement2{
		animation: tespElem2Ar 3s forwards ease-in-out;
	}

	@keyframes tespElem2Ar {
		0% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1420px;
			top: 40px;
			transform: rotate(135deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1450px;
			top: -20px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1420px;
			top: 0px;
			transform: rotate(135deg);
			
		}
	}

	body.rtl.testimonials-to-portfolio .globalElement3{
		animation: tespElem3Ar 3s forwards ease-in-out;
		
	}

	@keyframes tespElem3Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
			
		}
	}

	body.rtl.testimonials-to-portfolio .globalElement4{
		animation: tespGlobElem4Ar 1s forwards ease-in-out;
	}
	@keyframes tpGlobElem4Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}
	body.rtl.testimonials-to-portfolio .globalElement5{
		animation: tespGlobElem5Ar 1s forwards ease-in-out;
	}
	@keyframes tespGlobElem5Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}


	body.rtl.testimonials-to-portfolio .elementLine4{
		animation: tespElem4Ar 3s forwards ease-in-out;
	}
	@keyframes tespElem4Ar {
		0% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 430px;
			left: 1400px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: -10px;
			left: 1480px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 10px;
			left: 1450px;
			border-radius: 50%;
		}
	}

	body.rtl.testimonials-to-portfolio .elementLine4:before{
		animation: tespElem4BeforeAr 3s forwards ease-in-out;
	}
	@keyframes tespElem4BeforeAr {
		0% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
	}


	body.rtl.testimonials-to-portfolio .elementLine4:after{
		animation: tespElem4AfterAr 3s forwards ease-in-out;
	}
	@keyframes tespElem4AfterAr {
		0% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
	}


	/******************* Animate Testimonilas To Team **********************/

	body.rtl.testimonials-to-team .globalElement1{
		animation: testElem1Ar 1s forwards ease-in-out;
	}
	@keyframes testElem1Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.rtl.testimonials-to-team .globalElement2{
		animation: testElem2Ar 3s forwards ease-in-out;
	}
	@keyframes testElem2Ar {
		0% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1420px;
			top: 40px;
			transform: rotate(135deg);
			
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1450px;
			top: 40px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1420px;
			top: 40px;
			transform: rotate(135deg);
			
		}
	}

	body.rtl.testimonials-to-team .globalElement3{
		animation: testElem3Ar 1s forwards ease-in-out;
		
	}
	@keyframes testElem3Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.rtl.testimonials-to-team .globalElement4{
		animation: testGlobElem4Ar 1s forwards ease-in-out;
	}
	@keyframes testGlobElem4Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.rtl.testimonials-to-team .globalElement5{
		animation: testGlobElem5Ar 1s forwards ease-in-out;
	}
	@keyframes testGlobElem5Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.rtl.testimonials-to-team .elementLine4{
		animation: testElem4Ar 3s forwards ease-in-out;
	}
	@keyframes testElem4Ar {
		0% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 430px;
			left: 1400px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 445px;
			left: 1430px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 430px;
			left: 1400px;
			border-radius: 50%;
		}
	}

	body.rtl.testimonials-to-team .elementLine4:before{
		animation: testElem4BeforeAr 3s forwards ease-in-out;
	}
	@keyframes testElem4BeforeAr {
		0% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
	}


	body.rtl.testimonials-to-team .elementLine4:after{
		animation: testElem4AfterAr 3s forwards ease-in-out;
	}
	@keyframes testElem4AfterAr {
		0% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
			
		}
		70% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
	}
	

	/******************* Animate Testimonials To Clients **********************/

	body.rtl.testimonials-to-clients .globalElement3{
		left: 95%;
		right: unset;
	}
		
	body.rtl.testimonials-to-clients .globalElement1{
		animation: tescElem1Ar 3s forwards ease-in-out;
	}
	@keyframes tescElem1Ar {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 100px;
			top: -20px;
			transform: rotate(-70deg);
			opacity: 0;
		}
		100% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 100px;
			top: -20px;
			transform: rotate(-70deg);
			opacity: 1;
			
		}
	}

	body.rtl.testimonials-to-clients .globalElement2{
		animation: tescElem2Ar 3s forwards ease-in-out;
	}

	@keyframes tescElem2Ar {
		0% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1420px;
			top: 40px;
			transform: rotate(135deg);
		}
		70% { 
			border-radius: 50%;
			width: 250px;
			height: 250px;
			right: 625px;
			top: 525px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 250px;
			height: 250px;
			right: 625px;
			top: 495px;
			transform: rotate(135deg);
			
		}
	}


	body.rtl.testimonials-to-clients .globalElement4{
		animation: tescGlobElem4Ar 3s forwards ease-in-out;
	}
	@keyframes tcGlobElem4Ar {
		0% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 800px;
			top: 430px;
			transform: rotate(-60deg);
			z-index: -1;
			opacity: 0;
		}
		
		100% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 800px;
			top: 430px;
			transform: rotate(-60deg);
			z-index: -1;
			opacity: 1;
		}
	}

	body.rtl.testimonials-to-clients .globalElement5{
		animation: tescGlobElem5Ar 3s forwards ease-in-out;
	}
	@keyframes tescGlobElem5Ar {
		0% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			left: 80px;
			top: 80px;
			transform: rotate(60deg);
			opacity: 0;
		}
		
		100% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			left: 80px;
			top: 80px;
			transform: rotate(60deg);
			opacity: 1;
		}
	}


	body.rtl.testimonials-to-clients .elementLine4{
		animation: tescElem4Ar 3s forwards ease-in-out;
	}
	@keyframes tescElem4Ar {
		0% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 430px;
			left: 1400px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 500px;
			left: 610px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 470px;
			left: 600px;
			border-radius: 50%;
		}
	}

	body.rtl.testimonials-to-clients .elementLine4:before{
		animation: tescElem4BeforeAr 3s forwards ease-in-out;
	}
	@keyframes tescElem4BeforeAr {
		0% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 342px;
			height: 342px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 342px;
			height: 342px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
	}


	body.rtl.testimonials-to-clients .elementLine4:after{
		animation: tescElem4AfterAr 3s forwards ease-in-out;
	}
	@keyframes tescElem4AfterAr {
		0% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 384px;
			height: 384px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 384px;
			height: 384px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
	}


	/******************* Animate Testimonials To Contact **********************/

	body.rtl.testimonials-to-contact .globalElement1{
		animation: tesCtElem1Ar 1s forwards ease-in-out;
	}
	@keyframes tesCtElem1Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.rtl.testimonials-to-contact .globalElement2{
		animation: tesCtElem2Ar 3s forwards ease-in-out;
	}
	@keyframes tesCtElem2Ar {
		0% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1420px;
			top: 40px;
			transform: rotate(135deg);
			
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1450px;
			top: -20px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1420px;
			top: 0px;
			transform: rotate(135deg);
			
		}
	}

	body.rtl.testimonials-to-contact .globalElement3{
		animation: tesCtElem3Ar 1s forwards ease-in-out;
		
	}
	@keyframes tesCtElem3Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.rtl.testimonials-to-contact .globalElement4{
		animation: tesCtGlobElem4Ar 1s forwards ease-in-out;
	}
	@keyframes tesCtGlobElem4Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.rtl.testimonials-to-contact .globalElement5{
		animation: tesCtGlobElem5Ar 1s forwards ease-in-out;
	}
	@keyframes tesCtGlobElem5Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.rtl.testimonials-to-contact .elementLine4{
		animation: tesCtElem4Ar 3s forwards ease-in-out;
	}
	@keyframes tesCtElem4Ar {
		0% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 430px;
			left: 1400px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			left: 1480px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			left: 1450px;
			border-radius: 50%;
		}
	}

	body.rtl.testimonials-to-contact .elementLine4:before{
		animation: tesCtElem4BeforeAr 3s forwards ease-in-out;
	}
	@keyframes tesCtElem4BeforeAr {
		0% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
	}


	body.rtl.testimonials-to-contact .elementLine4:after{
		animation: tCtElem4AfterAr 3s forwards ease-in-out;
	}
	@keyframes tesCtElem4AfterAr {
		0% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
			
		}
		70% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
	}




/******************* Animate Client To ALL **********************/

	/******************* Animate Client To Home **********************/

	
	body.rtl.clients-to-home .globalElement3{
		right: 95%;
		left: unset;
	}
	body.rtl.clients-to-home .globalElement4{
		animation: chGlobElem4Ar 3s forwards ease-in-out;
	}
	@keyframes chGlobElem4Ar {
		0% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 800px;
			top: 430px;
			transform: rotate(-60deg);
			z-index: -1;
			
		}
		70% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 135px;
			top: 350px;
			transform: rotate(60deg);
		}
		100% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 135px;
			top: 350px;
			transform: rotate(60deg);
		}
	}
	body.rtl.clients-to-home .globalElement5{
		animation: chGlobElem5Ar 3s forwards ease-in-out;
	}
	@keyframes chGlobElem5Ar {
		0% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			left: 80px;
			top: 80px;
			transform: rotate(60deg);
			
		}
		70% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			left: 80px;
			top: 30px;
			transform: rotate(60deg);
		}
		100% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			left: 80px;
			top: 30px;
			transform: rotate(60deg);
		}
	}

	body.rtl.clients-to-home .globalElement1{
		animation: chElem1Ar 3s forwards ease-in-out;
	}
	@keyframes chElem1Ar {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 100px;
			top: -20px;
			transform: rotate(-70deg);
			
		}
		70% { 
			border-radius: 17px;
			width: 40px;
			height: 40px;
			right: 200px;
			top: 0px;
			transform: rotate(70deg);
		}
		100% { 
			border-radius: 17px;
			width: 40px;
			height: 40px;
			right: 200px;
			top: 0px;
			transform: rotate(70deg);
		}
	}
	body.rtl.clients-to-home .globalElement2{
		animation: chElem2Ar 3s forwards ease-in-out;
	}

	@keyframes chElem2Ar {
		0% { 
			border-radius: 50%;
			width: 250px;
			height: 250px;
			right: 625px;
			top: 495px;
			transform: rotate(135deg);
			
		}
		70% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 750px;
			top: 50px;
			transform: rotate(70deg);
		}
		100% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 750px;
			top: 50px;
			transform: rotate(70deg);
			
		}
	}


	body.rtl.clients-to-home .elementLine4{
		animation: chElem4Ar 3s forwards ease-in-out;
	}
	@keyframes chElem4Ar {
		0% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 470px;
			left: 600px;
			border-radius: 50%;
			
		}
		70% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			left: 425px;
			border-radius: 54px;
		}
		100% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			left: 425px;
			border-radius: 54px;
		}
	}

	body.rtl.clients-to-home .elementLine4:before{
		animation: chElem4BeforeAr 3s forwards ease-in-out;
	}
	@keyframes chElem4BeforeAr {
		0% { 
			z-index: -1;
			width: 342px;
			height: 342px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
			
		}
		70% { 
			z-index: 4;
			width: 172px;
			height: 172px;
			top: -14px;
			right: -14px;
			border-radius: 63px;
		}
		100% { 
			z-index: 4;
			width: 172px;
			height: 172px;
			top: -14px;
			right: -14px;
			border-radius: 63px;
		}
	}


	body.rtl.clients-to-home .elementLine4:after{
		animation: chElem4AfterAr 3s forwards ease-in-out;
	}
	@keyframes chElem4AfterAr {
		0% { 
			z-index: -1;
			width: 384px;
			height: 384px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
			
		}
		70% { 
			width: 197px;
			height: 196px;
			top: -26px;
			left: -26px;
			border-radius: 70px;
		}
		100% { 
			width: 197px;
			height: 196px;
			top: -26px;
			left: -26px;
			border-radius: 70px;
		}
	}



	body.rtl.clients-to-home.modal-open .elementLine5{
		animation: chElem5Ar 3s forwards ease-in-out;
		
		// display: none;
	}
	@keyframes chElem5Ar {
		0% { 
			z-index: 1100;
			width: 284px;
			height: 284px;
			top: -6px;
			right: -137px;
			border-radius: 50%;
			opacity: 0;
			transform: rotate(27deg);
		}
		70% { 
			transform: rotate(-114deg);
			z-index: 1057;
			width: 325px;
			height: 325px;
			top: 60px;
			right: 1060px;
			border-radius: 100px;
			opacity: 1;
		}
		99% { 
			transform: rotate(-114deg);
			z-index: 1056;
			width: 325px;
			height: 325px;
			top: 60px;
			right: 1034px;
			border-radius: 100px;
			opacity: 1;
		}
		100% { 
			transform: rotate(-114deg);
			z-index: 1056;
			width: 325px;
			height: 325px;
			top: 60px;
			right: 1034px;
			border-radius: 100px;
			opacity: 0;
		}
	}


	/******************* Animate Clients to About **********************/
						
	body.rtl.clients-to-about .about-img{
		animation: cliaboutImg 2.5s forwards ease-in-out;
	}
	@keyframes cliaboutImg {
		0% { 
			bottom: -600px;
			left: 100px;
			opacity: 0;
		}
		70% { 
			bottom: 10px;
			left: -10px;
			opacity: 1;
		}
		100% { 
			bottom: 0;
			left: 0;
			opacity: 1;
		}
	}

	
	body.rtl.clients-to-about .globalElement1{
		animation: caElem1Ar 3s forwards ease-in-out;
	}
	@keyframes caElem1Ar {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 100px;
			top: -20px;
			transform: rotate(-70deg);
		}
		50% { 
			left: 340px;
			top: 350px;
			width: 160px;
			height: 160px;
			border-radius: 47px;
			transform: rotate(120deg);
			/* Add the blur effect */
			backdrop-filter: blur(10px);
			-webkit-backdrop-filter: blur(10px);
			z-index: 5;
		}
		100% { 
			left: 290px;
			top: 325px;
			width: 160px;
			height: 160px;
			border-radius: 47px;
			transform: rotate(120deg);
			/* Add the blur effect */
			backdrop-filter: blur(10px);
			-webkit-backdrop-filter: blur(10px);
			z-index: 5;
			
		}
	}
	body.rtl.clients-to-about .globalElement2{
		animation: caElem2Ar 3s forwards ease-in-out;
	}

	@keyframes caElem2Ar {
		0% { 
			border-radius: 50%;
			width: 250px;
			height: 250px;
			right: 625px;
			top: 495px;
			transform: rotate(135deg);
		}
		70% { 
			border-radius: 55px;
			width: 150px;
			height: 150px;
			right: 1450px;
			left: unset;
			top: 30px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 55px;
			width: 150px;
			height: 150px;
			right: 1420px;
			left: unset;
			top: 30px;
			transform: rotate(135deg);
			
		}
	}

	body.rtl.clients-to-about .globalElement3{
		animation: caElem3Ar 3s forwards ease-in-out;
		
	}

	@keyframes caElem3Ar {
		0% { 
			border-radius: 80px;
			width: 284px;
			height: 284px;
			left: unset;
			right: 50px;
			bottom: 67px;
			transform: rotate(66deg);
			background: var(--bgGradient);
			opacity: 0;
		}
		
		100% { 
			border-radius: 80px;
			width: 284px;
			height: 284px;
			left: unset;
			right: 50px;
			bottom: 67px;
			transform: rotate(66deg);
			background: var(--bgGradient);
			opacity: 1;
		}
	}

	body.rtl.clients-to-about .globalElement4{
		animation: caGlobElem4Ar 3s forwards ease-in-out;
	}
	@keyframes caGlobElem4Ar {
		0% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 800px;
			top: 430px;
			transform: rotate(-60deg);
			z-index: -1;
			opacity: 1;
		}
		
		100% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 800px;
			top: 430px;
			transform: rotate(-60deg);
			z-index: -1;
			opacity: 0;
		}
	}

	body.rtl.clients-to-about .globalElement5{
		animation: caGlobElem5Ar 1s forwards ease-in-out;
	}
	@keyframes caGlobElem5Ar {
		0% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			left: 80px;
			top: 80px;
			transform: rotate(60deg);
			opacity: 1;
		}
		
		100% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			left: 80px;
			top: 80px;
			transform: rotate(60deg);
			opacity: 0;
		}
	}

	body.rtl.clients-to-about .elementLine4{
		animation: caElem4Ar 3s forwards ease-in-out;
	}
	@keyframes caElem4Ar {
		0% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 470px;
			left: 600px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 120px;
			left: 1460px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 120px;
			left: 1420px;
			border-radius: 50%;
		}
	}

	body.rtl.clients-to-about .elementLine4:before{
		animation: caElem4BeforeAr 3s forwards ease-in-out;
	}
	@keyframes caElem4BeforeAr {
		0% { 
			z-index: -1;
			width: 342px;
			height: 342px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 342px;
			height: 342px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 342px;
			height: 342px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
	}


	body.rtl.clients-to-about .elementLine4:after{
		animation: caElem4AfterAr 3s forwards ease-in-out;
	}
	@keyframes caElem4AfterAr {
		0% { 
			z-index: -1;
			width: 384px;
			height: 384px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 384px;
			height: 384px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 384px;
			height: 384px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
	}


	/******************* Animate Clients To Services **********************/

	body.rtl.clients-to-services .globalElement3{
		opacity: 0;
	}
		
	body.rtl.clients-to-services .globalElement1{
		animation: csElem1Ar 3s forwards ease-in-out;
	}
	@keyframes csElem1Ar {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 100px;
			top: -20px;
			transform: rotate(-70deg);
			opacity: 1;
		}
		100% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 100px;
			top: -20px;
			transform: rotate(-70deg);
			opacity: 0;
			
		}
	}

	body.rtl.clients-to-services .globalElement2{
		animation: csElem2Ar 3s forwards ease-in-out;
	}

	@keyframes csElem2Ar {
		0% { 
			border-radius: 50%;
			width: 250px;
			height: 250px;
			right: 625px;
			top: 495px;
			transform: rotate(135deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1450px;
			top: 60px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1420px;
			top: 60px;
			transform: rotate(135deg);
			
		}
	}


	body.rtl.clients-to-services .globalElement4{
		animation: csGlobElem4Ar 1s forwards ease-in-out;
	}
	@keyframes csGlobElem4Ar {
		0% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 800px;
			top: 430px;
			transform: rotate(-60deg);
			z-index: -1;
			opacity: 1;
		}
		
		100% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 800px;
			top: 430px;
			transform: rotate(-60deg);
			z-index: -1;
			opacity: 0;
		}
	}
	body.rtl.clients-to-services .globalElement5{
		animation: csGlobElem5Ar 1s forwards ease-in-out;
	}
	@keyframes csGlobElem5Ar {
		0% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			left: 80px;
			top: 80px;
			transform: rotate(60deg);
			opacity: 1;
		}
		
		100% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			left: 80px;
			top: 80px;
			transform: rotate(60deg);
			opacity: 0;
		}
	}


	body.rtl.clients-to-services .elementLine4{
		animation: csElem4Ar 3s forwards ease-in-out;
	}
	@keyframes csElem4Ar {
		0% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 470px;
			left: 600px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 36px;
			left: -125px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 36px;
			left: -95px;
			border-radius: 50%;
		}
	}

	body.rtl.clients-to-services .elementLine4:before{
		animation: csElem4BeforeAr 3s forwards ease-in-out;
	}
	@keyframes csElem4BeforeAr {
		0% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
	}


	body.rtl.clients-to-services .elementLine4:after{
		animation: csElem4AfterAr 3s forwards ease-in-out;
	}
	@keyframes csElem4AfterAr {
		0% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
	}


	/******************* Animate Clients To Portfolio **********************/

	body.rtl.clients-to-portfolio .globalElement3{
		opacity: 0;
	}
		
	body.rtl.clients-to-portfolio .globalElement1{
		animation: cpElem1Ar 3s forwards ease-in-out;
	}
	@keyframes cpElem1Ar {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 100px;
			top: -20px;
			transform: rotate(-70deg);
			opacity: 1;
		}
		100% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 100px;
			top: -20px;
			transform: rotate(-70deg);
			opacity: 0;
			
		}
	}

	body.rtl.clients-to-portfolio .globalElement2{
		animation: cpElem2Ar 3s forwards ease-in-out;
	}

	@keyframes cpElem2Ar {
		0% { 
			border-radius: 50%;
			width: 250px;
			height: 250px;
			right: 625px;
			top: 495px;
			transform: rotate(135deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1450px;
			top: -20px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1420px;
			top: 0px;
			transform: rotate(135deg);
			
		}
	}


	body.rtl.clients-to-portfolio .globalElement4{
		animation: cpGlobElem4Ar 1s forwards ease-in-out;
	}
	@keyframes cpGlobElem4Ar {
		0% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 800px;
			top: 430px;
			transform: rotate(-60deg);
			z-index: -1;
			opacity: 1;
		}
		
		100% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 800px;
			top: 430px;
			transform: rotate(-60deg);
			z-index: -1;
			opacity: 0;
		}
	}
	body.rtl.clients-to-portfolio .globalElement5{
		animation: cpGlobElem5Ar 1s forwards ease-in-out;
	}
	@keyframes cpGlobElem5Ar {
		0% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			left: 80px;
			top: 80px;
			transform: rotate(60deg);
			opacity: 1;
		}
		
		100% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			left: 80px;
			top: 80px;
			transform: rotate(60deg);
			opacity: 0;
		}
	}


	body.rtl.clients-to-portfolio .elementLine4{
		animation: cpElem4Ar 3s forwards ease-in-out;
	}
	@keyframes cpElem4Ar {
		0% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 470px;
			left: 600px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: -10px;
			left: 1480px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 10px;
			left: 1450px;
			border-radius: 50%;
		}
	}

	body.rtl.clients-to-portfolio .elementLine4:before{
		animation: csElem4BeforeAr 3s forwards ease-in-out;
	}
	@keyframes cpElem4BeforeAr {
		0% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
	}


	body.rtl.clients-to-portfolio .elementLine4:after{
		animation: cpElem4AfterAr 3s forwards ease-in-out;
	}
	@keyframes cpElem4AfterAr {
		0% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
	}
	

	/******************* Animate Clients To Team **********************/

	body.rtl.clients-to-team .globalElement3{
		opacity: 0;
	}
		
	body.rtl.clients-to-team .globalElement1{
		animation: ctElem1Ar 3s forwards ease-in-out;
	}
	@keyframes ctElem1Ar {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 100px;
			top: -20px;
			transform: rotate(-70deg);
			opacity: 1;
		}
		100% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 100px;
			top: -20px;
			transform: rotate(-70deg);
			opacity: 0;
			
		}
	}

	body.rtl.clients-to-team .globalElement2{
		animation: ctElem2Ar 3s forwards ease-in-out;
	}
	@keyframes ctElem2Ar {
		0% { 
			border-radius: 50%;
			width: 250px;
			height: 250px;
			right: 625px;
			top: 495px;
			transform: rotate(135deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1450px;
			top: 40px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1420px;
			top: 40px;
			transform: rotate(135deg);
			
		}
	}


	body.rtl.clients-to-team .globalElement4{
		animation: ctGlobElem4Ar 1s forwards ease-in-out;
	}
	@keyframes ctGlobElem4Ar {
		0% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 800px;
			top: 430px;
			transform: rotate(-60deg);
			z-index: -1;
			opacity: 1;
		}
		
		100% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 800px;
			top: 430px;
			transform: rotate(-60deg);
			z-index: -1;
			opacity: 0;
		}
	}
	body.rtl.clients-to-team .globalElement5{
		animation: ctGlobElem5Ar 1s forwards ease-in-out;
	}
	@keyframes ctGlobElem5Ar {
		0% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			left: 80px;
			top: 80px;
			transform: rotate(60deg);
			opacity: 1;
		}
		
		100% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			left: 80px;
			top: 80px;
			transform: rotate(60deg);
			opacity: 0;
		}
	}


	body.rtl.clients-to-team .elementLine4{
		animation: ctElem4Ar 3s forwards ease-in-out;
	}
	@keyframes ctElem4Ar {
		0% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 470px;
			left: 600px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 445px;
			left: 1430px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 430px;
			left: 1400px;
			border-radius: 50%;
		}
	}

	body.rtl.clients-to-team .elementLine4:before{
		animation: ctElem4BeforeAr 3s forwards ease-in-out;
	}
	@keyframes ctElem4BeforeAr {
		0% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
	}


	body.rtl.clients-to-team .elementLine4:after{
		animation: ctElem4AfterAr 3s forwards ease-in-out;
	}
	@keyframes ctElem4AfterAr {
		0% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
	}


	/******************* Animate Clients To Testimonials **********************/

	body.rtl.clients-to-testimonials .globalElement3{
		opacity: 0;
	}
		
	body.rtl.clients-to-testimonials .globalElement1{
		animation: ctesElem1Ar 3s forwards ease-in-out;
	}
	@keyframes ctesElem1Ar {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 100px;
			top: -20px;
			transform: rotate(-70deg);
			opacity: 1;
		}
		100% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 100px;
			top: -20px;
			transform: rotate(-70deg);
			opacity: 0;
			
		}
	}

	body.rtl.clients-to-testimonials .globalElement2{
		animation: ctesElem2Ar 3s forwards ease-in-out;
	}
	@keyframes ctesElem2Ar {
		0% { 
			border-radius: 50%;
			width: 250px;
			height: 250px;
			right: 625px;
			top: 495px;
			transform: rotate(135deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1450px;
			top: 40px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1420px;
			top: 40px;
			transform: rotate(135deg);
			
		}
	}


	body.rtl.clients-to-testimonials .globalElement4{
		animation: ctesGlobElem4Ar 1s forwards ease-in-out;
	}
	@keyframes ctesGlobElem4Ar {
		0% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 800px;
			top: 430px;
			transform: rotate(-60deg);
			z-index: -1;
			opacity: 1;
		}
		
		100% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 800px;
			top: 430px;
			transform: rotate(-60deg);
			z-index: -1;
			opacity: 0;
		}
	}
	body.rtl.clients-to-testimonials .globalElement5{
		animation: ctesGlobElem5Ar 1s forwards ease-in-out;
	}
	@keyframes ctesGlobElem5Ar {
		0% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			left: 80px;
			top: 80px;
			transform: rotate(60deg);
			opacity: 1;
		}
		
		100% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			left: 80px;
			top: 80px;
			transform: rotate(60deg);
			opacity: 0;
		}
	}


	body.rtl.clients-to-testimonials .elementLine4{
		animation: ctesElem4Ar 3s forwards ease-in-out;
	}
	@keyframes ctesElem4Ar {
		0% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 470px;
			left: 600px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 445px;
			left: 1430px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 430px;
			left: 1400px;
			border-radius: 50%;
		}
	}

	body.rtl.clients-to-testimonials .elementLine4:before{
		animation: ctesElem4BeforeAr 3s forwards ease-in-out;
	}
	@keyframes ctesElem4BeforeAr {
		0% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
	}


	body.rtl.clients-to-testimonials .elementLine4:after{
		animation: ctesElem4AfterAr 3s forwards ease-in-out;
	}
	@keyframes ctesElem4AfterAr {
		0% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
	}


	/******************* Animate Clients To Contact **********************/

	body.rtl.clients-to-contact .globalElement3{
		opacity: 0;
	}
		
	body.rtl.clients-to-contact .globalElement1{
		animation: cCtElem1Ar 3s forwards ease-in-out;
	}
	@keyframes cCtElem1Ar {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 100px;
			top: -20px;
			transform: rotate(-70deg);
			opacity: 1;
		}
		100% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 100px;
			top: -20px;
			transform: rotate(-70deg);
			opacity: 0;
			
		}
	}

	body.rtl.clients-to-contact .globalElement2{
		animation: cCtElem2Ar 3s forwards ease-in-out;
	}
	@keyframes cCtElem2Ar {
		0% { 
			border-radius: 50%;
			width: 250px;
			height: 250px;
			right: 625px;
			top: 495px;
			transform: rotate(135deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1450px;
			top: -20px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1420px;
			top: 0px;
			transform: rotate(135deg);
			
		}
	}


	body.rtl.clients-to-contact .globalElement4{
		animation: cCtGlobElem4Ar 1s forwards ease-in-out;
	}
	@keyframes cCtGlobElem4Ar {
		0% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 800px;
			top: 430px;
			transform: rotate(-60deg);
			z-index: -1;
			opacity: 1;
		}
		
		100% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 800px;
			top: 430px;
			transform: rotate(-60deg);
			z-index: -1;
			opacity: 0;
		}
	}
	body.rtl.clients-to-contact .globalElement5{
		animation: cCtGlobElem5Ar 1s forwards ease-in-out;
	}
	@keyframes cCtGlobElem5Ar {
		0% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			left: 80px;
			top: 80px;
			transform: rotate(60deg);
			opacity: 1;
		}
		
		100% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			left: 80px;
			top: 80px;
			transform: rotate(60deg);
			opacity: 0;
		}
	}


	body.rtl.clients-to-contact .elementLine4{
		animation: cCtElem4Ar 3s forwards ease-in-out;
	}
	@keyframes cCtElem4Ar {
		0% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 470px;
			left: 600px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			left: 1480px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			left: 1450px;
			border-radius: 50%;
		}
	}

	body.rtl.clients-to-contact .elementLine4:before{
		animation: cCtElem4BeforeAr 3s forwards ease-in-out;
	}
	@keyframes cCtElem4BeforeAr {
		0% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
	}


	body.rtl.clients-to-contact .elementLine4:after{
		animation: cCtElem4AfterAr 3s forwards ease-in-out;
	}
	@keyframes cCtElem4AfterAr {
		0% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
	}


	
/******************* Animate Contact Us To All **********************/

	/******************* Animate Contact Us To Home **********************/

	body.rtl.contact-to-home .globalElement4{
		animation: CthGlobElem4Ar 1s forwards ease-in-out;
	}
	@keyframes CthGlobElem4Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 1;
		}
	}
	body.rtl.contact-to-home .globalElement5{
		animation: CthGlobElem5Ar 1s forwards ease-in-out;
	}
	@keyframes CthGlobElem5Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 1;
		}
	}

	body.rtl.contact-to-home .globalElement1{
		animation: CthElem1Ar 1s forwards ease-in-out;
	}
	@keyframes CthElem1Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 1;
		}
	}
	body.rtl.contact-to-home .globalElement2{
		animation: CthElem2Ar 3s forwards ease-in-out;
	}

	@keyframes CthElem2Ar {
		0% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1420px;
			top: 0px;
			transform: rotate(135deg);
			
		}
		70% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 730px;
			top: 50px;
			transform: rotate(70deg);
		}
		100% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 750px;
			top: 50px;
			transform: rotate(70deg);
			
		}
	}

	body.rtl.contact-to-home .globalElement3{
		animation: CthElem3Ar 1s forwards ease-in-out;
		
	}

	@keyframes CthElem3Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 1;
		}
	}


	body.rtl.contact-to-home .elementLine4{
		animation: CthElem4Ar 3s forwards ease-in-out;
	}
	@keyframes CthElem4Ar {
		0% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			left: 1450px;
			border-radius: 50%;
			
		}
		70% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			left: 405px;
			border-radius: 54px;
		}
		100% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			left: 425px;
			border-radius: 54px;
		}
	}

	body.rtl.contact-to-home .elementLine4:before{
		animation: CthElem4BeforeAr 3s forwards ease-in-out;
	}
	@keyframes CthElem4BeforeAr {
		0% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
			
		}
		70% { 
			z-index: 4;
			width: 172px;
			height: 172px;
			top: -14px;
			right: -14px;
			border-radius: 63px;
		}
		100% { 
			z-index: 4;
			width: 172px;
			height: 172px;
			top: -14px;
			right: -14px;
			border-radius: 63px;
		}
	}


	body.rtl.contact-to-home .elementLine4:after{
		animation: CthElem4AfterAr 3s forwards ease-in-out;
	}
	@keyframes CthElem4AfterAr {
		0% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
			
		}
		70% { 
			width: 197px;
			height: 196px;
			top: -26px;
			left: -26px;
			border-radius: 70px;
		}
		100% { 
			width: 197px;
			height: 196px;
			top: -26px;
			left: -26px;
			border-radius: 70px;
		}
	}


	/******************* Animate Contact To About **********************/

	
	body.rtl.contact-to-about .about-img{
		animation: conaboutImg 2.5s forwards ease-in-out;
	}
	@keyframes conaboutImg {
		0% { 
			bottom: -600px;
			left: 100px;
			opacity: 0;
		}
		70% { 
			bottom: 10px;
			left: -10px;
			opacity: 1;
		}
		100% { 
			bottom: 0;
			left: 0;
			opacity: 1;
		}
	}


	body.rtl.contact-to-about .globalElement1{
		animation: caElem1Ar 3s forwards ease-in-out;
	}
	@keyframes caElem1Ar {
		0% { 
			left: 290px;
			top: 325px;
			width: 160px;
			height: 160px;
			border-radius: 47px;
			transform: rotate(120deg);
			/* Add the blur effect */
			backdrop-filter: blur(10px);
			-webkit-backdrop-filter: blur(10px);
			z-index: 5;
			opacity: 0;
		}
		100% { 
			left: 290px;
			top: 325px;
			width: 160px;
			height: 160px;
			border-radius: 47px;
			transform: rotate(120deg);
			/* Add the blur effect */
			backdrop-filter: blur(10px);
			-webkit-backdrop-filter: blur(10px);
			z-index: 5;
			opacity: 1;
		}
	}

	body.rtl.contact-to-about .globalElement2{
		animation: caElem2Ar 3s forwards ease-in-out;
	}

	@keyframes caElem2Ar {
		0% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1420px;
			top: 0px;
			transform: rotate(135deg);
		}
		70% { 
			border-radius: 55px;
			width: 150px;
			height: 150px;
			right: 1450px;
			left: unset;
			top: 30px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 55px;
			width: 150px;
			height: 150px;
			right: 1420px;
			left: unset;
			top: 30px;
			transform: rotate(135deg);
			
		}
	}

	body.rtl.contact-to-about .globalElement3{
		animation: caElem3Ar 3s forwards ease-in-out;
		
	}

	@keyframes caElem3Ar {
		0% { 
			border-radius: 80px;
			width: 284px;
			height: 284px;
			left: unset;
			right: 50px;
			bottom: 67px;
			transform: rotate(66deg);
			background: var(--bgGradient);
			opacity: 0;
		}
		
		100% { 
			border-radius: 80px;
			width: 284px;
			height: 284px;
			left: unset;
			right: 50px;
			bottom: 67px;
			transform: rotate(66deg);
			background: var(--bgGradient);
			opacity: 1;
			
		}
	}

	body.rtl.contact-to-about .globalElement4{
		animation: caGlobElem4Ar 1s forwards ease-in-out;
	}
	@keyframes scGlobElem4Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}
	body.rtl.contact-to-about .globalElement5{
		animation: scGlobElem5Ar 1s forwards ease-in-out;
	}
	@keyframes scGlobElem5Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}


	body.rtl.contact-to-about .elementLine4{
		animation: caElem4Ar 3s forwards ease-in-out;
	}
	@keyframes caElem4Ar {
		0% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			left: 1450px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 120px;
			left: 1460px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 120px;
			left: 1420px;
			border-radius: 50%;
		}
	}

	body.rtl.contact-to-about .elementLine4:before{
		animation: caElem4BeforeAr 3s forwards ease-in-out;
	}
	@keyframes caElem4BeforeAr {
		0% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 342px;
			height: 342px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 342px;
			height: 342px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
	}


	body.rtl.contact-to-about .elementLine4:after{
		animation: caElem4AfterAr 3s forwards ease-in-out;
	}
	@keyframes caElem4AfterAr {
		0% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 384px;
			height: 384px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 384px;
			height: 384px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
	}


	/******************* Animate Contact To Services **********************/

	body.rtl.contact-to-services .globalElement1{
		animation: CtsElem1Ar 1s forwards ease-in-out;
	}
	@keyframes CtsElem1Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.rtl.contact-to-services .globalElement2{
		animation: CtsElem2Ar 3s forwards ease-in-out;
	}
	@keyframes CtsElem2Ar {
		0% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1420px;
			top: 0px;
			transform: rotate(135deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1450px;
			top: 60px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1420px;
			top: 60px;
			transform: rotate(135deg);
			
		}
	}

	body.rtl.contact-to-services .globalElement3{
		animation: CtsElem3Ar 1s forwards ease-in-out;
		
	}
	@keyframes CtsElem3Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.rtl.contact-to-services .globalElement4{
		animation: CtsGlobElem4Ar 1s forwards ease-in-out;
	}
	@keyframes CtsGlobElem4Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.rtl.contact-to-services .globalElement5{
		animation: CtsGlobElem5Ar 1s forwards ease-in-out;
	}
	@keyframes CtsGlobElem5Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.rtl.contact-to-services .elementLine4{
		animation: CtsElem4Ar 3s forwards ease-in-out;
	}
	@keyframes CtsElem4Ar {
		0% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			left: 1450px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 36px;
			left: -125px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 36px;
			left: -95px;
			border-radius: 50%;
		}
	}

	body.rtl.contact-to-services .elementLine4:before{
		animation: CtsElem4BeforeAr 3s forwards ease-in-out;
	}
	@keyframes CtsElem4BeforeAr {
		0% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
	}


	body.rtl.contact-to-services .elementLine4:after{
		animation: CtsElem4AfterAr 3s forwards ease-in-out;
	}
	@keyframes CtsElem4AfterAr {
		0% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
			
		}
		70% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
	}


	/******************* Animate Contact To Portfolio **********************/
		
	body.rtl.contact-to-portfolio .globalElement1{
		animation: CtpElem1Ar 3s forwards ease-in-out;
	}
	@keyframes CtpElem1Ar {
		0% { 
			opacity: 0;
		}
		100% { 
			opacity: 0;
			
		}
	}

	body.rtl.contact-to-portfolio .globalElement2{
		animation: CtpElem2Ar 3s forwards ease-in-out;
	}

	@keyframes CtpElem2Ar {
		0% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1420px;
			top: 0px;
			transform: rotate(135deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1450px;
			top: -20px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1420px;
			top: 0px;
			transform: rotate(135deg);
			
		}
	}

	body.rtl.contact-to-portfolio .globalElement3{
		animation: CtpElem3Ar 3s forwards ease-in-out;
		
	}

	@keyframes CtpElem3Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
			
		}
	}

	body.rtl.contact-to-portfolio .globalElement4{
		animation: CtpGlobElem4Ar 1s forwards ease-in-out;
	}
	@keyframes CtpGlobElem4Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}
	body.rtl.contact-to-portfolio .globalElement5{
		animation: CtpGlobElem5Ar 1s forwards ease-in-out;
	}
	@keyframes CtpGlobElem5Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}


	body.rtl.contact-to-portfolio .elementLine4{
		animation: CtpElem4Ar 3s forwards ease-in-out;
	}
	@keyframes CtpElem4Ar {
		0% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			left: 1450px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: -10px;
			left: 1480px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 10px;
			left: 1450px;
			border-radius: 50%;
		}
	}

	body.rtl.contact-to-portfolio .elementLine4:before{
		animation: CtpElem4BeforeAr 3s forwards ease-in-out;
	}
	@keyframes CtpElem4BeforeAr {
		0% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
	}


	body.rtl.contact-to-portfolio .elementLine4:after{
		animation: CtpElem4AfterAr 3s forwards ease-in-out;
	}
	@keyframes CtpElem4AfterAr {
		0% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
	}


	/******************* Animate Contact To Team **********************/

	body.rtl.contact-to-team .globalElement1{
		animation: CttElem1Ar 1s forwards ease-in-out;
	}
	@keyframes CttElem1Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.rtl.contact-to-team .globalElement2{
		animation: CttElem2Ar 3s forwards ease-in-out;
	}
	@keyframes CttElem2Ar {
		0% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1420px;
			top: 0px;
			transform: rotate(135deg);
			
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1450px;
			top: 40px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1420px;
			top: 40px;
			transform: rotate(135deg);
			
		}
	}

	body.rtl.contact-to-team .globalElement3{
		animation: CttElem3Ar 1s forwards ease-in-out;
		
	}
	@keyframes CttElem3Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.rtl.contact-to-team .globalElement4{
		animation: CttGlobElem4Ar 1s forwards ease-in-out;
	}
	@keyframes CttGlobElem4Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.rtl.contact-to-team .globalElement5{
		animation: CttGlobElem5Ar 1s forwards ease-in-out;
	}
	@keyframes CttGlobElem5Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.rtl.contact-to-team .elementLine4{
		animation: CttElem4Ar 3s forwards ease-in-out;
	}
	@keyframes CttElem4Ar {
		0% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			left: 1450px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 445px;
			left: 1430px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 430px;
			left: 1400px;
			border-radius: 50%;
		}
	}

	body.rtl.contact-to-team .elementLine4:before{
		animation: CttElem4BeforeAr 3s forwards ease-in-out;
	}
	@keyframes CttElem4BeforeAr {
		0% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
	}


	body.rtl.contact-to-team .elementLine4:after{
		animation: CttElem4AfterAr 3s forwards ease-in-out;
	}
	@keyframes CttElem4AfterAr {
		0% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
			
		}
		70% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
	}


	/******************* Animate Contact To Testimonials **********************/

	body.rtl.contact-to-testimonials .globalElement1{
		animation: CttesElem1Ar 1s forwards ease-in-out;
	}
	@keyframes CttesElem1Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.rtl.contact-to-testimonials .globalElement2{
		animation: CttesElem2Ar 3s forwards ease-in-out;
	}
	@keyframes CttesElem2Ar {
		0% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1420px;
			top: 0px;
			transform: rotate(135deg);
			
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1450px;
			top: 40px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1420px;
			top: 40px;
			transform: rotate(135deg);
			
		}
	}

	body.rtl.contact-to-testimonials .globalElement3{
		animation: CttesElem3Ar 1s forwards ease-in-out;
		
	}
	@keyframes CttesElem3Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.rtl.contact-to-testimonials .globalElement4{
		animation: CttesGlobElem4Ar 1s forwards ease-in-out;
	}
	@keyframes CttesGlobElem4Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.rtl.contact-to-testimonials .globalElement5{
		animation: CttesGlobElem5Ar 1s forwards ease-in-out;
	}
	@keyframes CttesGlobElem5Ar {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.rtl.contact-to-testimonials .elementLine4{
		animation: CttesElem4Ar 3s forwards ease-in-out;
	}
	@keyframes CttesElem4Ar {
		0% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			left: 1450px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 445px;
			left: 1430px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 430px;
			left: 1400px;
			border-radius: 50%;
		}
	}

	body.rtl.contact-to-testimonials .elementLine4:before{
		animation: CttesElem4BeforeAr 3s forwards ease-in-out;
	}
	@keyframes CttesElem4BeforeAr {
		0% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
	}


	body.rtl.contact-to-testimonials .elementLine4:after{
		animation: CttesElem4AfterAr 3s forwards ease-in-out;
	}
	@keyframes CttesElem4AfterAr {
		0% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
			
		}
		70% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
	}


	/******************* Animate Contact To Clients **********************/

	body.rtl.contact-to-clients .globalElement3{
		left: 95%;
		right: unset;
	}
		
	body.rtl.contact-to-clients .globalElement1{
		animation: CtcElem1Ar 3s forwards ease-in-out;
	}
	@keyframes CtcElem1Ar {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 100px;
			top: -20px;
			transform: rotate(-70deg);
			opacity: 0;
		}
		100% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			right: 100px;
			top: -20px;
			transform: rotate(-70deg);
			opacity: 1;
			
		}
	}

	body.rtl.contact-to-clients .globalElement2{
		animation: CtcElem2Ar 3s forwards ease-in-out;
	}

	@keyframes CtcElem2Ar {
		0% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			right: 1420px;
			top: 0px;
			transform: rotate(135deg);
		}
		70% { 
			border-radius: 50%;
			width: 250px;
			height: 250px;
			right: 625px;
			top: 525px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 250px;
			height: 250px;
			right: 625px;
			top: 495px;
			transform: rotate(135deg);
			
		}
	}


	body.rtl.contact-to-clients .globalElement4{
		animation: CtcGlobElem4Ar 3s forwards ease-in-out;
	}
	@keyframes CtcGlobElem4Ar {
		0% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 800px;
			top: 430px;
			transform: rotate(-60deg);
			z-index: -1;
			opacity: 0;
		}
		
		100% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 800px;
			top: 430px;
			transform: rotate(-60deg);
			z-index: -1;
			opacity: 1;
		}
	}

	body.rtl.contact-to-clients .globalElement5{
		animation: CtcGlobElem5Ar 3s forwards ease-in-out;
	}
	@keyframes CtcGlobElem5Ar {
		0% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			left: 80px;
			top: 80px;
			transform: rotate(60deg);
			opacity: 0;
		}
		
		100% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			left: 80px;
			top: 80px;
			transform: rotate(60deg);
			opacity: 1;
		}
	}


	body.rtl.contact-to-clients .elementLine4{
		animation: CtcElem4Ar 3s forwards ease-in-out;
	}
	@keyframes CtcElem4Ar {
		0% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			left: 1450px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 500px;
			left: 610px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 470px;
			left: 600px;
			border-radius: 50%;
		}
	}

	body.rtl.contact-to-clients .elementLine4:before{
		animation: CtcElem4BeforeAr 3s forwards ease-in-out;
	}
	@keyframes CtcElem4BeforeAr {
		0% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 342px;
			height: 342px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 342px;
			height: 342px;
			top: -22px;
			right: -22px;
			border-radius: 50%;
		}
	}


	body.rtl.contact-to-clients .elementLine4:after{
		animation: CtcElem4AfterAr 3s forwards ease-in-out;
	}
	@keyframes CtcElem4AfterAr {
		0% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 384px;
			height: 384px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 384px;
			height: 384px;
			top: -43px;
			right: -43px;
			border-radius: 50%;
		}
	}