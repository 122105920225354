.ltr{
	.globalElement1{
		border-radius: 17px;
		width: 40px;
		height: 40px;
		left: 200px;
		top: 0px;
		transform: rotate(70deg);
		background: linear-gradient(120deg, rgba(255, 255, 255, .8) 0%, rgba(255, 255, 255, 0) 100%);
		// backdrop-filter: blur(10px);
		// -webkit-backdrop-filter: blur(10px);
		
	}
	.globalElement2{
		border-radius: 35px;
		width: 100px;
		height: 100px;
		left: 750px;
		top: 50px;
		transform: rotate(70deg);
		background: linear-gradient(120deg, rgba(255, 255, 255, .8) 0%, rgba(255, 255, 255, 0) 100%);
	}
	.globalElement3{
		border-radius: 80px;
		width: 250px;
		height: 250px;
		left: 95%;
		bottom: -100px;
		transform: rotate(70deg);
		background: linear-gradient(120deg, rgba(255, 255, 255, .8) 0%, rgba(255, 255, 255, 0) 100%);
	}
	.globalElement4{
		border-radius: 21px;
		width: 48px;
		height: 48px;
		left: 875px;
		top: 350px;
		transform: rotate(60deg);
		background: linear-gradient(120deg, rgba(255, 255, 255, .8) 0%, rgba(255, 255, 255, 0) 100%);
	}
	.globalElement5{
		border-radius: 18px;
		width: 40px;
		height: 40px;
		right: 80px;
		top: 30px;
		transform: rotate(60deg);
		background: linear-gradient(120deg, rgba(255, 255, 255, .8) 0%, rgba(255, 255, 255, 0) 100%);
	}


	.elementLine4{
		z-index: 0;
		width: 144px;
		height: 144px;
		top: 293px;
		right: 185px;
		border-radius: 54px;
		transform: rotate(27deg);
		background: transparent;
		border: 1px solid var(--bluePurple);
		opacity: .7;
	}
	.elementLine4:before{
		content: "";
		position: absolute;
		z-index: 4;
		width: 172px;
		height: 172px;
		top: -14px;
		right: -14px;
		border-radius: 63px;
		transform: rotate(0deg);
		background: transparent;
		border: 1px solid var(--bluePurple);
	}
	.elementLine4:after{
		content: "";
		position: absolute;
		z-index: 4;
		width: 197px;
		height: 196px;
		top: -26px;
		right: -26px;
		border-radius: 70px;
		transform: rotate(0deg);
		background: transparent;
		border: 1px solid var(--bluePurple);
	}

	.elementLine5{
		z-index: 0;
		transform: rotate(27deg);
		background: transparent;
		border: 1px solid var(--bluePurple);
		width: 284px;
		height: 284px;
		top: -6px;
		right: -137px;
		border-radius: 50%;
		opacity: 0;
		// display: none;
	}
}
/******************* Animate Home To About **********************/

body.ltr.home-to-about .globalElement4{
	animation: haGlobElem4 1s forwards ease-in-out;
}
@keyframes haGlobElem4 {
	0% { 
		opacity: 1;
	}
	
	100% { 
		opacity: 0;
	}
}
body.ltr.home-to-about .globalElement5{
	animation: haGlobElem5 1s forwards ease-in-out;
}
@keyframes haGlobElem5 {
	0% { 
		opacity: 1;
	}
	
	100% { 
		opacity: 0;
	}
}

 body.ltr.home-to-about .about-img{
	animation: aboutImg 2.5s forwards ease-in-out;
}
@keyframes aboutImg {
	0% { 
		bottom: -600px;
		left: 100px;
		opacity: 0;
	}
	70% { 
		bottom: 10px;
		left: -10px;
		opacity: 1;
	}
	100% { 
		bottom: 0;
		left: 0;
		opacity: 1;
	}
}

body.ltr.about-to-home .about-img{
	animation: aboutImgOut 2.5s forwards ease-in-out;
}
@keyframes aboutImgOut {
	0% { 
		bottom: 0;
		left: 0;
		opacity: 1;
		
	}
	100% { 
		bottom: -600px;
		left: 100px;
		opacity: 0;
	}
}

body.ltr.home-to-about .globalElement1{
	animation: haElem1 3s forwards ease-in-out;
}
@keyframes haElem1 {
	0% { 
		border-radius: 17px;
		width: 40px;
		height: 40px;
		left: 200px;
		top: 0px;
		transform: rotate(70deg);
		background: linear-gradient(120deg, rgba(255, 255, 255, .8) 0%, rgba(255, 255, 255, 0) 100%);
	}
	50% { 
		left: 340px;
		top: 320px;
		width: 160px;
		height: 160px;
		border-radius: 47px;
		transform: rotate(120deg);
		/* Add the blur effect */
		backdrop-filter: blur(10px);
		-webkit-backdrop-filter: blur(10px);
		z-index: 5;
	}
	100% { 
		left: 290px;
		top: 295px;
		width: 160px;
		height: 160px;
		border-radius: 47px;
		transform: rotate(120deg);
		/* Add the blur effect */
		backdrop-filter: blur(10px);
		-webkit-backdrop-filter: blur(10px);
		z-index: 5;
		
	}
}
body.ltr.home-to-about .globalElement2{
	animation: haElem2 3s forwards ease-in-out;
}

@keyframes haElem2 {
	0% { 
		border-radius: 35px;
		width: 100px;
		height: 100px;
		left: 750px;
		top: 50px;
		transform: rotate(70deg);
	}
	70% { 
		border-radius: 55px;
		width: 150px;
		height: 150px;
		left: 1450px;
		top: 30px;
		transform: rotate(140deg);
	}
	100% { 
		border-radius: 55px;
		width: 150px;
		height: 150px;
		left: 1420px;
		top: 30px;
		transform: rotate(135deg);
		
	}
}

body.ltr.home-to-about .globalElement3{
	animation: haElem3 3s forwards ease-in-out;
	
}

@keyframes haElem3 {
	0% { 
		border-radius: 80px;
		width: 250px;
		height: 250px;
		left: 95%;
		bottom: -100px;
		transform: rotate(70deg);
		background: linear-gradient(120deg, rgba(255, 255, 255, .8) 0%, rgba(255, 255, 255, 0) 100%);
		// transition: all 2s ease;
	}
	70% { 
		border-radius: 80px;
		width: 300px;
		height: 300px;
		left: 20px;
		bottom: 50px;
		transform: rotate(70deg);
		background: var(--bgGradient);
	}
	100% { 
		border-radius: 80px;
		width: 284px;
		height: 284px;
		left: 64px;
		bottom: 67px;
		transform: rotate(66deg);
		background: var(--bgGradient);
		
	}
}


body.ltr.home-to-about .elementLine4{
	animation: haElem4 3s forwards ease-in-out;
}
@keyframes haElem4 {
	0% { 
		z-index: 0;
		width: 144px;
		height: 144px;
		top: 293px;
		right: 185px;
		border-radius: 54px;
	}
	70% { 
		z-index: -1;
		width: 300px;
		height: 300px;
		top: 120px;
		right: 1460px;
		border-radius: 50%;
	}
	100% { 
		z-index: -1;
		width: 300px;
		height: 300px;
		top: 120px;
		right: 1420px;
		border-radius: 50%;
	}
}

body.ltr.home-to-about .elementLine4:before{
	animation: haElem4Before 3s forwards ease-in-out;
}
@keyframes haElem4Before {
	0% { 
		z-index: 4;
		width: 172px;
		height: 172px;
		top: -14px;
		left: -14px;
		border-radius: 63px;
	}
	70% { 
		z-index: -1;
		width: 342px;
		height: 342px;
		top: -22px;
		left: -22px;
		border-radius: 50%;
	}
	100% { 
		z-index: -1;
		width: 342px;
		height: 342px;
		top: -22px;
		left: -22px;
		border-radius: 50%;
	}
}


body.ltr.home-to-about .elementLine4:after{
	animation: haElem4After 3s forwards ease-in-out;
}
@keyframes haElem4After {
	0% { 
		width: 197px;
		height: 196px;
		top: -26px;
		right: -26px;
		border-radius: 70px;
	}
	70% { 
		z-index: -1;
		width: 384px;
		height: 384px;
		top: -43px;
		left: -43px;
		border-radius: 50%;
	}
	100% { 
		z-index: -1;
		width: 384px;
		height: 384px;
		top: -43px;
		left: -43px;
		border-radius: 50%;
	}
}





/******************* Animate Home To Services **********************/

body.ltr.home-to-services .globalElement4{
	animation: hsGlobElem4 1s forwards ease-in-out;
}
@keyframes hsGlobElem4 {
	0% { 
		opacity: 1;
	}
	
	100% { 
		opacity: 0;
	}
}
body.ltr.home-to-services .globalElement5{
	animation: hsGlobElem5 1s forwards ease-in-out;
}
@keyframes hsGlobElem5 {
	0% { 
		opacity: 1;
	}
	
	100% { 
		opacity: 0;
	}
}

body.ltr.home-to-services .globalElement1{
	animation: hsElem1 1s forwards ease-in-out;
}
@keyframes hsElem1 {
	0% { 
		opacity: 1;
	}
	
	100% { 
		opacity: 0;
	}
}
body.ltr.home-to-services .globalElement2{
	animation: hsElem2 3s forwards ease-in-out;
}

@keyframes hsElem2 {
	0% { 
		border-radius: 35px;
		width: 100px;
		height: 100px;
		left: 750px;
		top: 50px;
		transform: rotate(70deg);
	}
	70% { 
		border-radius: 50%;
		width: 150px;
		height: 150px;
		left: 1450px;
		top: 60px;
		transform: rotate(140deg);
	}
	100% { 
		border-radius: 50%;
		width: 150px;
		height: 150px;
		left: 1420px;
		top: 60px;
		transform: rotate(135deg);
		
	}
}

body.ltr.home-to-services .globalElement3{
	animation: hsElem3 1s forwards ease-in-out;
	
}

@keyframes hsElem3 {
	0% { 
		opacity: 1;
	}
	
	100% { 
		opacity: 0;
	}
}


body.ltr.home-to-services .elementLine4{
	animation: hsElem4 3s forwards ease-in-out;
}
@keyframes hsElem4 {
	0% { 
		z-index: 0;
		width: 144px;
		height: 144px;
		top: 293px;
		right: 185px;
		border-radius: 54px;
	}
	70% { 
		z-index: -1;
		width: 200px;
		height: 200px;
		top: 36px;
		right: -125px;
		border-radius: 50%;
	}
	100% { 
		z-index: -1;
		width: 200px;
		height: 200px;
		top: 36px;
		right: -95px;
		border-radius: 50%;
	}
}

body.ltr.home-to-services .elementLine4:before{
	animation: hsElem4Before 3s forwards ease-in-out;
}
@keyframes hsElem4Before {
	0% { 
		z-index: 4;
		width: 172px;
		height: 172px;
		top: -14px;
		left: -14px;
		border-radius: 63px;
	}
	70% { 
		z-index: -1;
		width: 242px;
		height: 242px;
		top: -22px;
		left: -22px;
		border-radius: 50%;
	}
	100% { 
		z-index: -1;
		width: 242px;
		height: 242px;
		top: -22px;
		left: -22px;
		border-radius: 50%;
	}
}


body.ltr.home-to-services .elementLine4:after{
	animation: hsElem4After 3s forwards ease-in-out;
}
@keyframes hsElem4After {
	0% { 
		width: 197px;
		height: 196px;
		top: -26px;
		right: -26px;
		border-radius: 70px;
	}
	70% { 
		z-index: -1;
		width: 284px;
		height: 284px;
		top: -43px;
		left: -43px;
		border-radius: 50%;
	}
	100% { 
		z-index: -1;
		width: 284px;
		height: 284px;
		top: -43px;
		left: -43px;
		border-radius: 50%;
	}
}



body.ltr.home-to-services.modal-open .elementLine5{
	animation: hsElem5 3s forwards ease-in-out;
	
	// display: none;
}
@keyframes hsElem5 {
	0% { 
		z-index: 1100;
		width: 284px;
		height: 284px;
		top: -6px;
		right: -137px;
		border-radius: 50%;
		opacity: 0;
		transform: rotate(27deg);
	}
	70% { 
		transform: rotate(-114deg);
		z-index: 1057;
		width: 325px;
		height: 325px;
		top: 60px;
		right: 1060px;
		border-radius: 100px;
		opacity: 1;
	}
	99% { 
		transform: rotate(-114deg);
		z-index: 1056;
		width: 325px;
		height: 325px;
		top: 60px;
		right: 1034px;
		border-radius: 100px;
		opacity: 1;
	}
	100% { 
		transform: rotate(-114deg);
		z-index: 1056;
		width: 325px;
		height: 325px;
		top: 60px;
		right: 1034px;
		border-radius: 100px;
		opacity: 0;
	}
}






/******************* Animate Home To Portfolio **********************/

body.ltr.home-to-portfolio .globalElement4{
	animation: hpGlobElem4 1s forwards ease-in-out;
}
@keyframes hpGlobElem4 {
	0% { 
		opacity: 1;
	}
	
	100% { 
		opacity: 0;
	}
}
body.ltr.home-to-portfolio .globalElement5{
	animation: hpGlobElem5 1s forwards ease-in-out;
}
@keyframes hpGlobElem5 {
	0% { 
		opacity: 1;
	}
	
	100% { 
		opacity: 0;
	}
}

body.ltr.home-to-portfolio .globalElement1{
	animation: hpElem1 1s forwards ease-in-out;
}
@keyframes hpElem1 {
	0% { 
		opacity: 1;
	}
	
	100% { 
		opacity: 0;
	}
}
body.ltr.home-to-portfolio .globalElement2{
	animation: hpElem2 3s forwards ease-in-out;
}

@keyframes hpElem2 {
	0% { 
		border-radius: 35px;
		width: 100px;
		height: 100px;
		left: 750px;
		top: 50px;
		transform: rotate(70deg);
	}
	70% { 
		border-radius: 50%;
		width: 150px;
		height: 150px;
		left: 1450px;
		top: -20px;
		transform: rotate(140deg);
	}
	100% { 
		border-radius: 50%;
		width: 150px;
		height: 150px;
		left: 1420px;
		top: 0px;
		transform: rotate(135deg);
		
	}
}

body.ltr.home-to-portfolio .globalElement3{
	animation: hpElem3 1s forwards ease-in-out;
	
}

@keyframes hpElem3 {
	0% { 
		opacity: 1;
	}
	
	100% { 
		opacity: 0;
	}
}


body.ltr.home-to-portfolio .elementLine4{
	animation: hpElem4 3s forwards ease-in-out;
}
@keyframes hpElem4 {
	0% { 
		z-index: 0;
		width: 144px;
		height: 144px;
		top: 293px;
		right: 185px;
		border-radius: 54px;
	}
	70% { 
		z-index: -1;
		width: 200px;
		height: 200px;
		top: -10px;
		right: 1480px;
		border-radius: 50%;
	}
	100% { 
		z-index: -1;
		width: 200px;
		height: 200px;
		top: 10px;
		right: 1450px;
		border-radius: 50%;
	}
}

body.ltr.home-to-portfolio .elementLine4:before{
	animation: hpElem4Before 3s forwards ease-in-out;
}
@keyframes hpElem4Before {
	0% { 
		z-index: 4;
		width: 172px;
		height: 172px;
		top: -14px;
		left: -14px;
		border-radius: 63px;
	}
	70% { 
		z-index: -1;
		width: 242px;
		height: 242px;
		top: -22px;
		left: -22px;
		border-radius: 50%;
	}
	100% { 
		z-index: -1;
		width: 242px;
		height: 242px;
		top: -22px;
		left: -22px;
		border-radius: 50%;
	}
}


body.ltr.home-to-portfolio .elementLine4:after{
	animation: hpElem4After 3s forwards ease-in-out;
}
@keyframes hpElem4After {
	0% { 
		width: 197px;
		height: 196px;
		top: -26px;
		right: -26px;
		border-radius: 70px;
	}
	70% { 
		z-index: -1;
		width: 284px;
		height: 284px;
		top: -43px;
		left: -43px;
		border-radius: 50%;
	}
	100% { 
		z-index: -1;
		width: 284px;
		height: 284px;
		top: -43px;
		left: -43px;
		border-radius: 50%;
	}
}


/******************* Animate Home To Team **********************/

body.ltr.home-to-team .globalElement4{
	animation: htGlobElem4 1s forwards ease-in-out;
}
@keyframes htGlobElem4 {
	0% { 
		opacity: 1;
	}
	
	100% { 
		opacity: 0;
	}
}
body.ltr.home-to-team .globalElement5{
	animation: htGlobElem5 1s forwards ease-in-out;
}
@keyframes htGlobElem5 {
	0% { 
		opacity: 1;
	}
	
	100% { 
		opacity: 0;
	}
}

body.ltr.home-to-team .globalElement1{
	animation: htElem1 1s forwards ease-in-out;
}
@keyframes htElem1 {
	0% { 
		opacity: 1;
	}
	
	100% { 
		opacity: 0;
	}
}
body.ltr.home-to-team .globalElement2{
	animation: htElem2 3s forwards ease-in-out;
}

@keyframes htElem2 {
	0% { 
		border-radius: 35px;
		width: 100px;
		height: 100px;
		left: 750px;
		top: 50px;
		transform: rotate(70deg);
	}
	70% { 
		border-radius: 50%;
		width: 150px;
		height: 150px;
		left: 1450px;
		top: 40px;
		transform: rotate(140deg);
	}
	100% { 
		border-radius: 50%;
		width: 150px;
		height: 150px;
		left: 1420px;
		top: 40px;
		transform: rotate(135deg);
		
	}
}

body.ltr.home-to-team .globalElement3{
	animation: htElem3 1s forwards ease-in-out;
	
}

@keyframes htElem3 {
	0% { 
		opacity: 1;
	}
	
	100% { 
		opacity: 0;
	}
}


body.ltr.home-to-team .elementLine4{
	animation: htElem4 3s forwards ease-in-out;
}
@keyframes htElem4 {
	0% { 
		z-index: 0;
		width: 144px;
		height: 144px;
		top: 293px;
		right: 185px;
		border-radius: 54px;
	}
	70% { 
		z-index: -1;
		width: 200px;
		height: 200px;
		top: 445px;
		right: 1430px;
		border-radius: 50%;
	}
	100% { 
		z-index: -1;
		width: 200px;
		height: 200px;
		top: 430px;
		right: 1400px;
		border-radius: 50%;
	}
}

body.ltr.home-to-team .elementLine4:before{
	animation: htElem4Before 3s forwards ease-in-out;
}
@keyframes htElem4Before {
	0% { 
		z-index: 4;
		width: 172px;
		height: 172px;
		top: -14px;
		left: -14px;
		border-radius: 63px;
	}
	70% { 
		z-index: -1;
		width: 242px;
		height: 242px;
		top: -22px;
		left: -22px;
		border-radius: 50%;
	}
	100% { 
		z-index: -1;
		width: 242px;
		height: 242px;
		top: -22px;
		left: -22px;
		border-radius: 50%;
	}
}


body.ltr.home-to-team .elementLine4:after{
	animation: htElem4After 3s forwards ease-in-out;
}
@keyframes htElem4After {
	0% { 
		width: 197px;
		height: 196px;
		top: -26px;
		right: -26px;
		border-radius: 70px;
	}
	70% { 
		z-index: -1;
		width: 284px;
		height: 284px;
		top: -43px;
		left: -43px;
		border-radius: 50%;
	}
	100% { 
		z-index: -1;
		width: 284px;
		height: 284px;
		top: -43px;
		left: -43px;
		border-radius: 50%;
	}
}


/******************* Animate Home To Testimonial **********************/

body.ltr.home-to-testimonials .globalElement4{
	animation: htesGlobElem4 1s forwards ease-in-out;
}
@keyframes htesGlobElem4 {
	0% { 
		opacity: 1;
	}
	
	100% { 
		opacity: 0;
	}
}
body.ltr.home-to-testimonials .globalElement5{
	animation: htesGlobElem5 1s forwards ease-in-out;
}
@keyframes htesGlobElem5 {
	0% { 
		opacity: 1;
	}
	
	100% { 
		opacity: 0;
	}
}

body.ltr.home-to-testimonials .globalElement1{
	animation: htesElem1 1s forwards ease-in-out;
}
@keyframes htesElem1 {
	0% { 
		opacity: 1;
	}
	
	100% { 
		opacity: 0;
	}
}
body.ltr.home-to-testimonials .globalElement2{
	animation: htesElem2 3s forwards ease-in-out;
}

@keyframes htesElem2 {
	0% { 
		border-radius: 35px;
		width: 100px;
		height: 100px;
		left: 750px;
		top: 50px;
		transform: rotate(70deg);
	}
	70% { 
		border-radius: 50%;
		width: 150px;
		height: 150px;
		left: 1450px;
		top: 40px;
		transform: rotate(140deg);
	}
	100% { 
		border-radius: 50%;
		width: 150px;
		height: 150px;
		left: 1420px;
		top: 40px;
		transform: rotate(135deg);
		
	}
}

body.ltr.home-to-testimonials .globalElement3{
	animation: htesElem3 1s forwards ease-in-out;
	
}

@keyframes htesElem3 {
	0% { 
		opacity: 1;
	}
	
	100% { 
		opacity: 0;
	}
}


body.ltr.home-to-testimonials .elementLine4{
	animation: htesElem4 3s forwards ease-in-out;
}
@keyframes htesElem4 {
	0% { 
		z-index: 0;
		width: 144px;
		height: 144px;
		top: 293px;
		right: 185px;
		border-radius: 54px;
	}
	70% { 
		z-index: -1;
		width: 200px;
		height: 200px;
		top: 445px;
		right: 1430px;
		border-radius: 50%;
	}
	100% { 
		z-index: -1;
		width: 200px;
		height: 200px;
		top: 430px;
		right: 1400px;
		border-radius: 50%;
	}
}

body.ltr.home-to-testimonials .elementLine4:before{
	animation: htesElem4Before 3s forwards ease-in-out;
}
@keyframes htesElem4Before {
	0% { 
		z-index: 4;
		width: 172px;
		height: 172px;
		top: -14px;
		left: -14px;
		border-radius: 63px;
	}
	70% { 
		z-index: -1;
		width: 242px;
		height: 242px;
		top: -22px;
		left: -22px;
		border-radius: 50%;
	}
	100% { 
		z-index: -1;
		width: 242px;
		height: 242px;
		top: -22px;
		left: -22px;
		border-radius: 50%;
	}
}


body.home-to-testimonials .elementLine4:after{
	animation: htesElem4After 3s forwards ease-in-out;
}
@keyframes htesElem4After {
	0% { 
		width: 197px;
		height: 196px;
		top: -26px;
		right: -26px;
		border-radius: 70px;
	}
	70% { 
		z-index: -1;
		width: 284px;
		height: 284px;
		top: -43px;
		left: -43px;
		border-radius: 50%;
	}
	100% { 
		z-index: -1;
		width: 284px;
		height: 284px;
		top: -43px;
		left: -43px;
		border-radius: 50%;
	}
}

/******************* Animate Home To Clients **********************/

body.ltr.home-to-clients .globalElement4{
	animation: hcGlobElem4 3s forwards ease-in-out;
}
@keyframes hcGlobElem4 {
	0% { 
		border-radius: 21px;
		width: 48px;
		height: 48px;
		left: 875px;
		top: 350px;
		transform: rotate(60deg);
	}
	70% { 
		border-radius: 21px;
		width: 48px;
		height: 48px;
		left: 620px;
		top: 450px;
		transform: rotate(-60deg);
		z-index: -1;
	}
	100% { 
		border-radius: 21px;
		width: 48px;
		height: 48px;
		left: 630px;
		top: 430px;
		transform: rotate(-60deg);
		z-index: -1;
	}
}
body.ltr.home-to-clients .globalElement5{
	animation: hcGlobElem5 3s forwards ease-in-out;
}
@keyframes hcGlobElem5 {
	0% { 
		border-radius: 18px;
		width: 40px;
		height: 40px;
		right: 80px;
		top: 30px;
		transform: rotate(60deg);
	}
	70% { 
		border-radius: 18px;
		width: 40px;
		height: 40px;
		right: 80px;
		top: 90px;
		transform: rotate(60deg);
	}
	100% { 
		border-radius: 18px;
		width: 40px;
		height: 40px;
		right: 80px;
		top: 80px;
		transform: rotate(60deg);
	}
}

body.ltr.home-to-clients .globalElement1{
	animation: hcElem1 3s forwards ease-in-out;
}
@keyframes hcElem1 {
	0% { 
		border-radius: 17px;
		width: 40px;
		height: 40px;
		left: 200px;
		top: 0px;
		transform: rotate(70deg);
	}
	70% { 
		border-radius: 35px;
		width: 100px;
		height: 100px;
		left: 90px;
		top: -25px;
		transform: rotate(-70deg);
	}
	100% { 
		border-radius: 35px;
		width: 100px;
		height: 100px;
		left: 100px;
		top: -20px;
		transform: rotate(-70deg);
	}
}
body.ltr.home-to-clients .globalElement2{
	animation: hcElem2 3s forwards ease-in-out;
}

@keyframes hcElem2 {
	0% { 
		border-radius: 35px;
		width: 100px;
		height: 100px;
		left: 750px;
		top: 50px;
		transform: rotate(70deg);
	}
	70% { 
		border-radius: 50%;
		width: 250px;
		height: 250px;
		left: 625px;
		top: 525px;
		transform: rotate(140deg);
	}
	100% { 
		border-radius: 50%;
		width: 250px;
		height: 250px;
		left: 625px;
		top: 495px;
		transform: rotate(135deg);
		
	}
}


body.ltr.home-to-clients .elementLine4{
	animation: hcElem4 3s forwards ease-in-out;
}
@keyframes hcElem4 {
	0% { 
		z-index: 0;
		width: 144px;
		height: 144px;
		top: 293px;
		right: 185px;
		border-radius: 54px;
	}
	70% { 
		z-index: -1;
		width: 300px;
		height: 300px;
		top: 500px;
		right: 610px;
		border-radius: 50%;
	}
	100% { 
		z-index: -1;
		width: 300px;
		height: 300px;
		top: 470px;
		right: 600px;
		border-radius: 50%;
	}
}

body.ltr.home-to-clients .elementLine4:before{
	animation: hcElem4Before 3s forwards ease-in-out;
}
@keyframes hcElem4Before {
	0% { 
		z-index: 4;
		width: 172px;
		height: 172px;
		top: -14px;
		left: -14px;
		border-radius: 63px;
	}
	70% { 
		z-index: -1;
		width: 342px;
		height: 342px;
		top: -22px;
		left: -22px;
		border-radius: 50%;
	}
	100% { 
		z-index: -1;
		width: 342px;
		height: 342px;
		top: -22px;
		left: -22px;
		border-radius: 50%;
	}
}


body.ltr.home-to-clients .elementLine4:after{
	animation: hcElem4After 3s forwards ease-in-out;
}
@keyframes hcElem4After {
	0% { 
		width: 197px;
		height: 196px;
		top: -26px;
		right: -26px;
		border-radius: 70px;
	}
	70% { 
		z-index: -1;
		width: 384px;
		height: 384px;
		top: -43px;
		left: -43px;
		border-radius: 50%;
	}
	100% { 
		z-index: -1;
		width: 384px;
		height: 384px;
		top: -43px;
		left: -43px;
		border-radius: 50%;
	}
}



body.ltr.home-to-clients.modal-open .elementLine5{
	animation: hcElem5 3s forwards ease-in-out;
	
	// display: none;
}
@keyframes hcElem5 {
	0% { 
		z-index: 1100;
		width: 284px;
		height: 284px;
		top: -6px;
		right: -137px;
		border-radius: 50%;
		opacity: 0;
		transform: rotate(27deg);
	}
	70% { 
		transform: rotate(-114deg);
		z-index: 1057;
		width: 325px;
		height: 325px;
		top: 60px;
		right: 1060px;
		border-radius: 100px;
		opacity: 1;
	}
	99% { 
		transform: rotate(-114deg);
		z-index: 1056;
		width: 325px;
		height: 325px;
		top: 60px;
		right: 1034px;
		border-radius: 100px;
		opacity: 1;
	}
	100% { 
		transform: rotate(-114deg);
		z-index: 1056;
		width: 325px;
		height: 325px;
		top: 60px;
		right: 1034px;
		border-radius: 100px;
		opacity: 0;
	}
}




/******************* Animate Home To Contact Us **********************/

body.ltr.home-to-contact .globalElement4{
	animation: hCtGlobElem4 1s forwards ease-in-out;
}
@keyframes hCtGlobElem4 {
	0% { 
		opacity: 1;
	}
	
	100% { 
		opacity: 0;
	}
}
body.ltr.home-to-contact .globalElement5{
	animation: hCtGlobElem5 1s forwards ease-in-out;
}
@keyframes hCtGlobElem5 {
	0% { 
		opacity: 1;
	}
	
	100% { 
		opacity: 0;
	}
}

body.ltr.home-to-contact .globalElement1{
	animation: hCtElem1 1s forwards ease-in-out;
}
@keyframes hCtElem1 {
	0% { 
		opacity: 1;
	}
	
	100% { 
		opacity: 0;
	}
}
body.ltr.home-to-contact .globalElement2{
	animation: hCtElem2 3s forwards ease-in-out;
}

@keyframes hCtElem2 {
	0% { 
		border-radius: 35px;
		width: 100px;
		height: 100px;
		left: 750px;
		top: 50px;
		transform: rotate(70deg);
	}
	70% { 
		border-radius: 50%;
		width: 150px;
		height: 150px;
		left: 1450px;
		top: -20px;
		transform: rotate(140deg);
	}
	100% { 
		border-radius: 50%;
		width: 150px;
		height: 150px;
		left: 1420px;
		top: 0px;
		transform: rotate(135deg);
		
	}
}

body.ltr.home-to-contact .globalElement3{
	animation: hCtElem3 1s forwards ease-in-out;
	
}

@keyframes hCtElem3 {
	0% { 
		opacity: 1;
	}
	
	100% { 
		opacity: 0;
	}
}


body.ltr.home-to-contact .elementLine4{
	animation: hCtElem4 3s forwards ease-in-out;
}
@keyframes hCtElem4 {
	0% { 
		z-index: 0;
		width: 144px;
		height: 144px;
		top: 293px;
		right: 185px;
		border-radius: 54px;
	}
	70% { 
		z-index: -1;
		width: 200px;
		height: 200px;
		top: 180px;
		right: 1480px;
		border-radius: 50%;
	}
	100% { 
		z-index: -1;
		width: 200px;
		height: 200px;
		top: 180px;
		right: 1450px;
		border-radius: 50%;
	}
}

body.ltr.home-to-contact .elementLine4:before{
	animation: hCtElem4Before 3s forwards ease-in-out;
}
@keyframes hCtElem4Before {
	0% { 
		z-index: 4;
		width: 172px;
		height: 172px;
		top: -14px;
		left: -14px;
		border-radius: 63px;
	}
	70% { 
		z-index: -1;
		width: 242px;
		height: 242px;
		top: -22px;
		left: -22px;
		border-radius: 50%;
	}
	100% { 
		z-index: -1;
		width: 242px;
		height: 242px;
		top: -22px;
		left: -22px;
		border-radius: 50%;
	}
}


body.ltr.home-to-contact .elementLine4:after{
	animation: hCtElem4After 3s forwards ease-in-out;
}
@keyframes hCtElem4After {
	0% { 
		width: 197px;
		height: 196px;
		top: -26px;
		right: -26px;
		border-radius: 70px;
	}
	70% { 
		z-index: -1;
		width: 284px;
		height: 284px;
		top: -43px;
		left: -43px;
		border-radius: 50%;
	}
	100% { 
		z-index: -1;
		width: 284px;
		height: 284px;
		top: -43px;
		left: -43px;
		border-radius: 50%;
	}
}



/******************* Animate Home To FAQs **********************/

body.ltr.home-to-faqs .globalElement4{
	animation: hfGlobElem4 1s forwards ease-in-out;
}
@keyframes hfGlobElem4 {
	0% { 
		opacity: 1;
	}
	
	100% { 
		opacity: 0;
	}
}
body.ltr.home-to-faqs .globalElement5{
	animation: hfGlobElem5 1s forwards ease-in-out;
}
@keyframes hfGlobElem5 {
	0% { 
		opacity: 1;
	}
	
	100% { 
		opacity: 0;
	}
}

body.ltr.home-to-faqs .globalElement1{
	animation: hfElem1 1s forwards ease-in-out;
}
@keyframes hfElem1 {
	0% { 
		opacity: 1;
	}
	
	100% { 
		opacity: 0;
	}
}
body.ltr.home-to-faqs .globalElement2{
	animation: hfElem2 3s forwards ease-in-out;
}

@keyframes hfElem2 {
	0% { 
		border-radius: 35px;
		width: 100px;
		height: 100px;
		left: 750px;
		top: 50px;
		transform: rotate(70deg);
	}
	70% { 
		border-radius: 50%;
		width: 150px;
		height: 150px;
		left: 1450px;
		top: -20px;
		transform: rotate(140deg);
	}
	100% { 
		border-radius: 50%;
		width: 150px;
		height: 150px;
		left: 1420px;
		top: 0px;
		transform: rotate(135deg);
		
	}
}

body.ltr.home-to-faqs .globalElement3{
	animation: hfElem3 1s forwards ease-in-out;
	
}

@keyframes hfElem3 {
	0% { 
		opacity: 1;
	}
	
	100% { 
		opacity: 0;
	}
}


body.ltr.home-to-faqs .elementLine4{
	animation: hfElem4 3s forwards ease-in-out;
}
@keyframes hfElem4 {
	0% { 
		z-index: 0;
		width: 144px;
		height: 144px;
		top: 293px;
		right: 185px;
		border-radius: 54px;
	}
	70% { 
		z-index: -1;
		width: 200px;
		height: 200px;
		top: 180px;
		right: 1480px;
		border-radius: 50%;
	}
	100% { 
		z-index: -1;
		width: 200px;
		height: 200px;
		top: 180px;
		right: 1450px;
		border-radius: 50%;
	}
}

body.ltr.home-to-faqs .elementLine4:before{
	animation: hfElem4Before 3s forwards ease-in-out;
}
@keyframes hfElem4Before {
	0% { 
		z-index: 4;
		width: 172px;
		height: 172px;
		top: -14px;
		left: -14px;
		border-radius: 63px;
	}
	70% { 
		z-index: -1;
		width: 242px;
		height: 242px;
		top: -22px;
		left: -22px;
		border-radius: 50%;
	}
	100% { 
		z-index: -1;
		width: 242px;
		height: 242px;
		top: -22px;
		left: -22px;
		border-radius: 50%;
	}
}


body.ltr.home-to-faqs .elementLine4:after{
	animation: hfElem4After 3s forwards ease-in-out;
}
@keyframes hfElem4After {
	0% { 
		width: 197px;
		height: 196px;
		top: -26px;
		right: -26px;
		border-radius: 70px;
	}
	70% { 
		z-index: -1;
		width: 284px;
		height: 284px;
		top: -43px;
		left: -43px;
		border-radius: 50%;
	}
	100% { 
		z-index: -1;
		width: 284px;
		height: 284px;
		top: -43px;
		left: -43px;
		border-radius: 50%;
	}
}





/******************* Animate About to All **********************/

	/******************* Animate About to Home **********************/
		body.ltr.about .leftColAbout img.about-img{
			bottom: 0;
			left: 0;
			opacity: 1;
		}
		
		body.ltr.about-to-home .globalElement1{
			animation: ahElem1 2s forwards ease-in-out;
		}
		@keyframes ahElem1 {
			0% { 
				left: 290px;
				top: 245px;
				width: 160px;
				height: 160px;
				border-radius: 47px;
				transform: rotate(120deg);
				/* Add the blur effect */
				backdrop-filter: blur(10px);
				-webkit-backdrop-filter: blur(10px);
				z-index: 5;
			}
			70% { 
				border-radius: 17px;
				width: 40px;
				height: 40px;
				left: 180px;
				top: -30px;
				transform: rotate(70deg);
				background: linear-gradient(120deg, rgba(255, 255, 255, .8) 0%, rgba(255, 255, 255, 0) 100%);
			}
			100% { 
				border-radius: 17px;
				width: 40px;
				height: 40px;
				left: 200px;
				top: 0px;
				transform: rotate(70deg);
				background: linear-gradient(120deg, rgba(255, 255, 255, .8) 0%, rgba(255, 255, 255, 0) 100%);
				// backdrop-filter: blur(10px);
				// -webkit-backdrop-filter: blur(10px);
				// transition: all 2s ease;
				
			}
		}


		body.ltr.about-to-home .globalElement2{
			animation: ahElem2 3s forwards ease-in-out;
		}

		@keyframes ahElem2 {
			0% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				left: 1420px;
				top: 30px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				left: 720px;
				top: 50px;
				transform: rotate(70deg);
			}
			100% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				left: 750px;
				top: 50px;
				transform: rotate(70deg);
			}
		}

		body.ltr.about-to-home .globalElement3{
			animation: ahElem3 3s forwards ease-in-out;
			
		}

		@keyframes ahElem3 {
			0% { 
				border-radius: 80px;
				width: 284px;
				height: 284px;
				left: 64px;
				bottom: 67px;
				transform: rotate(66deg);
				background: var(--bgGradient);
			}
			70% { 
				border-radius: 80px;
				width: 250px;
				height: 250px;
				left: 96%;
				bottom: -120px;
				transform: rotate(70deg);
				// background: linear-gradient(120deg, rgba(255, 255, 255, .8) 0%, rgba(255, 255, 255, 0) 100%);
			}
			100% { 
				border-radius: 80px;
				width: 250px;
				height: 250px;
				left: 95%;
				bottom: -100px;
				transform: rotate(70deg);
				background: linear-gradient(120deg, rgba(255, 255, 255, .8) 0%, rgba(255, 255, 255, 0) 100%);
				
				
			}
		}



		body.ltr.about-to-home .elementLine4{
			animation: ahElem4 3s forwards ease-in-out;
		}
		@keyframes ahElem4 {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1420px;
				border-radius: 50%;
			}
			70% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				right: 155px;
				border-radius: 54px;
			}
			100% { 
				z-index: 0;
				width: 144px;
				height: 144px;
				top: 293px;
				right: 185px;
				border-radius: 54px;
			}
		}

		body.ltr.about-to-home .elementLine4:before{
			animation: ahElem4Before 3s forwards ease-in-out;
		}
		@keyframes ahElem4Before {
			0% { 
				z-index: -1;
				width: 342px;
				height: 342px;
				top: -22px;
				left: -22px;
				border-radius: 50%;
			}
			70% { 
				z-index: 4;
				width: 172px;
				height: 172px;
				top: -14px;
				left: -14px;
				border-radius: 63px;
			}
			100% { 
				z-index: 4;
				width: 172px;
				height: 172px;
				top: -14px;
				left: -14px;
				border-radius: 63px;
			}
		}


		body.ltr.about-to-home .elementLine4:after{
			animation: ahElem4After 3s forwards ease-in-out;
		}
		@keyframes ahElem4After {
			0% { 
				z-index: -1;
				width: 384px;
				height: 384px;
				top: -43px;
				left: -43px;
				border-radius: 50%;
				
			}
			70% { 
				width: 197px;
				height: 196px;
				top: -26px;
				right: -26px;
				border-radius: 70px;
			}
			100% { 
				width: 197px;
				height: 196px;
				top: -26px;
				right: -26px;
				border-radius: 70px;
			}
		}


	/******************* Animate About to Services **********************/
		
		body.ltr.about-to-services .globalElement1{
			animation: asElem1 2s forwards ease-in-out;
		}
		@keyframes asElem1 {
			0% { 
				opacity: 0;
			}
			100% { 
				opacity: 0;
			}
		}


		body.ltr.about-to-services .globalElement2{
			animation: asElem2 3s forwards ease-in-out;
		}

		@keyframes asElem2 {
			0% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				left: 1420px;
				top: 30px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1450px;
				top: 60px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1420px;
				top: 60px;
				transform: rotate(135deg);
				
			}
		}

		body.ltr.about-to-services .globalElement3{
			animation: asElem3 3s forwards ease-in-out;
			
		}

		@keyframes asElem3 {
			0% { 
				opacity: 0;
			}
			100% { 
				opacity: 0;
			}
		}

		body.ltr.about-to-services .globalElement4{
			animation: asGlobElem4 1s forwards ease-in-out;
		}
		@keyframes asGlobElem4 {
			0% { 
				opacity: 0;
			}
			
			100% { 
				opacity: 0;
			}
		}

		body.ltr.about-to-services .globalElement5{
			animation: asGlobElem5 1s forwards ease-in-out;
		}
		@keyframes asGlobElem5 {
			0% { 
				opacity: 0;
			}
			
			100% { 
				opacity: 0;
			}
		}

		body.ltr.about-to-services .elementLine4{
			animation: asElem4 3s forwards ease-in-out;
		}
		@keyframes asElem4 {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1420px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				right: -125px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				right: -95px;
				border-radius: 50%;
			}
		}

		body.ltr.about-to-services .elementLine4:before{
			animation: asElem4Before 3s forwards ease-in-out;
		}
		@keyframes asElem4Before {
			0% { 
				z-index: -1;
				width: 342px;
				height: 342px;
				top: -22px;
				left: -22px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 242px;
				height: 242px;
				top: -22px;
				left: -22px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 242px;
				height: 242px;
				top: -22px;
				left: -22px;
				border-radius: 50%;
			}
		}


		body.ltr.about-to-services .elementLine4:after{
			animation: asElem4After 3s forwards ease-in-out;
		}
		@keyframes asElem4After {
			0% { 
				z-index: -1;
				width: 384px;
				height: 384px;
				top: -43px;
				left: -43px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: -1;
				width: 284px;
				height: 284px;
				top: -43px;
				left: -43px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 284px;
				height: 284px;
				top: -43px;
				left: -43px;
				border-radius: 50%;
			}
		}
			

	/******************* Animate About to Portfolio **********************/
		
		body.ltr.about-to-portfolio .globalElement1{
			animation: apElem1 2s forwards ease-in-out;
		}
		@keyframes apElem1 {
			0% { 
				opacity: 0;
			}
			100% { 
				opacity: 0;
			}
		}


		body.ltr.about-to-portfolio .globalElement2{
			animation: apElem2 3s forwards ease-in-out;
		}

		@keyframes apElem2 {
			0% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				left: 1420px;
				top: 30px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1450px;
				top: -20px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1420px;
				top: 0px;
				transform: rotate(135deg);
				
			}
		}

		body.ltr.about-to-portfolio .globalElement3{
			animation: apElem3 3s forwards ease-in-out;
			
		}

		@keyframes apElem3 {
			0% { 
				opacity: 0;
			}
			100% { 
				opacity: 0;
			}
		}

		body.ltr.about-to-portfolio .globalElement4{
			animation: apGlobElem4 1s forwards ease-in-out;
		}
		@keyframes apGlobElem4 {
			0% { 
				opacity: 0;
			}
			
			100% { 
				opacity: 0;
			}
		}

		body.ltr.about-to-portfolio .globalElement5{
			animation: apGlobElem5 1s forwards ease-in-out;
		}
		@keyframes apGlobElem5 {
			0% { 
				opacity: 0;
			}
			
			100% { 
				opacity: 0;
			}
		}

		body.ltr.about-to-portfolio .elementLine4{
			animation: apElem4 3s forwards ease-in-out;
		}
		@keyframes apElem4 {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1420px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: -10px;
				right: 1480px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				right: 1450px;
				border-radius: 50%;
			}
		}

		body.ltr.about-to-portfolio .elementLine4:before{
			animation: apElem4Before 3s forwards ease-in-out;
		}
		@keyframes apElem4Before {
			0% { 
				z-index: -1;
				width: 342px;
				height: 342px;
				top: -22px;
				left: -22px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 242px;
				height: 242px;
				top: -22px;
				left: -22px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 242px;
				height: 242px;
				top: -22px;
				left: -22px;
				border-radius: 50%;
			}
		}


		body.ltr.about-to-portfolio .elementLine4:after{
			animation: apElem4After 3s forwards ease-in-out;
		}
		@keyframes apElem4After {
			0% { 
				z-index: -1;
				width: 384px;
				height: 384px;
				top: -43px;
				left: -43px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: -1;
				width: 284px;
				height: 284px;
				top: -43px;
				left: -43px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 284px;
				height: 284px;
				top: -43px;
				left: -43px;
				border-radius: 50%;
			}
		}


	/******************* Animate About to Team **********************/
		
		body.ltr.about-to-team .globalElement1{
			animation: atElem1 2s forwards ease-in-out;
		}
		@keyframes atElem1 {
			0% { 
				opacity: 0;
			}
			100% { 
				opacity: 0;
			}
		}


		body.ltr.about-to-team .globalElement2{
			animation: atElem2 3s forwards ease-in-out;
		}

		@keyframes atElem2 {
			0% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				left: 1420px;
				top: 30px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1450px;
				top: 40px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1420px;
				top: 40px;
				transform: rotate(135deg);
				
			}
		}

		body.ltr.about-to-team .globalElement3{
			animation: atElem3 3s forwards ease-in-out;
			
		}

		@keyframes atElem3 {
			0% { 
				opacity: 0;
			}
			100% { 
				opacity: 0;
			}
		}

		body.ltr.about-to-team .globalElement4{
			animation: atGlobElem4 1s forwards ease-in-out;
		}
		@keyframes atGlobElem4 {
			0% { 
				opacity: 0;
			}
			
			100% { 
				opacity: 0;
			}
		}

		body.ltr.about-to-team .globalElement5{
			animation: atGlobElem5 1s forwards ease-in-out;
		}
		@keyframes atGlobElem5 {
			0% { 
				opacity: 0;
			}
			
			100% { 
				opacity: 0;
			}
		}

		body.ltr.about-to-team .elementLine4{
			animation: atElem4 3s forwards ease-in-out;
		}
		@keyframes atElem4 {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1420px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 445px;
				right: 1430px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1400px;
				border-radius: 50%;
			}
		}

		body.ltr.about-to-team .elementLine4:before{
			animation: atElem4Before 3s forwards ease-in-out;
		}
		@keyframes atElem4Before {
			0% { 
				z-index: -1;
				width: 342px;
				height: 342px;
				top: -22px;
				left: -22px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 242px;
				height: 242px;
				top: -22px;
				left: -22px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 242px;
				height: 242px;
				top: -22px;
				left: -22px;
				border-radius: 50%;
			}
		}


		body.ltr.about-to-team .elementLine4:after{
			animation: atElem4After 3s forwards ease-in-out;
		}
		@keyframes atElem4After {
			0% { 
				z-index: -1;
				width: 384px;
				height: 384px;
				top: -43px;
				left: -43px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: -1;
				width: 284px;
				height: 284px;
				top: -43px;
				left: -43px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 284px;
				height: 284px;
				top: -43px;
				left: -43px;
				border-radius: 50%;
			}
		}


	/******************* Animate About to Testimonials **********************/
		
		body.ltr.about-to-testimonials .globalElement1{
			animation: atesElem1 2s forwards ease-in-out;
		}
		@keyframes atesElem1 {
			0% { 
				opacity: 0;
			}
			100% { 
				opacity: 0;
			}
		}


		body.ltr.about-to-testimonials .globalElement2{
			animation: atesElem2 3s forwards ease-in-out;
		}

		@keyframes atesElem2 {
			0% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				left: 1420px;
				top: 30px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1450px;
				top: 40px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1420px;
				top: 40px;
				transform: rotate(135deg);
				
			}
		}

		body.ltr.about-to-testimonials .globalElement3{
			animation: atesElem3 3s forwards ease-in-out;
			
		}

		@keyframes atesElem3 {
			0% { 
				opacity: 0;
			}
			100% { 
				opacity: 0;
			}
		}

		body.ltr.about-to-testimonials .globalElement4{
			animation: atesGlobElem4 1s forwards ease-in-out;
		}
		@keyframes atesGlobElem4 {
			0% { 
				opacity: 0;
			}
			
			100% { 
				opacity: 0;
			}
		}

		body.ltr.about-to-testimonials .globalElement5{
			animation: atesGlobElem5 1s forwards ease-in-out;
		}
		@keyframes atesGlobElem5 {
			0% { 
				opacity: 0;
			}
			
			100% { 
				opacity: 0;
			}
		}

		body.ltr.about-to-testimonials .elementLine4{
			animation: atesElem4 3s forwards ease-in-out;
		}
		@keyframes atesElem4 {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1420px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 445px;
				right: 1430px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1400px;
				border-radius: 50%;
			}
		}

		body.ltr.about-to-testimonials .elementLine4:before{
			animation: atesElem4Before 3s forwards ease-in-out;
		}
		@keyframes atesElem4Before {
			0% { 
				z-index: -1;
				width: 342px;
				height: 342px;
				top: -22px;
				left: -22px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 242px;
				height: 242px;
				top: -22px;
				left: -22px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 242px;
				height: 242px;
				top: -22px;
				left: -22px;
				border-radius: 50%;
			}
		}


		body.ltr.about-to-testimonials .elementLine4:after{
			animation: atesElem4After 3s forwards ease-in-out;
		}
		@keyframes atesElem4After {
			0% { 
				z-index: -1;
				width: 384px;
				height: 384px;
				top: -43px;
				left: -43px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: -1;
				width: 284px;
				height: 284px;
				top: -43px;
				left: -43px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 284px;
				height: 284px;
				top: -43px;
				left: -43px;
				border-radius: 50%;
			}
		}


		/******************* Animate About to Clients **********************/
		
		body.ltr.about-to-clients .globalElement1{
			animation: acElem1 2s forwards ease-in-out;
		}
		@keyframes acElem1 {
			0% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				left: 100px;
				top: -20px;
				transform: rotate(-70deg);
				opacity: 0;
			}
			100% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				left: 100px;
				top: -20px;
				transform: rotate(-70deg);
				opacity: 1;
			}
		}


		body.ltr.about-to-clients .globalElement2{
			animation: acElem2 3s forwards ease-in-out;
		}

		@keyframes acElem2 {
			0% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				left: 1420px;
				top: 30px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 250px;
				height: 250px;
				left: 625px;
				top: 525px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 250px;
				height: 250px;
				left: 625px;
				top: 495px;
				transform: rotate(135deg);
				
			}
		}

		body.ltr.about-to-clients .globalElement3{
			animation: acElem3 3s forwards ease-in-out;
			
		}

		@keyframes acElem3 {
			0% { 
				border-radius: 80px;
				width: 250px;
				height: 250px;
				left: 95%;
				bottom: -100px;
				transform: rotate(70deg);
				background: linear-gradient(120deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0) 100%);
				opacity: 0;
			}
			100% { 
				border-radius: 80px;
				width: 250px;
				height: 250px;
				left: 95%;
				bottom: -100px;
				transform: rotate(70deg);
				background: linear-gradient(120deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0) 100%);
				opacity: 1;
			}
		}

		body.ltr.about-to-clients .globalElement4{
			animation: acGlobElem4 1s forwards ease-in-out;
		}
		@keyframes acGlobElem4 {
			0% { 
				border-radius: 21px;
				width: 48px;
				height: 48px;
				left: 630px;
				top: 430px;
				transform: rotate(-60deg);
				z-index: -1;
				opacity: 0;
			}
			
			100% { 
				border-radius: 21px;
				width: 48px;
				height: 48px;
				left: 630px;
				top: 430px;
				transform: rotate(-60deg);
				z-index: -1;
				opacity: 1;
			}
		}

		body.ltr.about-to-clients .globalElement5{
			animation: acGlobElem5 1s forwards ease-in-out;
		}
		@keyframes acGlobElem5 {
			0% { 
				border-radius: 18px;
				width: 40px;
				height: 40px;
				right: 80px;
				top: 80px;
				transform: rotate(60deg);
				opacity: 0;
			}
			
			100% { 
				border-radius: 18px;
				width: 40px;
				height: 40px;
				right: 80px;
				top: 80px;
				transform: rotate(60deg);
				opacity: 1;
			}
		}

		body.ltr.about-to-clients .elementLine4{
			animation: acElem4 3s forwards ease-in-out;
		}
		@keyframes acElem4 {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1420px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 500px;
				right: 610px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 470px;
				right: 600px;
				border-radius: 50%;
			}
		}

		body.ltr.about-to-clients .elementLine4:before{
			animation: acElem4Before 3s forwards ease-in-out;
		}
		@keyframes acElem4Before {
			0% { 
				z-index: -1;
				width: 342px;
				height: 342px;
				top: -22px;
				left: -22px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 342px;
				height: 342px;
				top: -22px;
				left: -22px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 342px;
				height: 342px;
				top: -22px;
				left: -22px;
				border-radius: 50%;
			}
		}


		body.ltr.about-to-clients .elementLine4:after{
			animation: acElem4After 3s forwards ease-in-out;
		}
		@keyframes acElem4After {
			0% { 
				z-index: -1;
				width: 384px;
				height: 384px;
				top: -43px;
				left: -43px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: -1;
				width: 384px;
				height: 384px;
				top: -43px;
				left: -43px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 384px;
				height: 384px;
				top: -43px;
				left: -43px;
				border-radius: 50%;
			}
		}


	/******************* Animate About to Contact **********************/
		
		body.ltr.about-to-contact .globalElement1{
			animation: aCtElem1 2s forwards ease-in-out;
		}
		@keyframes aCtElem1 {
			0% { 
				opacity: 0;
			}
			100% { 
				opacity: 0;
			}
		}


		body.ltr.about-to-contact .globalElement2{
			animation: aCtElem2 3s forwards ease-in-out;
		}

		@keyframes aCtElem2 {
			0% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				left: 1420px;
				top: 30px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1450px;
				top: -20px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1420px;
				top: 0px;
				transform: rotate(135deg);
				
			}
		}

		body.ltr.about-to-contact .globalElement3{
			animation: aCtElem3 3s forwards ease-in-out;
			
		}

		@keyframes aCtElem3 {
			0% { 
				opacity: 0;
			}
			100% { 
				opacity: 0;
			}
		}

		body.ltr.about-to-contact .globalElement4{
			animation: aCtGlobElem4 1s forwards ease-in-out;
		}
		@keyframes aCtGlobElem4 {
			0% { 
				opacity: 0;
			}
			
			100% { 
				opacity: 0;
			}
		}

		body.ltr.about-to-contact .globalElement5{
			animation: aCtGlobElem5 1s forwards ease-in-out;
		}
		@keyframes aCtGlobElem5 {
			0% { 
				opacity: 0;
			}
			
			100% { 
				opacity: 0;
			}
		}

		body.ltr.about-to-contact .elementLine4{
			animation: aCtElem4 3s forwards ease-in-out;
		}
		@keyframes aCtElem4 {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1420px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1480px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1450px;
				border-radius: 50%;
			}
		}

		body.ltr.about-to-contact .elementLine4:before{
			animation: aCtElem4Before 3s forwards ease-in-out;
		}
		@keyframes aCtElem4Before {
			0% { 
				z-index: -1;
				width: 342px;
				height: 342px;
				top: -22px;
				left: -22px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 242px;
				height: 242px;
				top: -22px;
				left: -22px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 242px;
				height: 242px;
				top: -22px;
				left: -22px;
				border-radius: 50%;
			}
		}


		body.ltr.about-to-contact .elementLine4:after{
			animation: aCtElem4After 3s forwards ease-in-out;
		}
		@keyframes aCtElem4After {
			0% { 
				z-index: -1;
				width: 384px;
				height: 384px;
				top: -43px;
				left: -43px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: -1;
				width: 284px;
				height: 284px;
				top: -43px;
				left: -43px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 284px;
				height: 284px;
				top: -43px;
				left: -43px;
				border-radius: 50%;
			}
		}


/******************* Animate Services to All **********************/

	/******************* Animate Services to About **********************/
		


	body.ltr.services-to-about .about-img{
		animation: seraboutImg 2.5s forwards ease-in-out;
	}
	@keyframes seraboutImg {
		0% { 
			bottom: -600px;
			left: 100px;
			opacity: 0;
		}
		70% { 
			bottom: 10px;
			left: -10px;
			opacity: 1;
		}
		100% { 
			bottom: 0;
			left: 0;
			opacity: 1;
		}
	}

	body.ltr.services-to-about .globalElement1{
		animation: saElem1 2s forwards ease-in-out;
	}
	@keyframes saElem1 {
		0% { 
			left: 290px;
			top: 295px;
			width: 160px;
			height: 160px;
			border-radius: 47px;
			transform: rotate(120deg);
			/* Add the blur effect */
			backdrop-filter: blur(10px);
			-webkit-backdrop-filter: blur(10px);
			z-index: 5;
			opacity: 0;
		}
		100% { 
			left: 290px;
			top: 295px;
			width: 160px;
			height: 160px;
			border-radius: 47px;
			transform: rotate(120deg);
			/* Add the blur effect */
			backdrop-filter: blur(10px);
			-webkit-backdrop-filter: blur(10px);
			z-index: 5;
			opacity: 1;
		}
	}


	body.ltr.services-to-about .globalElement2{
		animation: saElem2 3s forwards ease-in-out;
	}

	@keyframes saElem2 {
		0% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1420px;
			top: 60px;
			transform: rotate(135deg);
		}
		70% { 
			border-radius: 55px;
			width: 150px;
			height: 150px;
			left: 1450px;
			top: 30px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 55px;
			width: 150px;
			height: 150px;
			left: 1420px;
			top: 30px;
			transform: rotate(135deg);
			
		}
	}

	body.ltr.services-to-about .globalElement3{
		animation: saElem3 3s forwards ease-in-out;
		
	}

	@keyframes saElem3 {
		0% { 
			border-radius: 80px;
			width: 284px;
			height: 284px;
			left: 64px;
			bottom: 67px;
			transform: rotate(66deg);
			background: var(--bgGradient);
			opacity: 0;
		}
		100% { 
			border-radius: 80px;
			width: 284px;
			height: 284px;
			left: 64px;
			bottom: 67px;
			transform: rotate(66deg);
			background: var(--bgGradient);
			opacity: 1;
		}
	}

	body.ltr.services-to-about .globalElement4{
		animation: saGlobElem4 1s forwards ease-in-out;
	}
	@keyframes saGlobElem4 {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.ltr.services-to-about .globalElement5{
		animation: saGlobElem5 1s forwards ease-in-out;
	}
	@keyframes saGlobElem5 {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.ltr.services-to-about .elementLine4{
		animation: saElem4 3s forwards ease-in-out;
	}
	@keyframes saElem4 {
		0% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 36px;
			right: -95px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 120px;
			right: 1460px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 120px;
			right: 1420px;
			border-radius: 50%;
		}
	}

	body.ltr.services-to-about .elementLine4:before{
		animation: saElem4Before 3s forwards ease-in-out;
	}
	@keyframes saElem4Before {
		0% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 342px;
			height: 342px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 342px;
			height: 342px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
	}


	body.ltr.services-to-about .elementLine4:after{
		animation: saElem4After 3s forwards ease-in-out;
	}
	@keyframes saElem4After {
		0% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
			
		}
		70% { 
			z-index: -1;
			width: 384px;
			height: 384px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 384px;
			height: 384px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
		}
	}
	


	/******************* Animate Services to Portfolio **********************/

	body.ltr.services-to-portfolio .globalElement1{
		animation: spElem1 2s forwards ease-in-out;
	}
	@keyframes spElem1 {
		0% { 
			opacity: 0;
		}
		100% { 
			opacity: 0;
		}
	}


	body.ltr.services-to-portfolio .globalElement2{
		animation: spElem2 3s forwards ease-in-out;
	}

	@keyframes spElem2 {
		0% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1420px;
			top: 60px;
			transform: rotate(135deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1450px;
			top: -20px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1420px;
			top: 0px;
			transform: rotate(135deg);
			
		}
	}

	body.ltr.services-to-portfolio .globalElement3{
		animation: spElem3 3s forwards ease-in-out;
		
	}

	@keyframes spElem3 {
		0% { 
			opacity: 0;
		}
		100% { 
			opacity: 0;
		}
	}

	body.ltr.services-to-portfolio .globalElement4{
		animation: spGlobElem4 1s forwards ease-in-out;
	}
	@keyframes spGlobElem4 {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.ltr.services-to-portfolio .globalElement5{
		animation: spGlobElem5 1s forwards ease-in-out;
	}
	@keyframes spGlobElem5 {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.ltr.services-to-portfolio .elementLine4{
		animation: spElem4 3s forwards ease-in-out;
	}
	@keyframes spElem4 {
		0% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 36px;
			right: -95px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: -10px;
			right: 1480px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 10px;
			right: 1450px;
			border-radius: 50%;
		}
	}

	body.ltr.services-to-portfolio .elementLine4:before{
		animation: spElem4Before 3s forwards ease-in-out;
	}
	@keyframes spElem4Before {
		0% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
	}


	body.ltr.services-to-portfolio .elementLine4:after{
		animation: spElem4After 3s forwards ease-in-out;
	}
	@keyframes spElem4After {
		0% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
			
		}
		70% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
		}
	}


	/******************* Animate Services to Team **********************/

	body.ltr.services-to-team .globalElement1{
		animation: stElem1 2s forwards ease-in-out;
	}
	@keyframes stElem1 {
		0% { 
			opacity: 0;
		}
		100% { 
			opacity: 0;
		}
	}


	body.ltr.services-to-team .globalElement2{
		animation: stElem2 3s forwards ease-in-out;
	}

	@keyframes stElem2 {
		0% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1420px;
			top: 60px;
			transform: rotate(135deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1450px;
			top: 40px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1420px;
			top: 40px;
			transform: rotate(135deg);
			
		}
	}

	body.ltr.services-to-team .globalElement3{
		animation: stElem3 3s forwards ease-in-out;
		
	}

	@keyframes stElem3 {
		0% { 
			opacity: 0;
		}
		100% { 
			opacity: 0;
		}
	}

	body.ltr.services-to-team .globalElement4{
		animation: stGlobElem4 1s forwards ease-in-out;
	}
	@keyframes stGlobElem4 {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.ltr.services-to-team .globalElement5{
		animation: stGlobElem5 1s forwards ease-in-out;
	}
	@keyframes stGlobElem5 {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.ltr.services-to-team .elementLine4{
		animation: stElem4 3s forwards ease-in-out;
	}
	@keyframes stElem4 {
		0% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 36px;
			right: -95px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 445px;
			right: 1430px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 430px;
			right: 1400px;
			border-radius: 50%;
		}
	}

	body.ltr.services-to-team .elementLine4:before{
		animation: stElem4Before 3s forwards ease-in-out;
	}
	@keyframes stElem4Before {
		0% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
	}


	body.ltr.services-to-team .elementLine4:after{
		animation: stElem4After 3s forwards ease-in-out;
	}
	@keyframes stElem4After {
		0% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
			
		}
		70% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
		}
	}


	/******************* Animate Services to Testimonials **********************/

	body.ltr.services-to-testimonials .globalElement1{
		animation: stesElem1 2s forwards ease-in-out;
	}
	@keyframes stesElem1 {
		0% { 
			opacity: 0;
		}
		100% { 
			opacity: 0;
		}
	}


	body.ltr.services-to-testimonials .globalElement2{
		animation: stesElem2 3s forwards ease-in-out;
	}

	@keyframes stesElem2 {
		0% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1420px;
			top: 60px;
			transform: rotate(135deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1450px;
			top: 40px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1420px;
			top: 40px;
			transform: rotate(135deg);
			
		}
	}

	body.ltr.services-to-testimonials .globalElement3{
		animation: stesElem3 3s forwards ease-in-out;
		
	}

	@keyframes stesElem3 {
		0% { 
			opacity: 0;
		}
		100% { 
			opacity: 0;
		}
	}

	body.ltr.services-to-testimonials .globalElement4{
		animation: stesGlobElem4 1s forwards ease-in-out;
	}
	@keyframes stesGlobElem4 {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.ltr.services-to-testimonials .globalElement5{
		animation: stesGlobElem5 1s forwards ease-in-out;
	}
	@keyframes stesGlobElem5 {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.ltr.services-to-testimonials .elementLine4{
		animation: stesElem4 3s forwards ease-in-out;
	}
	@keyframes stesElem4 {
		0% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 36px;
			right: -95px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 445px;
			right: 1430px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 430px;
			right: 1400px;
			border-radius: 50%;
		}
	}

	body.ltr.services-to-testimonials .elementLine4:before{
		animation: stesElem4Before 3s forwards ease-in-out;
	}
	@keyframes stesElem4Before {
		0% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
	}


	body.ltr.services-to-testimonials .elementLine4:after{
		animation: stesElem4After 3s forwards ease-in-out;
	}
	@keyframes stesElem4After {
		0% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
			
		}
		70% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
		}
	}


	/******************* Animate Services to Clients **********************/

	body.ltr.services-to-clients .globalElement1{
		animation: scElem1 2s forwards ease-in-out;
	}
	@keyframes scElem1 {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 100px;
			top: -20px;
			transform: rotate(-70deg);
			opacity: 0;
		}
		100% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 100px;
			top: -20px;
			transform: rotate(-70deg);
			opacity: 1;
		}
	}


	body.ltr.services-to-clients .globalElement2{
		animation: scElem2 3s forwards ease-in-out;
	}

	@keyframes scElem2 {
		0% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1420px;
			top: 60px;
			transform: rotate(135deg);
		}
		70% { 
			border-radius: 50%;
			width: 250px;
			height: 250px;
			left: 625px;
			top: 525px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 250px;
			height: 250px;
			left: 625px;
			top: 495px;
			transform: rotate(135deg);
			
		}
	}

	body.ltr.services-to-clients .globalElement3{
		animation: scElem3 3s forwards ease-in-out;
		
	}

	@keyframes scElem3 {
		0% { 
			border-radius: 80px;
			width: 250px;
			height: 250px;
			left: 95%;
			bottom: -100px;
			transform: rotate(70deg);
			background: linear-gradient(120deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0) 100%);
			opacity: 0;
		}
		100% { 
			border-radius: 80px;
			width: 250px;
			height: 250px;
			left: 95%;
			bottom: -100px;
			transform: rotate(70deg);
			background: linear-gradient(120deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0) 100%);
			opacity: 1;
		}
	}

	body.ltr.services-to-clients .globalElement4{
		animation: scGlobElem4 1s forwards ease-in-out;
	}
	@keyframes scGlobElem4 {
		0% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 630px;
			top: 430px;
			transform: rotate(-60deg);
			z-index: -1;
			opacity: 0;
		}
		
		100% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 630px;
			top: 430px;
			transform: rotate(-60deg);
			z-index: -1;
			opacity: 1;
		}
	}

	body.ltr.services-to-clients .globalElement5{
		animation: scGlobElem5 1s forwards ease-in-out;
	}
	@keyframes scGlobElem5 {
		0% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			right: 80px;
			top: 80px;
			transform: rotate(60deg);
			opacity: 0;
		}
		
		100% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			right: 80px;
			top: 80px;
			transform: rotate(60deg);
			opacity: 1;
		}
	}

	body.ltr.services-to-clients .elementLine4{
		animation: scElem4 3s forwards ease-in-out;
	}
	@keyframes scElem4 {
		0% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 36px;
			right: -95px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 500px;
			right: 610px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 470px;
			right: 600px;
			border-radius: 50%;
		}
	}

	body.ltr.services-to-clients .elementLine4:before{
		animation: scElem4Before 3s forwards ease-in-out;
	}
	@keyframes scElem4Before {
		0% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 342px;
			height: 342px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 342px;
			height: 342px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
	}


	body.ltr.services-to-clients .elementLine4:after{
		animation: scElem4After 3s forwards ease-in-out;
	}
	@keyframes scElem4After {
		0% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
			
		}
		70% { 
			z-index: -1;
			width: 384px;
			height: 384px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 384px;
			height: 384px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
		}
	}



	/******************* Animate Services to Contact **********************/

		body.ltr.services-to-contact .globalElement1{
			animation: sCtElem1 2s forwards ease-in-out;
		}
		@keyframes sCtElem1 {
			0% { 
				opacity: 0;
			}
			100% { 
				opacity: 0;
			}
		}


		body.ltr.services-to-contact .globalElement2{
			animation: sCtElem2 3s forwards ease-in-out;
		}

		@keyframes sCtElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1420px;
				top: 60px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1450px;
				top: -20px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1420px;
				top: 0px;
				transform: rotate(135deg);
				
			}
		}

		body.ltr.services-to-contact .globalElement3{
			animation: sCtElem3 3s forwards ease-in-out;
			
		}

		@keyframes sCtElem3 {
			0% { 
				opacity: 0;
			}
			100% { 
				opacity: 0;
			}
		}

		body.ltr.services-to-contact .globalElement4{
			animation: sCtGlobElem4 1s forwards ease-in-out;
		}
		@keyframes sCtGlobElem4 {
			0% { 
				opacity: 0;
			}
			
			100% { 
				opacity: 0;
			}
		}

		body.ltr.services-to-contact .globalElement5{
			animation: sCtGlobElem5 1s forwards ease-in-out;
		}
		@keyframes sCtGlobElem5 {
			0% { 
				opacity: 0;
			}
			
			100% { 
				opacity: 0;
			}
		}

		body.ltr.services-to-contact .elementLine4{
			animation: sCtElem4 3s forwards ease-in-out;
		}
		@keyframes sCtElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				right: -95px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1480px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1450px;
				border-radius: 50%;
			}
		}

		body.ltr.services-to-contact .elementLine4:before{
			animation: sCtElem4Before 3s forwards ease-in-out;
		}
		@keyframes sCtElem4Before {
			0% { 
				z-index: -1;
				width: 242px;
				height: 242px;
				top: -22px;
				left: -22px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 242px;
				height: 242px;
				top: -22px;
				left: -22px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 242px;
				height: 242px;
				top: -22px;
				left: -22px;
				border-radius: 50%;
			}
		}


		body.ltr.services-to-contact .elementLine4:after{
			animation: sCtElem4After 3s forwards ease-in-out;
		}
		@keyframes sCtElem4After {
			0% { 
				z-index: -1;
				width: 284px;
				height: 284px;
				top: -43px;
				left: -43px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 284px;
				height: 284px;
				top: -43px;
				left: -43px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 284px;
				height: 284px;
				top: -43px;
				left: -43px;
				border-radius: 50%;
			}
		}




/******************* Animate Portfolio to All **********************/

	/******************* Animate Portfolio to About **********************/
		


	body.ltr.portfolio-to-about .about-img{
		animation: poraboutImg 2.5s forwards ease-in-out;
	}
	@keyframes poraboutImg {
		0% { 
			bottom: -600px;
			left: 100px;
			opacity: 0;
		}
		70% { 
			bottom: 10px;
			left: -10px;
			opacity: 1;
		}
		100% { 
			bottom: 0;
			left: 0;
			opacity: 1;
		}
	}

	body.ltr.portfolio-to-about .globalElement1{
		animation: paElem1 2s forwards ease-in-out;
	}
	@keyframes paElem1 {
		0% { 
			left: 290px;
			top: 295px;
			width: 160px;
			height: 160px;
			border-radius: 47px;
			transform: rotate(120deg);
			/* Add the blur effect */
			backdrop-filter: blur(10px);
			-webkit-backdrop-filter: blur(10px);
			z-index: 5;
			opacity: 0;
		}
		100% { 
			left: 290px;
			top: 295px;
			width: 160px;
			height: 160px;
			border-radius: 47px;
			transform: rotate(120deg);
			/* Add the blur effect */
			backdrop-filter: blur(10px);
			-webkit-backdrop-filter: blur(10px);
			z-index: 5;
			opacity: 1;
		}
	}


	body.ltr.portfolio-to-about .globalElement2{
		animation: paElem2 3s forwards ease-in-out;
	}

	@keyframes paElem2 {
		0% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1420px;
			top: 0px;
			transform: rotate(135deg);
		}
		70% { 
			border-radius: 55px;
			width: 150px;
			height: 150px;
			left: 1450px;
			top: 30px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 55px;
			width: 150px;
			height: 150px;
			left: 1420px;
			top: 30px;
			transform: rotate(135deg);
			
		}
	}

	body.ltr.portfolio-to-about .globalElement3{
		animation: paElem3 3s forwards ease-in-out;
		
	}

	@keyframes paElem3 {
		0% { 
			border-radius: 80px;
			width: 284px;
			height: 284px;
			left: 64px;
			bottom: 67px;
			transform: rotate(66deg);
			background: var(--bgGradient);
			opacity: 0;
		}
		100% { 
			border-radius: 80px;
			width: 284px;
			height: 284px;
			left: 64px;
			bottom: 67px;
			transform: rotate(66deg);
			background: var(--bgGradient);
			opacity: 1;
		}
	}

	body.ltr.portfolio-to-about .globalElement4{
		animation: paGlobElem4 1s forwards ease-in-out;
	}
	@keyframes paGlobElem4 {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.ltr.portfolio-to-about .globalElement5{
		animation: paGlobElem5 1s forwards ease-in-out;
	}
	@keyframes paGlobElem5 {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.ltr.portfolio-to-about .elementLine4{
		animation: paElem4 3s forwards ease-in-out;
	}
	@keyframes paElem4 {
		0% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 10px;
			right: 1450px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 120px;
			right: 1460px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 120px;
			right: 1420px;
			border-radius: 50%;
		}
	}

	body.ltr.portfolio-to-about .elementLine4:before{
		animation: paElem4Before 3s forwards ease-in-out;
	}
	@keyframes paElem4Before {
		0% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 342px;
			height: 342px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 342px;
			height: 342px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
	}


	body.ltr.portfolio-to-about .elementLine4:after{
		animation: paElem4After 3s forwards ease-in-out;
	}
	@keyframes paElem4After {
		0% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
			
		}
		70% { 
			z-index: -1;
			width: 384px;
			height: 384px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 384px;
			height: 384px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
		}
	}
	


	/******************* Animate Portfolio to Services **********************/

	body.ltr.portfolio-to-services .globalElement1{
		animation: psElem1 2s forwards ease-in-out;
	}
	@keyframes psElem1 {
		0% { 
			opacity: 0;
		}
		100% { 
			opacity: 0;
		}
	}


	body.ltr.portfolio-to-services .globalElement2{
		animation: psElem2 3s forwards ease-in-out;
	}

	@keyframes psElem2 {
		0% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1420px;
			top: 0px;
			transform: rotate(135deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1450px;
			top: 60px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1420px;
			top: 60px;
			transform: rotate(135deg);
			
		}
	}

	body.ltr.portfolio-to-services .globalElement3{
		animation: psElem3 3s forwards ease-in-out;
		
	}

	@keyframes psElem3 {
		0% { 
			opacity: 0;
		}
		100% { 
			opacity: 0;
		}
	}

	body.ltr.portfolio-to-services .globalElement4{
		animation: psGlobElem4 1s forwards ease-in-out;
	}
	@keyframes psGlobElem4 {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.ltr.portfolio-to-services .globalElement5{
		animation: psGlobElem5 1s forwards ease-in-out;
	}
	@keyframes psGlobElem5 {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.ltr.portfolio-to-services .elementLine4{
		animation: psElem4 3s forwards ease-in-out;
	}
	@keyframes psElem4 {
		0% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 10px;
			right: 1450px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 36px;
			right: -125px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 36px;
			right: -95px;
			border-radius: 50%;
		}
	}

	body.ltr.portfolio-to-services .elementLine4:before{
		animation: psElem4Before 3s forwards ease-in-out;
	}
	@keyframes psElem4Before {
		0% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
	}


	body.ltr.portfolio-to-services .elementLine4:after{
		animation: psElem4After 3s forwards ease-in-out;
	}
	@keyframes psElem4After {
		0% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
			
		}
		70% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
		}
	}


	/******************* Animate Portfolio to Team **********************/

	body.ltr.portfolio-to-team .globalElement1{
		animation: ptElem1 2s forwards ease-in-out;
	}
	@keyframes ptElem1 {
		0% { 
			opacity: 0;
		}
		100% { 
			opacity: 0;
		}
	}


	body.ltr.portfolio-to-team .globalElement2{
		animation: ptElem2 3s forwards ease-in-out;
	}

	@keyframes ptElem2 {
		0% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1420px;
			top: 0px;
			transform: rotate(135deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1450px;
			top: 40px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1420px;
			top: 40px;
			transform: rotate(135deg);
			
		}
	}

	body.ltr.portfolio-to-team .globalElement3{
		animation: ptElem3 3s forwards ease-in-out;
		
	}

	@keyframes ptElem3 {
		0% { 
			opacity: 0;
		}
		100% { 
			opacity: 0;
		}
	}

	body.ltr.portfolio-to-team .globalElement4{
		animation: ptGlobElem4 1s forwards ease-in-out;
	}
	@keyframes ptGlobElem4 {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.ltr.portfolio-to-team .globalElement5{
		animation: ptGlobElem5 1s forwards ease-in-out;
	}
	@keyframes ptGlobElem5 {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.ltr.portfolio-to-team .elementLine4{
		animation: ptElem4 3s forwards ease-in-out;
	}
	@keyframes ptElem4 {
		0% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 10px;
			right: 1450px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 445px;
			right: 1430px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 430px;
			right: 1400px;
			border-radius: 50%;
		}
	}

	body.ltr.portfolio-to-team .elementLine4:before{
		animation: ptElem4Before 3s forwards ease-in-out;
	}
	@keyframes ptElem4Before {
		0% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
	}


	body.ltr.portfolio-to-team .elementLine4:after{
		animation: ptElem4After 3s forwards ease-in-out;
	}
	@keyframes ptElem4After {
		0% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
			
		}
		70% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
		}
	}


	/******************* Animate Portfolio to Testimonials **********************/

	body.ltr.portfolio-to-testimonials .globalElement1{
		animation: ptesElem1 2s forwards ease-in-out;
	}
	@keyframes ptesElem1 {
		0% { 
			opacity: 0;
		}
		100% { 
			opacity: 0;
		}
	}


	body.ltr.portfolio-to-testimonials .globalElement2{
		animation: ptesElem2 3s forwards ease-in-out;
	}

	@keyframes ptesElem2 {
		0% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1420px;
			top: 0px;
			transform: rotate(135deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1450px;
			top: 40px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1420px;
			top: 40px;
			transform: rotate(135deg);
			
		}
	}

	body.ltr.portfolio-to-testimonials .globalElement3{
		animation: ptesElem3 3s forwards ease-in-out;
		
	}

	@keyframes ptesElem3 {
		0% { 
			opacity: 0;
		}
		100% { 
			opacity: 0;
		}
	}

	body.ltr.portfolio-to-testimonials .globalElement4{
		animation: ptesGlobElem4 1s forwards ease-in-out;
	}
	@keyframes ptesGlobElem4 {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.ltr.portfolio-to-testimonials .globalElement5{
		animation: ptesGlobElem5 1s forwards ease-in-out;
	}
	@keyframes ptesGlobElem5 {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.ltr.portfolio-to-testimonials .elementLine4{
		animation: ptesElem4 3s forwards ease-in-out;
	}
	@keyframes ptesElem4 {
		0% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 10px;
			right: 1450px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 445px;
			right: 1430px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 430px;
			right: 1400px;
			border-radius: 50%;
		}
	}

	body.ltr.portfolio-to-testimonials .elementLine4:before{
		animation: ptesElem4Before 3s forwards ease-in-out;
	}
	@keyframes ptesElem4Before {
		0% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
	}


	body.ltr.portfolio-to-testimonials .elementLine4:after{
		animation: ptesElem4After 3s forwards ease-in-out;
	}
	@keyframes ptesElem4After {
		0% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
			
		}
		70% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
		}
	}


	/******************* Animate Portfolio to Clients **********************/

	body.ltr.portfolio-to-clients .globalElement1{
		animation: pcElem1 2s forwards ease-in-out;
	}
	@keyframes pcElem1 {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 100px;
			top: -20px;
			transform: rotate(-70deg);
			opacity: 0;
		}
		100% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 100px;
			top: -20px;
			transform: rotate(-70deg);
			opacity: 1;
		}
	}


	body.ltr.portfolio-to-clients .globalElement2{
		animation: pcElem2 3s forwards ease-in-out;
	}

	@keyframes pcElem2 {
		0% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1420px;
			top: 0px;
			transform: rotate(135deg);
		}
		70% { 
			border-radius: 50%;
			width: 250px;
			height: 250px;
			left: 625px;
			top: 525px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 250px;
			height: 250px;
			left: 625px;
			top: 495px;
			transform: rotate(135deg);
			
		}
	}

	body.ltr.portfolio-to-clients .globalElement3{
		animation: pcElem3 3s forwards ease-in-out;
		
	}

	@keyframes pcElem3 {
		0% { 
			border-radius: 80px;
			width: 250px;
			height: 250px;
			left: 95%;
			bottom: -100px;
			transform: rotate(70deg);
			background: linear-gradient(120deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0) 100%);
			opacity: 0;
		}
		100% { 
			border-radius: 80px;
			width: 250px;
			height: 250px;
			left: 95%;
			bottom: -100px;
			transform: rotate(70deg);
			background: linear-gradient(120deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0) 100%);
			opacity: 1;
		}
	}

	body.ltr.portfolio-to-clients .globalElement4{
		animation: pcGlobElem4 1s forwards ease-in-out;
	}
	@keyframes pcGlobElem4 {
		0% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 630px;
			top: 430px;
			transform: rotate(-60deg);
			z-index: -1;
			opacity: 0;
		}
		
		100% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 630px;
			top: 430px;
			transform: rotate(-60deg);
			z-index: -1;
			opacity: 1;
		}
	}

	body.ltr.portfolio-to-clients .globalElement5{
		animation: pcGlobElem5 1s forwards ease-in-out;
	}
	@keyframes pcGlobElem5 {
		0% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			right: 80px;
			top: 80px;
			transform: rotate(60deg);
			opacity: 0;
		}
		
		100% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			right: 80px;
			top: 80px;
			transform: rotate(60deg);
			opacity: 1;
		}
	}

	body.ltr.portfolio-to-clients .elementLine4{
		animation: pcElem4 3s forwards ease-in-out;
	}
	@keyframes pcElem4 {
		0% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 10px;
			right: 1450px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 500px;
			right: 610px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 470px;
			right: 600px;
			border-radius: 50%;
		}
	}

	body.ltr.portfolio-to-clients .elementLine4:before{
		animation: pcElem4Before 3s forwards ease-in-out;
	}
	@keyframes pcElem4Before {
		0% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 342px;
			height: 342px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 342px;
			height: 342px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
	}


	body.ltr.portfolio-to-clients .elementLine4:after{
		animation: pcElem4After 3s forwards ease-in-out;
	}
	@keyframes pcElem4After {
		0% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
			
		}
		70% { 
			z-index: -1;
			width: 384px;
			height: 384px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 384px;
			height: 384px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
		}
	}



	/******************* Animate Portfolio to Contact **********************/

		body.ltr.portfolio-to-contact .globalElement1{
			animation: pCtElem1 2s forwards ease-in-out;
		}
		@keyframes pCtElem1 {
			0% { 
				opacity: 0;
			}
			100% { 
				opacity: 0;
			}
		}


		body.ltr.portfolio-to-contact .globalElement2{
			animation: pCtElem2 3s forwards ease-in-out;
		}

		@keyframes pCtElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1420px;
				top: 0px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1450px;
				top: -20px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1420px;
				top: 0px;
				transform: rotate(135deg);
				
			}
		}

		body.ltr.portfolio-to-contact .globalElement3{
			animation: pCtElem3 3s forwards ease-in-out;
			
		}

		@keyframes pCtElem3 {
			0% { 
				opacity: 0;
			}
			100% { 
				opacity: 0;
			}
		}

		body.ltr.portfolio-to-contact .globalElement4{
			animation: pCtGlobElem4 1s forwards ease-in-out;
		}
		@keyframes pCtGlobElem4 {
			0% { 
				opacity: 0;
			}
			
			100% { 
				opacity: 0;
			}
		}

		body.ltr.portfolio-to-contact .globalElement5{
			animation: pCtGlobElem5 1s forwards ease-in-out;
		}
		@keyframes pCtGlobElem5 {
			0% { 
				opacity: 0;
			}
			
			100% { 
				opacity: 0;
			}
		}

		body.ltr.portfolio-to-contact .elementLine4{
			animation: pCtElem4 3s forwards ease-in-out;
		}
		@keyframes pCtElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				right: 1450px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1480px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1450px;
				border-radius: 50%;
			}
		}

		body.ltr.portfolio-to-contact .elementLine4:before{
			animation: pCtElem4Before 3s forwards ease-in-out;
		}
		@keyframes pCtElem4Before {
			0% { 
				z-index: -1;
				width: 242px;
				height: 242px;
				top: -22px;
				left: -22px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 242px;
				height: 242px;
				top: -22px;
				left: -22px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 242px;
				height: 242px;
				top: -22px;
				left: -22px;
				border-radius: 50%;
			}
		}


		body.ltr.portfolio-to-contact .elementLine4:after{
			animation: pCtElem4After 3s forwards ease-in-out;
		}
		@keyframes pCtElem4After {
			0% { 
				z-index: -1;
				width: 284px;
				height: 284px;
				top: -43px;
				left: -43px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 284px;
				height: 284px;
				top: -43px;
				left: -43px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 284px;
				height: 284px;
				top: -43px;
				left: -43px;
				border-radius: 50%;
			}
		}




/******************* Animate Team to All **********************/

	/******************* Animate Team to About **********************/
		


	body.ltr.team-to-about .about-img{
		animation: teaaboutImg 2.5s forwards ease-in-out;
	}
	@keyframes teaaboutImg {
		0% { 
			bottom: -600px;
			left: 100px;
			opacity: 0;
		}
		70% { 
			bottom: 10px;
			left: -10px;
			opacity: 1;
		}
		100% { 
			bottom: 0;
			left: 0;
			opacity: 1;
		}
	}

	body.ltr.team-to-about .globalElement1{
		animation: taElem1 2s forwards ease-in-out;
	}
	@keyframes taElem1 {
		0% { 
			left: 290px;
			top: 295px;
			width: 160px;
			height: 160px;
			border-radius: 47px;
			transform: rotate(120deg);
			/* Add the blur effect */
			backdrop-filter: blur(10px);
			-webkit-backdrop-filter: blur(10px);
			z-index: 5;
			opacity: 0;
		}
		100% { 
			left: 290px;
			top: 295px;
			width: 160px;
			height: 160px;
			border-radius: 47px;
			transform: rotate(120deg);
			/* Add the blur effect */
			backdrop-filter: blur(10px);
			-webkit-backdrop-filter: blur(10px);
			z-index: 5;
			opacity: 1;
		}
	}


	body.ltr.team-to-about .globalElement2{
		animation: taElem2 3s forwards ease-in-out;
	}

	@keyframes taElem2 {
		0% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1420px;
			top: 40px;
			transform: rotate(135deg);
		}
		70% { 
			border-radius: 55px;
			width: 150px;
			height: 150px;
			left: 1450px;
			top: 30px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 55px;
			width: 150px;
			height: 150px;
			left: 1420px;
			top: 30px;
			transform: rotate(135deg);
			
		}
	}

	body.ltr.team-to-about .globalElement3{
		animation: taElem3 3s forwards ease-in-out;
		
	}

	@keyframes taElem3 {
		0% { 
			border-radius: 80px;
			width: 284px;
			height: 284px;
			left: 64px;
			bottom: 67px;
			transform: rotate(66deg);
			background: var(--bgGradient);
			opacity: 0;
		}
		100% { 
			border-radius: 80px;
			width: 284px;
			height: 284px;
			left: 64px;
			bottom: 67px;
			transform: rotate(66deg);
			background: var(--bgGradient);
			opacity: 1;
		}
	}

	body.ltr.team-to-about .globalElement4{
		animation: taGlobElem4 1s forwards ease-in-out;
	}
	@keyframes taGlobElem4 {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.ltr.team-to-about .globalElement5{
		animation: taGlobElem5 1s forwards ease-in-out;
	}
	@keyframes taGlobElem5 {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.ltr.team-to-about .elementLine4{
		animation: taElem4 3s forwards ease-in-out;
	}
	@keyframes taElem4 {
		0% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 430px;
			right: 1400px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 120px;
			right: 1460px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 120px;
			right: 1420px;
			border-radius: 50%;
		}
	}

	body.ltr.team-to-about .elementLine4:before{
		animation: taElem4Before 3s forwards ease-in-out;
	}
	@keyframes taElem4Before {
		0% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 342px;
			height: 342px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 342px;
			height: 342px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
	}


	body.ltr.team-to-about .elementLine4:after{
		animation: taElem4After 3s forwards ease-in-out;
	}
	@keyframes taElem4After {
		0% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
			
		}
		70% { 
			z-index: -1;
			width: 384px;
			height: 384px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 384px;
			height: 384px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
		}
	}
	


	/******************* Animate Team to Services **********************/

	body.ltr.team-to-services .globalElement1{
		animation: tsElem1 2s forwards ease-in-out;
	}
	@keyframes tsElem1 {
		0% { 
			opacity: 0;
		}
		100% { 
			opacity: 0;
		}
	}


	body.ltr.team-to-services .globalElement2{
		animation: tsElem2 3s forwards ease-in-out;
	}

	@keyframes tsElem2 {
		0% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1420px;
			top: 40px;
			transform: rotate(135deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1450px;
			top: 60px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1420px;
			top: 60px;
			transform: rotate(135deg);
			
		}
	}

	body.ltr.team-to-services .globalElement3{
		animation: tsElem3 3s forwards ease-in-out;
		
	}

	@keyframes tsElem3 {
		0% { 
			opacity: 0;
		}
		100% { 
			opacity: 0;
		}
	}

	body.ltr.team-to-services .globalElement4{
		animation: tsGlobElem4 1s forwards ease-in-out;
	}
	@keyframes tsGlobElem4 {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.ltr.team-to-services .globalElement5{
		animation: tsGlobElem5 1s forwards ease-in-out;
	}
	@keyframes tsGlobElem5 {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.ltr.team-to-services .elementLine4{
		animation: tsElem4 3s forwards ease-in-out;
	}
	@keyframes tsElem4 {
		0% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 430px;
			right: 1400px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 36px;
			right: -125px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 36px;
			right: -95px;
			border-radius: 50%;
		}
	}

	body.ltr.team-to-services .elementLine4:before{
		animation: tsElem4Before 3s forwards ease-in-out;
	}
	@keyframes tsElem4Before {
		0% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
	}


	body.ltr.team-to-services .elementLine4:after{
		animation: tsElem4After 3s forwards ease-in-out;
	}
	@keyframes tsElem4After {
		0% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
			
		}
		70% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
		}
	}


	/******************* Animate Team to Portfolio **********************/

	body.ltr.team-to-portfolio .globalElement1{
		animation: tpElem1 2s forwards ease-in-out;
	}
	@keyframes tpElem1 {
		0% { 
			opacity: 0;
		}
		100% { 
			opacity: 0;
		}
	}


	body.ltr.team-to-portfolio .globalElement2{
		animation: tpElem2 3s forwards ease-in-out;
	}

	@keyframes tpElem2 {
		0% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1420px;
			top: 40px;
			transform: rotate(135deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1450px;
			top: -20px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1420px;
			top: 0px;
			transform: rotate(135deg);
			
		}
	}

	body.ltr.team-to-portfolio .globalElement3{
		animation: tpElem3 3s forwards ease-in-out;
		
	}

	@keyframes tpElem3 {
		0% { 
			opacity: 0;
		}
		100% { 
			opacity: 0;
		}
	}

	body.ltr.team-to-portfolio .globalElement4{
		animation: tpGlobElem4 1s forwards ease-in-out;
	}
	@keyframes tpGlobElem4 {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.ltr.team-to-portfolio .globalElement5{
		animation: tpGlobElem5 1s forwards ease-in-out;
	}
	@keyframes tpGlobElem5 {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.ltr.team-to-portfolio .elementLine4{
		animation: tpElem4 3s forwards ease-in-out;
	}
	@keyframes tpElem4 {
		0% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 430px;
			right: 1400px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: -10px;
			right: 1480px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 10px;
			right: 1450px;
			border-radius: 50%;
		}
	}

	body.ltr.team-to-portfolio .elementLine4:before{
		animation: tpElem4Before 3s forwards ease-in-out;
	}
	@keyframes tpElem4Before {
		0% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
	}


	body.ltr.team-to-portfolio .elementLine4:after{
		animation: tpElem4After 3s forwards ease-in-out;
	}
	@keyframes tpElem4After {
		0% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
			
		}
		70% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
		}
	}


	/******************* Animate Team to Testimonials **********************/

	body.ltr.team-to-testimonials .globalElement1{
		animation: ttesElem1 2s forwards ease-in-out;
	}
	@keyframes ttesElem1 {
		0% { 
			opacity: 0;
		}
		100% { 
			opacity: 0;
		}
	}


	body.ltr.team-to-testimonials .globalElement2{
		animation: ttesElem2 3s forwards ease-in-out;
	}

	@keyframes ttesElem2 {
		0% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1420px;
			top: 40px;
			transform: rotate(135deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1420px;
			top: 40px;
			transform: rotate(135deg);
			
		}
	}

	body.ltr.team-to-testimonials .globalElement3{
		animation: ttesElem3 3s forwards ease-in-out;
		
	}

	@keyframes ttesElem3 {
		0% { 
			opacity: 0;
		}
		100% { 
			opacity: 0;
		}
	}

	body.ltr.team-to-testimonials .globalElement4{
		animation: ttesGlobElem4 1s forwards ease-in-out;
	}
	@keyframes ttesGlobElem4 {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.ltr.team-to-testimonials .globalElement5{
		animation: ttesGlobElem5 1s forwards ease-in-out;
	}
	@keyframes ttesGlobElem5 {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.ltr.team-to-testimonials .elementLine4{
		animation: ttesElem4 3s forwards ease-in-out;
	}
	@keyframes ttesElem4 {
		0% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 430px;
			right: 1400px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 430px;
			right: 1400px;
			border-radius: 50%;
		}
	}

	body.ltr.team-to-testimonials .elementLine4:before{
		animation: ttesElem4Before 3s forwards ease-in-out;
	}
	@keyframes ttesElem4Before {
		0% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
	}


	body.ltr.team-to-testimonials .elementLine4:after{
		animation: ttesElem4After 3s forwards ease-in-out;
	}
	@keyframes ttesElem4After {
		0% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
			
		}
		100% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
		}
	}


	/******************* Animate Team to Clients **********************/

	body.ltr.team-to-clients .globalElement1{
		animation: tcElem1 2s forwards ease-in-out;
	}
	@keyframes tcElem1 {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 100px;
			top: -20px;
			transform: rotate(-70deg);
			opacity: 0;
		}
		100% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 100px;
			top: -20px;
			transform: rotate(-70deg);
			opacity: 1;
		}
	}


	body.ltr.team-to-clients .globalElement2{
		animation: tcElem2 3s forwards ease-in-out;
	}

	@keyframes tcElem2 {
		0% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1420px;
			top: 40px;
			transform: rotate(135deg);
		}
		70% { 
			border-radius: 50%;
			width: 250px;
			height: 250px;
			left: 625px;
			top: 525px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 250px;
			height: 250px;
			left: 625px;
			top: 495px;
			transform: rotate(135deg);
			
		}
	}

	body.ltr.team-to-clients .globalElement3{
		animation: tcElem3 3s forwards ease-in-out;
		
	}

	@keyframes tcElem3 {
		0% { 
			border-radius: 80px;
			width: 250px;
			height: 250px;
			left: 95%;
			bottom: -100px;
			transform: rotate(70deg);
			background: linear-gradient(120deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0) 100%);
			opacity: 0;
		}
		100% { 
			border-radius: 80px;
			width: 250px;
			height: 250px;
			left: 95%;
			bottom: -100px;
			transform: rotate(70deg);
			background: linear-gradient(120deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0) 100%);
			opacity: 1;
		}
	}

	body.ltr.team-to-clients .globalElement4{
		animation: tcGlobElem4 1s forwards ease-in-out;
	}
	@keyframes tcGlobElem4 {
		0% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 630px;
			top: 430px;
			transform: rotate(-60deg);
			z-index: -1;
			opacity: 0;
		}
		
		100% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 630px;
			top: 430px;
			transform: rotate(-60deg);
			z-index: -1;
			opacity: 1;
		}
	}

	body.ltr.team-to-clients .globalElement5{
		animation: tcGlobElem5 1s forwards ease-in-out;
	}
	@keyframes tcGlobElem5 {
		0% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			right: 80px;
			top: 80px;
			transform: rotate(60deg);
			opacity: 0;
		}
		
		100% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			right: 80px;
			top: 80px;
			transform: rotate(60deg);
			opacity: 1;
		}
	}

	body.ltr.team-to-clients .elementLine4{
		animation: tcElem4 3s forwards ease-in-out;
	}
	@keyframes tcElem4 {
		0% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 430px;
			right: 1400px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 500px;
			right: 610px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 470px;
			right: 600px;
			border-radius: 50%;
		}
	}

	body.ltr.team-to-clients .elementLine4:before{
		animation: tcElem4Before 3s forwards ease-in-out;
	}
	@keyframes tcElem4Before {
		0% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 342px;
			height: 342px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 342px;
			height: 342px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
	}


	body.ltr.team-to-clients .elementLine4:after{
		animation: tcElem4After 3s forwards ease-in-out;
	}
	@keyframes tcElem4After {
		0% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
			
		}
		70% { 
			z-index: -1;
			width: 384px;
			height: 384px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 384px;
			height: 384px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
		}
	}



	/******************* Animate Team to Contact **********************/

		body.ltr.team-to-contact .globalElement1{
			animation: tCtElem1 2s forwards ease-in-out;
		}
		@keyframes tCtElem1 {
			0% { 
				opacity: 0;
			}
			100% { 
				opacity: 0;
			}
		}


		body.ltr.team-to-contact .globalElement2{
			animation: tCtElem2 3s forwards ease-in-out;
		}

		@keyframes tCtElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1420px;
				top: 40px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1450px;
				top: -20px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1420px;
				top: 0px;
				transform: rotate(135deg);
				
			}
		}

		body.ltr.team-to-contact .globalElement3{
			animation: tCtElem3 3s forwards ease-in-out;
			
		}

		@keyframes tCtElem3 {
			0% { 
				opacity: 0;
			}
			100% { 
				opacity: 0;
			}
		}

		body.ltr.team-to-contact .globalElement4{
			animation: tCtGlobElem4 1s forwards ease-in-out;
		}
		@keyframes tCtGlobElem4 {
			0% { 
				opacity: 0;
			}
			
			100% { 
				opacity: 0;
			}
		}

		body.ltr.team-to-contact .globalElement5{
			animation: tCtGlobElem5 1s forwards ease-in-out;
		}
		@keyframes tCtGlobElem5 {
			0% { 
				opacity: 0;
			}
			
			100% { 
				opacity: 0;
			}
		}

		body.ltr.team-to-contact .elementLine4{
			animation: tCtElem4 3s forwards ease-in-out;
		}
		@keyframes tCtElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1400px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1480px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1450px;
				border-radius: 50%;
			}
		}

		body.ltr.team-to-contact .elementLine4:before{
			animation: tCtElem4Before 3s forwards ease-in-out;
		}
		@keyframes tCtElem4Before {
			0% { 
				z-index: -1;
				width: 242px;
				height: 242px;
				top: -22px;
				left: -22px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 242px;
				height: 242px;
				top: -22px;
				left: -22px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 242px;
				height: 242px;
				top: -22px;
				left: -22px;
				border-radius: 50%;
			}
		}


		body.ltr.team-to-contact .elementLine4:after{
			animation: tCtElem4After 3s forwards ease-in-out;
		}
		@keyframes tCtElem4After {
			0% { 
				z-index: -1;
				width: 284px;
				height: 284px;
				top: -43px;
				left: -43px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 284px;
				height: 284px;
				top: -43px;
				left: -43px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 284px;
				height: 284px;
				top: -43px;
				left: -43px;
				border-radius: 50%;
			}
		}





/******************* Animate Testimonials to All **********************/

	/******************* Animate Testimonials to About **********************/
		


	body.ltr.testimonials-to-about .about-img{
		animation: tesaboutImg 2.5s forwards ease-in-out;
	}
	@keyframes tesaboutImg {
		0% { 
			bottom: -600px;
			left: 100px;
			opacity: 0;
		}
		70% { 
			bottom: 10px;
			left: -10px;
			opacity: 1;
		}
		100% { 
			bottom: 0;
			left: 0;
			opacity: 1;
		}
	}

	body.ltr.testimonials-to-about .globalElement1{
		animation: tesaElem1 2s forwards ease-in-out;
	}
	@keyframes tesaElem1 {
		0% { 
			left: 290px;
			top: 295px;
			width: 160px;
			height: 160px;
			border-radius: 47px;
			transform: rotate(120deg);
			/* Add the blur effect */
			backdrop-filter: blur(10px);
			-webkit-backdrop-filter: blur(10px);
			z-index: 5;
			opacity: 0;
		}
		100% { 
			left: 290px;
			top: 295px;
			width: 160px;
			height: 160px;
			border-radius: 47px;
			transform: rotate(120deg);
			/* Add the blur effect */
			backdrop-filter: blur(10px);
			-webkit-backdrop-filter: blur(10px);
			z-index: 5;
			opacity: 1;
		}
	}


	body.ltr.testimonials-to-about .globalElement2{
		animation: tesaElem2 3s forwards ease-in-out;
	}

	@keyframes tesaElem2 {
		0% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1420px;
			top: 40px;
			transform: rotate(135deg);
		}
		70% { 
			border-radius: 55px;
			width: 150px;
			height: 150px;
			left: 1450px;
			top: 30px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 55px;
			width: 150px;
			height: 150px;
			left: 1420px;
			top: 30px;
			transform: rotate(135deg);
			
		}
	}

	body.ltr.testimonials-to-about .globalElement3{
		animation: tesaElem3 3s forwards ease-in-out;
		
	}

	@keyframes tesaElem3 {
		0% { 
			border-radius: 80px;
			width: 284px;
			height: 284px;
			left: 64px;
			bottom: 67px;
			transform: rotate(66deg);
			background: var(--bgGradient);
			opacity: 0;
		}
		100% { 
			border-radius: 80px;
			width: 284px;
			height: 284px;
			left: 64px;
			bottom: 67px;
			transform: rotate(66deg);
			background: var(--bgGradient);
			opacity: 1;
		}
	}

	body.ltr.testimonials-to-about .globalElement4{
		animation: tesaGlobElem4 1s forwards ease-in-out;
	}
	@keyframes tesaGlobElem4 {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.ltr.testimonials-to-about .globalElement5{
		animation: tesaGlobElem5 1s forwards ease-in-out;
	}
	@keyframes tesaGlobElem5 {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.ltr.testimonials-to-about .elementLine4{
		animation: tesaElem4 3s forwards ease-in-out;
	}
	@keyframes tesaElem4 {
		0% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 430px;
			right: 1400px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 120px;
			right: 1460px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 120px;
			right: 1420px;
			border-radius: 50%;
		}
	}

	body.ltr.testimonials-to-about .elementLine4:before{
		animation: tesaElem4Before 3s forwards ease-in-out;
	}
	@keyframes tesaElem4Before {
		0% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 342px;
			height: 342px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 342px;
			height: 342px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
	}


	body.ltr.testimonials-to-about .elementLine4:after{
		animation: tesaElem4After 3s forwards ease-in-out;
	}
	@keyframes tesaElem4After {
		0% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
			
		}
		70% { 
			z-index: -1;
			width: 384px;
			height: 384px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 384px;
			height: 384px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
		}
	}
	


	/******************* Animate testimonials to Services **********************/

	body.ltr.testimonials-to-services .globalElement1{
		animation: tessElem1 2s forwards ease-in-out;
	}
	@keyframes tessElem1 {
		0% { 
			opacity: 0;
		}
		100% { 
			opacity: 0;
		}
	}


	body.ltr.testimonials-to-services .globalElement2{
		animation: tessElem2 3s forwards ease-in-out;
	}

	@keyframes tessElem2 {
		0% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1420px;
			top: 40px;
			transform: rotate(135deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1450px;
			top: 60px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1420px;
			top: 60px;
			transform: rotate(135deg);
			
		}
	}

	body.ltr.testimonials-to-services .globalElement3{
		animation: tessElem3 3s forwards ease-in-out;
		
	}

	@keyframes tessElem3 {
		0% { 
			opacity: 0;
		}
		100% { 
			opacity: 0;
		}
	}

	body.ltr.testimonials-to-services .globalElement4{
		animation: tessGlobElem4 1s forwards ease-in-out;
	}
	@keyframes tessGlobElem4 {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.ltr.testimonials-to-services .globalElement5{
		animation: tessGlobElem5 1s forwards ease-in-out;
	}
	@keyframes tessGlobElem5 {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.ltr.testimonials-to-services .elementLine4{
		animation: tessElem4 3s forwards ease-in-out;
	}
	@keyframes tessElem4 {
		0% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 430px;
			right: 1400px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 36px;
			right: -125px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 36px;
			right: -95px;
			border-radius: 50%;
		}
	}

	body.ltr.testimonials-to-services .elementLine4:before{
		animation: tessElem4Before 3s forwards ease-in-out;
	}
	@keyframes tessElem4Before {
		0% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
	}


	body.ltr.testimonials-to-services .elementLine4:after{
		animation: tessElem4After 3s forwards ease-in-out;
	}
	@keyframes tessElem4After {
		0% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
			
		}
		70% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
		}
	}


	/******************* Animate testimonials to Portfolio **********************/

	body.ltr.testimonials-to-portfolio .globalElement1{
		animation: tespElem1 2s forwards ease-in-out;
	}
	@keyframes tespElem1 {
		0% { 
			opacity: 0;
		}
		100% { 
			opacity: 0;
		}
	}


	body.ltr.testimonials-to-portfolio .globalElement2{
		animation: tespElem2 3s forwards ease-in-out;
	}

	@keyframes tespElem2 {
		0% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1420px;
			top: 40px;
			transform: rotate(135deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1450px;
			top: -20px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1420px;
			top: 0px;
			transform: rotate(135deg);
			
		}
	}

	body.ltr.testimonials-to-portfolio .globalElement3{
		animation: tespElem3 3s forwards ease-in-out;
		
	}

	@keyframes tespElem3 {
		0% { 
			opacity: 0;
		}
		100% { 
			opacity: 0;
		}
	}

	body.ltr.testimonials-to-portfolio .globalElement4{
		animation: tespGlobElem4 1s forwards ease-in-out;
	}
	@keyframes tespGlobElem4 {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.ltr.testimonials-to-portfolio .globalElement5{
		animation: tespGlobElem5 1s forwards ease-in-out;
	}
	@keyframes tespGlobElem5 {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.ltr.testimonials-to-portfolio .elementLine4{
		animation: tespElem4 3s forwards ease-in-out;
	}
	@keyframes tespElem4 {
		0% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 430px;
			right: 1400px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: -10px;
			right: 1480px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 10px;
			right: 1450px;
			border-radius: 50%;
		}
	}

	body.ltr.testimonials-to-portfolio .elementLine4:before{
		animation: tespElem4Before 3s forwards ease-in-out;
	}
	@keyframes tespElem4Before {
		0% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
	}


	body.ltr.testimonials-to-portfolio .elementLine4:after{
		animation: tespElem4After 3s forwards ease-in-out;
	}
	@keyframes tespElem4After {
		0% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
			
		}
		70% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
		}
	}


	/******************* Animate testimonials to Team **********************/

	body.ltr.testimonials-to-testimonials .globalElement1{
		animation: testElem1 2s forwards ease-in-out;
	}
	@keyframes testElem1 {
		0% { 
			opacity: 0;
		}
		100% { 
			opacity: 0;
		}
	}


	body.ltr.testimonials-to-testimonials .globalElement2{
		animation: testElem2 3s forwards ease-in-out;
	}

	@keyframes testElem2 {
		0% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1420px;
			top: 40px;
			transform: rotate(135deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1420px;
			top: 40px;
			transform: rotate(135deg);
			
		}
	}

	body.ltr.testimonials-to-testimonials .globalElement3{
		animation: testElem3 3s forwards ease-in-out;
		
	}

	@keyframes testElem3 {
		0% { 
			opacity: 0;
		}
		100% { 
			opacity: 0;
		}
	}

	body.ltr.testimonials-to-testimonials .globalElement4{
		animation: testGlobElem4 1s forwards ease-in-out;
	}
	@keyframes testGlobElem4 {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.ltr.testimonials-to-testimonials .globalElement5{
		animation: testGlobElem5 1s forwards ease-in-out;
	}
	@keyframes testGlobElem5 {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.ltr.testimonials-to-testimonials .elementLine4{
		animation: testElem4 3s forwards ease-in-out;
	}
	@keyframes testElem4 {
		0% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 430px;
			right: 1400px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 430px;
			right: 1400px;
			border-radius: 50%;
		}
	}

	body.ltr.testimonials-to-testimonials .elementLine4:before{
		animation: testElem4Before 3s forwards ease-in-out;
	}
	@keyframes testElem4Before {
		0% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
	}


	body.ltr.testimonials-to-testimonials .elementLine4:after{
		animation: testElem4After 3s forwards ease-in-out;
	}
	@keyframes testElem4After {
		0% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
			
		}
		100% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
		}
	}


	/******************* Animate testimonials to Clients **********************/

	body.ltr.testimonials-to-clients .globalElement1{
		animation: tescElem1 2s forwards ease-in-out;
	}
	@keyframes tescElem1 {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 100px;
			top: -20px;
			transform: rotate(-70deg);
			opacity: 0;
		}
		100% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 100px;
			top: -20px;
			transform: rotate(-70deg);
			opacity: 1;
		}
	}


	body.ltr.testimonials-to-clients .globalElement2{
		animation: tescElem2 3s forwards ease-in-out;
	}

	@keyframes tescElem2 {
		0% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1420px;
			top: 40px;
			transform: rotate(135deg);
		}
		70% { 
			border-radius: 50%;
			width: 250px;
			height: 250px;
			left: 625px;
			top: 525px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 250px;
			height: 250px;
			left: 625px;
			top: 495px;
			transform: rotate(135deg);
			
		}
	}

	body.ltr.testimonials-to-clients .globalElement3{
		animation: tescElem3 3s forwards ease-in-out;
		
	}

	@keyframes tescElem3 {
		0% { 
			border-radius: 80px;
			width: 250px;
			height: 250px;
			left: 95%;
			bottom: -100px;
			transform: rotate(70deg);
			background: linear-gradient(120deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0) 100%);
			opacity: 0;
		}
		100% { 
			border-radius: 80px;
			width: 250px;
			height: 250px;
			left: 95%;
			bottom: -100px;
			transform: rotate(70deg);
			background: linear-gradient(120deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0) 100%);
			opacity: 1;
		}
	}

	body.ltr.testimonials-to-clients .globalElement4{
		animation: tescGlobElem4 1s forwards ease-in-out;
	}
	@keyframes tescGlobElem4 {
		0% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 630px;
			top: 430px;
			transform: rotate(-60deg);
			z-index: -1;
			opacity: 0;
		}
		
		100% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 630px;
			top: 430px;
			transform: rotate(-60deg);
			z-index: -1;
			opacity: 1;
		}
	}

	body.ltr.testimonials-to-clients .globalElement5{
		animation: tescGlobElem5 1s forwards ease-in-out;
	}
	@keyframes tescGlobElem5 {
		0% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			right: 80px;
			top: 80px;
			transform: rotate(60deg);
			opacity: 0;
		}
		
		100% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			right: 80px;
			top: 80px;
			transform: rotate(60deg);
			opacity: 1;
		}
	}

	body.ltr.testimonials-to-clients .elementLine4{
		animation: tescElem4 3s forwards ease-in-out;
	}
	@keyframes tescElem4 {
		0% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 430px;
			right: 1400px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 500px;
			right: 610px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 470px;
			right: 600px;
			border-radius: 50%;
		}
	}

	body.ltr.testimonials-to-clients .elementLine4:before{
		animation: tescElem4Before 3s forwards ease-in-out;
	}
	@keyframes tescElem4Before {
		0% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 342px;
			height: 342px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 342px;
			height: 342px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
	}


	body.ltr.testimonials-to-clients .elementLine4:after{
		animation: tescElem4After 3s forwards ease-in-out;
	}
	@keyframes tescElem4After {
		0% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
			
		}
		70% { 
			z-index: -1;
			width: 384px;
			height: 384px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 384px;
			height: 384px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
		}
	}



	/******************* Animate Testimonials to Contact **********************/

		body.ltr.testimonials-to-contact .globalElement1{
			animation: tesCtElem1 2s forwards ease-in-out;
		}
		@keyframes tesCtElem1 {
			0% { 
				opacity: 0;
			}
			100% { 
				opacity: 0;
			}
		}


		body.ltr.testimonials-to-contact .globalElement2{
			animation: tesCtElem2 3s forwards ease-in-out;
		}

		@keyframes tesCtElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1420px;
				top: 40px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1450px;
				top: -20px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1420px;
				top: 0px;
				transform: rotate(135deg);
				
			}
		}

		body.ltr.testimonials-to-contact .globalElement3{
			animation: tesCtElem3 3s forwards ease-in-out;
			
		}

		@keyframes tesCtElem3 {
			0% { 
				opacity: 0;
			}
			100% { 
				opacity: 0;
			}
		}

		body.ltr.testimonials-to-contact .globalElement4{
			animation: tesCtGlobElem4 1s forwards ease-in-out;
		}
		@keyframes tesCtGlobElem4 {
			0% { 
				opacity: 0;
			}
			
			100% { 
				opacity: 0;
			}
		}

		body.ltr.testimonials-to-contact .globalElement5{
			animation: tesCtGlobElem5 1s forwards ease-in-out;
		}
		@keyframes tesCtGlobElem5 {
			0% { 
				opacity: 0;
			}
			
			100% { 
				opacity: 0;
			}
		}

		body.ltr.testimonials-to-contact .elementLine4{
			animation: tesCtElem4 3s forwards ease-in-out;
		}
		@keyframes tesCtElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1400px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1480px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1450px;
				border-radius: 50%;
			}
		}

		body.ltr.testimonials-to-contact .elementLine4:before{
			animation: tesCtElem4Before 3s forwards ease-in-out;
		}
		@keyframes tesCtElem4Before {
			0% { 
				z-index: -1;
				width: 242px;
				height: 242px;
				top: -22px;
				left: -22px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 242px;
				height: 242px;
				top: -22px;
				left: -22px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 242px;
				height: 242px;
				top: -22px;
				left: -22px;
				border-radius: 50%;
			}
		}


		body.ltr.testimonials-to-contact .elementLine4:after{
			animation: tesCtElem4After 3s forwards ease-in-out;
		}
		
		@keyframes tesCtElem4After {
			0% { 
				z-index: -1;
				width: 284px;
				height: 284px;
				top: -43px;
				left: -43px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 284px;
				height: 284px;
				top: -43px;
				left: -43px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 284px;
				height: 284px;
				top: -43px;
				left: -43px;
				border-radius: 50%;
			}
		}





/******************* Animate Clients to All **********************/

	/******************* Animate Clients to About **********************/
		


	body.ltr.clients-to-about .about-img{
		animation: cliaboutImg 2.5s forwards ease-in-out;
	}
	@keyframes cliaboutImg {
		0% { 
			bottom: -600px;
			left: 100px;
			opacity: 0;
		}
		70% { 
			bottom: 10px;
			left: -10px;
			opacity: 1;
		}
		100% { 
			bottom: 0;
			left: 0;
			opacity: 1;
		}
	}

	body.ltr.clients-to-about .globalElement1{
		animation: caElem1 2s forwards ease-in-out;
	}
	@keyframes caElem1 {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 100px;
			top: -20px;
			transform: rotate(-70deg);
		}
		100% { 
			left: 290px;
			top: 295px;
			width: 160px;
			height: 160px;
			border-radius: 47px;
			transform: rotate(120deg);
			/* Add the blur effect */
			backdrop-filter: blur(10px);
			-webkit-backdrop-filter: blur(10px);
			z-index: 5;
			opacity: 1;
		}
	}


	body.ltr.clients-to-about .globalElement2{
		animation: caElem2 3s forwards ease-in-out;
	}

	@keyframes caElem2 {
		0% { 
			border-radius: 50%;
			width: 250px;
			height: 250px;
			left: 625px;
			top: 495px;
			transform: rotate(135deg);
		}
		70% { 
			border-radius: 55px;
			width: 150px;
			height: 150px;
			left: 1450px;
			top: 30px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 55px;
			width: 150px;
			height: 150px;
			left: 1420px;
			top: 30px;
			transform: rotate(135deg);
			
		}
	}

	body.ltr.clients-to-about .globalElement3{
		animation: caElem3 3s forwards ease-in-out;
		
	}

	@keyframes caElem3 {
		0% { 
			border-radius: 80px;
			width: 284px;
			height: 284px;
			left: 64px;
			bottom: 67px;
			transform: rotate(66deg);
			background: var(--bgGradient);
			opacity: 0;
		}
		100% { 
			border-radius: 80px;
			width: 284px;
			height: 284px;
			left: 64px;
			bottom: 67px;
			transform: rotate(66deg);
			background: var(--bgGradient);
			opacity: 1;
		}
	}

	body.ltr.clients-to-about .globalElement4{
		animation: caGlobElem4 1s forwards ease-in-out;
	}
	@keyframes caGlobElem4 {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.ltr.clients-to-about .globalElement5{
		animation: caGlobElem5 1s forwards ease-in-out;
	}
	@keyframes caGlobElem5 {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.ltr.clients-to-about .elementLine4{
		animation: caElem4 3s forwards ease-in-out;
	}
	
	
	@keyframes caElem4 {
		0% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 470px;
			right: 600px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 120px;
			right: 1460px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 120px;
			right: 1420px;
			border-radius: 50%;
		}
	}

	body.ltr.clients-to-about .elementLine4:before{
		animation: caElem4Before 3s forwards ease-in-out;
	}
	@keyframes caElem4Before {
		0% { 
			z-index: -1;
			width: 342px;
			height: 342px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 342px;
			height: 342px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 342px;
			height: 342px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
	}


	body.ltr.clients-to-about .elementLine4:after{
		animation: caElem4After 3s forwards ease-in-out;
	}
	@keyframes caElem4After {
		0% { 
			z-index: -1;
			width: 384px;
			height: 384px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
			
		}
		70% { 
			z-index: -1;
			width: 384px;
			height: 384px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 384px;
			height: 384px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
		}
	}
	


	/******************* Animate Clients to Services **********************/

	body.ltr.clients-to-services .globalElement1{
		animation: csElem1 2s forwards ease-in-out;
	}
	@keyframes csElem1 {
		0% { 
			opacity: 0;
		}
		100% { 
			opacity: 0;
		}
	}


	body.ltr.clients-to-services .globalElement2{
		animation: csElem2 3s forwards ease-in-out;
	}

	@keyframes csElem2 {
		0% { 
			border-radius: 50%;
			width: 250px;
			height: 250px;
			left: 625px;
			top: 495px;
			transform: rotate(135deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1450px;
			top: 60px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1420px;
			top: 60px;
			transform: rotate(135deg);
			
		}
	}

	body.ltr.clients-to-services .globalElement3{
		animation: csElem3 3s forwards ease-in-out;
		
	}

	@keyframes csElem3 {
		0% { 
			opacity: 0;
		}
		100% { 
			opacity: 0;
		}
	}

	body.ltr.clients-to-services .globalElement4{
		animation: csGlobElem4 1s forwards ease-in-out;
	}
	@keyframes csGlobElem4 {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.ltr.clients-to-services .globalElement5{
		animation: csGlobElem5 1s forwards ease-in-out;
	}
	@keyframes csGlobElem5 {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.ltr.clients-to-services .elementLine4{
		animation: csElem4 3s forwards ease-in-out;
	}
	@keyframes csElem4 {
		0% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 470px;
			right: 600px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 36px;
			right: -125px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 36px;
			right: -95px;
			border-radius: 50%;
		}
	}

	body.ltr.clients-to-services .elementLine4:before{
		animation: csElem4Before 3s forwards ease-in-out;
	}
	@keyframes csElem4Before {
		0% { 
			z-index: -1;
			width: 342px;
			height: 342px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
	}


	body.ltr.clients-to-services .elementLine4:after{
		animation: csElem4After 3s forwards ease-in-out;
	}
	@keyframes csElem4After {
		0% { 
			z-index: -1;
			width: 384px;
			height: 384px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
			
		}
		70% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
		}
	}


	/******************* Animate Clients to Portfolio **********************/

	body.ltr.clients-to-portfolio .globalElement1{
		animation: cpElem1 2s forwards ease-in-out;
	}
	@keyframes cpElem1 {
		0% { 
			opacity: 0;
		}
		100% { 
			opacity: 0;
		}
	}


	body.ltr.clients-to-portfolio .globalElement2{
		animation: cpElem2 3s forwards ease-in-out;
	}

	@keyframes cpElem2 {
		0% { 
			border-radius: 50%;
			width: 250px;
			height: 250px;
			left: 625px;
			top: 495px;
			transform: rotate(135deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1450px;
			top: -20px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1420px;
			top: 0px;
			transform: rotate(135deg);
			
		}
	}

	body.ltr.clients-to-portfolio .globalElement3{
		animation: cpElem3 3s forwards ease-in-out;
		
	}

	@keyframes cpElem3 {
		0% { 
			opacity: 0;
		}
		100% { 
			opacity: 0;
		}
	}

	body.ltr.clients-to-portfolio .globalElement4{
		animation: cpGlobElem4 1s forwards ease-in-out;
	}
	@keyframes cpGlobElem4 {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.ltr.clients-to-portfolio .globalElement5{
		animation: cpGlobElem5 1s forwards ease-in-out;
	}
	@keyframes cpGlobElem5 {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.ltr.clients-to-portfolio .elementLine4{
		animation: cpElem4 3s forwards ease-in-out;
	}
	@keyframes cpElem4 {
		0% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 470px;
			right: 600px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: -10px;
			right: 1480px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 10px;
			right: 1450px;
			border-radius: 50%;
		}
	}

	body.ltr.clients-to-portfolio .elementLine4:before{
		animation: cpElem4Before 3s forwards ease-in-out;
	}
	@keyframes cpElem4Before {
		0% { 
			z-index: -1;
			width: 342px;
			height: 342px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
	}


	body.ltr.clients-to-portfolio .elementLine4:after{
		animation: cpElem4After 3s forwards ease-in-out;
	}
	@keyframes cpElem4After {
		0% { 
			z-index: -1;
			width: 384px;
			height: 384px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
			
		}
		70% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
		}
	}


	/******************* Animate Clients to Team **********************/

	body.ltr.clients-to-team .globalElement1{
		animation: ctElem1 2s forwards ease-in-out;
	}
	@keyframes ctElem1 {
		0% { 
			opacity: 0;
		}
		100% { 
			opacity: 0;
		}
	}


	body.ltr.clients-to-team .globalElement2{
		animation: ctElem2 3s forwards ease-in-out;
	}

	@keyframes ctesElem2 {
		0% { 
			border-radius: 50%;
			width: 250px;
			height: 250px;
			left: 625px;
			top: 495px;
			transform: rotate(135deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1420px;
			top: 40px;
			transform: rotate(135deg);
			
		}
	}

	body.ltr.clients-to-team .globalElement3{
		animation: ctElem3 3s forwards ease-in-out;
		
	}

	@keyframes ctElem3 {
		0% { 
			opacity: 0;
		}
		100% { 
			opacity: 0;
		}
	}

	body.ltr.clients-to-team .globalElement4{
		animation: ctGlobElem4 1s forwards ease-in-out;
	}
	@keyframes ctGlobElem4 {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.ltr.clients-to-team .globalElement5{
		animation: ctGlobElem5 1s forwards ease-in-out;
	}
	@keyframes ctGlobElem5 {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.ltr.clients-to-team .elementLine4{
		animation: ctElem4 3s forwards ease-in-out;
	}
	@keyframes ctElem4 {
		0% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 470px;
			right: 600px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 430px;
			right: 1400px;
			border-radius: 50%;
		}
	}

	body.ltr.clients-to-team .elementLine4:before{
		animation: ctElem4Before 3s forwards ease-in-out;
	}
	@keyframes ctElem4Before {
		0% { 
			z-index: -1;
			width: 342px;
			height: 342px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
	}


	body.ltr.clients-to-team .elementLine4:after{
		animation: ctElem4After 3s forwards ease-in-out;
	}
	@keyframes ctElem4After {
		0% { 
			z-index: -1;
			width: 384px;
			height: 384px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
			
		}
		100% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
		}
	}


	/******************* Animate Clients to Testimonials **********************/

	body.ltr.clients-to-testimonials .globalElement1{
		animation: ctesElem1 2s forwards ease-in-out;
	}
	@keyframes ctesElem1 {
		0% { 
			opacity: 0;
		}
		100% { 
			opacity: 0;
		}
	}


	body.ltr.clients-to-testimonials .globalElement2{
		animation: ctesElem2 3s forwards ease-in-out;
	}

	@keyframes ctesElem2 {
		0% { 
			border-radius: 50%;
			width: 250px;
			height: 250px;
			left: 625px;
			top: 495px;
			transform: rotate(135deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1420px;
			top: 40px;
			transform: rotate(135deg);
			
		}
	}

	body.ltr.clients-to-testimonials .globalElement3{
		animation: ctesElem3 3s forwards ease-in-out;
		
	}

	@keyframes ctesElem3 {
		0% { 
			opacity: 0;
		}
		100% { 
			opacity: 0;
		}
	}

	body.ltr.clients-to-testimonials .globalElement4{
		animation: ctesGlobElem4 1s forwards ease-in-out;
	}
	@keyframes ctesGlobElem4 {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.ltr.clients-to-testimonials .globalElement5{
		animation: ctesGlobElem5 1s forwards ease-in-out;
	}
	@keyframes ctesGlobElem5 {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.ltr.clients-to-testimonials .elementLine4{
		animation: ctesElem4 3s forwards ease-in-out;
	}
	@keyframes ctesElem4 {
		0% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 470px;
			right: 600px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 430px;
			right: 1400px;
			border-radius: 50%;
		}
	}

	body.ltr.clients-to-testimonials .elementLine4:before{
		animation: ctesElem4Before 3s forwards ease-in-out;
	}
	@keyframes ctesElem4Before {
		0% { 
			z-index: -1;
			width: 342px;
			height: 342px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
	}


	body.ltr.clients-to-testimonials .elementLine4:after{
		animation: ctesElem4After 3s forwards ease-in-out;
	}
	@keyframes ctesElem4After {
		0% { 
			z-index: -1;
			width: 384px;
			height: 384px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
			
		}
		100% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
		}
	}


	
	/******************* Animate Clients to Contact **********************/

		body.ltr.clients-to-contact .globalElement1{
			animation: cCtElem1 2s forwards ease-in-out;
		}
		@keyframes cCtElem1 {
			0% { 
				opacity: 0;
			}
			100% { 
				opacity: 0;
			}
		}


		body.ltr.clients-to-contact .globalElement2{
			animation: cCtElem2 3s forwards ease-in-out;
		}

		@keyframes cCtElem2 {
			0% { 
				border-radius: 50%;
				width: 250px;
				height: 250px;
				left: 625px;
				top: 495px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1450px;
				top: -20px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1420px;
				top: 0px;
				transform: rotate(135deg);
				
			}
		}

		body.ltr.clients-to-contact .globalElement3{
			animation: cCtElem3 3s forwards ease-in-out;
			
		}

		@keyframes cCtElem3 {
			0% { 
				opacity: 0;
			}
			100% { 
				opacity: 0;
			}
		}

		body.ltr.clients-to-contact .globalElement4{
			animation: cCtGlobElem4 1s forwards ease-in-out;
		}
		@keyframes cCtGlobElem4 {
			0% { 
				opacity: 0;
			}
			
			100% { 
				opacity: 0;
			}
		}

		body.ltr.clients-to-contact .globalElement5{
			animation: cCtGlobElem5 1s forwards ease-in-out;
		}
		@keyframes cCtGlobElem5 {
			0% { 
				opacity: 0;
			}
			
			100% { 
				opacity: 0;
			}
		}

		body.ltr.clients-to-contact .elementLine4{
			animation: cCtElem4 3s forwards ease-in-out;
		}
		@keyframes cCtElem4 {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 470px;
				right: 600px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1480px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1450px;
				border-radius: 50%;
			}
		}

		body.ltr.clients-to-contact .elementLine4:before{
			animation: cCtElem4Before 3s forwards ease-in-out;
		}
		@keyframes cCtElem4Before {
			0% { 
				z-index: -1;
				width: 342px;
				height: 342px;
				top: -22px;
				left: -22px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 242px;
				height: 242px;
				top: -22px;
				left: -22px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 242px;
				height: 242px;
				top: -22px;
				left: -22px;
				border-radius: 50%;
			}
		}


		body.ltr.clients-to-contact .elementLine4:after{
			animation: cCtElem4After 3s forwards ease-in-out;
		}
		
		@keyframes cCtElem4After {
			0% { 
				z-index: -1;
				width: 384px;
				height: 384px;
				top: -43px;
				left: -43px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 284px;
				height: 284px;
				top: -43px;
				left: -43px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 284px;
				height: 284px;
				top: -43px;
				left: -43px;
				border-radius: 50%;
			}
		}


/******************* Animate Contact to All **********************/

	/******************* Animate Contact to About **********************/
		


	body.ltr.contact-to-about .about-img{
		animation: CtaboutImg 2.5s forwards ease-in-out;
	}
	@keyframes CtaboutImg {
		0% { 
			bottom: -600px;
			left: 100px;
			opacity: 0;
		}
		70% { 
			bottom: 10px;
			left: -10px;
			opacity: 1;
		}
		100% { 
			bottom: 0;
			left: 0;
			opacity: 1;
		}
	}

	body.ltr.contact-to-about .globalElement1{
		animation: CtaElem1 2s forwards ease-in-out;
	}
	@keyframes CtaElem1 {
		0% { 
			left: 290px;
			top: 295px;
			width: 160px;
			height: 160px;
			border-radius: 47px;
			transform: rotate(120deg);
			/* Add the blur effect */
			backdrop-filter: blur(10px);
			-webkit-backdrop-filter: blur(10px);
			z-index: 5;
			opacity: 0;
		}
		100% { 
			left: 290px;
			top: 295px;
			width: 160px;
			height: 160px;
			border-radius: 47px;
			transform: rotate(120deg);
			/* Add the blur effect */
			backdrop-filter: blur(10px);
			-webkit-backdrop-filter: blur(10px);
			z-index: 5;
			opacity: 1;
		}
	}


	body.ltr.contact-to-about .globalElement2{
		animation: CtaElem2 3s forwards ease-in-out;
	}

	@keyframes CtaElem2 {
		0% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1420px;
			top: 0px;
			transform: rotate(135deg);
		}
		70% { 
			border-radius: 55px;
			width: 150px;
			height: 150px;
			left: 1450px;
			top: 30px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 55px;
			width: 150px;
			height: 150px;
			left: 1420px;
			top: 30px;
			transform: rotate(135deg);
			
		}
	}

	body.ltr.contact-to-about .globalElement3{
		animation: CtaElem3 3s forwards ease-in-out;
		
	}

	@keyframes CtaElem3 {
		0% { 
			border-radius: 80px;
			width: 284px;
			height: 284px;
			left: 64px;
			bottom: 67px;
			transform: rotate(66deg);
			background: var(--bgGradient);
			opacity: 0;
		}
		100% { 
			border-radius: 80px;
			width: 284px;
			height: 284px;
			left: 64px;
			bottom: 67px;
			transform: rotate(66deg);
			background: var(--bgGradient);
			opacity: 1;
		}
	}

	body.ltr.contact-to-about .globalElement4{
		animation: CtaGlobElem4 1s forwards ease-in-out;
	}
	@keyframes CtaGlobElem4 {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.ltr.contact-to-about .globalElement5{
		animation: CtaGlobElem5 1s forwards ease-in-out;
	}
	@keyframes CtaGlobElem5 {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.ltr.contact-to-about .elementLine4{
		animation: CtaElem4 3s forwards ease-in-out;
	}
	@keyframes CtaElem4 {
		0% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			right: 1450px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 120px;
			right: 1460px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 120px;
			right: 1420px;
			border-radius: 50%;
		}
	}

	body.ltr.contact-to-about .elementLine4:before{
		animation: CtaElem4Before 3s forwards ease-in-out;
	}
	@keyframes CtaElem4Before {
		0% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 342px;
			height: 342px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 342px;
			height: 342px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
	}


	body.ltr.contact-to-about .elementLine4:after{
		animation: CtaElem4After 3s forwards ease-in-out;
	}
	@keyframes CtaElem4After {
		0% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
			
		}
		70% { 
			z-index: -1;
			width: 384px;
			height: 384px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 384px;
			height: 384px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
		}
	}
	


	/******************* Animate Contact to Services **********************/

	body.ltr.contact-to-services .globalElement1{
		animation: CtsElem1 2s forwards ease-in-out;
	}
	@keyframes CtsElem1 {
		0% { 
			opacity: 0;
		}
		100% { 
			opacity: 0;
		}
	}


	body.ltr.contact-to-services .globalElement2{
		animation: CtsElem2 3s forwards ease-in-out;
	}

	@keyframes CtsElem2 {
		0% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1420px;
			top: 0px;
			transform: rotate(135deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1450px;
			top: 60px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1420px;
			top: 60px;
			transform: rotate(135deg);
			
		}
	}

	body.ltr.contact-to-services .globalElement3{
		animation: CtsElem3 3s forwards ease-in-out;
		
	}

	@keyframes CtsElem3 {
		0% { 
			opacity: 0;
		}
		100% { 
			opacity: 0;
		}
	}

	body.ltr.contact-to-services .globalElement4{
		animation: CtsGlobElem4 1s forwards ease-in-out;
	}
	@keyframes CtsGlobElem4 {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.ltr.contact-to-services .globalElement5{
		animation: CtsGlobElem5 1s forwards ease-in-out;
	}
	@keyframes CtsGlobElem5 {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.ltr.contact-to-services .elementLine4{
		animation: CtsElem4 3s forwards ease-in-out;
	}
	@keyframes CtsElem4 {
		0% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			right: 1450px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 36px;
			right: -125px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 36px;
			right: -95px;
			border-radius: 50%;
		}
	}

	body.ltr.contact-to-services .elementLine4:before{
		animation: CtsElem4Before 3s forwards ease-in-out;
	}
	@keyframes CtsElem4Before {
		0% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
	}


	body.ltr.contact-to-services .elementLine4:after{
		animation: CtsElem4After 3s forwards ease-in-out;
	}
	@keyframes CtsElem4After {
		0% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
			
		}
		70% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
		}
	}


	/******************* Animate Contact to Portfolio **********************/

	body.ltr.contact-to-portfolio .globalElement1{
		animation: CtpElem1 2s forwards ease-in-out;
	}
	@keyframes CtpElem1 {
		0% { 
			opacity: 0;
		}
		100% { 
			opacity: 0;
		}
	}


	body.ltr.contact-to-portfolio .globalElement2{
		animation: CtpElem2 3s forwards ease-in-out;
	}

	@keyframes CtpElem2 {
		0% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1420px;
			top: 0px;
			transform: rotate(135deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1450px;
			top: -20px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1420px;
			top: 0px;
			transform: rotate(135deg);
			
		}
	}

	body.ltr.contact-to-portfolio .globalElement3{
		animation: CtpElem3 3s forwards ease-in-out;
		
	}

	@keyframes CtpElem3 {
		0% { 
			opacity: 0;
		}
		100% { 
			opacity: 0;
		}
	}

	body.ltr.contact-to-portfolio .globalElement4{
		animation: CtpGlobElem4 1s forwards ease-in-out;
	}
	@keyframes CtpGlobElem4 {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.ltr.contact-to-portfolio .globalElement5{
		animation: CtpGlobElem5 1s forwards ease-in-out;
	}
	@keyframes CtpGlobElem5 {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.ltr.contact-to-portfolio .elementLine4{
		animation: CtpElem4 3s forwards ease-in-out;
	}
	@keyframes CtpElem4 {
		0% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			right: 1450px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: -10px;
			right: 1480px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 10px;
			right: 1450px;
			border-radius: 50%;
		}
	}

	body.ltr.contact-to-portfolio .elementLine4:before{
		animation: CtpElem4Before 3s forwards ease-in-out;
	}
	@keyframes CtpElem4Before {
		0% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
	}


	body.ltr.contact-to-portfolio .elementLine4:after{
		animation: CtpElem4After 3s forwards ease-in-out;
	}
	@keyframes CtpElem4After {
		0% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
			
		}
		70% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
		}
	}



	/******************* Animate Contact to Team **********************/

	body.ltr.contact-to-team .globalElement1{
		animation: CttElem1 2s forwards ease-in-out;
	}
	@keyframes CttElem1 {
		0% { 
			opacity: 0;
		}
		100% { 
			opacity: 0;
		}
	}


	body.ltr.contact-to-team .globalElement2{
		animation: CttElem2 3s forwards ease-in-out;
	}

	@keyframes CttElem2 {
		0% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1420px;
			top: 0px;
			transform: rotate(135deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1450px;
			top: 40px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1420px;
			top: 40px;
			transform: rotate(135deg);
			
		}
	}

	body.ltr.contact-to-team .globalElement3{
		animation: CttElem3 3s forwards ease-in-out;
		
	}

	@keyframes CttElem3 {
		0% { 
			opacity: 0;
		}
		100% { 
			opacity: 0;
		}
	}

	body.ltr.contact-to-team .globalElement4{
		animation: CttGlobElem4 1s forwards ease-in-out;
	}
	@keyframes CttGlobElem4 {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.ltr.contact-to-team .globalElement5{
		animation: CttGlobElem5 1s forwards ease-in-out;
	}
	@keyframes CttGlobElem5 {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.ltr.contact-to-team .elementLine4{
		animation: CttElem4 3s forwards ease-in-out;
	}
	@keyframes CttElem4 {
		0% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			right: 1450px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 445px;
			right: 1430px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 430px;
			right: 1400px;
			border-radius: 50%;
		}
	}

	body.ltr.contact-to-team .elementLine4:before{
		animation: CttElem4Before 3s forwards ease-in-out;
	}
	@keyframes CttElem4Before {
		0% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
	}


	body.ltr.contact-to-team .elementLine4:after{
		animation: CttElem4After 3s forwards ease-in-out;
	}
	@keyframes CttElem4After {
		0% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
			
		}
		70% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
		}
	}


	/******************* Animate Contact to Testimonials **********************/

	body.ltr.contact-to-testimonials .globalElement1{
		animation: CttesElem1 2s forwards ease-in-out;
	}
	@keyframes CttesElem1 {
		0% { 
			opacity: 0;
		}
		100% { 
			opacity: 0;
		}
	}


	body.ltr.contact-to-testimonials .globalElement2{
		animation: CttesElem2 3s forwards ease-in-out;
	}

	@keyframes CttesElem2 {
		0% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1420px;
			top: 0px;
			transform: rotate(135deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1450px;
			top: 40px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1420px;
			top: 40px;
			transform: rotate(135deg);
			
		}
	}

	body.ltr.contact-to-testimonials .globalElement3{
		animation: CttesElem3 3s forwards ease-in-out;
		
	}

	@keyframes CttesElem3 {
		0% { 
			opacity: 0;
		}
		100% { 
			opacity: 0;
		}
	}

	body.ltr.contact-to-testimonials .globalElement4{
		animation: CttesGlobElem4 1s forwards ease-in-out;
	}
	@keyframes CttesGlobElem4 {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.ltr.contact-to-testimonials .globalElement5{
		animation: CttesGlobElem5 1s forwards ease-in-out;
	}
	@keyframes CttesGlobElem5 {
		0% { 
			opacity: 0;
		}
		
		100% { 
			opacity: 0;
		}
	}

	body.ltr.contact-to-testimonials .elementLine4{
		animation: CttesElem4 3s forwards ease-in-out;
	}
	@keyframes CttesElem4 {
		0% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			right: 1450px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 445px;
			right: 1430px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 430px;
			right: 1400px;
			border-radius: 50%;
		}
	}

	body.ltr.contact-to-testimonials .elementLine4:before{
		animation: CttesElem4Before 3s forwards ease-in-out;
	}
	@keyframes CttesElem4Before {
		0% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
	}


	body.ltr.contact-to-testimonials .elementLine4:after{
		animation: CttesElem4After 3s forwards ease-in-out;
	}
	@keyframes CttesElem4After {
		0% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
			
		}
		70% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
		}
	}


	/******************* Animate Contact to Clients **********************/

	body.ltr.contact-to-clients .globalElement1{
		animation: CtcElem1 2s forwards ease-in-out;
	}
	@keyframes CtcElem1 {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 100px;
			top: -20px;
			transform: rotate(-70deg);
			opacity: 0;
		}
		100% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 100px;
			top: -20px;
			transform: rotate(-70deg);
			opacity: 1;
		}
	}


	body.ltr.contact-to-clients .globalElement2{
		animation: CtcElem2 3s forwards ease-in-out;
	}

	@keyframes CtcElem2 {
		0% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1420px;
			top: 0px;
			transform: rotate(135deg);
		}
		70% { 
			border-radius: 50%;
			width: 250px;
			height: 250px;
			left: 625px;
			top: 525px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 250px;
			height: 250px;
			left: 625px;
			top: 495px;
			transform: rotate(135deg);
			
		}
	}

	body.ltr.contact-to-clients .globalElement3{
		animation: CtcElem3 3s forwards ease-in-out;
		
	}

	@keyframes CtcElem3 {
		0% { 
			border-radius: 80px;
			width: 250px;
			height: 250px;
			left: 95%;
			bottom: -100px;
			transform: rotate(70deg);
			background: linear-gradient(120deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0) 100%);
			opacity: 0;
		}
		100% { 
			border-radius: 80px;
			width: 250px;
			height: 250px;
			left: 95%;
			bottom: -100px;
			transform: rotate(70deg);
			background: linear-gradient(120deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0) 100%);
			opacity: 1;
		}
	}

	body.ltr.contact-to-clients .globalElement4{
		animation: CtcGlobElem4 1s forwards ease-in-out;
	}
	@keyframes CtcGlobElem4 {
		0% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 630px;
			top: 430px;
			transform: rotate(-60deg);
			z-index: -1;
			opacity: 0;
		}
		
		100% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 630px;
			top: 430px;
			transform: rotate(-60deg);
			z-index: -1;
			opacity: 1;
		}
	}

	body.ltr.contact-to-clients .globalElement5{
		animation: CtcGlobElem5 1s forwards ease-in-out;
	}
	@keyframes CtcGlobElem5 {
		0% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			right: 80px;
			top: 80px;
			transform: rotate(60deg);
			opacity: 0;
		}
		
		100% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			right: 80px;
			top: 80px;
			transform: rotate(60deg);
			opacity: 1;
		}
	}

	body.ltr.contact-to-clients .elementLine4{
		animation: CtcElem4 3s forwards ease-in-out;
	}
	@keyframes CtcElem4 {
		0% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			right: 1450px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 500px;
			right: 610px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 470px;
			right: 600px;
			border-radius: 50%;
		}
	}

	body.ltr.contact-to-clients .elementLine4:before{
		animation: CtcElem4Before 3s forwards ease-in-out;
	}
	@keyframes CtcElem4Before {
		0% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
		70% { 
			z-index: -1;
			width: 342px;
			height: 342px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 342px;
			height: 342px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
	}


	body.ltr.contact-to-clients .elementLine4:after{
		animation: CtcElem4After 3s forwards ease-in-out;
	}
	@keyframes CtcElem4After {
		0% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
			
		}
		70% { 
			z-index: -1;
			width: 384px;
			height: 384px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 384px;
			height: 384px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
		}
	}




/******************* Animate Home To Get A Quote **********************/
.ltr{
	.getQuoteMain{
		position: absolute;
		z-index: 2;
		width: 220px;
		bottom: -30px;
		left: 160px;
	}
	.modalQuoEle1{
		width: 55px;
		left: 145px;
		top: 30px;
	}
	.modalQuoEle2{
		width: 40px;
		left: 325px;
		top: 45px;
	}
	.modalQuoEle3{
		width: 40px;
		left: 363px;
		top: 165px;
	}
	.modalQuoEle4{
		width: 40px;
		left: 150px;
		top: 130px;
	}
}
