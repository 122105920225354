@media (min-width: 1550px) {
    
    header.container-fluid, section > .container-fluid, footer.container-fluid{
        max-width: 1500px;
    }
}
@media (max-width: 1900px) {
    .clientHand{
        left: -200px;
    }
}
@media (max-width: 1800px) {
    .clientHand{
        left: -150px;
    }
}
@media (max-width: 1700px) {
    .clientHand{
        left: -100px;
        bottom: 0px;
    }
}
@media (max-width: 1600px) {
    .clientHand{
        left: -50px;
    }
}
@media (max-width: 1549px) {
    
    header.container-fluid, section > .container-fluid, footer.container-fluid{
        max-width: 1450px;
    }
}
@media (max-width: 1500px) {
    .globalElement3 {
        right: -140px;
    }
    .modal-xl{
        margin: 12px;
        max-width: 100%;
    }
    header.container-fluid, section > .container-fluid, footer.container-fluid{
        max-width: 1440px;
    }
}
@media (max-width: 1440px) {
    .banner-content{
        padding-right: 50px;
        .bannertTxtPrimary{
            .bannertTxtPrimary1{
            }
            .bannertTxtPrimary2{
            }
        }
        .bannertHeading{
        }
        .bannertTxtSecondary{
        }
    }
    header.container-fluid, section > .container-fluid, footer.container-fluid{
        max-width: 1380px;
    }
    .portfolioDetailBtn button {
        border-radius: 0 32px 0 32px !important;
    }
    .rightColContentQuote{
        padding: 10px 30px 0 10px;
    }
}
@media (max-width: 1399px) {
    .processIconsWrapper {
        height: 203px;
        padding: 29px 10px 0 10px;
    }
    .processIcon {
        height: 144px;
        width: 144px;
    }
    header.container-fluid, section > .container-fluid, footer.container-fluid{
        max-width: 1340px;
    }
    .clientsCol{
        padding: 0px 46px 0px 46px;
    }
}
@media (max-width: 1350px) {
    header.container-fluid, section > .container-fluid, footer.container-fluid{
        max-width: 1280px;
    }
    // .rightColContentServices{
    //     width: 55%;
    // }
    .portfolioDetailBtn button {
        border-radius: 0 28px 0 28px !important;
    }
    .rightColContentQuote{
        padding: 10px 30px 0 10px;
    }
    .banner-content {
        padding-right: 0;
    }
}

@media (max-width: 1299px) {
    .globalElement2 {
        left: 620px;
    }
    .globalElement3 {
        right: -170px;
    }
    .banner-content{
        padding-right: 0px;
        .bannertTxtPrimary{
            .bannertTxtPrimary1{
            }
            .bannertTxtPrimary2{
            }
        }
        .bannertHeading{
        }
        .bannertTxtSecondary{
        }
    }
    .element5 {
        right: -120px;
    }
    header.container-fluid, section > .container-fluid, footer.container-fluid{
        max-width: 1230px;
    }
    .servicesText {
        padding: 8px 0px 0px 0px;
    }

}

@media (max-width: 1250px) {
    header.container-fluid, section > .container-fluid, footer.container-fluid{
        max-width: 1180px;
    }
    .mainMainTxt {
        padding: 0 100px 0 100px;
    }
    .portfolioDetailBtn button {
        border-radius: 0 26px 0 26px !important;
    }
    .leftColIcoGetQuote {
		max-width: 450px;
		min-width: 450px;
	}
    .rightColContentQuote {
        padding: 10px 30px 0 10px;
        max-width: calc(100% - 450px);
    }
    .contatcHeading .headingContainer .HeadingFirst, .contatcHeading .headingContainer .HeadingSecond{
        font-size: 32px;
    }
    footer{
        height: auto !important;
    }
}

@media (max-width: 1199px) {
    .container-fluid{
        padding-left: 13px;
        padding-right: 13px;
    }
    .banner-content{
        padding-left: 0;
        padding-right: 50px;
        .bannertTxtPrimary{
            .bannertTxtPrimary1{
            }
            .bannertTxtPrimary2{
            }
        }
        .bannertHeading{
        }
        .bannertTxtSecondary{
        }
    }
    .banner-wrap .slick-dots{
        padding-left: 6px;
    }
    .banner-wrap .slick-dots::after {
        left: 15px;
    }
    .banner-wrap.row .slick-slider{
        padding-left: 0;
        padding-right: 0;
    }
    .banner-wrap.row .slick-slider .slick-list{
        padding-right: calc(var(--bs-gutter-x) * .5);
        padding-left: calc(var(--bs-gutter-x) * .5);
    }
    .element5 {
        right: -60px;
    }
    .globalElement3 {
        right: -220px;
    }
    .globalElement2 {
        left: 540px;
    }
    .processIconsWrapper {
        height: 170px;
        padding: 25px 9px 0 9px;
    }
    .processIcon {
        height: 120px;
        width: 120px;
        padding: 5px;
    }
    .processIconInner img {
        max-width: 66px;
        max-height: 66px;
    }
    header.container-fluid, section > .container-fluid, footer.container-fluid{
        max-width: 1130px;
    }
    .mainMainTxt {
        padding: 0 50px 0 50px;
    }
    .leftColAbout{
        min-width: 420px;
    }
    .rightColContentAbout{
        max-width: calc(100% - 420px);
    }
    .clientsCol{
        padding: 0px 26px 0px 26px;
    }
    .leftColIcoServices{
        min-width: 500px;
    }
    .rightColContentServices{
        max-width: calc(100% - 500px);
    }
    .devsServicesWrapper {
        gap: 5px;
    }
    .servicesModImgMd {
        left: 38px;
    }
    .servicesModImgWd {
        left: 74px;
    }
    .devServicesCol {
        max-width: calc(22% - 5px);
        min-width: calc(22% - 5px);
    }
    .servicesModImgUiUx {
        left: 80px;
    }
    .servicesModImgSocial {
        left: 66px;
    }
    /*
    .logo svg,
    .footer-logo>svg {
        width: 180px !important;
    }

    .nav-bar-list {
        gap: 10px !important;
    }

    .banner-content {
        gap: 0 !important;
        width: 100% !important;
        padding-top: 35px !important;
    }

    button {
        padding: 6px 15px !important;
    }

    .feature-box>div {
        width: 38% !important;
    }

    .feature-img {
        width: 40% !important;
        right: 90px !important;
    }

    .contact-row {
        padding: 70px 30px 0 !important;
    }
    */
}
@media (max-width: 1150px){
    header.container-fluid, section > .container-fluid, footer.container-fluid{
        max-width: 1080px;
    }
    .portfolioDetailBtn button {
        border-radius: 0 24px 0 24px !important;
    }
}

@media (max-width: 1099px) {
    .banner-content{
        padding-left: 0;
        padding-right: 0px;
        .bannertTxtPrimary{
            .bannertTxtPrimary1{
            }
            .bannertTxtPrimary2{
            }
        }
        .bannertHeading{
        }
        .bannertTxtSecondary{
        }
    }
    header.container-fluid, section > .container-fluid, footer.container-fluid{
        max-width: 1030px;
    }
    .leftColIco {
        min-width: 420px;
    }
    .rightColContentServices{
        max-width: calc(100% - 420px);
    }
    .about-img {
        max-width: 340px;
    }
    .servicesCol {
        padding: 80px 16px 40px 16px;
    }
    .clientsCol{
        padding: 0px 46px 0px 46px;
    }
    .clientHand{
        left: -50px;
        max-width: 260px;
    }
    .servicesModImgMd {
        left: -1px;
    }
    .servicesModImgWd {
        left: 30px;
    }
    .servicesEleImg1 {
        left: 240px;
    }
    .servicesEleImg2 {
        left: 160px;
    }
    .servicesEleImg3 {
        left: 0px;
    }
    .servicesModImgUiUx {
        left: 30px;
    }
    .servicesEleImg4 {
        left: 160px;
    }
    .servicesModImgSocial {
        left: 36px;
    }
    .servicesEleImg5 {
        left: 230px;
    }
    .servicesEleImg6 {
        left: 130px;
    }
    .portfolioDetailBtn button {
        border-radius: 0 30px 0 30px !important;
    }
}
@media (max-width: 1050px) {
    header.container-fluid, section > .container-fluid, footer.container-fluid{
        max-width: 980px;
    }
}
@media (max-width: 991px) {


    /***********************Header Responsive**********************/
    
    header.container-fluid{
        max-width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
    }
    header.sticky{
        max-width: 100%;
        padding: 7px 13px !important;
        background: rgba(255, 255, 255, 0.5);
        backdrop-filter: blur(15px);
	    -webkit-backdrop-filter: blur(15px);
    }
    .nav-bar-list {
        display: none !important;
    }
    header.sticky .logo svg {
        width: 70px;
        height: auto;
    }
    

    .toggleBtn {
        margin: 0 5px;
        display: block !important;
        cursor: pointer;
        transition: all 0.5s cubic-bezier(0.77, 0, 0.18, 1);
    }

    .toggleBtn svg {
        color: var(--themColor);
    }
    .nav-bar-list a{
        display: flex;
        border-radius: 0 !important;
        padding: 8px 15px !important;
    }

    .showToggle {
        position: absolute !important;
        top: 81px;
        left: 0;
        // background: var(--white) !important;
        background: rgba(255, 255, 255, 0.8);
        backdrop-filter: blur(15px);
	    -webkit-backdrop-filter: blur(15px);
        transition: all 0.4s cubic-bezier(0.77, 0, 0.18, 1);
        color: var(--white) !important;
        width: 100% !important;
        display: block !important;
        box-shadow: 0px 5px 15px rgb(42 110 41 / 59%);
        -webkit-transition: all 0.4s cubic-bezier(0.77, 0, 0.18, 1);
        -moz-transition: all 0.4s cubic-bezier(0.77, 0, 0.18, 1);
        -ms-transition: all 0.4s cubic-bezier(0.77, 0, 0.18, 1);
        -o-transition: all 0.4s cubic-bezier(0.77, 0, 0.18, 1);
    }
    header.sticky .showToggle{
        top: 60px;
    }

    .showToggle div {
        border-bottom: 1px solid var(--grey2);
        outline: none;
        width: 100%;
        // padding: 20px 10px;
    }

    .showToggle a:hover {
        color: var(--themeColor);
    }

    .showToggle .active {
        color: var(--themeColor);
    }

    .showToggle .nav-bar-list a {
        color: var(--black);
        background: none !important;

        &:focus,
        &::before,
        &.active::before {
            content: "";
            position: absolute;
            bottom: 0;
            right: 0;
            left: 0;
            margin: auto;
            width: 0;
            height: 0;
            background: transparent !important;
        }
    }
    .nav-bar-right {
        gap: 5px;
    }

    /***********************Header Responsive End**********************/

    .main-artical {
        height: auto;
    }
    body{
        height: auto;
        overflow-y: auto;
    }
    .section-common{
        height: auto;
    }
    .section-common > .container-fluid{
        height: auto;
        max-width: 100%;
    }
    .globalElements{
        display: none !important;
    }
    #home .banner-content-col{
        padding: 0;
    }
    #home .banner-content-row{
        margin-right: 0;
        margin-left: 0;
    }
    .banner-wrap.row .slick-slider .slick-list{
        padding-left: 0;
        padding-right: 0;
    }
    .banner-content{
        width: 100%;
    }
    .bannertTxtPrimary{
        text-align: center;
        padding-left: 13px;
        padding-right: 13px;
    }
    .bannertTxtPrimary .bannertTxtPrimary1, .bannertTxtPrimary .bannertTxtPrimary2{
        font-size: 30px !important;
        white-space: nowrap;
    }
    .banner-content .bannertHeading{
        font-size: 40px;
        line-height: 55px;
        padding-left: 13px;
        padding-right: 13px;
        text-align: center;
        justify-content: center;
    }
    .bannertTxtSecondary{
        text-align: center;
        padding-left: 13px;
        padding-right: 13px;
        justify-content: center;
    }
    
    .whatsappContainer{
        display: flex;
        justify-content: center;
        padding-left: 13px;
        padding-right: 13px;
    }
    .section-common{
        padding-top: 30px;
        padding-bottom: 30px;
    }
    #home{
        padding-top: 80px;
        padding-bottom: 30px;
    }
    .banner-wrap .slick-slider ul{
        width: 100%;
        text-align: center;
        // display: flex !important;
        display: none !important;
        justify-content: center;
        padding: 0px 0 0 0;
        margin-top: 20px;
        bottom: 0;
    }
    .banner-wrap .slick-dots::after {
        left: 0;
        right: 0;
        width: 190px;
    }
    .banner-wrap .slick-dots li{
        margin: 0 10px 0 10px;
    }
    .leftColAbout{
        display: none !important;
    }
    .leftRightColWrap.about-wrap.row{
        width: auto;
    }
    .about-wrap .rightColContent{
        width: 100%;
        max-width: 100%;
    }
    .headingContainer{
        text-align: center;
    }
    .HeadingFirst, .HeadingSecond {
        font-size: 32px !important;
    }
    .tabWrapper{
        justify-content: center;
        gap: 10px;
        button{
            padding: 8px 20px !important;
        }
    }
    .tabWrapperProduct{
        justify-content: flex-start !important;
        gap: 15px !important;
        overflow-x: auto;
        button{
            padding: 8px 20px !important;
        }
    }
    .aboutWrapper{
        padding-top: 0;
    }
    .TabsAboutWrapper > *{
        text-align: center;
    }
    .tabTxt{
        margin-bottom: 20px;
    }
    .processSocialContainer{
        gap: 20px !important;
        margin-top: 15px !important;
    }
    .processSocial {
        gap: 5px !important;
        width: 100%;
        justify-content: center;
    }
    button.btn.btn-primary{
        border-radius: 16px;
    }
    
    .btn.btn-primary svg{
        width: 30px;
        height: 30px;
    }
    .mainMainTxt {
        padding: 0 calc(var(--bs-gutter-x) * .5) 0 calc(var(--bs-gutter-x) * .5);
    }
    .leftColIcoServices{
        display: none;
    }
    .rightColContentServices{
        width: 100%;
        max-width: 100%;
        padding: 0 0 30px 0;
        height: auto;
    }
    .TabsServicesWrapper > *{
        text-align: center;
    }
    .leftRightColWrap.services-wrap{
        width: auto;
    }
    .modal-xl .modal-content {
        height: auto;
        min-height: auto;
    }
    .modal-backdrop.show{
        opacity: 1;
        background: rgba(255, 255, 255, 0.05);
        backdrop-filter: blur(8px);
	    -webkit-backdrop-filter: blur(8px);
    }
    .TabsServicesWrapper .modalHeading{
        margin-bottom: 15px;
    }
    .devsServicesWrapper{
        gap: 8px;
        justify-content: center;
    }
    .devServicesCol {
        max-width: calc(25% - 8px);
        min-width: calc(25% - 8px);
    }
    .viewProfile{
        justify-content: center;
    }
    .devServicesCol{
        -webkit-box-shadow: 0px 0px 8px 0px rgba(82, 80, 237, 0.1);
        -moz-box-shadow: 0px 0px 8px 0px rgba(82, 80, 237, 0.1);
        box-shadow: 0px 0px 8px 0px rgba(82, 80, 237, 0.1);
    }
    .modal.show .modal-dialog {
        height: auto;
        // height: calc(100% - 24px);
        // margin-top: 30px;
        // margin-bottom: 30px;
    }
    .portfolioColWrap{
        // padding: 0;
    }
    .portfolioCol{
        padding: 0 7px 0 7px;
    }
    .portfolioDetailBtn {
        left: 7px;
    }
    .portfolioDetailBtn button {
        border-radius: 0 26px 0 26px !important;
    }
    .portfolioBody{
        flex-wrap: wrap;
        justify-content: center;
    }
    .rightColImg{
        display: flex;
        position: relative;
        width: 100%;
        justify-content: center;
        padding-top: 30px;
        left: unset !important;
        right: unset !important;
    }
    .rightColCntnt{
        display: none;
    }
    .leftColCntnt {
        width: 100%;
        height: auto;
    }
    .leftColPortfolio{
        padding-left: 80px;
        padding-right: calc(var(--bs-gutter-x) * .5);
    }
    .clientHand{
        display: none;
    }
    .clientsCol {
        padding: 0px 26px 0px 26px;
    }
    .contactFormMain{
        margin-bottom: 30px;
    }
    .rightColContentContact {
        padding-left: calc(var(--bs-gutter-x) * .5) !important;
    }
    .leftRightColWrap{
        flex-wrap: wrap;
        width: auto;
    }
    .leftColIcoGetQuote{
        max-width: 100%;
        min-width: 100%;
        height: auto;
    }
    .quoteEleWrapper{
        display: none;
    }
    .rightColContentQuote{
        width: 100%;
        max-width: 100%;
        padding-right: calc(var(--bs-gutter-x) * .5) !important;
        height: auto;
    }
    .processWrapper .union1, .processWrapper .union2{
        display: none;
    }
    .processIconsWrapper {
        height: auto;
        padding: 0px 0px 0 0px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 30px;
    }
    .processIcons2 .processNumber,
    .processIcons4 .processNumber,
    .processIcons6 .processNumber{
        top: -40px;
    }

    .processIcons2 .processNumber::after,
    .processIcons4 .processNumber::after,
    .processIcons6 .processNumber::after{
        bottom: -30px;
    }

    .processIcons1 .processNumber,
    .processIcons3 .processNumber,
    .processIcons5 .processNumber {
        bottom: unset;
        top: -40px;
    }

    .processIcons1 .processNumber::after,
    .processIcons3 .processNumber::after,
    .processIcons5 .processNumber::after {
        top: unset;
        bottom: -30px;
    }
    .processDetailHeading1, .processDetailHeading2, .processDetailHeading3, .processDetailHeading4, .processDetailHeading5, .processDetailHeading6{
        bottom: unset;
        top: -75px;
    }
    .processNumber::after {
        height: 30px;
    }
    .processIcons{
        margin-top: 70px;
    }
    .processText span {
        margin-bottom: 50px;
    }
    .banner-img-container{
        display: none;
    }
    .getQuote-wrap{
        padding-top: 40px;
    }
    .rtl .testimonialsHeading, .rtl .testimonialsDesig, .testimonialsText {
        text-align: center;
    }
    .imgQuote {
        right: 30px;
        top: 20px;
        width: 50px;
    }
    .techHdg{
        display: block;
    }
    .testimonialsHeading{
        text-align: center;
    }
    .testimonialsDesig{
        text-align: center;
    }
    .banner-content{
        padding-top: 0;
    }
    .item-wrapper{
        height: auto !important;
        padding: 0 !important;
    }
    .faqsHeading .headingContainer{
        text-align: left;
    }
    .faqsHeading{
        padding-right: 0 !important;
    }
}

@media (max-width: 768px) {
    /*
    .main-heading,
    .MainHeading {
        font-size: 35px !important;
    }

    .subHeading,
    .about-content-wrap>.cKSVes,
    .contact-wrap .jHzoaX {
        font-size: 28px !important;
    }

    .contact-text {

        font-size: 14px !important;
    }

    p {
        font-size: 15px !important;
    }

    .footer-logo {
        height: 60px !important;
    }

    .footer-content {
        gap: 0 !important;
        flex-direction: column;

        & div {
            margin: 2px 0 !important;
        }
    }
    */
}
@media (max-width: 767px) {
    .bannertTxtPrimary .bannertTxtPrimary1, .bannertTxtPrimary .bannertTxtPrimary2{
        white-space: unset;
    }
    .testimonialsColWrap .slick-slide > div{
        margin: 0px 0px 0 0px;
    }
    .testimonialsColWrap .slick-slide{
        padding: 0px 10px 0 10px;
    }
    .rightColContentQuote .optionListWrapper > * {
        flex: calc(50% - 20px) !important;
        width: calc(50% - 20px) !important;
        // max-width: calc(50% - 20px) !important;
    }
    .faqsHeading{
        flex-wrap: wrap;
        justify-content: center !important;
    }
    .faqsHeading .headingContainer{
        text-align: center;
        margin-bottom: 10px;
    }
    
}
@media (max-width: 640px) {
    .whyChooseUsContainer{
        flex-wrap: wrap;
        gap: 20px;
    }
    .whyChooseUsContainer > *{
        flex: 0 0 calc(33% - 15px);
        width: calc(33% - 15px);
        padding: 0;
        .whyChooseUsTxt{
            margin-top: 10px;
        }
    }
    .servicesCol {
        padding: 80px 10px 40px 10px;
    }
    .devsServicesWrapper {
        gap: 45px;
        justify-content: center;
        flex-wrap: wrap;
        .devServicesCol{
            min-width: 180px;
            max-width: 180px;
        }
    }
    .projectLogoName{
        flex-wrap: wrap;
        gap: 15px;
    }
    .leftRightColWrap.portfolio-wrap{
        flex-wrap: wrap;
    }
    .portfolioTechListWrapper{
        position: relative;
        width: 100%;
        flex-direction: row;
        justify-content: flex-start;
        
    }
    .portfolioTechListWrapper a.techList img{
        width: 47px;
        height: 47px;
    }
    .leftColPortfolio {
        padding-right: 0px;
        padding-left: 0px;
    }
    
}


@media (max-width: 576px) {
    .bannertTxtPrimary .bannertTxtPrimary1, .bannertTxtPrimary .bannertTxtPrimary2 {
        font-size: 27px !important;
    }
    .banner-content .bannertHeading {
        font-size: 38px;
        line-height: 50px;
    }
    .portfolioDetailBtn button {
        border-radius: 0 45px 0 45px !important;
    }
/*
    .banner-content {
        justify-content: center;
        align-items: center;
        padding-bottom: 35px !important;
        text-align: center;

        & p {
            text-align: center;
        }
    }

    .main-heading,
    .MainHeading {
        font-size: 30px !important;
    }

    .subHeading,
    .about-content-wrap>.cKSVes,
    .contact-wrap .jHzoaX {
        font-size: 24px !important;
    }

    .banner-items-2 {
        left: -20px !important;
        width: 130px;
    }

    .banner-items-1 {
        top: -10px !important;
        width: 130px;
    }

    .service-content-wrapper {
        text-align: center;

        & div {
            justify-content: center;
        }
    }

    .feature-box>div>div {
        text-align: center;
        justify-content: center;
    }

    .nav-bar button {
        display: none !important;
    }

    .banner-items-2 {
        left: 0px !important;
        width: 130px;
    }

    .banner-dash-img {
        width: 93%;
    }

    .space-bottom-100 {
        padding-bottom: 50px;
    }

    .space-100 {
        padding: 50px 0;
    }

    .service-content-wrapper>div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        & .gRGRCG {
            width: 140px;
        }
    }
    */
}

@media (max-width: 480px) {
    .whatsappBg{
        justify-content: center;
        flex-wrap: wrap;
        gap: 15px !important;
    }
    .whatsappButton{
        width: 100%;
        justify-content: center;
        button{
            width: 100%;
            justify-content: center;
        }
    }
    .tabWrapper{
        flex-wrap: wrap;
        &>div{
            width: 100%;
        }
        button{
            width: 100%;
        }
    }
    .processBorder{
        display: none !important;
    }
    .processSocialContainer{
        justify-content: center;
        flex-wrap: wrap;
    }
    .whyChooseUsContainer > *{
        flex: 0 0 calc(50% - 11px);
        width: calc(50% - 11px);
    }
    .portfolioDetailBtn button {
        border-radius: 0 40px 0 40px !important;
    }
    .rhap_container{
        width: 100% !important;
        padding: 14px 10px !important;
    }
    .field-box-wh{
        flex-wrap: wrap;
        gap: 0;
    }
    // .optionListWrapper > * {
    //     flex: 0 0 100% !important;
    //     width: 100% !important;
    // }
    .rightColContentQuote .optionListWrapper > * {
        flex: 100% !important;
        width: 100% !important;
        // max-width: calc(50% - 20px) !important;
    }
    .leftColIcoGetQuote, .rightColContentQuote{
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    // .tabTxt{
    //     margin-bottom: 20px;
    // }
    .processSocial {
        width: 100%;
        justify-content: center;
    }
    .faqsHeading .field-box-wh, .faqsHeading .field-box{
        width: 100% !important;
    }
    .faqsHeading .field-box input {
        width: 100% !important;
    }
    
}

@media (max-width: 420px) {

    .main-heading,
    .MainHeading,
    .service-content-wrapper .hXFhlD {
        font-size: 22px !important;
    }

    .banner-items-2 {
        left: 0px !important;
        width: 100px;
    }

    .banner-items-1 {
        top: -10px !important;
        width: 100px;
    }

    .subHeading,
    .about-content-wrap>.cKSVes,
    .contact-wrap .jHzoaX,
    .service-content-wrapper>span {
        font-size: 18px !important;
    }

    .about-bg>div {
        padding: 25px 0 !important;
    }

    .contact-row {
        padding: 70px 10px 0 !important;
    }
    .portfolioDetailBtn button {
        border-radius: 0 32px 0 32px !important;
    }

}
@media (max-width: 380px) {
    .whyChooseUsContainer > *{
        flex: 0 0 100%;
        width: 100%;
    }
    .portfolioDetailBtn button {
        border-radius: 0 28px 0 28px !important;
    }
    .clientsCol {
        padding: 0px 15px 0px 15px;
    }
}
@media (max-width: 350px) {
    .portfolioDetailBtn button {
        border-radius: 0 25px 0 25px !important;
    }
}

/***************** RTL DIrection*************************/


@media (max-width: 1900px) {
    
}
@media (max-width: 1800px) {
    
}
@media (max-width: 1700px) {
    
}
@media (max-width: 1600px) {
    
}
@media (min-width: 1550px) {
    
    
}

@media (max-width: 1500px) {
    
}
@media (max-width: 1440px) {
    .rtl .banner-content{
        padding-left: 110px;
    }
    .rtl .rightColContentQuote {
        padding: 10px 10px 0 30px;
    }
}
@media (max-width: 1399px) {
    
}
@media (max-width: 1350px) {
    
}

@media (max-width: 1299px) {
    

}

@media (max-width: 1250px) {
    
}

@media (max-width: 1199px) {
    .rtl .banner-content {
        padding-right: 0px;
        padding-left: 50px;
    }
}
@media (max-width: 1150px){
    .rtl .banner-content {
        padding-right: 0px;
        padding-left: 0px;
    }
}

@media (max-width: 1099px) {
    
}
@media (max-width: 1050px) {
    
}
@media (max-width: 991px) {
    .rtl .banner-wrap .slick-dots {
        margin-top: -20px;
    }
    .rtl .testimonialsText {
        text-align: center;
    }
    .rtl .bannertTxtPrimary .bannertTxtPrimary1, .rtl .bannertTxtPrimary .bannertTxtPrimary2 {
        font-size: 25px !important;
    }
    .rtl .faqsHeading{
        padding: 0 !important;
    }
    .rtl .faqsHeading .headingContainer{
        text-align: right;
    }
}

@media (max-width: 768px) {
    
}
@media (max-width: 767px) {
    .rtl .banner-content .bannertHeading {
        font-size: 30px;
        line-height: 50px;
        padding-bottom: 10px;
    }
    .rtl .bannertTxtPrimary .bannertTxtPrimary1, .rtl .bannertTxtPrimary .bannertTxtPrimary2 {
        font-size: 22px !important;
        width: 100%;
    }
    .rtl .faqsHeading .headingContainer{
        text-align: center;
    }
}
@media (max-width: 640px) {
    
    
}


@media (max-width: 576px) {
    
}

@media (max-width: 480px) {
    
    
}

@media (max-width: 420px) {

    

}
@media (max-width: 380px) {
    
}
@media (max-width: 350px) {
    
}




/****************Animation***************/

@media (min-width: 1550px) {
    
}
@media (max-width: 1549px) {
    
}
@media (max-width: 1500px) {
    
}
@media (max-width: 1440px) {
    
}
@media (max-width: 1399px) {
    
}
@media (max-width: 1350px) {
    
}

@media (max-width: 1299px) {
    
}

@media (max-width: 1250px) {
    
}

@media (max-width: 1199px) {
    
}
@media (max-width: 1150px){
    
}

@media (max-width: 1099px) {
    
}
@media (max-width: 1050px) {
    
}