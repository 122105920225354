@import "variables";
@import "fonts";
// or import scss

html {
  position: relative;
  width: 100%;
}

* {
//   margin: 0;
//   padding: 0;
  box-sizing: border-box;

}

body {
	position: relative !important;
	font-family: var(--regular);
	font-style: normal;
	font-size: 18px;
	background-color: var(--white);
	color: var(--darkBlue);
	width: 100%;
	height: 100vh;
	overflow: hidden;
	overflow-x: hidden;
	background-image: url('../images/png/bg_default.jpg');
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	//   animation: changeBackground 2s infinite ease-in-out; /* Animation properties */
	//animation: dissolve 6s infinite alternate;
	// animation: dissolve 5s infinite alternate;
}
body::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-size: cover;
	background-position: center;
	// background-image: url('../images/png/bg_default.jpg');
	z-index: -1; /* Ensure it's behind content */
	animation: dissolve 3s infinite alternate;
}
  
body::after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-size: cover;
	background-position: center;
	background-image: url('../images/png/bg_variant.jpg');
	z-index: -2; /* Ensure it's behind first image */
	opacity: 0; /* Initially invisible */
	animation: dissolve 3s infinite alternate;
}
  
@keyframes dissolve {
	0%, 100% { opacity: 1; }
	50% { opacity: 0; }
}
/*
@keyframes dissolve {
	0%, 100% { opacity: 1; }
	50% { opacity: 0; }
}
body::after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-size: cover;
	background-position: center;
	background-image: url('../images/png/bg_variant.jpg');
	animation: dissolve 5s infinite alternate;
	opacity: 0;
	z-index: -1;
  }
*/
  
/*
@keyframes dissolve {
	0%, 100% { opacity: 1; }
	50% { opacity: 0; }
}
  
  body {
	animation-name: first-image;
  }
  
  @keyframes first-image {
	0%, 100% { background-image: url('../images/png/bg_default.jpg'); }
  }
  
  body {
	animation-name: second-image;
  }
  
  @keyframes second-image {
	0%, 100% { background-image: url('../images/png/bg_variant.jpg'); }
}
*/
// @keyframes dissolve {
// 	0% { opacity: 1; background-image: url('../images/png/bg_default.jpg'); }
// 	50% { opacity: 0; background-image: url('../images/png/bg_variant.jpg'); }
// }


img {
  max-width: 100%;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  cursor: pointer;
}

a,
.btn {
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

a:focus,
.btn:focus {
  text-decoration: none;
  outline: none;
}

p {
  font-size: 15px;
  font-family: var(--regular);
  padding: 15px 0;
  margin-bottom: 0 !important;
}

*::-moz-selection {
  background: var(--bluePurple);
  color: #fff;
  text-shadow: none;
}

// *::-moz-selection {
//   background: var(--themeColor) none repeat scroll 0 0;
//   color: #fff;
//   text-shadow: none;
// }

*::selection {
  background-color: var(--bluePurple);
  color: #fff;
  text-shadow: none;

}

/******************Contact Form********************/

.field-box{
	display: flex;
	flex-direction: column;
	gap:8px;
	margin:0px 0 25px 0;
	position: relative;
	width: 100%;
	img{
		position: absolute;
		left: 15px;
		top: 11px;
	}
}
.input-field{
	padding: 11px 15px 11px 50px;
	border-radius: 10px;
	border: none;
	outline: none;
	width: 100%;
	font-size: 15px;
	color:var(--darkBlue);
	background:  var(--bgWhiteGradient2);
	border: 1px solid  rgba(255, 255, 255, .5);
	box-shadow: 0px 0px 9px 0px rgba(152,111,31,0.2);
	-webkit-box-shadow: 0px 0px 9px 0px rgba(152,111,31,0.2);
	-moz-box-shadow: 0px 0px 9px 0px rgba(152,111,31,0.2);

}

.input-field::placeholder{
	color:var(--textPalceholder);
}
.error-message{
font-family:var(--regular);
// border:1px solid red;
color:red;
// padding:6px 8px;
padding: 0;
border-radius:8px;
font-size: 13px;
// background-color: #ff000014;
position: absolute;
left: 0;
bottom: -19px;
}
.rtl .error-message{
	font-family:var(--arRegular);
	right: 0;
	left: unset;
}
/* .loading-btn{
position: absolute;
left: 0;
right: 0;
width: 50%;
height: 20%;
z-index: 10;
background: #0b0b0b6b;
text-align: center;
top: 0;
bottom: 0;
margin: auto;
display: flex;
align-items: center;
justify-content: center;
color:var(--whiteColor);
font-family:var(--regular);

} */
.loading-btn{
	position: fixed;
	left: 0;
	right: 0;
	width: 500px;
	height: 300px;
	z-index: 10;
	background: #0b0b0b6b;
	text-align: center;
	top: 0;
	bottom: 0;
	margin: auto;
	display: flex;
	align-items: center;
	justify-content: center;
	color:var(--whiteColor);
	font-family:var(--regular);
}
.loading-btn {
    font-size: 18px;
    font-family: var(--semiBold);
    color: var(--darkBlue) !important;
}
.spinner {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    border: 3px solid;
    border-color: var(--white) transparent;
    animation: spin 1s infinite ease-out;
}
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.contact-social-links{
position: relative;
left: 15px;
}
.field-box-wh {
display: flex;
width: 100%;
gap: 16px;
}
.btnMed{
svg{
	width: 32px;
	height: 32px;
}
}

/*************************************/




.container-fluid{
	// padding-left: 30px;
	// padding-right: 30px;
	// max-width: 1500px;
	position: relative;
	// z-index: 5;
}
.container-fluid > .container{
	display: block;
	width: 100%;
	max-width: 100%;
	padding: 0;
}
section{
	display: flex;
	width: 100%;
	// direction: rtl;
}
.nav-bar-right{
	display: flex;
	align-items: center;
	gap: 20px;
}
.nav-bar-btns{
	display: flex;
	width: fit-content;
}
.header-btn{
	display: flex;
	gap: 7px;
	align-items: center;
	font-size: 16px;
	font-family: var(--semiBold);
	color: var(--darkBlue);
	border-radius: 21px;
	padding: 7px 12px 7px 8px;
	background: linear-gradient(120deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 100%);
	border: 1px solid var(--white);
	/* Add the blur effect */
	backdrop-filter: blur(10px);
	-webkit-backdrop-filter: blur(10px);
}
.header-btn img{
	max-width: 25px;
}
.get-a-quote{
	font-weight: normal !important;
	font-family: var(--semiBold) !important;
	white-space: nowrap;
}
.banner-content-col{
	display: flex;
	// align-items: center;
	padding-top: 30px;
}
.banner-content{
	padding-left: 30px;
	padding-right: 90px;
	padding-bottom: 50px;
	padding-top: 30px;
	.bannertTxtPrimary{
		display: block;
		width: 100%;
		.bannertTxtPrimary1{
			font-size: 35px;
			color: var(--darkBlue);
			line-height: 40px;
		}
		.bannertTxtPrimary2{
			font-size: 35px;
			color: var(--primary);
			font-family: var(--bold);
			display: unset;
		}
	}
	.bannertHeading{
		display: flex;
		width: 100%;
		font-size: 73px;
		font-family: var(--bold);
		background: var(--bgGradient);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		line-height: 85px;
	}
	.bannertTxtSecondary{
		display: flex;
		width: 100%;
		font-size: 16px;
		color: var(--darkBlue);
		margin-bottom: 30px;
	}
	
}
.banner-img-container{
	display: flex;
	justify-content: center;
	.banner-img{
		display: flex;
		width: fit-content;
		position: relative;
		img{
			max-width: 250px;
			position: relative;
			z-index: 5;
			/* Add the blur effect */
			backdrop-filter: blur(6px);
			-webkit-backdrop-filter: blur(6px);
		}
	}
}


.main-artical{
	height: 100vh;
}

.element{
	position: absolute;
	height: auto;
}
.element1{
	z-index: 6;
	width: 80px;
	top: 144px;
	left: -13px;
}
.element2{
	z-index: 4;
	width: 80px;
	top: 50px;
	right: -23px;
}
.element3{
	z-index: 4;
	width: 50px;
	top: 320px;
	left: -126px;
}
.element4{
	z-index: 4;
	width: 130px;
	top: 270px;
	right: -58px;
}
.element5{
	z-index: 4;
	width: 40px;
	top: 1px;
	right: -170px;
}



.site_loader{
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
}



/****************Slick Slider Customize Start*****************/

.slick-prev, .slick-next{
	width: 28px !important;
	height: 28px !important;
}
.banner-wrap .slick-dots{
	bottom: 35px;
	width: fit-content;
	padding-left: 30px;
	position: relative;
	float: left;
	display: none !important;
}
.banner-wrap .slick-dots:after{
	content: '';
	position: absolute;
	left: 45px;
  	right: 32px;
	height: 1px;
	background-color: var(--white);
	top: 0;
	bottom: 0;
	margin: auto;
	z-index: -1;
}
.banner-wrap .slick-dots li{
	float: left;
	width: auto;
	height: auto;
	margin-right: 20px;
}

.banner-wrap .slick-dots li button{
	float: left;
	border-radius: 10px;
	width: 24px;
	height: 24px;
	background: transparent;
	border: 1px solid transparent;
	
}

.banner-wrap .slick-dots li button::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	margin: auto;
	width: 14px;
	height: 14px;
	text-align: center;
	background: var(--white);
	border-radius: 50%;
	opacity: 1;
	-webkit-box-shadow: 0px 10px 10px 0px rgba(82,80,237,0.2);
	-moz-box-shadow: 0px 10px 10px 0px rgba(82,80,237,0.2);
	box-shadow: 0px 10px 10px 0px rgba(82,80,237,0.2);
}

.banner-wrap .slick-dots li.slick-active button{
	background: linear-gradient(120deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 100%);
	border: 1px solid var(--white);
	/* Add the blur effect */
	backdrop-filter: blur(10px);
	-webkit-backdrop-filter: blur(10px);
	-webkit-box-shadow: 0px 10px 10px 0px rgba(82,80,237,0.2);
	-moz-box-shadow: 0px 10px 10px 0px rgba(82,80,237,0.2);
	box-shadow: 0px 10px 10px 0px rgba(82,80,237,0.2);
}

.banner-wrap .slick-dots li.slick-active button::before {
	background: var(--bgGradient);
}


/****************Slick Slider Customize End*****************/

.section-common{
	height: calc(100vh - 82px);
	display: flex;
	align-items: center;
	// background-color: rgb(244, 246, 248);
}
.section-common > .container-fluid{
	height: 520px;
	// background-color: rgba(250, 247, 251, .3);
}
.leftRightColWrap{
	display: flex;
	width: 100%;
	height: 100%;
}
.leftColIco{
	flex: 0 0 auto;
    width: 40%;
	// min-width: 390px;
	min-width: 576px;
	
	// background-color: aliceblue;
}
.rightColContent{
	flex: 1 1 auto;
    width: 60%;
	max-width: calc(100% - 576px);
	// display: flex;
	// flex-wrap: wrap;
	// flex-direction: column;
	// justify-content: center;
}
.modal-xl{
	margin-top: 12px;
	margin-bottom: 12px;
	max-width: 1500px;
}
.modal-xl .modal-content{
	height: calc(100vh - 24px);
	background: linear-gradient(120deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 100%);
	border: 1px solid rgba(255, 255, 255, .5);
	/* Add the blur effect */
	backdrop-filter: blur(60px);
	-webkit-backdrop-filter: blur(60px);
	border-radius: 30px;
	min-height: calc(600px - 24px);
}
.modal-backdrop.show{
	// opacity: 0;
	opacity: 1;
	background: rgba(255, 255, 255, 0.05);
	backdrop-filter: blur(8px);
	-webkit-backdrop-filter: blur(8px);
	// background: linear-gradient(120deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 100%);
}
button.crossModal{
	padding: 0;
	background: transparent;
	border: 0;
	position: absolute;
	right: 20px;
	top: 20px;
	z-index: 500;
	
}
button.crossModal:hover{
	background: transparent;
	border: 0;
	opacity: .8;
}
button.crossModal svg{
	width: 35px;
	height: 35px;
}
button.btnPryMedium{
	border-radius: 18px !important;
}
.btnPryMedium svg{
	width: 30px;
	height: 30px;
	
}

/********************About************************/

.about-img{
	max-width: 375px;
	position: relative;
	bottom: -800px;
	left: 100px;
	opacity: 0;
}
.tabWrapper{
	display: flex;
	width: 100%;
	align-items: center;
	gap: 20px;
	margin-top: 20px;
	margin-bottom: 30px;
}
.tabTxt{
	margin-bottom: 40px;
}
.leftColAbout{
	padding-top: 30px;
	position: relative;
	z-index: 1;
	display: flex;
  	align-items: center;
}
.sliderContainer{
	display: block;
	width: 100%;
	padding-left: 25px;
	padding-right: 25px;
}
.expertiseCol{
	display: flex !important;
	width: 100%;
	flex-wrap: wrap;
	padding: 0 7px 0 7px;
	justify-content: flex-start;
	align-items: center;
	flex-direction: column;
}
.expertiseImg{
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100px;
	height: 100px;
	border-radius: 30px;
	background: linear-gradient(120deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 100%);
	border: 1px solid var(--white);
	/* Add the blur effect */
	backdrop-filter: blur(10px);
	-webkit-backdrop-filter: blur(10px);
}
.expertiseImg img{
	display: flex;
	max-width: 40px;
	max-height: 40px;
}
.expertiseTxt{
	font-size: 14px;
	color: var(--darkBlue);
	width: 100%;
	text-align: center;
	margin-top: 20px;
}

.whyChooseUsContainer{
	display: flex;
	width: 100%;
}
.whyChooseUsCol{
	display: flex !important;
	width: 100%;
	flex-wrap: wrap;
	padding: 0 5px 0 5px;
	justify-content: flex-start;
	align-items: center;
	flex-direction: column;
}
.whyChooseUsImg{
	display: flex;
	justify-content: center;
	align-items: center;
	width: 85px;
	height: 85px;
	border-radius: 30px;
	background: linear-gradient(120deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 100%);
	border: 1px solid var(--white);
	/* Add the blur effect */
	backdrop-filter: blur(10px);
	-webkit-backdrop-filter: blur(10px);
}
.whyChooseUsImg img{
	display: flex;
	max-width: 40px;
	max-height: 40px;
}
.whyChooseUsTxt{
	font-size: 14px;
	color: var(--darkBlue);
	width: 100%;
	text-align: center;
	margin-top: 20px;
}


 /*************************Our Process**************************/


.processText span{
	width: 100%;
	margin-bottom: 100px;
}
.processWrapper {
	display: flex;
	width: 100%;
	position: relative;
	padding: 0 0px 0 0px;
}
.processWrapper .union1, .processWrapper .union2{
	position: absolute;
	width: 100%;
	height: auto;
}
.processWrapper .union1{
	z-index: 2;
}
.processWrapper img.processImg {
	width: 100%;
	height: auto;
}

.processIconsWrapper {
	display: grid;
	// position: absolute;
	// top: 0;
	// bottom: 0;
	// left: 0;
	// right: 0;
	width: 100%;
	height: 236px;
	z-index: 10;
	padding: 36px 11px 0 11px;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}

.processIcons {
	position: relative;
	display: flex;
	justify-content: center;
	// padding-top: 33px;
	// padding-bottom: 33px;
}

.processIcon {
	position: relative;
	display: flex;
	justify-content: center;
	padding: 16px;
	height: 164px;
	width: 164px;
	border-radius: 50%;
	background: rgb(255, 255, 255);
}

.processIconInner {
	display: flex;
	height: 100%;
	width: 100%;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
}

.processIconInner img {
	max-width: 80px;
	max-height: 80px;
}


.processDetailWrapper {
	position: relative;
}

.processDetailWrapperRes {
	display: none;
}

.processDetailWrapperInner {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	position: absolute;
	bottom: 60px;
}

// .processDetailContainer {
// 	padding: 0 45px 0 45px;
// }

.PRRow{
	padding-top: 20px;
}
.PRRow .primaryHeading {
	
}

.processNumber {
	position: absolute;
	
	width: 24px;
	height: 24px;
	
	font-size: 16px;
	border-radius: 50%;
	font-family: var(--semiBold);
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: var(--white);
}
.processNumber span{
	background: var(--bgGradient);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	padding-top: 1px;
}
.processNumber::after {
	content: '';
	width: 2px;
	height: 50px;
	position: absolute;
	left: 0;
	right: 0;

	margin: auto;
	background-color: var(--white);
}

.processIcons2 .processNumber,
.processIcons4 .processNumber,
.processIcons6 .processNumber{
	top: -73px;
}

.processIcons2 .processNumber::after,
.processIcons4 .processNumber::after,
.processIcons6 .processNumber::after{
	bottom: -50px;
}

.processIcons1 .processNumber,
.processIcons3 .processNumber,
.processIcons5 .processNumber {
	bottom: -73px;
}

.processIcons1 .processNumber::after,
.processIcons3 .processNumber::after,
.processIcons5 .processNumber::after {
	top: -50px;
}
.processDetailHeading{
	position: absolute;
	left: 0;
	right: 0;
	text-align: center;
	font-size: 16px;
	color: var(--darkBlue);
	font-family: var(--semiBold);
}
.processDetailHeading2, .processDetailHeading4, .processDetailHeading6{
	top: -103px;
}
.processDetailHeading1, .processDetailHeading3, .processDetailHeading5{
	bottom: -103px;
}
/* 
.processDetailContainer1 {
	padding-left: 0;
}

.processDetailContainer4 {
	padding-right: 0;
}

.processDetailContainer2 {
	padding: 0 45px 0 35px;
}

.processDetailContainer3 {
	padding: 0 45px 0 57px;
}

.processDetailContainer4 {
	padding: 0 14px 0 54px
}

.processDetailWrapperBottom .processDetailWrapperInner {
	display: flex;
	justify-content: center;
	bottom: auto;
	top: 60px;
	width: 100%;
}
/* 
.processDetailWrapperBottom .processDetailWrapperInner .processDetailContainer {
	width: 25%;
}

.processDetailContainer5 {
	padding: 0 42px 0 20px;
}

.processDetailContainer6 {
	padding: 0 30px 0 30px;
}

.processDetailContainer7 {
	padding: 0 50px 0 50px;
}
 */

/**************************Services*****************************/

.TabsServicesWrapper{
	padding-top: 40px;
}
.leftColIcoServices, .rightColContentServices{
	height: 520px;
}
// .rightColContentServices{
// 	padding-right: 40px;
// }
.servicesColWrap{
	display: block;
	width: 100%;
	gap: 20px;
	padding: 0px 14px 0 14px;
}
.servicesCol{
	display: flex;
	width: 100%;
	flex-wrap: wrap;
	border-radius: 40px;
	position: relative;
	padding: 80px 16px 60px 16px;
	height: 100%;
	background: var(--bgWhiteGradient);
	backdrop-filter: blur(10px);
	-webkit-backdrop-filter: blur(10px);
	animation: serCol 2s forwards ease-in-out;
}
@keyframes serCol {
	0% { 
		transform: scale(0);
		opacity: 0;
	}
	70% { 
		transform: scale(1.05);
		opacity: 1;
	}
	100% { 
		transform: scale(1);
		opacity: 1;
	}
}
.servicesIcon{
	position: absolute;
	top: -58px;
	left: 0;
	right: 0;
	display: flex;
	justify-content: center;
	animation: serImgIcon 2s forwards ease-in-out;
	animation-delay: 1.5s;
	transform: scale(0);
}
.servicesIcon img{
	max-width: 130px;
}
@keyframes serImgIcon {
	0% { 
		transform: scale(0);
		opacity: 0;
	}
	70% { 
		transform: scale(1.1);
		opacity: 1;
	}
	100% { 
		transform: scale(1);
		opacity: 1;
	}
}



.mainMainTxt{
	padding: 0 150px 0 150px;
}
.servicesHeading{
	display: block;
	width: 100%;
	text-align: center;
	font-family: var(--semiBold);
	color: var(--secondary);
	font-size: 18px;
}
.servicesText{
	display: block;
	width: 100%;
	text-align: center;
	color: var(--darkBlue);
	font-size: 15px;
	padding: 8px 10px 0px 10px;
	line-height: 24px;
}
.detailBtn{
	position: absolute;
	bottom: -22px;
	left: 0;
	right: 0;
	display: flex;
	justify-content: center;
}
.devsServicesWrapper{
	gap: 20px;
	margin: 20px 0 30px 0;
}
.devServicesCol{
	display: flex;
	max-width: calc(15.99% - 8px);
	min-width: calc(15.99% - 8px);
	border-radius: 25px;
	background-color: var(--white);
	position: relative;
}
.devServicesImg{
	position: absolute;
	top: -24px;
	left: 0;
	right: 0;
	display: flex;
	justify-content: center;
}
.devServicesImg img{
	max-width: 40px;
	height: auto;
}
.devServicesTitle{
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	padding: 46px 2px 30px 2px;
	color: var(--darkBlue);
	font-family: var(--semiBold);
	font-size: 16px;
	
}
.leftColIcoServices{
	display: flex;
	align-items: center;
	position: relative;
	margin-top: 50px;
	
}
// .leftColIcoServices > *{
// 	z-index: 1100;
// }
.leftColIcoServices .servicesModImg{
	z-index: 1110;
}

.devsServicesWrapper{
	display: flex;
    width: 100%;
}
.servicesColWrap .slick-slide > div{
	margin: 0px 10px 0 10px;
	height: 100%;
}
.servicesColWrap .slick-list{
	padding-top: 50px;
	padding-bottom: 16px;
}
.mainWrapper .slick-track{
	display: flex;
	padding-bottom: 22px;
}
.mainWrapper .slick-track .slick-slide{
	height: auto;
}


/**************************Team******************************/

.teamColWrap{
	display: block;
	width: 100%;
	gap: 20px;
	padding: 20px 14px 0 14px;
}
.teamCol{
	display: flex;
	width: 100%;
	flex-wrap: wrap;
	position: relative;
	padding: 0px 16px 0px 16px;
	margin: 0 10px 0 10px;
}
.teamIcon{
	display: flex;
	justify-content: center;
}
.teamIcon img{
	max-width: 100%;
	min-width: 100%;
	animation: teamImg 2s forwards ease-in-out;
	transform: scale(0);
}

@keyframes teamImg {
	0% { 
		transform: scale(0);
		opacity: 0;
	}
	70% { 
		transform: scale(1.1);
		opacity: 1;
	}
	100% { 
		transform: scale(1);
		opacity: 1;
	}
}

.teamHeading{
	display: flex;
	width: 100%;
	text-align: center;
	justify-content: center;
	font-family: var(--semiBold);
	margin-top: 15px;
	background: var(--bgGradient);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	font-size: 16px;
}
.teamText{
	display: flex;
	width: 100%;
	text-align: center;
	justify-content: center;
	margin-top: 3px;
	font-size: 13px;
	
}


/**************************Portfolio******************************/

.portfolioColWrap{
	display: block;
	width: 100%;
	gap: 20px;
	padding: 20px 14px 0 14px;
}
.portfolioCol{
	display: flex;
	width: 100%;
	flex-wrap: wrap;
	position: relative;
	padding: 0px 10px 0px 10px;
	// margin: 0 10px 0 10px;
	
}
.leftRightColWrap{
	flex-wrap: nowrap;
}
.leftColCntnt, .rightColCntnt{
	height: 520px;
}
.leftColCntnt{
	flex: 0 0 auto;
  	width: 60%;
}
.leftColPortfolio .leftColCntntRow{
	flex-direction: column;
	height: 100%;
}
.rightColCntnt{
	flex: 0 0 auto;
  	width: 40%;
}
.rightColCntnt img{
	max-width: 100%;
	max-height: 100%;
}
.rightColImg{
	position: absolute;
	right: 0;
	bottom: 0;
	top: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: -1;
}
.rightColImg img{
	max-width: 100%;
	height: auto;
}
.rightColImg.rightColImgBeep{
	max-width: 450px;
	right: 50px;
}
.portfolioBody{
	display: flex;
	align-items: center;
}


.portfolioIcon{
	display: flex;
	justify-content: center;
}
.portfolioIcon img{
	max-width: 100%;
	min-width: 100%;
	animation: teamImg 2s forwards ease-in-out;
	transform: scale(0);
}

@keyframes teamImg {
	0% { 
		transform: scale(0);
		opacity: 0;
	}
	70% { 
		transform: scale(1.05);
		opacity: 1;
	}
	100% { 
		transform: scale(1);
		opacity: 1;
	}
}

.portfolioDetailBtn{
	position: absolute;
	bottom: 0;
	left: 10px;
	display: flex;
	justify-content: center;
	
}
.portfolioDetailBtn button{
	background: var(--white) !important;
	border-radius: 0 34px 0 34px !important;
	padding: 13px 40px 12px 22px !important;
}
.portfolioDetailBtn button span{
	color: var(--darkBlue) !important;
}
.portfolioDetailBtn button:hover{
	opacity: 1 !important;
	background: var(--darkBlue) !important;
}
.portfolioDetailBtn button:hover span{
	color: var(--white) !important;
}
.projectLogoName{
	display: flex;
	width: 100%;
	align-items: center;
	gap: 25px;
	margin-bottom: 20px;
}
.projectModTitle{
	font-size: 26px;
	font-family: var(--semiBold);
	background: var(--bgGradient);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}
.projectLogo svg{
	width: 100px;
	height: auto;
	max-height: 100px;
}
.smallTitleHeading{
	display: flex;
	width: 100%;
	font-family: var(--semiBold);
	color: var(--secondary);
	font-size: 18px;
	margin-bottom: 3px;
}
.sthDesc{
	display: block;
	width: 100%;
	margin-bottom: 20px;
}
.techHdg{
	display: none;
}
ul.portfolioListWrapper{
	display: block;
	width: 100%;
	margin: 0;
	padding: 0;
}
ul.portfolioListWrapper li{
	padding-left: 20px;
	background-image: url(../images/svg/listArrow.svg);
	background-size: 8px auto;
	background-position: 1px 7px;
	background-repeat: no-repeat;
	margin-bottom: 10px;
}
ul.portfolioListWrapper li:last-child{
	margin-bottom: 0;
}
.portfolioVisitListWrapper{
	display: flex;
	width: 100%;
	gap: 12px;
	padding-top: 2px;
}
.portfolioVisitListWrapper a.visitLink{
	display: block;
}
.portfolioVisitListWrapper a.visitLink img{
	display: block;
	width: 47px;
}



.portfolioTechListWrapper{
	position: absolute;
	width: 60px;
	top: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	gap: 15px;
	right: 5px;
	flex-wrap: wrap;
	flex-direction: column;
	justify-content: center;
	padding: 0;
}
.portfolioTechListWrapper a.techList{
	display: block;
}
.portfolioTechListWrapper a.techList img{
	display: block;
	width: 60px;
}

// .slick-initialized .slick-slide

/**************************Clients******************************/

.clientsColWrap{
	display: block;
	width: 100%;
	gap: 20px;
	padding: 40px 14px 0 14px;
}
.clientsCol{
	display: flex;
	width: 100%;
	flex-wrap: wrap;
	position: relative;
	padding: 0px 46px 0px 46px;
	// margin: 0 10px 0 10px;
}

.clientsIcon{
	display: flex;
	justify-content: center;
}
.clientsIcon img{
	max-width: 100%;
	min-width: 100%;
	animation: clientImg 2s forwards ease-in-out;
	transform: scale(0);
}

@keyframes clientImg {
	0% { 
		transform: scale(0);
		opacity: 0;
	}
	70% { 
		transform: scale(1.05);
		opacity: 1;
	}
	100% { 
		transform: scale(1);
		opacity: 1;
	}
}

.clientsColWrap .slick-slide {
	display: flex !important;
	align-items: center;
	justify-content: center;
}
.clientHand{
	position: absolute;
	left: -254px;
	bottom: -50px;
	max-width: 300px;
}
.mainWrapper .clientsColWrap .slick-track{
	padding-bottom: 0;
}



/**************************Testimonials*****************************/

.TabsServicesWrapper{
	padding-top: 40px;
}
.leftColIcoServices, .rightColContentServices{
	height: 520px;
}
.testimonialsColWrap{
	display: block;
	width: 100%;
	gap: 20px;
	padding: 0px 14px 0 14px;
}
.testimonialsCol{
	display: flex;
	width: 100%;
	flex-wrap: wrap;
	border-radius: 40px;
	position: relative;
	padding: 70px 20px 60px 20px;
	height: 100%;
	background: var(--bgWhiteGradient);
	backdrop-filter: blur(10px);
	-webkit-backdrop-filter: blur(10px);
	animation: serCol 2s forwards ease-in-out;
	position: relative;
}
@keyframes serCol {
	0% { 
		transform: scale(0);
		opacity: 0;
	}
	70% { 
		transform: scale(1.05);
		opacity: 1;
	}
	100% { 
		transform: scale(1);
		opacity: 1;
	}
}
.testimonialsIcon{
	position: absolute;
	top: -50px;
	left: 0;
	right: 0;
	margin: auto;
	width: 100px;
	height: 100px;
	display: flex;
	justify-content: center;
	animation: serImgIcon 2s forwards ease-in-out;
	animation-delay: 1.5s;
	transform: scale(0);
	border-radius: 50%;
	overflow: hidden;
}
.testimonialsIcon img{
	width: 100%;
	height: 100%;
	object-fit: cover;
	
}
@keyframes serImgIcon {
	0% { 
		transform: scale(0);
		opacity: 0;
	}
	70% { 
		transform: scale(1.1);
		opacity: 1;
	}
	100% { 
		transform: scale(1);
		opacity: 1;
	}
}

.imgQuote{
	position: absolute;
	right: 10px;
	top: 50px;
	width: 90px;
	z-index: -1;
}
.imgQuote svg{
	width: 100%;
	height: auto;
}
.testimonialsHeading{
	font-size: 16px;
	font-family: var(--semiBold);
	color: var(--purple);
}
.testimonialsDesig{
	font-size: 13px;
	font-family: var(--regular);
	color: var(--grey1);
}

.testimonialsText{
	display: block;
	width: 100%;
	color: var(--darkBlue);
	font-size: 15px;
	padding: 15px 0px 0px 0px;
	line-height: 24px;
}
.detailBtn{
	position: absolute;
	bottom: -22px;
	left: 0;
	right: 0;
	display: flex;
	justify-content: center;
}
.devsServicesWrapper{
	gap: 20px;
	margin: 20px 0 30px 0;
}
.devServicesCol{
	display: flex;
	max-width: calc(15.99% - 8px);
	min-width: calc(15.99% - 8px);
	border-radius: 25px;
	background-color: var(--white);
	position: relative;
}
.devServicesImg{
	position: absolute;
	top: -24px;
	left: 0;
	right: 0;
	display: flex;
	justify-content: center;
}
.devServicesImg img{
	max-width: 40px;
	height: auto;
}
.devServicesTitle{
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	padding: 46px 2px 30px 2px;
	color: var(--darkBlue);
	font-family: var(--semiBold);
	font-size: 16px;
	text-align: center;
	
}
.leftColIcoServices{
	display: flex;
	align-items: center;
	position: relative;
	margin-top: 50px;
	
}
// .leftColIcoServices > *{
// 	z-index: 1100;
// }
.leftColIcoServices .servicesModImg{
	z-index: 1110;
}

.devsServicesWrapper{
	display: flex;
    width: 100%;
}
.testimonialsColWrap .slick-slide > div{
	margin: 0px 10px 0 10px;
	height: 100%;
}
.testimonialsColWrap .slick-list{
	padding-top: 50px;
	padding-bottom: 16px;
}
.mainWrapper .slick-track{
	display: flex;
	padding-bottom: 22px;
}
.mainWrapper .slick-track .slick-slide{
	height: auto;
}
.rhap_controls-section{
	display: none !important;
}
.projectLogo svg.portfolioModLogoJay, .projectLogo svg.portfolioModLogoSahab, .projectLogo svg.portfolioModLogoAHouse{
	width: 150px;
}


/***************************Modal Element***********************/

.modalElement{
	position: absolute;
	height: auto;
	// z-index: 5;
}
.modalElement2{
	border-radius: 100px;
	width: 275px;
	height: 275px;
	left: 0;
	bottom: 0;
	right: 0;
	top: 0;
	margin: auto;
	transform: rotate(66deg);
	background: var(--bgGradient);
}
.modalElement2:before{
	content: "";
	position: absolute;
	z-index: 4;
	width: 375px;
	height: 375px;
	top: -50px;
	right: -50px;
	border-radius: 120px;
	transform: rotate(0deg);
	background: transparent;
	border: 1px solid var(--bluePurple);
}

.modalElement2:after{
	content: "";
	position: absolute;
	z-index: 4;
	width: 326px;
	height: 326px;
	top: -24px;
	right: -26px;
	border-radius: 102px;
	transform: rotate(0deg);
	background: transparent;
	border: 1px solid var(--bluePurple);
	animation: modElem2 2.95s forwards ease-in-out;
}

@keyframes modElem2 {
	0% { 
		width: 326px;
		height: 326px;
		top: -24px;
		right: -26px;
		border-radius: 102px;
		transform: rotate(0deg);
		opacity: 0;
	}
	99% { 
		width: 326px;
		height: 326px;
		top: -24px;
		right: -26px;
		border-radius: 102px;
		transform: rotate(0deg);
		opacity: 0;
	}
	100% { 
		width: 326px;
		height: 326px;
		top: -24px;
		right: -26px;
		border-radius: 102px;
		transform: rotate(0deg);
		opacity: 1;
	}
}


.servicesModImg{
	position: absolute;
	// z-index: 10;
}
.servicesModImgMd{
	width: 390px;
	top: 39px;
	left: 76px;
	animation: modImgMd 3s forwards ease-in-out;
}

@keyframes modImgMd {
	0% { 
		transform: scale(0);
		opacity: 0;
	}
	70% { 
		transform: scale(1.1);
		opacity: 1;
	}
	100% { 
		transform: scale(1);
		opacity: 1;
	}
}

.servicesModImgWd{
	width: 370px;
	top: 70px;
	left: 104px;
	animation: modImgWd 3s forwards ease-in-out;
}

@keyframes modImgWd {
	0% { 
		transform: scale(0);
		opacity: 0;
	}
	70% { 
		transform: scale(1.1);
		opacity: 1;
	}
	100% { 
		transform: scale(1);
		opacity: 1;
	}
}

.servicesModImgUiUx{
	width: 370px;
	top: 90px;
	left: 100px;
	animation: modImgUiUx 3s forwards ease-in-out;
}

@keyframes modImgUiUx {
	0% { 
		transform: scale(0);
		opacity: 0;
	}
	70% { 
		transform: scale(1.1);
		opacity: 1;
	}
	100% { 
		transform: scale(1);
		opacity: 1;
	}
}
.servicesModImgSocial{
	width: 370px;
	top: 70px;
	left: 106px;
	animation: modImgSocial 3s forwards ease-in-out;
}

@keyframes modImgSocial {
	0% { 
		transform: scale(0);
		opacity: 0;
	}
	70% { 
		transform: scale(1.1);
		opacity: 1;
	}
	100% { 
		transform: scale(1);
		opacity: 1;
	}
}
.servicesFeather{
	width: 220px;
	right: -50px;
	top: -160px;
}
.servicesEleImg{
	position: absolute;
	z-index: 20;
}
.servicesEleImg1{
	top: 30px;
	left: 330px;
	max-width: 80px;
	height: auto;
}
.servicesEleImg2{
	top: 425px;
	left: 210px;
	max-width: 70px;
	height: auto;
}
.servicesEleImg3{
	top: 70px;
	left: 60px;
	max-width: 110px;
	height: auto;
}
.servicesEleImg4{
	top: -40px;
	left: 280px;
	max-width: 70px;
	height: auto;
}
.servicesEleImg5{
	top: 15px;
	left: 310px;
	max-width: 70px;
	height: auto;
}
.servicesEleImg6{
	top: 433px;
	left: 200px;
	max-width: 100px;
	height: auto;
}

.servicesEleImg1, .servicesEleImg2, .servicesEleImg3, .servicesEleImg4, .servicesEleImg5, .servicesEleImg6{
	animation: servicesEleImgAll 3s forwards ease-in-out;
}

@keyframes servicesEleImgAll {
	0% { 
		opacity: 0;
	}
	100% { 
		opacity: 1;
	}
}


.modalElement4{
	position: relative;
	border-radius: 50px;
	width: 140px;
	height: 140px;
	left: 0;
	bottom: 0;
	right: 0;
	top: 65px;
	margin: auto;
	transform: rotate(22deg);
	background: var(--bgGradient);
}
.modalElement4:before{
	content: "";
	position: absolute;
	z-index: 4;
	width: 190px;
	height: 190px;
	top: -25px;
	right: -25px;
	border-radius: 70px;
	transform: rotate(0deg);
	background: transparent;
	border: 1px solid var(--bluePurple);
}

.modalElement4:after{
	content: "";
	position: absolute;
	z-index: 4;
	width: 240px;
	height: 240px;
	top: -49px;
  	right: -50px;
	border-radius: 90px;
	transform: rotate(0deg);
	background: transparent;
	border: 1px solid var(--bluePurple);
	animation: modElem4 2.95s forwards ease-in-out;
}
/* 
@keyframes modElem4 {
	0% { 
		width: 228px;
		height: 228px;
		top: -49px;
		right: -50px;
		border-radius: 90px;
		transform: rotate(0deg);
		opacity: 0;
	}
	99% { 
		width: 228px;
		height: 228px;
		top: -49px;
		right: -50px;
		border-radius: 90px;
		transform: rotate(0deg);
		opacity: 0;
	}
	100% { 
		width: 228px;
		height: 228px;
		top: -49px;
		right: -50px;
		border-radius: 90px;
		transform: rotate(0deg);
		opacity: 1;
	}
} */
.quoteEleWrapper{
	position: relative;
	width: 100%;
	height: 390px;
}




.leftColIcoGetQuote, .rightColContentQuote{
	height: 520px;
}
.leftColIcoGetQuote{
	max-width: 576px;
}
.rightColContentQuote{
	padding: 10px 30px 0 10px;
}
.modalQuoEle1, .modalQuoEle2, .modalQuoEle3, .modalQuoEle4{
	z-index: 5;
}

.scaleAnim{
	animation: scaleAnim 3s forwards ease-in-out;
}

@keyframes scaleAnim {
	0% { 
		transform: scale(0);
		opacity: 0;
	}
	70% { 
		transform: scale(1.1);
		opacity: 1;
	}
	100% { 
		transform: scale(1);
		opacity: 1;
	}
}


/**********************Global Elements*************************/

/* 
.globalElement1{
	border-radius: 17px;
	width: 40px;
	height: 40px;
	left: 200px;
	top: 0px;
	transform: rotate(70deg);
	background: linear-gradient(120deg, rgba(255, 255, 255, .8) 0%, rgba(255, 255, 255, 0) 100%);
	// backdrop-filter: blur(10px);
	// -webkit-backdrop-filter: blur(10px);
	
}
.globalElement2{
	border-radius: 35px;
	width: 100px;
	height: 100px;
	left: 750px;
	top: 50px;
	transform: rotate(70deg);
	background: linear-gradient(120deg, rgba(255, 255, 255, .8) 0%, rgba(255, 255, 255, 0) 100%);
}
.globalElement3{
	border-radius: 80px;
	width: 250px;
	height: 250px;
	right: -200px;
	bottom: -50px;
	transform: rotate(70deg);
	background: linear-gradient(120deg, rgba(255, 255, 255, .8) 0%, rgba(255, 255, 255, 0) 100%);
}

.elementLine4{
	z-index: 4;
	width: 144px;
	height: 144px;
	top: 263px;
	right: -65px;
	border-radius: 54px;
	transform: rotate(27deg);
	background: transparent;
	border: 1px solid var(--bluePurple);
	opacity: .7;
}
.elementLine4:before{
	content: "";
	position: absolute;
	z-index: 4;
	width: 172px;
	height: 172px;
	top: -14px;
	right: -14px;
	border-radius: 63px;
	transform: rotate(0deg);
	background: transparent;
	border: 1px solid var(--bluePurple);
}
.elementLine4:after{
	content: "";
	position: absolute;
	z-index: 4;
	width: 197px;
	height: 196px;
	top: -26px;
	right: -26px;
	border-radius: 70px;
	transform: rotate(0deg);
	background: transparent;
	border: 1px solid var(--bluePurple);
}
 */

/******************* Animate Home **********************/
/* 
body.animHome .globalElement1{
	border-radius: 17px;
	width: 40px;
	height: 40px;
	left: 200px;
	top: 0px;
	transform: rotate(70deg);
	background: linear-gradient(120deg, rgba(255, 255, 255, .8) 0%, rgba(255, 255, 255, 0) 100%);
	// backdrop-filter: blur(10px);
	// -webkit-backdrop-filter: blur(10px);
	transition: all 2s ease;
}
body.animHome .globalElement2{
	border-radius: 35px;
	width: 100px;
	height: 100px;
	left: 750px;
	top: 50px;
	transform: rotate(70deg);
	background: linear-gradient(120deg, rgba(255, 255, 255, .8) 0%, rgba(255, 255, 255, 0) 100%);
	transition: all 2s ease;
}
body.animHome .globalElement3{
	border-radius: 80px;
	width: 250px;
	height: 250px;
	right: -200px;
	bottom: -50px;
	transform: rotate(70deg);
	background: linear-gradient(120deg, rgba(255, 255, 255, .8) 0%, rgba(255, 255, 255, 0) 100%);
	transition: all 2s ease;
}

body.animHome .elementLine4{
	z-index: 4;
	width: 144px;
	height: 144px;
	top: 293px;
  	right: 185px;
	border-radius: 54px;
	transform: rotate(27deg);
	background: transparent;
	border: 1px solid var(--bluePurple);
	opacity: .7;
	transition: all 2s ease;
}
body.animHome .elementLine4:before{
	content: "";
	position: absolute;
	z-index: 4;
	width: 172px;
	height: 172px;
	top: -14px;
	right: -14px;
	border-radius: 63px;
	transform: rotate(0deg);
	background: transparent;
	border: 1px solid var(--bluePurple);
}
body.animHome .elementLine4:after{
	content: "";
	position: absolute;
	z-index: 4;
	width: 197px;
	height: 196px;
	top: -26px;
	right: -26px;
	border-radius: 70px;
	transform: rotate(0deg);
	background: transparent;
	border: 1px solid var(--bluePurple);
}
 */
/******************* Animate About **********************/
/* 
body.animAbout .globalElement1{
	border-radius: 47px;
	width: 160px;
	height: 160px;
	left: 290px;
	top: 245px;
	transform: rotate(120deg);
	background: linear-gradient(120deg, rgba(255, 255, 255, .8) 0%, rgba(255, 255, 255, 0) 100%);
	backdrop-filter: blur(10px);
	-webkit-backdrop-filter: blur(10px);
	z-index: 5;
	transition: all 2s ease;
}
body.animAbout .globalElement2{
	border-radius: 35px;
	width: 100px;
	height: 100px;
	left: 750px;
	top: 50px;
	transform: rotate(70deg);
	background: linear-gradient(120deg, rgba(255, 255, 255, .8) 0%, rgba(255, 255, 255, 0) 100%);
	transition: all 2s ease;
}
body.animAbout .globalElement3{
	border-radius: 80px;
	width: 250px;
	height: 250px;
	right: -200px;
	bottom: -50px;
	transform: rotate(70deg);
	background: linear-gradient(120deg, rgba(255, 255, 255, .8) 0%, rgba(255, 255, 255, 0) 100%);
	transition: all 2s ease;
}

body.animAbout .elementLine4{
	width: 350px;
	height: 350px;
	top: 50px;
  	right: 1390px;
	// left: -200px;
	border-radius: 50%;
	z-index: -1;
	transition: all 2s ease;
}
body.animAbout .elementLine4:before{
	content: "";
	position: absolute;
	z-index: 4;
	width: 172px;
	height: 172px;
	top: -14px;
	right: -14px;
	border-radius: 63px;
	transform: rotate(0deg);
	background: transparent;
	border: 1px solid var(--bluePurple);
}
body.animAbout .elementLine4:after{
	content: "";
	position: absolute;
	z-index: 4;
	width: 197px;
	height: 196px;
	top: -26px;
	right: -26px;
	border-radius: 70px;
	transform: rotate(0deg);
	background: transparent;
	border: 1px solid var(--bluePurple);
}
 */
/**********************Global Elements End*************************/





body.rtl,
.rtl body p,
.rtl .contact-text,
.rtl span,
.rtl input::placeholder,
.rtl textarea,
.rtl .tabWrapper button,
.rtl .testimonialsDesig {
  font-family: var(--arRegular) !important;
}

.rtl .item-box > span,
.rtl .title404{
	font-family: var(--arMedium) !important;
}




.rtl .nav-bar-list a,
.rtl .nav-bar-list a:hover,
.rtl .nav-bar-list a.active,
.rtl button.btn-primary span,
.rtl .servicesHeading,
.rtl .teamHeading,
.rtl .testimonialsHeading,
.rtl .processDetailHeading,
.rtl .projectModTitle,
.rtl .sthDesc .smallTitleHeading,
.rtl .devServicesTitle{
  font-family: var(--arSemiBold) !important;
}


.rtl .banner-content .bannertHeading,
.rtl .copyRight a {
	font-family: var(--arBold) !important;
}


.rtl .feature-img {
  right: -70px !important;
}

.rtl .input-field {
  border: none;
  border-radius: 8px;
  padding: 9px 50px 9px 15px !important;
  width: 100%;
}

.rtl .header-btn svg,
.rtl .input-svg,
.rtl .svg,
.rtl .field-box img {
  transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
  -o-transform: scaleX(-1);
}
.rtl .field-box img {
	left: unset;
	right: 15px;
}
.rtl .change-Btn {
  position: fixed;
  bottom: 0;
  right: auto !important;
  left: 0;
}
.rtl .whatsapp{
	right: 0 !important;
  	left: auto !important;
}

.rtl .contact-img {
  right: -120px !important;
  transform: scaleX(-1) !important;
  -webkit-transform: scaleX(-1) !important;
  -moz-transform: scaleX(-1) !important;
  -ms-transform: scaleX(-1) !important;
  -o-transform: scaleX(-1) !important;
}

.rtl .main-heading {
  font-size: 30px;
}

.rtl .contact-text {
  direction: ltr;
}




// @import '~video-react/styles/scss/video-react';

.fade-enter {
	opacity: 0;
  }
  
  .fade-enter-active {
	opacity: 1;
	transition: opacity 250ms ease-in-out; /* Adjust timings as desired */
  }
  
  .fade-exit {
	opacity: 1;
  }
  
  .fade-exit-active {
	opacity: 0;
	transition: opacity 250ms ease-in-out; /* Adjust timings as desired */
  }



/***********************RTL**************************/
// .rtl .rightColImg.rightColImgBeep
.rtl .slick-arrow,
.rtl .btn-primary svg,
.rtl .getQuoteMain,
.rtl .rightColImgBeepApp {
	transform: scaleX(-1) !important;
	-webkit-transform: scaleX(-1) !important;
	-moz-transform: scaleX(-1) !important;
	-ms-transform: scaleX(-1) !important;
	-o-transform: scaleX(-1) !important;
}


.rtl .banner-content-row{
	direction: rtl;
}
.rtl .banner-content {
	padding-right: 30px;
	padding-left: 180px;
}
.rtl .banner-content-row .banner-content-col{
	// order: 2;
}
.rtl .banner-content .bannertTxtPrimary .bannertTxtPrimary1 {
	font-size: 32px;
	line-height: 40px;
}
.rtl .banner-content .bannertTxtPrimary .bannertTxtPrimary2 {
	font-size: 32px;
}
.rtl .banner-content .bannertHeading {
	font-size: 58px;
	line-height: 70px;
	padding-bottom: 10px;
}
.rtl .wtspNumber{
	direction: ltr;
}
.rtl .banner-wrap .slick-dots {
	padding-left: 0;
	padding-right: 30px;
	float: right;
}
.rtl .banner-wrap .slick-dots::after {
	right: 45px;
	left: 32px;
}
.rtl .banner-wrap .slick-dots li {
	float: right;
	margin-left: 20px;
	margin-right: 0px;
}
.rtl .wtspNumber{
	margin: 0 !important;
	white-space: nowrap;
}
.rtl .btn-primary svg{
	margin-left: -12px;
	margin-right: unset;
}
.rtl .slick-arrow{
	margin-top: -14px;
}
.rtl .btn-primary{
	direction: rtl
}
.rtl .testimonialsHeading,
.rtl .testimonialsDesig{
	text-align: right;
}
.rtl .customCheck {
	padding-left: unset;
	padding-right: 35px;
}
.rtl .customCheck .checkmark {
	left: unset;
	right: 0;
}
.rtl button.crossModal {
	right: unset;
	left: 20px;
}
.rtl .rightColContentQuote {
	padding: 10px 10px 0 30px;
}
.rtl .rightColImg.rightColImgBeep {
	right: auto;
	left: 50px;
}
.rtl ul.portfolioListWrapper li{
	padding-left: 0px;
	padding-right: 20px;
	background-position: top 10px right 0;
}
.rtl .portfolioTechListWrapper {
	right: unset;
	left: 5px;
}
.rtl ul.portfolioListWrapper li{
	padding-left: 20px;
	background-image: url(../images/svg/listArrowRtl.svg);
}

.rtl .imgQuote{
	position: absolute;
	right: unset;
	left: 10px;
	top: 50px;
	width: 90px;
	z-index: -1;
}
.rtl .imgQuote svg{
	
	transform: scaleX(-1) !important;
	-webkit-transform: scaleX(-1) !important;
	-moz-transform: scaleX(-1) !important;
	-ms-transform: scaleX(-1) !important;
	-o-transform: scaleX(-1) !important;
}
.rtl .testimonialsText{
	text-align: right
}
.rtl .item-wrapper{
	padding-right: 0 !important;
	padding-left: 15px;
}
.rtl .faqsHeading .input-field {
	border: none;
	border-radius: 8px;
	padding-left: 50px !important;
	padding-right: 15px !important;
	width: 100%;
}
.rtl .field-box button.btnSearch{
	right: unset !important;
	left: 1px !important;
	transform: scaleX(-1) !important;
	-webkit-transform: scaleX(-1) !important;
	-moz-transform: scaleX(-1) !important;
	-ms-transform: scaleX(-1) !important;
	-o-transform: scaleX(-1) !important;
}
.rtl .faqsHeading {
	padding-right: unset !important;
	padding-left: 15px !important;
}
// .testimonials-to-clients .elementLine4{
// 	display: none !important;
// }

/* 
.changeBtnWrap{
	visibility: hidden;
	width: 0;
	height: 0;
	overflow: hidden;
}
 */



@import "responsive";