

@media (max-width: 1550px) {
    
    .ltr .elementLine4 {
		right: 172px;
	}
	
	@keyframes haElem4 {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			right: 172px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 120px;
			right: 1460px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 120px;
			right: 1380px;
			border-radius: 50%;
		}
	}

	@keyframes ahElem4 {
		0% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 120px;
			right: 1380px;
			border-radius: 50%;
		}
		70% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			right: 155px;
			border-radius: 54px;
		}
		100% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			right: 172px;
			border-radius: 54px;
		}
	}

	/*************Home to Services************/
	
	body.ltr.home-to-services .globalElement2{
		animation: hsElem2 3s forwards ease-in-out;
	}

	@keyframes hsElem2 {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 750px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1400px;
			top: 60px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1370px;
			top: 60px;
			transform: rotate(135deg);
			
		}
	}

	/*************Home to Team************/
	
	body.ltr.home-to-team .globalElement2{
		animation: htElem2 3s forwards ease-in-out;
	}

	@keyframes htElem2 {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 750px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1400px;
			top: 40px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1370px;
			top: 40px;
			transform: rotate(135deg);
			
		}
	}

	/*************Home to Portfolio************/

	body.ltr.home-to-portfolio .globalElement2{
		animation: hpElem2 3s forwards ease-in-out;
	}
	
	@keyframes hpElem2 {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 750px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1400px;
			top: -20px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1370px;
			top: 0px;
			transform: rotate(135deg);
			
		}
	}
	
	body.ltr.home-to-portfolio .elementLine4{
		animation: hpElem4 3s forwards ease-in-out;
	}
	@keyframes hpElem4 {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			right: 172px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: -10px;
			right: 1430px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 10px;
			right: 1400px;
			border-radius: 50%;
		}
	}

/* 
	body.ltr.home-to-services .elementLine4{
		animation: hsElem4 3s forwards ease-in-out;
	}
	@keyframes hsElem4 {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			right: 185px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 36px;
			right: -125px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 36px;
			right: -95px;
			border-radius: 50%;
		}
	}

	body.ltr.home-to-services .elementLine4:before{
		animation: hsElem4Before 3s forwards ease-in-out;
	}
	@keyframes hsElem4Before {
		0% { 
			z-index: 4;
			width: 172px;
			height: 172px;
			top: -14px;
			left: -14px;
			border-radius: 63px;
		}
		70% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 242px;
			height: 242px;
			top: -22px;
			left: -22px;
			border-radius: 50%;
		}
	}


	body.ltr.home-to-services .elementLine4:after{
		animation: hsElem4After 3s forwards ease-in-out;
	}
	@keyframes hsElem4After {
		0% { 
			width: 197px;
			height: 196px;
			top: -26px;
			right: -26px;
			border-radius: 70px;
		}
		70% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 284px;
			height: 284px;
			top: -43px;
			left: -43px;
			border-radius: 50%;
		}
	}
	 */

	.ltr .globalElement4{
		left: 835px;
	}
	.ltr .globalElement5{
		right: 65px;
	}

	/******************* Animate Home To Clients **********************/

	body.ltr.home-to-clients .globalElement4{
		animation: hcGlobElem4 3s forwards ease-in-out;
	}
	@keyframes hcGlobElem4 {
		0% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 835px;
			top: 350px;
			transform: rotate(60deg);
		}
		70% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 570px;
			top: 450px;
			transform: rotate(-60deg);
			z-index: -1;
		}
		100% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 580px;
			top: 430px;
			transform: rotate(-60deg);
			z-index: -1;
		}
	}
	body.ltr.home-to-clients .globalElement5{
		animation: hcGlobElem5 3s forwards ease-in-out;
	}
	@keyframes hcGlobElem5 {
		0% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			right: 65px;
			top: 30px;
			transform: rotate(60deg);
		}
		70% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			right: 65px;
			top: 90px;
			transform: rotate(60deg);
		}
		100% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			right: 65px;
			top: 80px;
			transform: rotate(60deg);
		}
	}

	body.ltr.home-to-clients .globalElement1{
		animation: hcElem1 3s forwards ease-in-out;
	}
	@keyframes hcElem1 {
		0% { 
			border-radius: 17px;
			width: 40px;
			height: 40px;
			left: 200px;
			top: 0px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 90px;
			top: -25px;
			transform: rotate(-70deg);
		}
		100% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 100px;
			top: -20px;
			transform: rotate(-70deg);
		}
	}
	body.ltr.home-to-clients .globalElement2{
		animation: hcElem2 3s forwards ease-in-out;
	}

	@keyframes hcElem2 {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 750px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 250px;
			height: 250px;
			left: 575px;
			top: 525px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 250px;
			height: 250px;
			left: 575px;
			top: 495px;
			transform: rotate(135deg);
			
		}
	}


	body.ltr.home-to-clients .elementLine4{
		animation: hcElem4 3s forwards ease-in-out;
	}
	@keyframes hcElem4 {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			right: 172px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 500px;
			right: 610px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 470px;
			right: 600px;
			border-radius: 50%;
		}
	}

	
	body.ltr.home-to-clients.modal-open .elementLine5{
		animation: hcElem5 3s forwards ease-in-out;
	}
	@keyframes hcElem5 {
		0% { 
			z-index: 1100;
			width: 284px;
			height: 284px;
			top: -6px;
			right: -137px;
			border-radius: 50%;
			opacity: 0;
			transform: rotate(27deg);
		}
		70% { 
			transform: rotate(-114deg);
			z-index: 1057;
			width: 325px;
			height: 325px;
			top: 60px;
			right: 1060px;
			border-radius: 100px;
			opacity: 1;
		}
		99% { 
			transform: rotate(-114deg);
			z-index: 1056;
			width: 325px;
			height: 325px;
			top: 60px;
			right: 1034px;
			border-radius: 100px;
			opacity: 1;
		}
		100% { 
			transform: rotate(-114deg);
			z-index: 1056;
			width: 325px;
			height: 325px;
			top: 60px;
			right: 1034px;
			border-radius: 100px;
			opacity: 0;
		}
	}

	/*************Home to Testimonials************/
		
	body.ltr.home-to-testimonials .globalElement2{
		animation: htesElem2 3s forwards ease-in-out;
	}

	@keyframes htesElem2 {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 750px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1400px;
			top: 40px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1370px;
			top: 40px;
			transform: rotate(135deg);
			
		}
	}

	/*************Home to Contact Us************/

	body.ltr.home-to-contact .globalElement2{
		animation: hCtElem2 3s forwards ease-in-out;
	}
	
	@keyframes hCtElem2 {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 750px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1400px;
			top: -20px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1370px;
			top: 0px;
			transform: rotate(135deg);
			
		}
	}
	
	body.ltr.home-to-contact .elementLine4{
		animation: hCtElem4 3s forwards ease-in-out;
	}
	@keyframes hCtElem4 {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			right: 172px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			right: 1430px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			right: 1400px;
			border-radius: 50%;
		}
	}


	/*************Home to Faqs************/

	body.ltr.home-to-faqs .globalElement2{
		animation: hfElem2 3s forwards ease-in-out;
	}
	
	@keyframes hfElem2 {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 750px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1400px;
			top: -20px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1370px;
			top: 0px;
			transform: rotate(135deg);
			
		}
	}
	
	body.ltr.home-to-faqs .elementLine4{
		animation: hfElem4 3s forwards ease-in-out;
	}
	@keyframes hfElem4 {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			right: 172px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			right: 1430px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			right: 1400px;
			border-radius: 50%;
		}
	}

}
	

@media (max-width: 1499px) {
	/*************Home to Services************/
	body.ltr.home-to-services .globalElement2{
		animation: hsElem2 3s forwards ease-in-out;
	}

	@keyframes hsElem2 {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 750px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1390px;
			top: 60px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1360px;
			top: 60px;
			transform: rotate(135deg);
			
		}
	}

	/******************* Animate Home To Clients **********************/
/*
	body.ltr.home-to-clients .globalElement4{
		animation: hcGlobElem4 3s forwards ease-in-out;
	}
	@keyframes hcGlobElem4 {
		0% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 835px;
			top: 350px;
			transform: rotate(60deg);
		}
		70% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 570px;
			top: 450px;
			transform: rotate(-60deg);
			z-index: -1;
		}
		100% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 580px;
			top: 430px;
			transform: rotate(-60deg);
			z-index: -1;
		}
	}
	body.ltr.home-to-clients .elementLine4{
		animation: hcElem4 3s forwards ease-in-out;
	}
	@keyframes hcElem4 {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			right: 185px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 500px;
			right: 610px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 470px;
			right: 600px;
			border-radius: 50%;
		}
	}
*/
}

@media (max-width: 1440px) {
    .ltr .elementLine4 {
		right: 152px;
	}
	.ltr .globalElement4{
		left: 785px;
	}
	.ltr .globalElement5{
		right: 50px;
	}
	
	body.ltr.home-to-about .globalElement2{
		animation: haElem2 3s forwards ease-in-out;
	}

	@keyframes haElem2 {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 750px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1330px;
			top: 60px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1300px;
			top: 60px;
			transform: rotate(135deg);
			
		}
	}

	@keyframes haElem4 {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			right: 152px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 120px;
			right: 1350px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 120px;
			right: 1300px;
			border-radius: 50%;
		}
	}

	@keyframes ahElem4 {
		0% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 120px;
			right: 1380px;
			border-radius: 50%;
		}
		70% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			right: 135px;
			border-radius: 54px;
		}
		100% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			right: 152px;
			border-radius: 54px;
		}
	}

	/*************Home to Services************/
	body.ltr.home-to-services .globalElement2{
		animation: hsElem2 3s forwards ease-in-out;
	}

	@keyframes hsElem2 {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 750px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1330px;
			top: 60px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1300px;
			top: 60px;
			transform: rotate(135deg);
			
		}
	}

	/*************Home to Team************/
	
	body.ltr.home-to-team .globalElement2{
		animation: htElem2 3s forwards ease-in-out;
	}

	@keyframes htElem2 {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 750px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1330px;
			top: 40px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1300px;
			top: 40px;
			transform: rotate(135deg);
			
		}
	}

	body.ltr.home-to-team .elementLine4{
		animation: htElem4 3s forwards ease-in-out;
	}
	@keyframes htElem4 {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			right: 185px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 445px;
			right: 1330px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 430px;
			right: 1300px;
			border-radius: 50%;
		}
	}

	/*************Home to Portfolio************/

	body.ltr.home-to-portfolio .globalElement2{
		animation: hpElem2 3s forwards ease-in-out;
	}
	
	@keyframes hpElem2 {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 750px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1350px;
			top: -20px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1320px;
			top: 0px;
			transform: rotate(135deg);
			
		}
	}
	
	body.ltr.home-to-portfolio .elementLine4{
		animation: hpElem4 3s forwards ease-in-out;
	}
	@keyframes hpElem4 {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			right: 152px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: -10px;
			right: 1380px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 10px;
			right: 1350px;
			border-radius: 50%;
		}
	}

/******************* Animate Home To Clients **********************/

	body.ltr.home-to-clients .globalElement4{
		animation: hcGlobElem4 3s forwards ease-in-out;
	}
	@keyframes hcGlobElem4 {
		0% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 785px;
			top: 350px;
			transform: rotate(60deg);
		}
		70% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 570px;
			top: 450px;
			transform: rotate(-60deg);
			z-index: -1;
		}
		100% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 580px;
			top: 430px;
			transform: rotate(-60deg);
			z-index: -1;
		}
	}
	body.ltr.home-to-clients .globalElement5{
		animation: hcGlobElem5 3s forwards ease-in-out;
	}
	@keyframes hcGlobElem5 {
		0% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			right: 50px;
			top: 30px;
			transform: rotate(60deg);
		}
		70% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			right: 50px;
			top: 90px;
			transform: rotate(60deg);
		}
		100% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			right: 50px;
			top: 80px;
			transform: rotate(60deg);
		}
	}

	
	body.ltr.home-to-clients .globalElement2{
		animation: hcElem2 3s forwards ease-in-out;
	}

	@keyframes hcElem2 {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 750px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 250px;
			height: 250px;
			left: 565px;
			top: 525px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 250px;
			height: 250px;
			left: 565px;
			top: 495px;
			transform: rotate(135deg);
			
		}
	}


	body.ltr.home-to-clients .elementLine4{
		animation: hcElem4 3s forwards ease-in-out;
	}
	@keyframes hcElem4 {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			right: 152px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 500px;
			right: 550px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 470px;
			right: 540px;
			border-radius: 50%;
		}
	}

	/*************Home to Testimonials************/
	
	body.ltr.home-to-testimonials .globalElement2{
		animation: htesElem2 3s forwards ease-in-out;
	}

	@keyframes htesElem2 {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 750px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1330px;
			top: 40px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1300px;
			top: 40px;
			transform: rotate(135deg);
			
		}
	}

	body.ltr.home-to-testimonials .elementLine4{
		animation: htesElem4 3s forwards ease-in-out;
	}
	@keyframes htesElem4 {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			right: 185px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 445px;
			right: 1330px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 430px;
			right: 1300px;
			border-radius: 50%;
		}
	}


	/*************Home to Contact Us************/

	body.ltr.home-to-contact .globalElement2{
		animation: hCtElem2 3s forwards ease-in-out;
	}
	
	@keyframes hCtElem2 {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 750px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1350px;
			top: -20px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1320px;
			top: 0px;
			transform: rotate(135deg);
			
		}
	}
	
	body.ltr.home-to-contact .elementLine4{
		animation: hCtElem4 3s forwards ease-in-out;
	}
	@keyframes hCtElem4 {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			right: 152px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			right: 1380px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			right: 1350px;
			border-radius: 50%;
		}
	}

	/*************Home to Faqs************/

	body.ltr.home-to-faqs .globalElement2{
		animation: hfElem2 3s forwards ease-in-out;
	}
	
	@keyframes hfElem2 {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 750px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1350px;
			top: -20px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1320px;
			top: 0px;
			transform: rotate(135deg);
			
		}
	}
	
	body.ltr.home-to-faqs .elementLine4{
		animation: hfElem4 3s forwards ease-in-out;
	}
	@keyframes hfElem4 {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			right: 152px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			right: 1380px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			right: 1350px;
			border-radius: 50%;
		}
	}

	/*********************Get A Quote*******************/
	.ltr .getQuoteMain{
		left: 170px;
	}
	.ltr .modalQuoEle1{
		left: 150px;
	}
	.ltr .modalQuoEle2{
		left: 335px;
	}
	.ltr .modalQuoEle3{
		left: 375px;
	}
	.ltr .modalQuoEle4{
		left: 160px;
	}

}
@media (max-width: 1399px) {
    .ltr .elementLine4 {
		right: 142px;
	}
	.ltr .globalElement4{
		left: 755px;
	}
	.ltr .globalElement5{
		right: 40px;
	}
	
	@keyframes haElem4 {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			right: 142px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 120px;
			right: 1300px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 120px;
			right: 1260px;
			border-radius: 50%;
		}
	}

	@keyframes ahElem4 {
		0% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 120px;
			right: 1260px;
			border-radius: 50%;
		}
		70% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			right: 130px;
			border-radius: 54px;
		}
		100% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			right: 142px;
			border-radius: 54px;
		}
	}

	/*************Home to Services************/
	body.ltr.home-to-services .globalElement2{
		animation: hsElem2 3s forwards ease-in-out;
	}

	@keyframes hsElem2 {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 750px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1290px;
			top: 60px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1260px;
			top: 60px;
			transform: rotate(135deg);
			
		}
	}

	/*************Home to Team************/
	
	body.ltr.home-to-team .globalElement2{
		animation: htElem2 3s forwards ease-in-out;
	}

	@keyframes htElem2 {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 750px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1280px;
			top: 40px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1250px;
			top: 40px;
			transform: rotate(135deg);
			
		}
	}

	body.ltr.home-to-team .elementLine4{
		animation: htElem4 3s forwards ease-in-out;
	}
	@keyframes htElem4 {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			right: 185px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 445px;
			right: 1280px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 430px;
			right: 1250px;
			border-radius: 50%;
		}
	}

	/*************Home to Portfolio************/

	body.ltr.home-to-portfolio .globalElement2{
		animation: hpElem2 3s forwards ease-in-out;
	}
	
	@keyframes hpElem2 {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 750px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1300px;
			top: -20px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1270px;
			top: 0px;
			transform: rotate(135deg);
			
		}
	}
	
	body.ltr.home-to-portfolio .elementLine4{
		animation: hpElem4 3s forwards ease-in-out;
	}
	@keyframes hpElem4 {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			right: 142px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: -10px;
			right: 1350px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 10px;
			right: 1300px;
			border-radius: 50%;
		}
	}

	/******************* Animate Home To Clients **********************/

	body.ltr.home-to-clients .globalElement4{
		animation: hcGlobElem4 3s forwards ease-in-out;
	}
	@keyframes hcGlobElem4 {
		0% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 755px;
			top: 350px;
			transform: rotate(60deg);
		}
		70% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 520px;
			top: 450px;
			transform: rotate(-60deg);
			z-index: -1;
		}
		100% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 530px;
			top: 430px;
			transform: rotate(-60deg);
			z-index: -1;
		}
	}
	body.ltr.home-to-clients .globalElement5{
		animation: hcGlobElem5 3s forwards ease-in-out;
	}
	@keyframes hcGlobElem5 {
		0% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			right: 40px;
			top: 30px;
			transform: rotate(60deg);
		}
		70% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			right: 40px;
			top: 90px;
			transform: rotate(60deg);
		}
		100% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			right: 40px;
			top: 80px;
			transform: rotate(60deg);
		}
	}

	
	body.ltr.home-to-clients .globalElement2{
		animation: hcElem2 3s forwards ease-in-out;
	}

	@keyframes hcElem2 {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 750px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 250px;
			height: 250px;
			left: 525px;
			top: 525px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 250px;
			height: 250px;
			left: 525px;
			top: 495px;
			transform: rotate(135deg);
			
		}
	}


	body.ltr.home-to-clients .elementLine4{
		animation: hcElem4 3s forwards ease-in-out;
	}
	@keyframes hcElem4 {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			right: 152px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 500px;
			right: 550px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 470px;
			right: 540px;
			border-radius: 50%;
		}
	}

	/*************Home to Testimonials************/
	
	body.ltr.home-to-testimonials .globalElement2{
		animation: htesElem2 3s forwards ease-in-out;
	}

	@keyframes htesElem2 {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 750px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1280px;
			top: 40px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1250px;
			top: 40px;
			transform: rotate(135deg);
			
		}
	}

	body.ltr.home-to-testimonials .elementLine4{
		animation: htesElem4 3s forwards ease-in-out;
	}
	@keyframes htesElem4 {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			right: 185px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 445px;
			right: 1280px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 430px;
			right: 1250px;
			border-radius: 50%;
		}
	}


	/*************Home to Contact Us************/

	body.ltr.home-to-contact .globalElement2{
		animation: hCtElem2 3s forwards ease-in-out;
	}
	
	@keyframes hCtElem2 {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 750px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1300px;
			top: -20px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1270px;
			top: 0px;
			transform: rotate(135deg);
			
		}
	}
	
	body.ltr.home-to-contact .elementLine4{
		animation: hCtElem4 3s forwards ease-in-out;
	}
	@keyframes hCtElem4 {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			right: 142px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			right: 1350px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			right: 1300px;
			border-radius: 50%;
		}
	}


	/*************Home to Faqs************/

	body.ltr.home-to-faqs .globalElement2{
		animation: hfElem2 3s forwards ease-in-out;
	}
	
	@keyframes hfElem2 {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 750px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1300px;
			top: -20px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1270px;
			top: 0px;
			transform: rotate(135deg);
			
		}
	}
	
	body.ltr.home-to-faqs .elementLine4{
		animation: hfElem4 3s forwards ease-in-out;
	}
	@keyframes hfElem4 {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			right: 142px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			right: 1350px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			right: 1300px;
			border-radius: 50%;
		}
	}


}

@media (max-width: 1350px) {
    .ltr .elementLine4 {
		right: 130px;
	}
	.ltr .globalElement2 {
		left: 550px;
	}
	.ltr .globalElement4{
		left: 705px;
	}
	.ltr .globalElement5{
		right: 25px;
	}
	
	@keyframes haElem4 {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			right: 130px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 120px;
			right: 1260px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 120px;
			right: 1200px;
			border-radius: 50%;
		}
	}

	@keyframes ahElem4 {
		0% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 120px;
			right: 1200px;
			border-radius: 50%;
		}
		70% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			right: 110px;
			border-radius: 54px;
		}
		100% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			right: 130px;
			border-radius: 54px;
		}
	}

	@keyframes haElem2 {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 550px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 55px;
			width: 150px;
			height: 150px;
			left: 1250px;
			top: 30px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 55px;
			width: 150px;
			height: 150px;
			left: 1220px;
			top: 30px;
			transform: rotate(135deg);
			
		}
	}
	@keyframes ahElem2 {
		0% { 
			border-radius: 55px;
			width: 150px;
			height: 150px;
			left: 1220px;
			top: 30px;
			transform: rotate(135deg);
		}
		70% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 520px;
			top: 50px;
			transform: rotate(70deg);
		}
		100% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 550px;
			top: 50px;
			transform: rotate(70deg);
		}
	}

	/*************Home to Services************/
	body.ltr.home-to-services .globalElement2{
		animation: hsElem2 3s forwards ease-in-out;
	}

	@keyframes hsElem2 {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 750px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1230px;
			top: 60px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1200px;
			top: 60px;
			transform: rotate(135deg);
			
		}
	}

	/*************Home to Team************/
	
	body.ltr.home-to-team .globalElement2{
		animation: htElem2 3s forwards ease-in-out;
	}

	@keyframes htElem2 {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 550px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1230px;
			top: 40px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1180px;
			top: 40px;
			transform: rotate(135deg);
			
		}
	}

	body.ltr.home-to-team .elementLine4{
		animation: htElem4 3s forwards ease-in-out;
	}
	@keyframes htElem4 {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			right: 185px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 445px;
			right: 1230px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 430px;
			right: 1180px;
			border-radius: 50%;
		}
	}


	/*************Home to Portfolio************/

	body.ltr.home-to-portfolio .globalElement2{
		animation: hpElem2 3s forwards ease-in-out;
	}
	
	@keyframes hpElem2 {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 550px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1250px;
			top: -20px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1220px;
			top: 0px;
			transform: rotate(135deg);
			
		}
	}
	
	body.ltr.home-to-portfolio .elementLine4{
		animation: hpElem4 3s forwards ease-in-out;
	}
	@keyframes hpElem4 {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			right: 130px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: -10px;
			right: 1300px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 10px;
			right: 1250px;
			border-radius: 50%;
		}
	}

	/******************* Animate Home To Clients **********************/

	body.ltr.home-to-clients .globalElement4{
		animation: hcGlobElem4 3s forwards ease-in-out;
	}
	@keyframes hcGlobElem4 {
		0% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 705px;
			top: 350px;
			transform: rotate(60deg);
		}
		70% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 470px;
			top: 450px;
			transform: rotate(-60deg);
			z-index: -1;
		}
		100% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 480px;
			top: 430px;
			transform: rotate(-60deg);
			z-index: -1;
		}
	}
	body.ltr.home-to-clients .globalElement5{
		animation: hcGlobElem5 3s forwards ease-in-out;
	}
	@keyframes hcGlobElem5 {
		0% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			right: 25px;
			top: 30px;
			transform: rotate(60deg);
		}
		70% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			right: 25px;
			top: 90px;
			transform: rotate(60deg);
		}
		100% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			right: 25px;
			top: 80px;
			transform: rotate(60deg);
		}
	}

	
	body.ltr.home-to-clients .globalElement2{
		animation: hcElem2 3s forwards ease-in-out;
	}

	@keyframes hcElem2 {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 550px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 250px;
			height: 250px;
			left: 465px;
			top: 525px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 250px;
			height: 250px;
			left: 465px;
			top: 495px;
			transform: rotate(135deg);
			
		}
	}


	body.ltr.home-to-clients .elementLine4{
		animation: hcElem4 3s forwards ease-in-out;
	}
	@keyframes hcElem4 {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			right: 152px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 500px;
			right: 550px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 470px;
			right: 540px;
			border-radius: 50%;
		}
	}

	/*************Home to Testimonials************/
	
	body.ltr.home-to-testimonials .globalElement2{
		animation: htesElem2 3s forwards ease-in-out;
	}

	@keyframes htesElem2 {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 550px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1230px;
			top: 40px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1180px;
			top: 40px;
			transform: rotate(135deg);
			
		}
	}

	body.ltr.home-to-testimonials .elementLine4{
		animation: htesElem4 3s forwards ease-in-out;
	}
	@keyframes htesElem4 {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			right: 185px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 445px;
			right: 1230px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 430px;
			right: 1180px;
			border-radius: 50%;
		}
	}


	/*************Home to Contact Us************/

	body.ltr.home-to-contact .globalElement2{
		animation: hCtElem2 3s forwards ease-in-out;
	}

	@keyframes hCtElem2 {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 550px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1250px;
			top: -20px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1220px;
			top: 0px;
			transform: rotate(135deg);
			
		}
	}

	body.ltr.home-to-contact .elementLine4{
		animation: hCtElem4 3s forwards ease-in-out;
	}
	@keyframes hCtElem4 {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			right: 130px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			right: 1300px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			right: 1250px;
			border-radius: 50%;
		}
	}


	/*************Home to Faqs************/

	body.ltr.home-to-faqs .globalElement2{
		animation: hfElem2 3s forwards ease-in-out;
	}

	@keyframes hfElem2 {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 550px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1250px;
			top: -20px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1220px;
			top: 0px;
			transform: rotate(135deg);
			
		}
	}

	body.ltr.home-to-faqs .elementLine4{
		animation: hfElem4 3s forwards ease-in-out;
	}
	@keyframes hfElem4 {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			right: 130px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			right: 1300px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			right: 1250px;
			border-radius: 50%;
		}
	}

	/*********************Get A Quote*******************/
	/* .getQuoteMain{
		left: 150px;
	}
	.modalQuoEle1{
		left: 130px;
	}
	.modalQuoEle2{
		left: 320px;
	}
	.modalQuoEle3{
		left: 350px;
	}
	.modalQuoEle4{
		left: 140px;
	} */
}

@media (max-width: 1299px) {
    .ltr .elementLine4 {
		right: 116px;
	}
	.ltr .globalElement4{
		left: 675px;
	}
	.ltr .globalElement5{
		right: 65px;
	}
	
	
	@keyframes haElem4 {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			right: 130px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 120px;
			right: 1190px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 120px;
			right: 1150px;
			border-radius: 50%;
		}
	}

	@keyframes ahElem4 {
		0% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 120px;
			right: 1150px;
			border-radius: 50%;
		}
		70% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			right: 110px;
			border-radius: 54px;
		}
		100% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			right: 116px;
			border-radius: 54px;
		}
	}

	/*************Home to Services************/
	body.ltr.home-to-services .globalElement2{
		animation: hsElem2 3s forwards ease-in-out;
	}

	@keyframes hsElem2 {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 750px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1180px;
			top: 60px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1150px;
			top: 60px;
			transform: rotate(135deg);
			
		}
	}

	/*************Home to Team************/
	
	body.ltr.home-to-team .globalElement2{
		animation: htElem2 3s forwards ease-in-out;
	}

	@keyframes htElem2 {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 550px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1180px;
			top: 40px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1130px;
			top: 40px;
			transform: rotate(135deg);
			
		}
	}

	body.ltr.home-to-team .elementLine4{
		animation: htElem4 3s forwards ease-in-out;
	}
	@keyframes htElem4 {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			right: 185px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 445px;
			right: 1180px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 430px;
			right: 1130px;
			border-radius: 50%;
		}
	}

	/*************Home to Portfolio************/

	body.ltr.home-to-portfolio .globalElement2{
		animation: hpElem2 3s forwards ease-in-out;
	}
	
	@keyframes hpElem2 {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 550px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1200px;
			top: -20px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1170px;
			top: 0px;
			transform: rotate(135deg);
			
		}
	}
	
	body.ltr.home-to-portfolio .elementLine4{
		animation: hpElem4 3s forwards ease-in-out;
	}
	@keyframes hpElem4 {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			right: 116px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: -10px;
			right: 1250px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 10px;
			right: 1220px;
			border-radius: 50%;
		}
	}


	/******************* Animate Home To Clients **********************/

	body.ltr.home-to-clients .globalElement4{
		animation: hcGlobElem4 3s forwards ease-in-out;
	}
	@keyframes hcGlobElem4 {
		0% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 675px;
			top: 350px;
			transform: rotate(60deg);
		}
		70% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 420px;
			top: 450px;
			transform: rotate(-60deg);
			z-index: -1;
		}
		100% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 430px;
			top: 430px;
			transform: rotate(-60deg);
			z-index: -1;
		}
	}
	body.ltr.home-to-clients .globalElement5{
		animation: hcGlobElem5 3s forwards ease-in-out;
	}
	@keyframes hcGlobElem5 {
		0% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			right: 65px;
			top: 30px;
			transform: rotate(60deg);
		}
		70% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			right: 65px;
			top: 90px;
			transform: rotate(60deg);
		}
		100% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			right: 65px;
			top: 80px;
			transform: rotate(60deg);
		}
	}

	
	body.ltr.home-to-clients .globalElement2{
		animation: hcElem2 3s forwards ease-in-out;
	}

	@keyframes hcElem2 {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 550px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 250px;
			height: 250px;
			left: 425px;
			top: 525px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 250px;
			height: 250px;
			left: 415px;
			top: 495px;
			transform: rotate(135deg);
			
		}
	}


	body.ltr.home-to-clients .elementLine4{
		animation: hcElem4 3s forwards ease-in-out;
	}
	@keyframes hcElem4 {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			right: 116px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 500px;
			right: 550px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 470px;
			right: 540px;
			border-radius: 50%;
		}
	}

	/*************Home to Testimonials************/
	
	body.ltr.home-to-testimonials .globalElement2{
		animation: htesElem2 3s forwards ease-in-out;
	}

	@keyframes htesElem2 {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 550px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1180px;
			top: 40px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1130px;
			top: 40px;
			transform: rotate(135deg);
			
		}
	}

	body.ltr.home-to-testimonials .elementLine4{
		animation: htesElem4 3s forwards ease-in-out;
	}
	@keyframes htesElem4 {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			right: 185px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 445px;
			right: 1180px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 430px;
			right: 1130px;
			border-radius: 50%;
		}
	}


	/*************Home to Contact Us************/

	body.ltr.home-to-contact .globalElement2{
		animation: hCtElem2 3s forwards ease-in-out;
	}
	
	@keyframes hCtElem2 {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 550px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1200px;
			top: -20px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1170px;
			top: 0px;
			transform: rotate(135deg);
			
		}
	}
	
	body.ltr.home-to-contact .elementLine4{
		animation: hCtElem4 3s forwards ease-in-out;
	}
	@keyframes hCtElem4 {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			right: 116px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			right: 1250px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			right: 1220px;
			border-radius: 50%;
		}
	}


	/*************Home to Faqs************/

	body.ltr.home-to-faqs .globalElement2{
		animation: hfElem2 3s forwards ease-in-out;
	}
	
	@keyframes hfElem2 {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 550px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1200px;
			top: -20px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1170px;
			top: 0px;
			transform: rotate(135deg);
			
		}
	}
	
	body.ltr.home-to-faqs .elementLine4{
		animation: hfElem4 3s forwards ease-in-out;
	}
	@keyframes hfElem4 {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			right: 116px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			right: 1250px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			right: 1220px;
			border-radius: 50%;
		}
	}

}

@media (max-width: 1250px) {
    .ltr .elementLine4 {
		right: 104px;
	}
	.ltr .globalElement4{
		left: 635px;
	}
	.ltr .globalElement5{
		right: 49px;
	}
	
	
	@keyframes haElem4 {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			right: 104px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 120px;
			right: 1140px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 120px;
			right: 1100px;
			border-radius: 50%;
		}
	}

	@keyframes ahElem4 {
		0% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 120px;
			right: 1100px;
			border-radius: 50%;
		}
		70% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			right: 90px;
			border-radius: 54px;
		}
		100% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			right: 104px;
			border-radius: 54px;
		}
	}

	@keyframes haElem2 {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 550px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 55px;
			width: 150px;
			height: 150px;
			left: 1150px;
			top: 30px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 55px;
			width: 150px;
			height: 150px;
			left: 1120px;
			top: 30px;
			transform: rotate(135deg);
			
		}
	}
	@keyframes ahElem2 {
		0% { 
			border-radius: 55px;
			width: 150px;
			height: 150px;
			left: 1120px;
			top: 30px;
			transform: rotate(135deg);
		}
		70% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 520px;
			top: 50px;
			transform: rotate(70deg);
		}
		100% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 550px;
			top: 50px;
			transform: rotate(70deg);
		}
	}

	/*************Home to Services************/
	body.ltr.home-to-services .globalElement2{
		animation: hsElem2 3s forwards ease-in-out;
	}

	@keyframes hsElem2 {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 750px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1130px;
			top: 60px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1100px;
			top: 60px;
			transform: rotate(135deg);
			
		}
	}

	/*************Home to Team************/
	
	body.ltr.home-to-team .globalElement2{
		animation: htElem2 3s forwards ease-in-out;
	}

	@keyframes htElem2 {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 550px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1130px;
			top: 40px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1100px;
			top: 40px;
			transform: rotate(135deg);
			
		}
	}

	body.ltr.home-to-team .elementLine4{
		animation: htElem4 3s forwards ease-in-out;
	}
	@keyframes htElem4 {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			right: 185px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 445px;
			right: 1130px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 430px;
			right: 1100px;
			border-radius: 50%;
		}
	}

	/*************Home to Portfolio************/

	body.ltr.home-to-portfolio .globalElement2{
		animation: hpElem2 3s forwards ease-in-out;
	}
	
	@keyframes hpElem2 {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 550px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1150px;
			top: -20px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1120px;
			top: 0px;
			transform: rotate(135deg);
			
		}
	}
	
	body.ltr.home-to-portfolio .elementLine4{
		animation: hpElem4 3s forwards ease-in-out;
	}
	@keyframes hpElem4 {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			right: 104px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: -10px;
			right: 1200px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 10px;
			right: 1170px;
			border-radius: 50%;
		}
	}


	/******************* Animate Home To Clients **********************/

	body.ltr.home-to-clients .globalElement4{
		animation: hcGlobElem4 3s forwards ease-in-out;
	}
	@keyframes hcGlobElem4 {
		0% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 635px;
			top: 350px;
			transform: rotate(60deg);
		}
		70% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 390px;
			top: 450px;
			transform: rotate(-60deg);
			z-index: -1;
		}
		100% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 400px;
			top: 430px;
			transform: rotate(-60deg);
			z-index: -1;
		}
	}
	body.ltr.home-to-clients .globalElement5{
		animation: hcGlobElem5 3s forwards ease-in-out;
	}
	@keyframes hcGlobElem5 {
		0% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			right: 49px;
			top: 30px;
			transform: rotate(60deg);
		}
		70% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			right: 49px;
			top: 90px;
			transform: rotate(60deg);
		}
		100% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			right: 49px;
			top: 80px;
			transform: rotate(60deg);
		}
	}

	
	body.ltr.home-to-clients .globalElement2{
		animation: hcElem2 3s forwards ease-in-out;
	}

	@keyframes hcElem2 {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 550px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 250px;
			height: 250px;
			left: 375px;
			top: 525px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 250px;
			height: 250px;
			left: 365px;
			top: 495px;
			transform: rotate(135deg);
			
		}
	}


	body.ltr.home-to-clients .elementLine4{
		animation: hcElem4 3s forwards ease-in-out;
	}
	@keyframes hcElem4 {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			right: 104px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 500px;
			right: 550px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 470px;
			right: 540px;
			border-radius: 50%;
		}
	}

	/*************Home to Testimonials************/
		
	body.ltr.home-to-testimonials .globalElement2{
		animation: htesElem2 3s forwards ease-in-out;
	}

	@keyframes htesElem2 {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 550px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1130px;
			top: 40px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1100px;
			top: 40px;
			transform: rotate(135deg);
			
		}
	}

	body.ltr.home-to-testimonials .elementLine4{
		animation: htesElem4 3s forwards ease-in-out;
	}
	@keyframes htesElem4 {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			right: 185px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 445px;
			right: 1130px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 430px;
			right: 1100px;
			border-radius: 50%;
		}
	}


	/*************Home to Contact Us************/

	body.ltr.home-to-contact .globalElement2{
		animation: hCtElem2 3s forwards ease-in-out;
	}
	
	@keyframes hCtElem2 {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 550px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1150px;
			top: -20px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1120px;
			top: 0px;
			transform: rotate(135deg);
			
		}
	}
	
	body.ltr.home-to-contact .elementLine4{
		animation: hCtElem4 3s forwards ease-in-out;
	}
	@keyframes hCtElem4 {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			right: 104px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			right: 1200px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			right: 1170px;
			border-radius: 50%;
		}
	}


	/*************Home to Faqs************/

	body.ltr.home-to-faqs .globalElement2{
		animation: hfElem2 3s forwards ease-in-out;
	}
	
	@keyframes hfElem2 {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 550px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1150px;
			top: -20px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1120px;
			top: 0px;
			transform: rotate(135deg);
			
		}
	}
	
	body.ltr.home-to-faqs .elementLine4{
		animation: hfElem4 3s forwards ease-in-out;
	}
	@keyframes hfElem4 {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			right: 104px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			right: 1200px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			right: 1170px;
			border-radius: 50%;
		}
	}


	/*********************Get A Quote*******************/
	
	.ltr .getQuoteMain{
		left: 100px;
	}
	.ltr .modalQuoEle1{
		left: 80px;
	}
	.ltr .modalQuoEle2{
		left: 270px;
	}
	.ltr .modalQuoEle3{
		left: 300px;
	}
	.ltr .modalQuoEle4{
		left: 90px;
	}

}

@media (max-width: 1199px) {
    .ltr .elementLine4 {
		right: 74px;
	}
	.ltr .globalElement4{
		left: 615px;
	}
	.ltr .globalElement5{
		right: 79px;
	}
	
	
	@keyframes haElem4 {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			right: 74px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 120px;
			right: 1070px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 120px;
			right: 1040px;
			border-radius: 50%;
		}
	}

	@keyframes ahElem4 {
		0% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 120px;
			right: 1040px;
			border-radius: 50%;
		}
		70% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			right: 60px;
			border-radius: 54px;
		}
		100% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			right: 74px;
			border-radius: 54px;
		}
	}

	@keyframes haElem2 {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 550px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 55px;
			width: 150px;
			height: 150px;
			left: 1050px;
			top: 30px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 55px;
			width: 150px;
			height: 150px;
			left: 1020px;
			top: 30px;
			transform: rotate(135deg);
			
		}
	}
	@keyframes ahElem2 {
		0% { 
			border-radius: 55px;
			width: 150px;
			height: 150px;
			left: 1050px;
			top: 30px;
			transform: rotate(135deg);
		}
		70% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 520px;
			top: 50px;
			transform: rotate(70deg);
		}
		100% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 550px;
			top: 50px;
			transform: rotate(70deg);
		}
	}

	@keyframes haElem1 {
		0% { 
			border-radius: 17px;
			width: 40px;
			height: 40px;
			left: 200px;
			top: 0px;
			transform: rotate(70deg);
			background: linear-gradient(120deg, rgba(255, 255, 255, .8) 0%, rgba(255, 255, 255, 0) 100%);
		}
		50% { 
			left: 300px;
			top: 330px;
			width: 160px;
			height: 160px;
			border-radius: 47px;
			transform: rotate(120deg);
			/* Add the blur effect */
			backdrop-filter: blur(10px);
			-webkit-backdrop-filter: blur(10px);
			z-index: 5;
		}
		100% { 
			left: 270px;
			top: 295px;
			width: 160px;
			height: 160px;
			border-radius: 47px;
			transform: rotate(120deg);
			/* Add the blur effect */
			backdrop-filter: blur(10px);
			-webkit-backdrop-filter: blur(10px);
			z-index: 5;
			
		}
	}
	@keyframes ahElem1 {
		0% { 
			left: 270px;
			top: 295px;
			width: 160px;
			height: 160px;
			border-radius: 47px;
			transform: rotate(120deg);
			/* Add the blur effect */
			backdrop-filter: blur(10px);
			-webkit-backdrop-filter: blur(10px);
			z-index: 5;
		}
		70% { 
			border-radius: 17px;
			width: 40px;
			height: 40px;
			left: 180px;
			top: -30px;
			transform: rotate(70deg);
			background: linear-gradient(120deg, rgba(255, 255, 255, .8) 0%, rgba(255, 255, 255, 0) 100%);
		}
		100% { 
			border-radius: 17px;
			width: 40px;
			height: 40px;
			left: 200px;
			top: 0px;
			transform: rotate(70deg);
			background: linear-gradient(120deg, rgba(255, 255, 255, .8) 0%, rgba(255, 255, 255, 0) 100%);
			// backdrop-filter: blur(10px);
			// -webkit-backdrop-filter: blur(10px);
			// transition: all 2s ease;
			
		}
	}


	/*************Home to Services************/
	body.ltr.home-to-services .globalElement2{
		animation: hsElem2 3s forwards ease-in-out;
	}

	@keyframes hsElem2 {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 750px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1080px;
			top: 60px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1050px;
			top: 60px;
			transform: rotate(135deg);
			
		}
	}

	/*************Home to Team************/
	
	body.ltr.home-to-team .globalElement2{
		animation: htElem2 3s forwards ease-in-out;
	}

	@keyframes htElem2 {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 550px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1080px;
			top: 40px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1050px;
			top: 40px;
			transform: rotate(135deg);
			
		}
	}

	body.ltr.home-to-team .elementLine4{
		animation: htElem4 3s forwards ease-in-out;
	}
	@keyframes htElem4 {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			right: 74px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 445px;
			right: 1080px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 430px;
			right: 1050px;
			border-radius: 50%;
		}
	}


	/*************Home to Portfolio************/

	body.ltr.home-to-portfolio .globalElement2{
		animation: hpElem2 3s forwards ease-in-out;
	}
	
	@keyframes hpElem2 {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 550px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1100px;
			top: -20px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1070px;
			top: 0px;
			transform: rotate(135deg);
			
		}
	}
	
	body.ltr.home-to-portfolio .elementLine4{
		animation: hpElem4 3s forwards ease-in-out;
	}
	@keyframes hpElem4 {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			right: 74px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: -10px;
			right: 1150px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 10px;
			right: 1120px;
			border-radius: 50%;
		}
	}


	/******************* Animate Home To Clients **********************/

	body.ltr.home-to-clients .globalElement4{
		animation: hcGlobElem4 3s forwards ease-in-out;
	}
	@keyframes hcGlobElem4 {
		0% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 615px;
			top: 350px;
			transform: rotate(60deg);
		}
		70% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 440px;
			top: 460px;
			transform: rotate(-60deg);
			z-index: -1;
		}
		100% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 450px;
			top: 440px;
			transform: rotate(-60deg);
			z-index: -1;
		}
	}
	body.ltr.home-to-clients .globalElement5{
		animation: hcGlobElem5 3s forwards ease-in-out;
	}
	@keyframes hcGlobElem5 {
		0% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			right: 79px;
			top: 30px;
			transform: rotate(60deg);
		}
		70% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			right: 79px;
			top: 90px;
			transform: rotate(60deg);
		}
		100% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			right: 79px;
			top: 80px;
			transform: rotate(60deg);
		}
	}

	
	body.ltr.home-to-clients .globalElement2{
		animation: hcElem2 3s forwards ease-in-out;
	}

	@keyframes hcElem2 {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 550px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 250px;
			height: 250px;
			left: 475px;
			top: 525px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 250px;
			height: 250px;
			left: 455px;
			top: 495px;
			transform: rotate(135deg);
			
		}
	}


	body.ltr.home-to-clients .elementLine4{
		animation: hcElem4 3s forwards ease-in-out;
	}
	@keyframes hcElem4 {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			right: 74px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 500px;
			right: 410px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 470px;
			right: 400px;
			border-radius: 50%;
		}
	}


	/*************Home to Testimonials************/
	
	body.ltr.home-to-testimonials .globalElement2{
		animation: htesElem2 3s forwards ease-in-out;
	}

	@keyframes htesElem2 {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 550px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1080px;
			top: 40px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1050px;
			top: 40px;
			transform: rotate(135deg);
			
		}
	}

	body.ltr.home-to-testimonials .elementLine4{
		animation: htesElem4 3s forwards ease-in-out;
	}
	@keyframes htesElem4 {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			right: 74px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 445px;
			right: 1080px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 430px;
			right: 1050px;
			border-radius: 50%;
		}
	}


	/*************Home to Contact Us************/

	body.ltr.home-to-contact .globalElement2{
		animation: hCtElem2 3s forwards ease-in-out;
	}
	
	@keyframes hCtElem2 {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 550px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1100px;
			top: -20px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1070px;
			top: 0px;
			transform: rotate(135deg);
			
		}
	}
	
	body.ltr.home-to-contact .elementLine4{
		animation: hCtElem4 3s forwards ease-in-out;
	}
	@keyframes hCtElem4 {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			right: 74px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			right: 1150px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			right: 1120px;
			border-radius: 50%;
		}
	}

	/*************Home to Faqs************/

	body.ltr.home-to-faqs .globalElement2{
		animation: hfElem2 3s forwards ease-in-out;
	}
	
	@keyframes hfElem2 {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 550px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1100px;
			top: -20px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1070px;
			top: 0px;
			transform: rotate(135deg);
			
		}
	}
	
	body.ltr.home-to-faqs .elementLine4{
		animation: hfElem4 3s forwards ease-in-out;
	}
	@keyframes hfElem4 {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			right: 74px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			right: 1150px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			right: 1120px;
			border-radius: 50%;
		}
	}

}

@media (max-width: 1150px) {
    .ltr .elementLine4 {
		right: 60px;
		top: 291px;
	}
	.ltr .globalElement4{
		left: 575px;
	}
	.ltr .globalElement5{
		right: 69px;
	}
	
	
	@keyframes haElem4 {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			right: 60px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 120px;
			right: 1040px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 120px;
			right: 1000px;
			border-radius: 50%;
		}
	}

	@keyframes ahElem4 {
		0% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 120px;
			right: 1000px;
			border-radius: 50%;
		}
		70% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 291px;
			right: 45px;
			border-radius: 54px;
		}
		100% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 291px;
			right: 60px;
			border-radius: 54px;
		}
	}

	@keyframes haElem1 {
		0% { 
			border-radius: 17px;
			width: 40px;
			height: 40px;
			left: 200px;
			top: 0px;
			transform: rotate(70deg);
			background: linear-gradient(120deg, rgba(255, 255, 255, .8) 0%, rgba(255, 255, 255, 0) 100%);
		}
		50% { 
			left: 300px;
			top: 330px;
			width: 140px;
			height: 140px;
			border-radius: 47px;
			transform: rotate(120deg);
			/* Add the blur effect */
			backdrop-filter: blur(10px);
			-webkit-backdrop-filter: blur(10px);
			z-index: 5;
		}
		100% { 
			left: 270px;
			top: 295px;
			width: 140px;
			height: 140px;
			border-radius: 47px;
			transform: rotate(120deg);
			/* Add the blur effect */
			backdrop-filter: blur(10px);
			-webkit-backdrop-filter: blur(10px);
			z-index: 5;
			
		}
	}
	@keyframes ahElem1 {
		0% { 
			left: 270px;
			top: 295px;
			width: 140px;
			height: 140px;
			border-radius: 47px;
			transform: rotate(120deg);
			/* Add the blur effect */
			backdrop-filter: blur(10px);
			-webkit-backdrop-filter: blur(10px);
			z-index: 5;
		}
		70% { 
			border-radius: 17px;
			width: 40px;
			height: 40px;
			left: 180px;
			top: -30px;
			transform: rotate(70deg);
			background: linear-gradient(120deg, rgba(255, 255, 255, .8) 0%, rgba(255, 255, 255, 0) 100%);
		}
		100% { 
			border-radius: 17px;
			width: 40px;
			height: 40px;
			left: 200px;
			top: 0px;
			transform: rotate(70deg);
			background: linear-gradient(120deg, rgba(255, 255, 255, .8) 0%, rgba(255, 255, 255, 0) 100%);
			// backdrop-filter: blur(10px);
			// -webkit-backdrop-filter: blur(10px);
			// transition: all 2s ease;
			
		}
	}

	/*************Home to Services************/
	body.ltr.home-to-services .globalElement2{
		animation: hsElem2 3s forwards ease-in-out;
	}

	@keyframes hsElem2 {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 750px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1030px;
			top: 60px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1000px;
			top: 60px;
			transform: rotate(135deg);
			
		}
	}

	/*************Home to Team************/
	
	body.ltr.home-to-team .globalElement2{
		animation: htElem2 3s forwards ease-in-out;
	}

	@keyframes htElem2 {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 550px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1030px;
			top: 40px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1000px;
			top: 40px;
			transform: rotate(135deg);
			
		}
	}

	body.ltr.home-to-team .elementLine4{
		animation: htElem4 3s forwards ease-in-out;
	}
	@keyframes htElem4 {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			right: 60px;
			top: 291px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 425px;
			right: 1030px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 410px;
			right: 1000px;
			border-radius: 50%;
		}
	}


	/*************Home to Portfolio************/

	body.ltr.home-to-portfolio .globalElement2{
		animation: hpElem2 3s forwards ease-in-out;
	}
	
	@keyframes hpElem2 {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 550px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1100px;
			top: -20px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1070px;
			top: 0px;
			transform: rotate(135deg);
			
		}
	}
	
	body.ltr.home-to-portfolio .elementLine4{
		animation: hpElem4 3s forwards ease-in-out;
	}
	@keyframes hpElem4 {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			right: 60px;
    		top: 291px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: -10px;
			right: 1150px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 10px;
			right: 1120px;
			border-radius: 50%;
		}
	}


	/******************* Animate Home To Clients **********************/

	body.ltr.home-to-clients .globalElement4{
		animation: hcGlobElem4 3s forwards ease-in-out;
	}
	@keyframes hcGlobElem4 {
		0% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 575px;
			top: 350px;
			transform: rotate(60deg);
		}
		70% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 430px;
			top: 400px;
			transform: rotate(-60deg);
			z-index: -1;
		}
		100% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 440px;
			top: 420px;
			transform: rotate(-60deg);
			z-index: -1;
		}
	}
	body.ltr.home-to-clients .globalElement5{
		animation: hcGlobElem5 3s forwards ease-in-out;
	}
	@keyframes hcGlobElem5 {
		0% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			right: 69px;
			top: 30px;
			transform: rotate(60deg);
		}
		70% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			right: 69px;
			top: 90px;
			transform: rotate(60deg);
		}
		100% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			right: 69px;
			top: 80px;
			transform: rotate(60deg);
		}
	}

	
	body.ltr.home-to-clients .globalElement2{
		animation: hcElem2 3s forwards ease-in-out;
	}

	@keyframes hcElem2 {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 550px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 250px;
			height: 250px;
			left: 425px;
			top: 525px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 250px;
			height: 250px;
			left: 405px;
			top: 495px;
			transform: rotate(135deg);
			
		}
	}


	body.ltr.home-to-clients .elementLine4{
		animation: hcElem4 3s forwards ease-in-out;
	}
	@keyframes hcElem4 {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			right: 60px;
    		top: 291px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 500px;
			right: 410px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 470px;
			right: 400px;
			border-radius: 50%;
		}
	}

	/*************Home to Testimonials************/
		
	body.ltr.home-to-testimonials .globalElement2{
		animation: htesElem2 3s forwards ease-in-out;
	}

	@keyframes htesElem2 {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 550px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1030px;
			top: 40px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1000px;
			top: 40px;
			transform: rotate(135deg);
			
		}
	}

	body.ltr.home-to-testimonials .elementLine4{
		animation: htesElem4 3s forwards ease-in-out;
	}
	@keyframes htesElem4 {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			right: 60px;
			top: 291px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 425px;
			right: 1030px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 410px;
			right: 1000px;
			border-radius: 50%;
		}
	}


	/*************Home to Contact Us************/

	body.ltr.home-to-contact .globalElement2{
		animation: hCtElem2 3s forwards ease-in-out;
	}
	
	@keyframes hCtElem2 {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 550px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1050px;
			top: -20px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1020px;
			top: 0px;
			transform: rotate(135deg);
			
		}
	}
	
	body.ltr.home-to-contact .elementLine4{
		animation: hCtElem4 3s forwards ease-in-out;
	}
	@keyframes hCtElem4 {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			right: 60px;
    		top: 291px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			right: 1100px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			right: 1070px;
			border-radius: 50%;
		}
	}

	/*************Home to Faqs************/

	body.ltr.home-to-faqs .globalElement2{
		animation: hfElem2 3s forwards ease-in-out;
	}
	
	@keyframes hfElem2 {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 550px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1050px;
			top: -20px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1020px;
			top: 0px;
			transform: rotate(135deg);
			
		}
	}
	
	body.ltr.home-to-faqs .elementLine4{
		animation: hfElem4 3s forwards ease-in-out;
	}
	@keyframes hfElem4 {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			right: 60px;
    		top: 291px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			right: 1100px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			right: 1070px;
			border-radius: 50%;
		}
	}

	/*********************Get A Quote*******************/
	/* .getQuoteMain{
		left: 75px;
	}
	.modalQuoEle1{
		left: 60px;
	}
	.modalQuoEle2{
		left: 240px;
	}
	.modalQuoEle3{
		left: 280px;
	}
	.modalQuoEle4{
		left: 70px;
	} */
	.ltr .rightColContentQuote {
		padding: 10px 80px 0 10px;
	}

}

@media (max-width: 1099px) {
    .ltr .elementLine4 {
		right: 50px;
	}
	.ltr .globalElement4{
		left: 538px;
	}
	.ltr .globalElement5{
		right: 55px;
	}
	
	
	@keyframes haElem4 {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 293px;
			right: 50px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 120px;
			right: 1000px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 120px;
			right: 940px;
			border-radius: 50%;
		}
	}

	@keyframes ahElem4 {
		0% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 120px;
			right: 1000px;
			border-radius: 50%;
		}
		70% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 291px;
			right: 45px;
			border-radius: 54px;
		}
		100% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 291px;
			right: 50px;
			border-radius: 54px;
		}
	}

	@keyframes haElem1 {
		0% { 
			border-radius: 17px;
			width: 40px;
			height: 40px;
			left: 200px;
			top: 0px;
			transform: rotate(70deg);
			background: linear-gradient(120deg, rgba(255, 255, 255, .8) 0%, rgba(255, 255, 255, 0) 100%);
		}
		50% { 
			left: 280px;
			top: 360px;
			width: 130px;
			height: 130px;
			border-radius: 47px;
			transform: rotate(120deg);
			/* Add the blur effect */
			backdrop-filter: blur(10px);
			-webkit-backdrop-filter: blur(10px);
			z-index: 5;
		}
		100% { 
			left: 250px;
			top: 320px;
			width: 130px;
			height: 130px;
			border-radius: 47px;
			transform: rotate(120deg);
			/* Add the blur effect */
			backdrop-filter: blur(10px);
			-webkit-backdrop-filter: blur(10px);
			z-index: 5;
			
		}
	}
	@keyframes ahElem1 {
		0% { 
			left: 250px;
			top: 320px;
			width: 130px;
			height: 130px;
			border-radius: 47px;
			transform: rotate(120deg);
			/* Add the blur effect */
			backdrop-filter: blur(10px);
			-webkit-backdrop-filter: blur(10px);
			z-index: 5;
		}
		70% { 
			border-radius: 17px;
			width: 40px;
			height: 40px;
			left: 180px;
			top: -30px;
			transform: rotate(70deg);
			background: linear-gradient(120deg, rgba(255, 255, 255, .8) 0%, rgba(255, 255, 255, 0) 100%);
		}
		100% { 
			border-radius: 17px;
			width: 40px;
			height: 40px;
			left: 200px;
			top: 0px;
			transform: rotate(70deg);
			background: linear-gradient(120deg, rgba(255, 255, 255, .8) 0%, rgba(255, 255, 255, 0) 100%);
			// backdrop-filter: blur(10px);
			// -webkit-backdrop-filter: blur(10px);
			// transition: all 2s ease;
			
		}
	}

	/*************Home to Services************/
	body.ltr.home-to-services .globalElement2{
		animation: hsElem2 3s forwards ease-in-out;
	}

	@keyframes hsElem2 {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 750px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 980px;
			top: 60px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 950px;
			top: 60px;
			transform: rotate(135deg);
			
		}
	}


	/*************Home to Portfolio************/

	body.ltr.home-to-portfolio .globalElement2{
		animation: hpElem2 3s forwards ease-in-out;
	}
	
	@keyframes hpElem2 {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 550px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1050px;
			top: -20px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1020px;
			top: 0px;
			transform: rotate(135deg);
			
		}
	}
	
	body.ltr.home-to-portfolio .elementLine4{
		animation: hpElem4 3s forwards ease-in-out;
	}
	@keyframes hpElem4 {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			right: 50px;
    		top: 291px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: -10px;
			right: 1100px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 10px;
			right: 1070px;
			border-radius: 50%;
		}
	}


	/******************* Animate Home To Clients **********************/

	body.ltr.home-to-clients .globalElement4{
		animation: hcGlobElem4 3s forwards ease-in-out;
	}
	@keyframes hcGlobElem4 {
		0% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 538px;
			top: 350px;
			transform: rotate(60deg);
		}
		70% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 390px;
			top: 440px;
			transform: rotate(-60deg);
			z-index: -1;
		}
		100% { 
			border-radius: 21px;
			width: 48px;
			height: 48px;
			left: 400px;
			top: 420px;
			transform: rotate(-60deg);
			z-index: -1;
		}
	}
	body.ltr.home-to-clients .globalElement5{
		animation: hcGlobElem5 3s forwards ease-in-out;
	}
	@keyframes hcGlobElem5 {
		0% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			right: 55px;
			top: 30px;
			transform: rotate(60deg);
		}
		70% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			right: 55px;
			top: 90px;
			transform: rotate(60deg);
		}
		100% { 
			border-radius: 18px;
			width: 40px;
			height: 40px;
			right: 55px;
			top: 80px;
			transform: rotate(60deg);
		}
	}

	
	body.ltr.home-to-clients .globalElement2{
		animation: hcElem2 3s forwards ease-in-out;
	}

	@keyframes hcElem2 {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 550px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 250px;
			height: 250px;
			left: 425px;
			top: 525px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 250px;
			height: 250px;
			left: 405px;
			top: 495px;
			transform: rotate(135deg);
			
		}
	}


	body.ltr.home-to-clients .elementLine4{
		animation: hcElem4 3s forwards ease-in-out;
	}
	@keyframes hcElem4 {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			right: 60px;
    		top: 291px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 500px;
			right: 410px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 470px;
			right: 400px;
			border-radius: 50%;
		}
	}


	/*************Home to Contact Us************/

	body.ltr.home-to-contact .globalElement2{
		animation: hCtElem2 3s forwards ease-in-out;
	}
	
	@keyframes hCtElem2 {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 550px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1000px;
			top: -20px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 970px;
			top: 0px;
			transform: rotate(135deg);
			
		}
	}
	
	body.ltr.home-to-contact .elementLine4{
		animation: hCtElem4 3s forwards ease-in-out;
	}
	@keyframes hCtElem4 {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 291px;
			right: 50px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			right: 1050px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			right: 1020px;
			border-radius: 50%;
		}
	}

	/*************Home to Faqs************/

	body.ltr.home-to-faqs .globalElement2{
		animation: hftElem2 3s forwards ease-in-out;
	}
	
	@keyframes hfElem2 {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 550px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1000px;
			top: -20px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 970px;
			top: 0px;
			transform: rotate(135deg);
			
		}
	}
	
	body.ltr.home-to-faqs .elementLine4{
		animation: hfElem4 3s forwards ease-in-out;
	}
	@keyframes hfElem4 {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 291px;
			right: 50px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			right: 1050px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			right: 1020px;
			border-radius: 50%;
		}
	}

	/*********************Get A Quote*******************/
	.ltr .getQuoteMain{
		left: 100px;
	}
	.ltr .modalQuoEle1{
		left: 80px;
	}
	.ltr .modalQuoEle2{
		left: 260px;
	}
	.ltr .modalQuoEle3{
		left: 300px;
	}
	.ltr .modalQuoEle4{
		left: 80px;
	}
	.ltr .rightColContentQuote {
		padding: 10px 50px 0 10px;
	}

}

@media (max-width: 1050px) {
    .ltr .elementLine4 {
		top: 305px;
		right: 36px;
	}
	.ltr .globalElement2{
		left: 500px;
	}
	.ltr .globalElement4{
		left: 503px;
		top: 364px;
	}
	.ltr .globalElement5{
		right: 42px;
		top: 46px;
	}
	
	
	@keyframes haElem4 {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 305px;
			right: 36px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 120px;
			right: 950px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 120px;
			right: 900px;
			border-radius: 50%;
		}
	}

	@keyframes ahElem4 {
		0% { 
			z-index: -1;
			width: 300px;
			height: 300px;
			top: 120px;
			right: 900px;
			border-radius: 50%;
		}
		70% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 315px;
			right: 20px;
			border-radius: 54px;
		}
		100% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 305px;
			right: 36px;
			border-radius: 54px;
		}
	}

	@keyframes ahElem3 {
		0% { 
			border-radius: 80px;
			width: 284px;
			height: 284px;
			left: 50px;
			bottom: 72px;
			transform: rotate(66deg);
			background: var(--bgGradient);
		}
		70% { 
			border-radius: 80px;
			width: 250px;
			height: 250px;
			left: 96%;
			bottom: -120px;
			transform: rotate(70deg);
			// background: linear-gradient(120deg, rgba(255, 255, 255, .8) 0%, rgba(255, 255, 255, 0) 100%);
		}
		100% { 
			border-radius: 80px;
			width: 250px;
			height: 250px;
			left: 95%;
			bottom: -100px;
			transform: rotate(70deg);
			background: linear-gradient(120deg, rgba(255, 255, 255, .8) 0%, rgba(255, 255, 255, 0) 100%);
			
			
		}
	}
	@keyframes haElem3 {
		0% { 
			border-radius: 80px;
			width: 250px;
			height: 250px;
			left: 95%;
			bottom: -100px;
			transform: rotate(70deg);
			background: linear-gradient(120deg, rgba(255, 255, 255, .8) 0%, rgba(255, 255, 255, 0) 100%);
			// transition: all 2s ease;
		}
		70% { 
			border-radius: 80px;
			width: 300px;
			height: 300px;
			left: 20px;
			bottom: 50px;
			transform: rotate(70deg);
			background: var(--bgGradient);
		}
		100% { 
			border-radius: 80px;
			width: 284px;
			height: 284px;
			left: 50px;
			bottom: 72px;
			transform: rotate(66deg);
			background: var(--bgGradient);
			
		}
	}

	@keyframes haElem2 {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 500px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 55px;
			width: 150px;
			height: 150px;
			left: 980px;
			top: 30px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 55px;
			width: 150px;
			height: 150px;
			left: 950px;
			top: 30px;
			transform: rotate(135deg);
			
		}
	}
	@keyframes ahElem2 {
		0% { 
			border-radius: 55px;
			width: 150px;
			height: 150px;
			left: 980px;
			top: 30px;
			transform: rotate(135deg);
		}
		70% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 480px;
			top: 50px;
			transform: rotate(70deg);
		}
		100% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 500px;
			top: 50px;
			transform: rotate(70deg);
		}
	}

	@keyframes haElem1 {
		0% { 
			border-radius: 17px;
			width: 40px;
			height: 40px;
			left: 200px;
			top: 0px;
			transform: rotate(70deg);
			background: linear-gradient(120deg, rgba(255, 255, 255, .8) 0%, rgba(255, 255, 255, 0) 100%);
		}
		50% { 
			left: 280px;
			top: 360px;
			width: 130px;
			height: 130px;
			border-radius: 47px;
			transform: rotate(120deg);
			/* Add the blur effect */
			backdrop-filter: blur(10px);
			-webkit-backdrop-filter: blur(10px);
			z-index: 5;
		}
		100% { 
			left: 240px;
			top: 320px;
			width: 130px;
			height: 130px;
			border-radius: 47px;
			transform: rotate(120deg);
			/* Add the blur effect */
			backdrop-filter: blur(10px);
			-webkit-backdrop-filter: blur(10px);
			z-index: 5;
			
		}
	}
	@keyframes ahElem1 {
		0% { 
			left: 240px;
			top: 320px;
			width: 130px;
			height: 130px;
			border-radius: 47px;
			transform: rotate(120deg);
			/* Add the blur effect */
			backdrop-filter: blur(10px);
			-webkit-backdrop-filter: blur(10px);
			z-index: 5;
		}
		70% { 
			border-radius: 17px;
			width: 40px;
			height: 40px;
			left: 180px;
			top: -30px;
			transform: rotate(70deg);
			background: linear-gradient(120deg, rgba(255, 255, 255, .8) 0%, rgba(255, 255, 255, 0) 100%);
		}
		100% { 
			border-radius: 17px;
			width: 40px;
			height: 40px;
			left: 200px;
			top: 0px;
			transform: rotate(70deg);
			background: linear-gradient(120deg, rgba(255, 255, 255, .8) 0%, rgba(255, 255, 255, 0) 100%);
			// backdrop-filter: blur(10px);
			// -webkit-backdrop-filter: blur(10px);
			// transition: all 2s ease;
			
		}
	}

	/*************Home to Services************/
	body.ltr.home-to-services .globalElement2{
		animation: hsElem2 3s forwards ease-in-out;
	}

	@keyframes hsElem2 {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 750px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 930px;
			top: 60px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 900px;
			top: 60px;
			transform: rotate(135deg);
			
		}
	}

	/*************Home to Team************/
	
	body.ltr.home-to-team .globalElement2{
		animation: htElem2 3s forwards ease-in-out;
	}

	@keyframes htElem2 {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 550px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 980px;
			top: 40px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 950px;
			top: 40px;
			transform: rotate(135deg);
			
		}
	}

	body.ltr.home-to-team .elementLine4{
		animation: htElem4 3s forwards ease-in-out;
	}
	@keyframes htElem4 {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			right: 60px;
			top: 291px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 425px;
			right: 980px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 410px;
			right: 950px;
			border-radius: 50%;
		}
	}


	/*************Home to Portfolio************/

	body.ltr.home-to-portfolio .globalElement2{
		animation: hpElem2 3s forwards ease-in-out;
	}
	
	@keyframes hpElem2 {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 500px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 1000px;
			top: -20px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 970px;
			top: 0px;
			transform: rotate(135deg);
			
		}
	}
	
	body.ltr.home-to-portfolio .elementLine4{
		animation: hpElem4 3s forwards ease-in-out;
	}
	@keyframes hpElem4 {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 305px;
    		right: 36px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: -10px;
			right: 1050px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 10px;
			right: 1020px;
			border-radius: 50%;
		}
	}


	/*************Home to Testimonials************/
	
	body.ltr.home-to-testimonials .globalElement2{
		animation: htesElem2 3s forwards ease-in-out;
	}

	@keyframes htesElem2 {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 550px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 980px;
			top: 40px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 950px;
			top: 40px;
			transform: rotate(135deg);
			
		}
	}

	body.ltr.home-to-testimonials .elementLine4{
		animation: htesElem4 3s forwards ease-in-out;
	}
	@keyframes htesElem4 {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			right: 60px;
			top: 291px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 425px;
			right: 980px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 410px;
			right: 950px;
			border-radius: 50%;
		}
	}

	/*************Home to Contact Us************/

	body.ltr.home-to-contact .globalElement2{
		animation: hCtElem2 3s forwards ease-in-out;
	}

	@keyframes hCtElem2 {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 500px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 950px;
			top: -20px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 920px;
			top: 0px;
			transform: rotate(135deg);
			
		}
	}

	body.ltr.home-to-contact .elementLine4{
		animation: hCtElem4 3s forwards ease-in-out;
	}
	@keyframes hCtElem4 {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 305px;
    		right: 36px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			right: 1000px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			right: 970px;
			border-radius: 50%;
		}
	}

	/*************Home to Faqs************/

	body.ltr.home-to-faqs .globalElement2{
		animation: hfElem2 3s forwards ease-in-out;
	}

	@keyframes hfElem2 {
		0% { 
			border-radius: 35px;
			width: 100px;
			height: 100px;
			left: 500px;
			top: 50px;
			transform: rotate(70deg);
		}
		70% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 950px;
			top: -20px;
			transform: rotate(140deg);
		}
		100% { 
			border-radius: 50%;
			width: 150px;
			height: 150px;
			left: 920px;
			top: 0px;
			transform: rotate(135deg);
			
		}
	}

	body.ltr.home-to-faqs .elementLine4{
		animation: hfElem4 3s forwards ease-in-out;
	}
	@keyframes hfElem4 {
		0% { 
			z-index: 0;
			width: 144px;
			height: 144px;
			top: 305px;
    		right: 36px;
			border-radius: 54px;
		}
		70% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			right: 1000px;
			border-radius: 50%;
		}
		100% { 
			z-index: -1;
			width: 200px;
			height: 200px;
			top: 180px;
			right: 970px;
			border-radius: 50%;
		}
	}

	/*********************Get A Quote*******************/
	/* .getQuoteMain{
		left: 55px;
	}
	.modalQuoEle1{
		left: 40px;
	}
	.modalQuoEle2{
		left: 220px;
	}
	.modalQuoEle3{
		left: 260px;
	}
	.modalQuoEle4{
		left: 50px;
	} */
	.ltr .rightColContentQuote {
		padding: 10px 70px 0 10px;
	}

}



/******************* Animate About to All **********************/

	/******************* Animate About to Services **********************/

	@media (max-width: 1550px) {
		body.ltr.about-to-services .globalElement2{
			animation: asElem2 3s forwards ease-in-out;
		}
		@keyframes asElem2 {
			0% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				left: 1420px;
				top: 30px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1400px;
				top: 60px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1370px;
				top: 60px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.about-to-services .elementLine4{
			animation: asElem4 3s forwards ease-in-out;
		}
		@keyframes asElem4 {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1380px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				right: -125px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				right: -95px;
				border-radius: 50%;
			}
		}

				
		body.ltr.about-to-services .elementLine4:before{
			animation: asElem4Before 3s forwards ease-in-out;
		}
		@keyframes asElem4Before {
			0% { 
				z-index: -1;
				width: 342px;
				height: 342px;
				top: -22px;
				left: -22px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 242px;
				height: 242px;
				top: -22px;
				left: -22px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 242px;
				height: 242px;
				top: -22px;
				left: -22px;
				border-radius: 50%;
			}
		}


		body.ltr.about-to-services .elementLine4:after{
			animation: asElem4After 3s forwards ease-in-out;
		}
		@keyframes asElem4After {
			0% { 
				z-index: -1;
				width: 384px;
				height: 384px;
				top: -43px;
				left: -43px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: -1;
				width: 284px;
				height: 284px;
				top: -43px;
				left: -43px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 284px;
				height: 284px;
				top: -43px;
				left: -43px;
				border-radius: 50%;
			}
		}


	}
	@media (max-width: 1499px) {
		body.ltr.home-to-services .globalElement2{
			animation: asElem2 3s forwards ease-in-out;
		}
	
		@keyframes asElem2 {
			0% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				left: 1420px;
				top: 30px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1390px;
				top: 60px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1360px;
				top: 60px;
				transform: rotate(135deg);
				
			}
			
		}
	}
	/* @media (max-width: 1440px) {
		body.ltr.home-to-services .globalElement2{
			animation: asElem2 3s forwards ease-in-out;
		}
	
		@keyframes asElem2 {
			0% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				left: 1420px;
				top: 30px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1390px;
				top: 60px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1360px;
				top: 60px;
				transform: rotate(135deg);
				
			}
			
		}
	} */
	@media (max-width: 1440px) {
		body.ltr.about-to-services .globalElement2{
			animation: asElem2 3s forwards ease-in-out;
		}
		
		@keyframes asElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1300px;
				top: 60px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1330px;
				top: 60px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1300px;
				top: 60px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.about-to-services .elementLine4{
			animation: asElem4 3s forwards ease-in-out;
		}
		@keyframes asElem4 {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1300px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				right: -115px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				right: -95px;
				border-radius: 50%;
			}
		}
	}
	@media (max-width: 1399px) {
		body.ltr.about-to-services .globalElement2{
			animation: asElem2 3s forwards ease-in-out;
		}
		
		@keyframes asElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1300px;
				top: 60px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1290px;
				top: 60px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1260px;
				top: 60px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.about-to-services .elementLine4{
			animation: asElem4 3s forwards ease-in-out;
		}
		@keyframes asElem4 {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1260px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				right: -115px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				right: -95px;
				border-radius: 50%;
			}
		}
	}

	@media (max-width: 1350px) {
		body.ltr.about-to-services .globalElement2{
			animation: asElem2 3s forwards ease-in-out;
		}
		
		@keyframes asElem2 {
			0% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				left: 1220px;
				top: 30px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1230px;
				top: 60px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1200px;
				top: 60px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.about-to-services .elementLine4{
			animation: asElem4 3s forwards ease-in-out;
		}
		@keyframes asElem4 {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1200px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				right: -115px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				right: -95px;
				border-radius: 50%;
			}
		}
	}

	@media (max-width: 1299px) {
		body.ltr.about-to-services .globalElement2{
			animation: asElem2 3s forwards ease-in-out;
		}
		
		@keyframes asElem2 {
			0% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				left: 1220px;
				top: 30px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1180px;
				top: 60px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1150px;
				top: 60px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.about-to-services .elementLine4{
			animation: asElem4 3s forwards ease-in-out;
		}
		@keyframes asElem4 {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1150px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				right: -115px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				right: -95px;
				border-radius: 50%;
			}
		}
	}
	@media (max-width: 1250px) {
		body.ltr.about-to-services .globalElement2{
			animation: asElem2 3s forwards ease-in-out;
		}
		
		@keyframes asElem2 {
			0% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				left: 1120px;
				top: 30px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1130px;
				top: 60px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1100px;
				top: 60px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.about-to-services .elementLine4{
			animation: asElem4 3s forwards ease-in-out;
		}
		@keyframes asElem4 {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1100px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				right: -115px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				right: -95px;
				border-radius: 50%;
			}
		}
	}

	/******************* Animate About to Portfolio **********************/

	@media (max-width: 1550px) {
		body.ltr.about-to-portfolio .globalElement2{
			animation: apElem2 3s forwards ease-in-out;
		}
		@keyframes apElem2 {
			0% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				left: 1420px;
				top: 30px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1400px;
				top: -20px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1370px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.about-to-portfolio .elementLine4{
			animation: apElem4 3s forwards ease-in-out;
		}
		@keyframes apElem4 {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1380px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: -10px;
				right: 1430px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				right: 1400px;
				border-radius: 50%;
			}
		}

		
	}

	@media (max-width: 1440px) {
		body.ltr.about-to-portfolio .globalElement2{
			animation: apElem2 3s forwards ease-in-out;
		}
		
		@keyframes apElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1300px;
				top: 60px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1350px;
				top: -20px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1320px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.about-to-portfolio .elementLine4{
			animation: apElem4 3s forwards ease-in-out;
		}
		@keyframes apElem4 {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1300px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: -10px;
				right: 1380px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				right: 1350px;
				border-radius: 50%;
			}
		}
	}
	@media (max-width: 1399px) {
		body.ltr.about-to-portfolio .globalElement2{
			animation: apElem2 3s forwards ease-in-out;
		}
		
		@keyframes apElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1300px;
				top: 60px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1300px;
				top: -20px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1270px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.about-to-portfolio .elementLine4{
			animation: apElem4 3s forwards ease-in-out;
		}
		@keyframes apElem4 {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1260px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: -10px;
				right: 1350px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				right: 1300px;
				border-radius: 50%;
			}
		}
	}

	@media (max-width: 1350px) {
		body.ltr.about-to-portfolio .globalElement2{
			animation: apElem2 3s forwards ease-in-out;
		}
		
		@keyframes apElem2 {
			0% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				left: 1220px;
				top: 30px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1250px;
				top: -20px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1220px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.about-to-portfolio .elementLine4{
			animation: apElem4 3s forwards ease-in-out;
		}
		@keyframes apElem4 {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1200px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: -10px;
				right: 1300px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				right: 1250px;
				border-radius: 50%;
			}
		}
	}

	@media (max-width: 1299px) {
		body.ltr.about-to-portfolio .globalElement2{
			animation: apElem2 3s forwards ease-in-out;
		}
		
		@keyframes apElem2 {
			0% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				left: 1220px;
				top: 30px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1200px;
				top: -20px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1170px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.about-to-portfolio .elementLine4{
			animation: apElem4 3s forwards ease-in-out;
		}
		@keyframes apElem4 {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1150px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: -10px;
				right: 1250px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				right: 1220px;
				border-radius: 50%;
			}
		}
	}
	@media (max-width: 1250px) {
		body.ltr.about-to-portfolio .globalElement2{
			animation: apElem2 3s forwards ease-in-out;
		}
		
		@keyframes apElem2 {
			0% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				left: 1120px;
				top: 30px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1150px;
				top: -20px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1120px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.about-to-portfolio .elementLine4{
			animation: apElem4 3s forwards ease-in-out;
		}
		@keyframes apElem4 {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1100px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: -10px;
				right: 1200px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				right: 1170px;
				border-radius: 50%;
			}
		}
	}

	/******************* Animate About to Team **********************/

	@media (max-width: 1550px) {
		body.ltr.about-to-team .globalElement2{
			animation: atElem2 3s forwards ease-in-out;
		}
		@keyframes atElem2 {
			0% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				left: 1420px;
				top: 30px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1400px;
				top: 40px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1370px;
				top: 40px;
				transform: rotate(135deg);
				
			}
			
		}
		
		body.ltr.about-to-team .elementLine4{
			animation: atElem4 3s forwards ease-in-out;
		}
		@keyframes atElem4 {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1380px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 445px;
				right: 1430px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1400px;
				border-radius: 50%;
			}
		}
 		
		
	}

	@media (max-width: 1440px) {
		body.ltr.about-to-team .globalElement2{
			animation: atElem2 3s forwards ease-in-out;
		}
		
		@keyframes atElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1300px;
				top: 60px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1330px;
				top: 40px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1300px;
				top: 40px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.about-to-team .elementLine4{
			animation: atElem4 3s forwards ease-in-out;
		}
		@keyframes atElem4 {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1300px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 445px;
				right: 1330px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1300px;
				border-radius: 50%;
			}
		}
	}
	@media (max-width: 1399px) {
		body.ltr.about-to-team .globalElement2{
			animation: atElem2 3s forwards ease-in-out;
		}
		
		@keyframes atElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1300px;
				top: 60px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1280px;
				top: 40px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1250px;
				top: 40px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.about-to-team .elementLine4{
			animation: atElem4 3s forwards ease-in-out;
		}
		@keyframes atElem4 {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1260px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 445px;
				right: 1280px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1250px;
				border-radius: 50%;
			}
		}
	}

	@media (max-width: 1350px) {
		body.ltr.about-to-team .globalElement2{
			animation: atElem2 3s forwards ease-in-out;
		}
		
		@keyframes atElem2 {
			0% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				left: 1220px;
				top: 30px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1230px;
				top: 40px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1180px;
				top: 40px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.about-to-team .elementLine4{
			animation: atElem4 3s forwards ease-in-out;
		}
		@keyframes atElem4 {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1200px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 445px;
				right: 1230px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1180px;
				border-radius: 50%;
			}
		}
	}

	@media (max-width: 1299px) {
		body.ltr.about-to-team .globalElement2{
			animation: atElem2 3s forwards ease-in-out;
		}
		
		@keyframes atElem2 {
			0% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				left: 1220px;
				top: 30px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1180px;
				top: 40px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1130px;
				top: 40px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.about-to-team .elementLine4{
			animation: atElem4 3s forwards ease-in-out;
		}
		@keyframes atElem4 {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1150px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 445px;
				right: 1180px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1130px;
				border-radius: 50%;
			}
		}
	}
	@media (max-width: 1250px) {
		body.ltr.about-to-team .globalElement2{
			animation: atElem2 3s forwards ease-in-out;
		}
		
		@keyframes atElem2 {
			0% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				left: 1120px;
				top: 30px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1130px;
				top: 40px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1100px;
				top: 40px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.about-to-team .elementLine4{
			animation: atElem4 3s forwards ease-in-out;
		}
		@keyframes atElem4 {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1100px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 445px;
				right: 1130px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1100px;
				border-radius: 50%;
			}
		}
	}

	/******************* Animate About to Testimonials **********************/

	@media (max-width: 1550px) {
		body.ltr.about-to-testimonials .globalElement2{
			animation: atesElem2 3s forwards ease-in-out;
		}
		@keyframes atesElem2 {
			0% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				left: 1420px;
				top: 30px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1400px;
				top: 40px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1370px;
				top: 40px;
				transform: rotate(135deg);
				
			}
			
		}
		
		body.ltr.about-to-testimonials .elementLine4{
			animation: atesElem4 3s forwards ease-in-out;
		}
		@keyframes atesElem4 {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1380px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 445px;
				right: 1430px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1400px;
				border-radius: 50%;
			}
		}
 		
		
	}

	@media (max-width: 1440px) {
		body.ltr.about-to-testimonials .globalElement2{
			animation: atesElem2 3s forwards ease-in-out;
		}
		
		@keyframes atesElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1300px;
				top: 60px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1330px;
				top: 40px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1300px;
				top: 40px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.about-to-testimonials .elementLine4{
			animation: atesElem4 3s forwards ease-in-out;
		}
		@keyframes atesElem4 {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1300px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 445px;
				right: 1330px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1300px;
				border-radius: 50%;
			}
		}
	}
	@media (max-width: 1399px) {
		body.ltr.about-to-testimonials .globalElement2{
			animation: atesElem2 3s forwards ease-in-out;
		}
		
		@keyframes atesElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1300px;
				top: 60px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1280px;
				top: 40px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1250px;
				top: 40px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.about-to-testimonials .elementLine4{
			animation: atesElem4 3s forwards ease-in-out;
		}
		@keyframes atesElem4 {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1260px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 445px;
				right: 1280px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1250px;
				border-radius: 50%;
			}
		}
	}

	@media (max-width: 1350px) {
		body.ltr.about-to-testimonials .globalElement2{
			animation: atesElem2 3s forwards ease-in-out;
		}
		
		@keyframes atesElem2 {
			0% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				left: 1220px;
				top: 30px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1230px;
				top: 40px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1180px;
				top: 40px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.about-to-testimonials .elementLine4{
			animation: atesElem4 3s forwards ease-in-out;
		}
		@keyframes atesElem4 {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1200px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 445px;
				right: 1230px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1180px;
				border-radius: 50%;
			}
		}
	}

	@media (max-width: 1299px) {
		body.ltr.about-to-testimonials .globalElement2{
			animation: atesElem2 3s forwards ease-in-out;
		}
		
		@keyframes atesElem2 {
			0% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				left: 1220px;
				top: 30px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1180px;
				top: 40px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1130px;
				top: 40px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.about-to-testimonials .elementLine4{
			animation: atesElem4 3s forwards ease-in-out;
		}
		@keyframes atesElem4 {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1150px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 445px;
				right: 1180px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1130px;
				border-radius: 50%;
			}
		}
	}
	@media (max-width: 1250px) {
		body.ltr.about-to-testimonials .globalElement2{
			animation: atesElem2 3s forwards ease-in-out;
		}
		
		@keyframes atesElem2 {
			0% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				left: 1120px;
				top: 30px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1130px;
				top: 40px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1100px;
				top: 40px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.about-to-testimonials .elementLine4{
			animation: atesElem4 3s forwards ease-in-out;
		}
		@keyframes atesElem4 {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1100px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 445px;
				right: 1130px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1100px;
				border-radius: 50%;
			}
		}
	}

	/******************* Animate About to Clients **********************/

	@media (max-width: 1550px) {
		/* body.ltr.about-to-clients .globalElement1{
			animation: acElem1 3s forwards ease-in-out;
		}
		@keyframes acElem1 {
			0% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				left: 100px;
				top: -20px;
				transform: rotate(-70deg);
			}
			70% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				left: 90px;
				top: -25px;
				transform: rotate(-70deg);
			}
			100% { 
				border-radius: 35px;
				width: 100px;
				height: 100px;
				left: 100px;
				top: -20px;
				transform: rotate(-70deg);
			}
		}
		 */
		body.ltr.about-to-clients .globalElement2{
			animation: acElem2 3s forwards ease-in-out;
		}
		@keyframes acElem2 {
			0% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				left: 1420px;
				top: 30px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 250px;
				height: 250px;
				left: 575px;
				top: 525px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 250px;
				height: 250px;
				left: 575px;
				top: 495px;
				transform: rotate(135deg);
				
			}
			
		}
		
		body.ltr.about-to-clients .elementLine4{
			animation: acElem4 3s forwards ease-in-out;
		}
		@keyframes acElem4 {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1380px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 500px;
				right: 610px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 470px;
				right: 600px;
				border-radius: 50%;
			}
		}
 		
		
	}

	@media (max-width: 1440px) {
		body.ltr.about-to-clients .globalElement2{
			animation: acElem2 3s forwards ease-in-out;
		}
		
		@keyframes acElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1300px;
				top: 60px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 250px;
				height: 250px;
				left: 565px;
				top: 525px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 250px;
				height: 250px;
				left: 565px;
				top: 495px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.about-to-clients .elementLine4{
			animation: acElem4 3s forwards ease-in-out;
		}
		@keyframes acElem4 {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1300px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 500px;
				right: 550px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 470px;
				right: 540px;
				border-radius: 50%;
			}
		}
	}
	@media (max-width: 1399px) {
		body.ltr.about-to-clients .globalElement2{
			animation: acElem2 3s forwards ease-in-out;
		}
		
		@keyframes acElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1300px;
				top: 60px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 250px;
				height: 250px;
				left: 525px;
				top: 525px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 250px;
				height: 250px;
				left: 525px;
				top: 495px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.about-to-clients .elementLine4{
			animation: acElem4 3s forwards ease-in-out;
		}
		@keyframes acElem4 {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1260px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 500px;
				right: 550px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 470px;
				right: 540px;
				border-radius: 50%;
			}
		}
	}

	@media (max-width: 1350px) {
		body.ltr.about-to-clients .globalElement2{
			animation: acElem2 3s forwards ease-in-out;
		}
		
		@keyframes acElem2 {
			0% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				left: 1220px;
				top: 30px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 250px;
				height: 250px;
				left: 465px;
				top: 525px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 250px;
				height: 250px;
				left: 465px;
				top: 495px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.about-to-clients .elementLine4{
			animation: acElem4 3s forwards ease-in-out;
		}
		@keyframes acElem4 {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1200px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 500px;
				right: 550px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 470px;
				right: 540px;
				border-radius: 50%;
			}
		}
	}

	@media (max-width: 1299px) {
		body.ltr.about-to-clients .globalElement2{
			animation: acElem2 3s forwards ease-in-out;
		}
		
		@keyframes acElem2 {
			0% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				left: 1220px;
				top: 30px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 250px;
				height: 250px;
				left: 425px;
				top: 525px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 250px;
				height: 250px;
				left: 415px;
				top: 495px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.about-to-clients .elementLine4{
			animation: acElem4 3s forwards ease-in-out;
		}
		@keyframes acElem4 {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1150px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 500px;
				right: 550px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 470px;
				right: 540px;
				border-radius: 50%;
			}
		}
	}
	@media (max-width: 1250px) {
		body.ltr.about-to-clients .globalElement2{
			animation: acElem2 3s forwards ease-in-out;
		}
		
		@keyframes acElem2 {
			0% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				left: 1120px;
				top: 30px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 250px;
				height: 250px;
				left: 375px;
				top: 525px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 250px;
				height: 250px;
				left: 365px;
				top: 495px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.about-to-clients .elementLine4{
			animation: acElem4 3s forwards ease-in-out;
		}
		@keyframes acElem4 {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1100px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 500px;
				right: 550px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 470px;
				right: 540px;
				border-radius: 50%;
			}
		}
	}

	/******************* Animate About to Contact **********************/

	@media (max-width: 1550px) {
		body.ltr.about-to-contact .globalElement2{
			animation: aCtElem2 3s forwards ease-in-out;
		}
		@keyframes aCtElem2 {
			0% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				left: 1420px;
				top: 30px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1400px;
				top: -20px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1370px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			
		}
		
		body.ltr.about-to-contact .elementLine4{
			animation: aCtElem4 3s forwards ease-in-out;
		}
		@keyframes aCtElem4 {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1380px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1430px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1400px;
				border-radius: 50%;
			}
		}
 		
		
	}

	@media (max-width: 1440px) {
		body.ltr.about-to-contact .globalElement2{
			animation: aCtElem2 3s forwards ease-in-out;
		}
		
		@keyframes aCtElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1300px;
				top: 60px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1350px;
				top: -20px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1320px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.about-to-contact .elementLine4{
			animation: aCtElem4 3s forwards ease-in-out;
		}
		@keyframes aCtElem4 {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1300px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1380px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1350px;
				border-radius: 50%;
			}
		}
	}
	@media (max-width: 1399px) {
		body.ltr.about-to-contact .globalElement2{
			animation: aCtElem2 3s forwards ease-in-out;
		}
		
		@keyframes aCtElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1300px;
				top: 60px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1300px;
				top: -20px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1270px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.about-to-contact .elementLine4{
			animation: aCtElem4 3s forwards ease-in-out;
		}
		@keyframes aCtElem4 {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1260px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1350px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1300px;
				border-radius: 50%;
			}
		}
	}

	@media (max-width: 1350px) {
		body.ltr.about-to-contact .globalElement2{
			animation: aCtElem2 3s forwards ease-in-out;
		}
		
		@keyframes aCtElem2 {
			0% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				left: 1220px;
				top: 30px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1250px;
				top: -20px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1220px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.about-to-contact .elementLine4{
			animation: aCtElem4 3s forwards ease-in-out;
		}
		@keyframes aCtElem4 {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1200px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1300px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1250px;
				border-radius: 50%;
			}
		}
	}

	@media (max-width: 1299px) {
		body.ltr.about-to-contact .globalElement2{
			animation: aCtElem2 3s forwards ease-in-out;
		}
		
		@keyframes aCtElem2 {
			0% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				left: 1220px;
				top: 30px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1200px;
				top: -20px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1170px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.about-to-contact .elementLine4{
			animation: aCtElem4 3s forwards ease-in-out;
		}
		@keyframes aCtElem4 {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1150px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1250px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1220px;
				border-radius: 50%;
			}
		}
	}
	@media (max-width: 1250px) {
		body.ltr.about-to-contact .globalElement2{
			animation: aCtElem2 3s forwards ease-in-out;
		}
		
		@keyframes aCtElem2 {
			0% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				left: 1120px;
				top: 30px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1150px;
				top: -20px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1120px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.about-to-contact .elementLine4{
			animation: aCtElem4 3s forwards ease-in-out;
		}
		@keyframes aCtElem4 {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1100px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1200px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1170px;
				border-radius: 50%;
			}
		}
	}


	/******************* Animate About to Faqs **********************/

	@media (max-width: 1550px) {
		body.ltr.about-to-faqs .globalElement2{
			animation: afElem2 3s forwards ease-in-out;
		}
		@keyframes afElem2 {
			0% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				left: 1420px;
				top: 30px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1400px;
				top: -20px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1370px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			
		}
		
		body.ltr.about-to-faqs .elementLine4{
			animation: afElem4 3s forwards ease-in-out;
		}
		@keyframes afElem4 {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1380px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1430px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1400px;
				border-radius: 50%;
			}
		}
 		
		
	}

	@media (max-width: 1440px) {
		body.ltr.about-to-faqs .globalElement2{
			animation: afElem2 3s forwards ease-in-out;
		}
		
		@keyframes afElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1300px;
				top: 60px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1350px;
				top: -20px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1320px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.about-to-faqs .elementLine4{
			animation: afElem4 3s forwards ease-in-out;
		}
		@keyframes aCtElem4 {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1300px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1380px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1350px;
				border-radius: 50%;
			}
		}
	}
	@media (max-width: 1399px) {
		body.ltr.about-to-faqs .globalElement2{
			animation: afElem2 3s forwards ease-in-out;
		}
		
		@keyframes afElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1300px;
				top: 60px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1300px;
				top: -20px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1270px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.about-to-faqs .elementLine4{
			animation: afElem4 3s forwards ease-in-out;
		}
		@keyframes afElem4 {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1260px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1350px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1300px;
				border-radius: 50%;
			}
		}
	}

	@media (max-width: 1350px) {
		body.ltr.about-to-faqs .globalElement2{
			animation: afElem2 3s forwards ease-in-out;
		}
		
		@keyframes afElem2 {
			0% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				left: 1220px;
				top: 30px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1250px;
				top: -20px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1220px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.about-to-faqs .elementLine4{
			animation: afElem4 3s forwards ease-in-out;
		}
		@keyframes afElem4 {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1200px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1300px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1250px;
				border-radius: 50%;
			}
		}
	}

	@media (max-width: 1299px) {
		body.ltr.about-to-faqs .globalElement2{
			animation: afElem2 3s forwards ease-in-out;
		}
		
		@keyframes afElem2 {
			0% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				left: 1220px;
				top: 30px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1200px;
				top: -20px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1170px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.about-to-faqs .elementLine4{
			animation: afElem4 3s forwards ease-in-out;
		}
		@keyframes afElem4 {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1150px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1250px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1220px;
				border-radius: 50%;
			}
		}
	}
	@media (max-width: 1250px) {
		body.ltr.about-to-faqs .globalElement2{
			animation: afElem2 3s forwards ease-in-out;
		}
		
		@keyframes afElem2 {
			0% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				left: 1120px;
				top: 30px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1150px;
				top: -20px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1120px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.about-to-faqs .elementLine4{
			animation: afElem4 3s forwards ease-in-out;
		}
		@keyframes afElem4 {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1100px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1200px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1170px;
				border-radius: 50%;
			}
		}
	}


/******************* Animate Services to All **********************/

	/******************* Animate Services to About **********************/

	@media (max-width: 1550px) {
		body.ltr.services-to-about .globalElement2{
			animation: saElem2 3s forwards ease-in-out;
		}
		@keyframes saElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1370px;
				top: 60px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				left: 1450px;
				top: 30px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				left: 1420px;
				top: 30px;
				transform: rotate(135deg);
			}
			
		}

		body.ltr.services-to-about .elementLine4{
			animation: saElem4 3s forwards ease-in-out;
		}
		@keyframes saElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				right: -95px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1460px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1380px;
				border-radius: 50%;
			}
		}

	}
	
	@media (max-width: 1440px) {
		body.ltr.services-to-about .globalElement2{
			animation: saElem2 3s forwards ease-in-out;
		}
		
		@keyframes saElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1300px;
				top: 60px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1330px;
				top: 60px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1300px;
				top: 60px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.services-to-about .elementLine4{
			animation: saElem4 3s forwards ease-in-out;
		}
		@keyframes saElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				right: -95px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1350px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1300px;
				border-radius: 50%;
			}
		}
	}
	@media (max-width: 1399px) {
		body.ltr.services-to-about .globalElement2{
			animation: saElem2 3s forwards ease-in-out;
		}
		
		@keyframes saElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1260px;
				top: 60px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1330px;
				top: 60px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1300px;
				top: 60px;
				transform: rotate(135deg);
			}
			
		}

		body.ltr.services-to-about .elementLine4{
			animation: saElem4 3s forwards ease-in-out;
		}
		@keyframes saElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				right: -95px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1300px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1260px;
				border-radius: 50%;
			}
		}
	}

	@media (max-width: 1350px) {
		body.ltr.services-to-about .globalElement2{
			animation: saElem2 3s forwards ease-in-out;
		}
		
		@keyframes saElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1200px;
				top: 60px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				left: 1250px;
				top: 30px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				left: 1220px;
				top: 30px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.services-to-about .elementLine4{
			animation: saElem4 3s forwards ease-in-out;
		}
		@keyframes saElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				right: -95px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1230px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1200px;
				border-radius: 50%;
			}
		}
	}

	@media (max-width: 1299px) {
		body.ltr.services-to-about .globalElement2{
			animation: saElem2 3s forwards ease-in-out;
		}
		
		@keyframes saElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1150px;
				top: 60px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				left: 1250px;
				top: 30px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				left: 1220px;
				top: 30px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.services-to-about .elementLine4{
			animation: saElem4 3s forwards ease-in-out;
		}
		@keyframes saElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				right: -95px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1180px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1150px;
				border-radius: 50%;
			}
		}
	}
	@media (max-width: 1250px) {
		body.ltr.services-to-about .globalElement2{
			animation: saElem2 3s forwards ease-in-out;
		}
		
		@keyframes saElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1100px;
				top: 60px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				left: 1150px;
				top: 30px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				left: 1120px;
				top: 30px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.services-to-about .elementLine4{
			animation: saElem4 3s forwards ease-in-out;
		}
		@keyframes saElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				right: -95px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1130px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1100px;
				border-radius: 50%;
			}
		}
	}

	/******************* Animate Services to Portfolio **********************/

	@media (max-width: 1550px) {
		body.ltr.services-to-portfolio .globalElement2{
			animation: spElem2 3s forwards ease-in-out;
		}
		@keyframes spElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1370px;
				top: 60px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1400px;
				top: -20px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1370px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.services-to-portfolio .elementLine4{
			animation: spElem4 3s forwards ease-in-out;
		}
		@keyframes spElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				right: -95px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: -10px;
				right: 1430px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				right: 1400px;
				border-radius: 50%;
			}
		}

		
	}

	@media (max-width: 1440px) {
		body.ltr.services-to-portfolio .globalElement2{
			animation: spElem2 3s forwards ease-in-out;
		}
		
		@keyframes spElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1300px;
				top: 60px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1350px;
				top: -20px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1320px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.services-to-portfolio .elementLine4{
			animation: spElem4 3s forwards ease-in-out;
		}
		@keyframes spElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				right: -95px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: -10px;
				right: 1380px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				right: 1350px;
				border-radius: 50%;
			}
		}
	}
	@media (max-width: 1399px) {
		body.ltr.services-to-portfolio .globalElement2{
			animation: spElem2 3s forwards ease-in-out;
		}
		
		@keyframes spElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1260px;
				top: 60px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1300px;
				top: -20px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1270px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.services-to-portfolio .elementLine4{
			animation: spElem4 3s forwards ease-in-out;
		}
		@keyframes spElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				right: -95px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: -10px;
				right: 1350px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				right: 1300px;
				border-radius: 50%;
			}
		}
	}

	@media (max-width: 1350px) {
		body.ltr.services-to-portfolio .globalElement2{
			animation: spElem2 3s forwards ease-in-out;
		}
		
		@keyframes spElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1200px;
				top: 60px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1250px;
				top: -20px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1220px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.services-to-portfolio .elementLine4{
			animation: spElem4 3s forwards ease-in-out;
		}
		@keyframes spElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				right: -95px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: -10px;
				right: 1300px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				right: 1250px;
				border-radius: 50%;
			}
		}
	}

	@media (max-width: 1299px) {
		body.ltr.services-to-portfolio .globalElement2{
			animation: spElem2 3s forwards ease-in-out;
		}
		
		@keyframes spElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1150px;
				top: 60px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1200px;
				top: -20px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1170px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.services-to-portfolio .elementLine4{
			animation: apElem4 3s forwards ease-in-out;
		}
		@keyframes spElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				right: -95px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: -10px;
				right: 1250px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				right: 1220px;
				border-radius: 50%;
			}
		}
	}
	@media (max-width: 1250px) {
		body.ltr.services-to-portfolio .globalElement2{
			animation: spElem2 3s forwards ease-in-out;
		}
		
		@keyframes spElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1100px;
				top: 60px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1150px;
				top: -20px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1120px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.services-to-portfolio .elementLine4{
			animation: spElem4 3s forwards ease-in-out;
		}
		@keyframes spElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				right: -95px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: -10px;
				right: 1200px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				right: 1170px;
				border-radius: 50%;
			}
		}
	}

	/******************* Animate Services to Team **********************/

	@media (max-width: 1550px) {
		body.ltr.services-to-team .globalElement2{
			animation: stElem2 3s forwards ease-in-out;
		}
		@keyframes stElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1370px;
				top: 60px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1400px;
				top: 40px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1370px;
				top: 40px;
				transform: rotate(135deg);
				
			}
			
		}
		
		body.ltr.services-to-team .elementLine4{
			animation: stElem4 3s forwards ease-in-out;
		}
		@keyframes stElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				right: -95px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 445px;
				right: 1430px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1400px;
				border-radius: 50%;
			}
		}
 		
		
	}

	@media (max-width: 1440px) {
		body.ltr.services-to-team .globalElement2{
			animation: stElem2 3s forwards ease-in-out;
		}
		
		@keyframes stElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1300px;
				top: 60px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1330px;
				top: 40px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1300px;
				top: 40px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.services-to-team .elementLine4{
			animation: stElem4 3s forwards ease-in-out;
		}
		@keyframes stElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				right: -95px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 445px;
				right: 1330px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1300px;
				border-radius: 50%;
			}
		}
	}
	@media (max-width: 1399px) {
		body.ltr.services-to-team .globalElement2{
			animation: stElem2 3s forwards ease-in-out;
		}
		
		@keyframes stElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1260px;
				top: 60px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1280px;
				top: 40px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1250px;
				top: 40px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.services-to-team .elementLine4{
			animation: stElem4 3s forwards ease-in-out;
		}
		@keyframes stElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				right: -95px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 445px;
				right: 1280px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1250px;
				border-radius: 50%;
			}
		}
	}

	@media (max-width: 1350px) {
		body.ltr.services-to-team .globalElement2{
			animation: stElem2 3s forwards ease-in-out;
		}
		
		@keyframes stElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1200px;
				top: 60px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1230px;
				top: 40px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1180px;
				top: 40px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.services-to-team .elementLine4{
			animation: stElem4 3s forwards ease-in-out;
		}
		@keyframes stElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				right: -95px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 445px;
				right: 1230px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1180px;
				border-radius: 50%;
			}
		}
	}

	@media (max-width: 1299px) {
		body.ltr.services-to-team .globalElement2{
			animation: stElem2 3s forwards ease-in-out;
		}
		
		@keyframes stElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1150px;
				top: 60px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1180px;
				top: 40px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1130px;
				top: 40px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.services-to-team .elementLine4{
			animation: stElem4 3s forwards ease-in-out;
		}
		@keyframes stElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				right: -95px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 445px;
				right: 1180px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1130px;
				border-radius: 50%;
			}
		}
	}
	@media (max-width: 1250px) {
		body.ltr.services-to-team .globalElement2{
			animation: stElem2 3s forwards ease-in-out;
		}
		
		@keyframes stElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1100px;
				top: 60px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1130px;
				top: 40px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1100px;
				top: 40px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.services-to-team .elementLine4{
			animation: stElem4 3s forwards ease-in-out;
		}
		@keyframes stElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				right: -95px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 445px;
				right: 1130px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1100px;
				border-radius: 50%;
			}
		}
	}

	/******************* Animate Services to Testimonials **********************/

	@media (max-width: 1550px) {
		body.ltr.services-to-testimonials .globalElement2{
			animation: stesElem2 3s forwards ease-in-out;
		}
		@keyframes stesElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1370px;
				top: 60px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1400px;
				top: 40px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1370px;
				top: 40px;
				transform: rotate(135deg);
				
			}
			
		}
		
		body.ltr.services-to-testimonials .elementLine4{
			animation: stesElem4 3s forwards ease-in-out;
		}
		@keyframes stesElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				right: -95px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 445px;
				right: 1430px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1400px;
				border-radius: 50%;
			}
		}
 		
		
	}

	@media (max-width: 1440px) {
		body.ltr.services-to-testimonials .globalElement2{
			animation: stesElem2 3s forwards ease-in-out;
		}
		
		@keyframes stesElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1300px;
				top: 60px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1330px;
				top: 40px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1300px;
				top: 40px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.services-to-testimonials .elementLine4{
			animation: stesElem4 3s forwards ease-in-out;
		}
		@keyframes stesElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				right: -95px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 445px;
				right: 1330px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1300px;
				border-radius: 50%;
			}
		}
	}
	@media (max-width: 1399px) {
		body.ltr.services-to-testimonials .globalElement2{
			animation: stesElem2 3s forwards ease-in-out;
		}
		
		@keyframes stesElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1260px;
				top: 60px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1280px;
				top: 40px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1250px;
				top: 40px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.services-to-testimonials .elementLine4{
			animation: stesElem4 3s forwards ease-in-out;
		}
		@keyframes stesElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				right: -95px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 445px;
				right: 1280px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1250px;
				border-radius: 50%;
			}
		}
	}

	@media (max-width: 1350px) {
		body.ltr.services-to-testimonials .globalElement2{
			animation: stesElem2 3s forwards ease-in-out;
		}
		
		@keyframes stesElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1200px;
				top: 60px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1230px;
				top: 40px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1180px;
				top: 40px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.services-to-testimonials .elementLine4{
			animation: stesElem4 3s forwards ease-in-out;
		}
		@keyframes stesElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				right: -95px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 445px;
				right: 1230px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1180px;
				border-radius: 50%;
			}
		}
	}

	@media (max-width: 1299px) {
		body.ltr.services-to-testimonials .globalElement2{
			animation: stesElem2 3s forwards ease-in-out;
		}
		
		@keyframes stesElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1150px;
				top: 60px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1180px;
				top: 40px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1130px;
				top: 40px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.services-to-testimonials .elementLine4{
			animation: stesElem4 3s forwards ease-in-out;
		}
		@keyframes stesElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				right: -95px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 445px;
				right: 1180px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1130px;
				border-radius: 50%;
			}
		}
	}
	@media (max-width: 1250px) {
		body.ltr.services-to-testimonials .globalElement2{
			animation: stesElem2 3s forwards ease-in-out;
		}
		
		@keyframes stesElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1100px;
				top: 60px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1130px;
				top: 40px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1100px;
				top: 40px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.services-to-testimonials .elementLine4{
			animation: stesElem4 3s forwards ease-in-out;
		}
		@keyframes stesElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				right: -95px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 445px;
				right: 1130px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1100px;
				border-radius: 50%;
			}
		}
	}

	/******************* Animate Services to Clients **********************/

	@media (max-width: 1550px) {
		
		body.ltr.services-to-clients .globalElement2{
			animation: scElem2 3s forwards ease-in-out;
		}
		@keyframes scElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1370px;
				top: 60px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 250px;
				height: 250px;
				left: 575px;
				top: 525px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 250px;
				height: 250px;
				left: 575px;
				top: 495px;
				transform: rotate(135deg);
				
			}
			
		}
		
		body.ltr.services-to-clients .elementLine4{
			animation: scElem4 3s forwards ease-in-out;
		}
		@keyframes scElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				right: -95px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 500px;
				right: 610px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 470px;
				right: 600px;
				border-radius: 50%;
			}
		}
 		
		
	}

	@media (max-width: 1440px) {
		body.ltr.services-to-clients .globalElement2{
			animation: scElem2 3s forwards ease-in-out;
		}
		
		@keyframes scElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1300px;
				top: 60px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 250px;
				height: 250px;
				left: 565px;
				top: 525px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 250px;
				height: 250px;
				left: 565px;
				top: 495px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.services-to-clients .elementLine4{
			animation: scElem4 3s forwards ease-in-out;
		}
		@keyframes scElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				right: -95px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 500px;
				right: 550px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 470px;
				right: 540px;
				border-radius: 50%;
			}
		}
	}
	@media (max-width: 1399px) {
		body.ltr.services-to-clients .globalElement2{
			animation: scElem2 3s forwards ease-in-out;
		}
		
		@keyframes scElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1260px;
				top: 60px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 250px;
				height: 250px;
				left: 525px;
				top: 525px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 250px;
				height: 250px;
				left: 525px;
				top: 495px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.services-to-clients .elementLine4{
			animation: scElem4 3s forwards ease-in-out;
		}
		@keyframes scElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				right: -95px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 500px;
				right: 550px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 470px;
				right: 540px;
				border-radius: 50%;
			}
		}
	}

	@media (max-width: 1350px) {
		body.ltr.services-to-clients .globalElement2{
			animation: scElem2 3s forwards ease-in-out;
		}
		
		@keyframes scElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1200px;
				top: 60px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 250px;
				height: 250px;
				left: 465px;
				top: 525px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 250px;
				height: 250px;
				left: 465px;
				top: 495px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.services-to-clients .elementLine4{
			animation: scElem4 3s forwards ease-in-out;
		}
		@keyframes scElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				right: -95px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 500px;
				right: 550px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 470px;
				right: 540px;
				border-radius: 50%;
			}
		}
	}

	@media (max-width: 1299px) {
		body.ltr.services-to-clients .globalElement2{
			animation: scElem2 3s forwards ease-in-out;
		}
		
		@keyframes scElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1150px;
				top: 60px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 250px;
				height: 250px;
				left: 425px;
				top: 525px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 250px;
				height: 250px;
				left: 415px;
				top: 495px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.services-to-clients .elementLine4{
			animation: scElem4 3s forwards ease-in-out;
		}
		@keyframes scElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				right: -95px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 500px;
				right: 550px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 470px;
				right: 540px;
				border-radius: 50%;
			}
		}
	}
	@media (max-width: 1250px) {
		body.ltr.services-to-clients .globalElement2{
			animation: scElem2 3s forwards ease-in-out;
		}
		
		@keyframes scElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1100px;
				top: 60px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 250px;
				height: 250px;
				left: 375px;
				top: 525px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 250px;
				height: 250px;
				left: 365px;
				top: 495px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.services-to-clients .elementLine4{
			animation: scElem4 3s forwards ease-in-out;
		}
		@keyframes scElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				right: -95px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 500px;
				right: 550px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 470px;
				right: 540px;
				border-radius: 50%;
			}
		}
	}

	/******************* Animate Services to Contact **********************/

	@media (max-width: 1550px) {
		body.ltr.services-to-contact .globalElement2{
			animation: sCtElem2 3s forwards ease-in-out;
		}
		@keyframes sCtElem2 {
			0% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				left: 1420px;
				top: 30px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1400px;
				top: -20px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1370px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			
		}
		
		body.ltr.services-to-contact .elementLine4{
			animation: sCtElem4 3s forwards ease-in-out;
		}
		@keyframes sCtElem4 {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1380px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1430px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1400px;
				border-radius: 50%;
			}
		}
 		
		
	}

	@media (max-width: 1440px) {
		body.ltr.services-to-contact .globalElement2{
			animation: sCtElem2 3s forwards ease-in-out;
		}
		
		@keyframes sCtElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1300px;
				top: 60px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1350px;
				top: -20px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1320px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.services-to-contact .elementLine4{
			animation: sCtElem4 3s forwards ease-in-out;
		}
		@keyframes aCtElem4 {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1300px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1380px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1350px;
				border-radius: 50%;
			}
		}
	}
	@media (max-width: 1399px) {
		body.ltr.services-to-contact .globalElement2{
			animation: sCtElem2 3s forwards ease-in-out;
		}
		
		@keyframes sCtElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1300px;
				top: 60px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1300px;
				top: -20px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1270px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.services-to-contact .elementLine4{
			animation: aCtElem4 3s forwards ease-in-out;
		}
		@keyframes sCtElem4 {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1260px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1350px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1300px;
				border-radius: 50%;
			}
		}
	}

	@media (max-width: 1350px) {
		body.ltr.services-to-contact .globalElement2{
			animation: sCtElem2 3s forwards ease-in-out;
		}
		
		@keyframes sCtElem2 {
			0% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				left: 1220px;
				top: 30px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1250px;
				top: -20px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1220px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.services-to-contact .elementLine4{
			animation: sCtElem4 3s forwards ease-in-out;
		}
		@keyframes sCtElem4 {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1200px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1300px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1250px;
				border-radius: 50%;
			}
		}
	}

	@media (max-width: 1299px) {
		body.ltr.services-to-contact .globalElement2{
			animation: sCtElem2 3s forwards ease-in-out;
		}
		
		@keyframes sCtElem2 {
			0% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				left: 1220px;
				top: 30px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1200px;
				top: -20px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1170px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.services-to-contact .elementLine4{
			animation: sCtElem4 3s forwards ease-in-out;
		}
		@keyframes sCtElem4 {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1150px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1250px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1220px;
				border-radius: 50%;
			}
		}
	}
	@media (max-width: 1250px) {
		body.ltr.services-to-contact .globalElement2{
			animation: sCtElem2 3s forwards ease-in-out;
		}
		
		@keyframes sCtElem2 {
			0% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				left: 1120px;
				top: 30px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1150px;
				top: -20px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1120px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.services-to-contact .elementLine4{
			animation: sCtElem4 3s forwards ease-in-out;
		}
		@keyframes sCtElem4 {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1100px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1200px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1170px;
				border-radius: 50%;
			}
		}
	}


	/******************* Animate Services to FAQs **********************/

	@media (max-width: 1550px) {
		body.ltr.services-to-faqs .globalElement2{
			animation: sfElem2 3s forwards ease-in-out;
		}
		@keyframes sfElem2 {
			0% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				left: 1420px;
				top: 30px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1400px;
				top: -20px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1370px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			
		}
		
		body.ltr.services-to-faqs .elementLine4{
			animation: sfElem4 3s forwards ease-in-out;
		}
		@keyframes sfElem4 {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1380px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1430px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1400px;
				border-radius: 50%;
			}
		}
 		
		
	}

	@media (max-width: 1440px) {
		body.ltr.services-to-faqs .globalElement2{
			animation: sfElem2 3s forwards ease-in-out;
		}
		
		@keyframes sfElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1300px;
				top: 60px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1350px;
				top: -20px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1320px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.services-to-faqs .elementLine4{
			animation: sfElem4 3s forwards ease-in-out;
		}
		@keyframes afElem4 {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1300px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1380px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1350px;
				border-radius: 50%;
			}
		}
	}
	@media (max-width: 1399px) {
		body.ltr.services-to-faqs .globalElement2{
			animation: sfElem2 3s forwards ease-in-out;
		}
		
		@keyframes sfElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1300px;
				top: 60px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1300px;
				top: -20px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1270px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.services-to-faqs .elementLine4{
			animation: afElem4 3s forwards ease-in-out;
		}
		@keyframes sfElem4 {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1260px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1350px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1300px;
				border-radius: 50%;
			}
		}
	}

	@media (max-width: 1350px) {
		body.ltr.services-to-faqs .globalElement2{
			animation: sfElem2 3s forwards ease-in-out;
		}
		
		@keyframes sCtElem2 {
			0% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				left: 1220px;
				top: 30px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1250px;
				top: -20px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1220px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.services-to-faqs .elementLine4{
			animation: sfElem4 3s forwards ease-in-out;
		}
		@keyframes sfElem4 {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1200px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1300px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1250px;
				border-radius: 50%;
			}
		}
	}

	@media (max-width: 1299px) {
		body.ltr.services-to-faqs .globalElement2{
			animation: sfElem2 3s forwards ease-in-out;
		}
		
		@keyframes sfElem2 {
			0% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				left: 1220px;
				top: 30px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1200px;
				top: -20px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1170px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.services-to-faqs .elementLine4{
			animation: sfElem4 3s forwards ease-in-out;
		}
		@keyframes sfElem4 {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1150px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1250px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1220px;
				border-radius: 50%;
			}
		}
	}
	@media (max-width: 1250px) {
		body.ltr.services-to-faqs .globalElement2{
			animation: sfElem2 3s forwards ease-in-out;
		}
		
		@keyframes sfElem2 {
			0% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				left: 1120px;
				top: 30px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1150px;
				top: -20px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1120px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.services-to-faqs .elementLine4{
			animation: sfElem4 3s forwards ease-in-out;
		}
		@keyframes sfElem4 {
			0% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1100px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1200px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1170px;
				border-radius: 50%;
			}
		}
	}


/******************* Animate Portfolio to All **********************/

	/******************* Animate Portfolio to About **********************/

	@media (max-width: 1550px) {
		body.ltr.portfolio-to-about .globalElement2{
			animation: paElem2 3s forwards ease-in-out;
		}
		@keyframes paElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1370px;
				top: 0px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				left: 1450px;
				top: 30px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				left: 1420px;
				top: 30px;
				transform: rotate(135deg);
			}
			
		}

		body.ltr.portfolio-to-about .elementLine4{
			animation: paElem4 3s forwards ease-in-out;
		}
		@keyframes paElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				right: 1400px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1460px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1380px;
				border-radius: 50%;
			}
		}

	}
	
	@media (max-width: 1440px) {
		body.ltr.portfolio-to-about .globalElement2{
			animation: paElem2 3s forwards ease-in-out;
		}
		
		@keyframes paElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1320px;
				top: 0px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				left: 1330px;
				top: 60px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				left: 1300px;
				top: 60px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.portfolio-to-about .elementLine4{
			animation: paElem4 3s forwards ease-in-out;
		}
		@keyframes paElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				right: 1350px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1350px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1300px;
				border-radius: 50%;
			}
		}
	}
	@media (max-width: 1399px) {
		body.ltr.portfolio-to-about .globalElement2{
			animation: paElem2 3s forwards ease-in-out;
		}
		
		@keyframes paElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1270px;
				top: 0px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				left: 1330px;
				top: 60px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				left: 1300px;
				top: 60px;
				transform: rotate(135deg);
			}
			
		}

		body.ltr.portfolio-to-about .elementLine4{
			animation: paElem4 3s forwards ease-in-out;
		}
		@keyframes paElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				right: 1300px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1300px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1260px;
				border-radius: 50%;
			}
		}
	}

	@media (max-width: 1350px) {
		body.ltr.portfolio-to-about .globalElement2{
			animation: paElem2 3s forwards ease-in-out;
		}
		
		@keyframes paElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1220px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				left: 1250px;
				top: 30px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				left: 1220px;
				top: 30px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.portfolio-to-about .elementLine4{
			animation: paElem4 3s forwards ease-in-out;
		}
		@keyframes paElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				right: 1250px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1230px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1200px;
				border-radius: 50%;
			}
		}
	}

	@media (max-width: 1299px) {
		body.ltr.portfolio-to-about .globalElement2{
			animation: paElem2 3s forwards ease-in-out;
		}
		
		@keyframes paElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1170px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				left: 1250px;
				top: 30px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				left: 1220px;
				top: 30px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.portfolio-to-about .elementLine4{
			animation: paElem4 3s forwards ease-in-out;
		}
		@keyframes paElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				right: 1220px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1180px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1150px;
				border-radius: 50%;
			}
		}
	}
	@media (max-width: 1250px) {
		body.ltr.portfolio-to-about .globalElement2{
			animation: paElem2 3s forwards ease-in-out;
		}
		
		@keyframes paElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1120px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				left: 1150px;
				top: 30px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				left: 1120px;
				top: 30px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.portfolio-to-about .elementLine4{
			animation: paElem4 3s forwards ease-in-out;
		}
		@keyframes paElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				right: 1170px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1130px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1100px;
				border-radius: 50%;
			}
		}
	}

	/******************* Animate Portfolio to Services **********************/

	@media (max-width: 1550px) {
		body.ltr.portfolio-to-services .globalElement2{
			animation: psElem2 3s forwards ease-in-out;
		}
		@keyframes psElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1370px;
				top: 0px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1400px;
				top: 60px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1370px;
				top: 60px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.portfolio-to-services .elementLine4{
			animation: psElem4 3s forwards ease-in-out;
		}
		@keyframes psElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				right: 1400px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				right: -125px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				right: -95px;
				border-radius: 50%;
			}
		}



	}
	
	
	@media (max-width: 1440px) {
		body.ltr.portfolio-to-services .globalElement2{
			animation: psElem2 3s forwards ease-in-out;
		}
		
		@keyframes psElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1320px;
				top: 0px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1330px;
				top: 60px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1300px;
				top: 60px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.portfolio-to-services .elementLine4{
			animation: psElem4 3s forwards ease-in-out;
		}
		@keyframes psElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				right: 1350px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				right: -115px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				right: -95px;
				border-radius: 50%;
			}
		}
	}
	@media (max-width: 1399px) {
		body.ltr.portfolio-to-services .globalElement2{
			animation: psElem2 3s forwards ease-in-out;
		}
		
		@keyframes psElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1270px;
				top: 0px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1290px;
				top: 60px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1260px;
				top: 60px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.portfolio-to-services .elementLine4{
			animation: psElem4 3s forwards ease-in-out;
		}
		@keyframes psElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				right: 1300px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				right: -115px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				right: -95px;
				border-radius: 50%;
			}
		}
	}

	@media (max-width: 1350px) {
		body.ltr.portfolio-to-services .globalElement2{
			animation: psElem2 3s forwards ease-in-out;
		}
		
		@keyframes psElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1220px;
				top: 0px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1230px;
				top: 60px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1200px;
				top: 60px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.portfolio-to-services .elementLine4{
			animation: psElem4 3s forwards ease-in-out;
		}
		@keyframes psElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				right: 1250px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				right: -115px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				right: -95px;
				border-radius: 50%;
			}
		}
	}

	@media (max-width: 1299px) {
		body.ltr.portfolio-to-services .globalElement2{
			animation: psElem2 3s forwards ease-in-out;
		}
		
		@keyframes psElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1170px;
				top: 0px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1180px;
				top: 60px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1150px;
				top: 60px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.portfolio-to-services .elementLine4{
			animation: psElem4 3s forwards ease-in-out;
		}
		@keyframes psElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				right: 1220px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				right: -115px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				right: -95px;
				border-radius: 50%;
			}
		}
	}
	@media (max-width: 1250px) {
		body.ltr.portfolio-to-services .globalElement2{
			animation: psElem2 3s forwards ease-in-out;
		}
		
		@keyframes psElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1120px;
				top: 0px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1130px;
				top: 60px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1100px;
				top: 60px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.portfolio-to-services .elementLine4{
			animation: psElem4 3s forwards ease-in-out;
		}
		@keyframes psElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				right: 1170px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				right: -115px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				right: -95px;
				border-radius: 50%;
			}
		}
	}

	/******************* Animate Portfolio to Team **********************/

	@media (max-width: 1550px) {
		body.ltr.portfolio-to-team .globalElement2{
			animation: ptElem2 3s forwards ease-in-out;
		}
		@keyframes ptElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1370px;
				top: 0px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1400px;
				top: 40px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1370px;
				top: 40px;
				transform: rotate(135deg);
				
			}
			
		}
		
		body.ltr.portfolio-to-team .elementLine4{
			animation: ptElem4 3s forwards ease-in-out;
		}
		@keyframes ptElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				right: 1400px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 445px;
				right: 1430px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1400px;
				border-radius: 50%;
			}
		}
 		
		
	}

	@media (max-width: 1440px) {
		body.ltr.portfolio-to-team .globalElement2{
			animation: ptElem2 3s forwards ease-in-out;
		}
		
		@keyframes ptElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1320px;
				top: 0px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1330px;
				top: 40px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1300px;
				top: 40px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.portfolio-to-team .elementLine4{
			animation: ptElem4 3s forwards ease-in-out;
		}
		@keyframes ptElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				right: 1350px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 445px;
				right: 1330px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1300px;
				border-radius: 50%;
			}
		}
	}
	@media (max-width: 1399px) {
		body.ltr.portfolio-to-team .globalElement2{
			animation: ptElem2 3s forwards ease-in-out;
		}
		
		@keyframes ptElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1270px;
				top: 0px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1280px;
				top: 40px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1250px;
				top: 40px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.portfolio-to-team .elementLine4{
			animation: ptElem4 3s forwards ease-in-out;
		}
		@keyframes ptElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				right: 1300px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 445px;
				right: 1280px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1250px;
				border-radius: 50%;
			}
		}
	}

	@media (max-width: 1350px) {
		body.ltr.portfolio-to-team .globalElement2{
			animation: ptElem2 3s forwards ease-in-out;
		}
		
		@keyframes ptElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1220px;
				top: 0px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1230px;
				top: 40px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1180px;
				top: 40px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.portfolio-to-team .elementLine4{
			animation: ptElem4 3s forwards ease-in-out;
		}
		@keyframes ptElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				right: 1250px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 445px;
				right: 1230px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1180px;
				border-radius: 50%;
			}
		}
	}

	@media (max-width: 1299px) {
		body.ltr.portfolio-to-team .globalElement2{
			animation: ptElem2 3s forwards ease-in-out;
		}
		
		@keyframes ptElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1170px;
				top: 0px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1180px;
				top: 40px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1130px;
				top: 40px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.portfolio-to-team .elementLine4{
			animation: ptElem4 3s forwards ease-in-out;
		}
		@keyframes ptElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				right: 1220px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 445px;
				right: 1180px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1130px;
				border-radius: 50%;
			}
		}
	}
	@media (max-width: 1250px) {
		body.ltr.portfolio-to-team .globalElement2{
			animation: ptElem2 3s forwards ease-in-out;
		}
		
		@keyframes ptElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1120px;
				top: 0px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1130px;
				top: 40px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1100px;
				top: 40px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.portfolio-to-team .elementLine4{
			animation: ptElem4 3s forwards ease-in-out;
		}
		@keyframes ptElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				right: 1170px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 445px;
				right: 1130px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1100px;
				border-radius: 50%;
			}
		}
	}

	/******************* Animate Portfolio to Testimonials **********************/

	@media (max-width: 1550px) {
		body.ltr.portfolio-to-testimonials .globalElement2{
			animation: ptesElem2 3s forwards ease-in-out;
		}
		@keyframes ptesElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1370px;
				top: 0px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1400px;
				top: 40px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1370px;
				top: 40px;
				transform: rotate(135deg);
				
			}
			
		}
		
		body.ltr.portfolio-to-testimonials .elementLine4{
			animation: ptesElem4 3s forwards ease-in-out;
		}
		@keyframes ptesElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				right: 1400px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 445px;
				right: 1430px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1400px;
				border-radius: 50%;
			}
		}
 		
		
	}

	@media (max-width: 1440px) {
		body.ltr.portfolio-to-testimonials .globalElement2{
			animation: ptesElem2 3s forwards ease-in-out;
		}
		
		@keyframes ptesElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1320px;
				top: 0px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1330px;
				top: 40px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1300px;
				top: 40px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.portfolio-to-testimonials .elementLine4{
			animation: ptesElem4 3s forwards ease-in-out;
		}
		@keyframes ptesElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				right: 1350px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 445px;
				right: 1330px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1300px;
				border-radius: 50%;
			}
		}
	}
	@media (max-width: 1399px) {
		body.ltr.portfolio-to-testimonials .globalElement2{
			animation: ptesElem2 3s forwards ease-in-out;
		}
		
		@keyframes ptesElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1270px;
				top: 0px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1280px;
				top: 40px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1250px;
				top: 40px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.portfolio-to-testimonials .elementLine4{
			animation: ptesElem4 3s forwards ease-in-out;
		}
		@keyframes ptesElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				right: 1300px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 445px;
				right: 1280px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1250px;
				border-radius: 50%;
			}
		}
	}

	@media (max-width: 1350px) {
		body.ltr.portfolio-to-testimonials .globalElement2{
			animation: ptesElem2 3s forwards ease-in-out;
		}
		
		@keyframes ptesElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1220px;
				top: 0px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1230px;
				top: 40px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1180px;
				top: 40px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.portfolio-to-testimonials .elementLine4{
			animation: ptesElem4 3s forwards ease-in-out;
		}
		@keyframes ptesElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				right: 1250px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 445px;
				right: 1230px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1180px;
				border-radius: 50%;
			}
		}
	}

	@media (max-width: 1299px) {
		body.ltr.portfolio-to-testimonials .globalElement2{
			animation: ptesElem2 3s forwards ease-in-out;
		}
		
		@keyframes ptesElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1170px;
				top: 0px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1180px;
				top: 40px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1130px;
				top: 40px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.portfolio-to-testimonials .elementLine4{
			animation: ptesElem4 3s forwards ease-in-out;
		}
		@keyframes ptesElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				right: 1220px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 445px;
				right: 1180px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1130px;
				border-radius: 50%;
			}
		}
	}
	@media (max-width: 1250px) {
		body.ltr.portfolio-to-testimonials .globalElement2{
			animation: ptesElem2 3s forwards ease-in-out;
		}
		
		@keyframes ptesElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1120px;
				top: 0px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1130px;
				top: 40px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1100px;
				top: 40px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.portfolio-to-testimonials .elementLine4{
			animation: ptesElem4 3s forwards ease-in-out;
		}
		@keyframes ptesElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				right: 1170px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 445px;
				right: 1130px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1100px;
				border-radius: 50%;
			}
		}
	}

	/******************* Animate Portfolio to Clients **********************/

	@media (max-width: 1550px) {
		
		body.ltr.portfolio-to-clients .globalElement2{
			animation: pcElem2 3s forwards ease-in-out;
		}
		@keyframes pcElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1370px;
				top: 0px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 250px;
				height: 250px;
				left: 575px;
				top: 525px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 250px;
				height: 250px;
				left: 575px;
				top: 495px;
				transform: rotate(135deg);
				
			}
			
		}
		
		body.ltr.portfolio-to-clients .elementLine4{
			animation: pcElem4 3s forwards ease-in-out;
		}
		@keyframes pcElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				right: 1400px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 500px;
				right: 610px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 470px;
				right: 600px;
				border-radius: 50%;
			}
		}
 		
		
	}

	@media (max-width: 1440px) {
		body.ltr.portfolio-to-clients .globalElement2{
			animation: pcElem2 3s forwards ease-in-out;
		}
		
		@keyframes pcElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1320px;
				top: 0px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 250px;
				height: 250px;
				left: 565px;
				top: 525px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 250px;
				height: 250px;
				left: 565px;
				top: 495px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.portfolio-to-clients .elementLine4{
			animation: pcElem4 3s forwards ease-in-out;
		}
		@keyframes pcElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				right: 1350px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 500px;
				right: 550px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 470px;
				right: 540px;
				border-radius: 50%;
			}
		}
	}
	@media (max-width: 1399px) {
		body.ltr.portfolio-to-clients .globalElement2{
			animation: pcElem2 3s forwards ease-in-out;
		}
		
		@keyframes pcElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1270px;
				top: 0px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 250px;
				height: 250px;
				left: 525px;
				top: 525px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 250px;
				height: 250px;
				left: 525px;
				top: 495px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.portfolio-to-clients .elementLine4{
			animation: pcElem4 3s forwards ease-in-out;
		}
		@keyframes pcElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				right: 1300px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 500px;
				right: 550px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 470px;
				right: 540px;
				border-radius: 50%;
			}
		}
	}

	@media (max-width: 1350px) {
		body.ltr.portfolio-to-clients .globalElement2{
			animation: pcElem2 3s forwards ease-in-out;
		}
		
		@keyframes pcElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1220px;
				top: 0px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 250px;
				height: 250px;
				left: 465px;
				top: 525px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 250px;
				height: 250px;
				left: 465px;
				top: 495px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.portfolio-to-clients .elementLine4{
			animation: pcElem4 3s forwards ease-in-out;
		}
		@keyframes pcElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				right: 1250px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 500px;
				right: 550px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 470px;
				right: 540px;
				border-radius: 50%;
			}
		}
	}

	@media (max-width: 1299px) {
		body.ltr.portfolio-to-clients .globalElement2{
			animation: pcElem2 3s forwards ease-in-out;
		}
		
		@keyframes pcElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1170px;
				top: 0px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 250px;
				height: 250px;
				left: 425px;
				top: 525px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 250px;
				height: 250px;
				left: 415px;
				top: 495px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.portfolio-to-clients .elementLine4{
			animation: pcElem4 3s forwards ease-in-out;
		}
		@keyframes pcElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				right: 1220px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 500px;
				right: 550px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 470px;
				right: 540px;
				border-radius: 50%;
			}
		}
	}
	@media (max-width: 1250px) {
		body.ltr.portfolio-to-clients .globalElement2{
			animation: pcElem2 3s forwards ease-in-out;
		}
		
		@keyframes pcElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1120px;
				top: 0px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 250px;
				height: 250px;
				left: 375px;
				top: 525px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 250px;
				height: 250px;
				left: 365px;
				top: 495px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.portfolio-to-clients .elementLine4{
			animation: pcElem4 3s forwards ease-in-out;
		}
		@keyframes pcElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				right: 1170px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 500px;
				right: 550px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 470px;
				right: 540px;
				border-radius: 50%;
			}
		}
	}

	/******************* Animate Portfolio to Contact **********************/

	@media (max-width: 1550px) {
		body.ltr.portfolio-to-contact .globalElement2{
			animation: pCtElem2 3s forwards ease-in-out;
		}
		@keyframes pCtElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1370px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1400px;
				top: -20px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1370px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			
		}
		
		body.ltr.portfolio-to-contact .elementLine4{
			animation: pCtElem4 3s forwards ease-in-out;
		}
		@keyframes pCtElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				right: 1400px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1430px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1400px;
				border-radius: 50%;
			}
		}
 		
		
	}

	@media (max-width: 1440px) {
		body.ltr.portfolio-to-contact .globalElement2{
			animation: pCtElem2 3s forwards ease-in-out;
		}
		
		@keyframes pCtElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1320px;
				top: 0px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1350px;
				top: -20px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1320px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.portfolio-to-contact .elementLine4{
			animation: pCtElem4 3s forwards ease-in-out;
		}
		@keyframes aCtElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				right: 1350px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1380px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1350px;
				border-radius: 50%;
			}
		}
	}
	@media (max-width: 1399px) {
		body.ltr.portfolio-to-contact .globalElement2{
			animation: pCtElem2 3s forwards ease-in-out;
		}
		
		@keyframes pCtElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1270px;
				top: 0px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1300px;
				top: -20px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1270px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.portfolio-to-contact .elementLine4{
			animation: pCtElem4 3s forwards ease-in-out;
		}
		@keyframes pCtElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				right: 1300px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1350px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1300px;
				border-radius: 50%;
			}
		}
	}

	@media (max-width: 1350px) {
		body.ltr.portfolio-to-contact .globalElement2{
			animation: pCtElem2 3s forwards ease-in-out;
		}
		
		@keyframes pCtElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1220px;
				top: 0px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1250px;
				top: -20px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1220px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.portfolio-to-contact .elementLine4{
			animation: pCtElem4 3s forwards ease-in-out;
		}
		@keyframes pCtElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				right: 1250px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1300px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1250px;
				border-radius: 50%;
			}
		}
	}

	@media (max-width: 1299px) {
		body.ltr.portfolio-to-contact .globalElement2{
			animation: pCtElem2 3s forwards ease-in-out;
		}
		
		@keyframes pCtElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1170px;
				top: 0px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1200px;
				top: -20px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1170px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.portfolio-to-contact .elementLine4{
			animation: pCtElem4 3s forwards ease-in-out;
		}
		@keyframes pCtElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				right: 1220px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1250px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1220px;
				border-radius: 50%;
			}
		}
	}
	@media (max-width: 1250px) {
		body.ltr.portfolio-to-contact .globalElement2{
			animation: pCtElem2 3s forwards ease-in-out;
		}
		
		@keyframes pCtElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1120px;
				top: 0px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1150px;
				top: -20px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1120px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.portfolio-to-contact .elementLine4{
			animation: pCtElem4 3s forwards ease-in-out;
		}
		@keyframes pCtElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				right: 1170px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1200px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1170px;
				border-radius: 50%;
			}
		}
	}


	/******************* Animate Portfolio to FAQs **********************/

	@media (max-width: 1550px) {
		body.ltr.portfolio-to-faqs .globalElement2{
			animation: pfElem2 3s forwards ease-in-out;
		}
		@keyframes pfElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1370px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1400px;
				top: -20px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1370px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			
		}
		
		body.ltr.portfolio-to-faqs .elementLine4{
			animation: pfElem4 3s forwards ease-in-out;
		}
		@keyframes pfElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				right: 1400px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1430px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1400px;
				border-radius: 50%;
			}
		}
 		
		
	}

	@media (max-width: 1440px) {
		body.ltr.portfolio-to-faqs .globalElement2{
			animation: pfElem2 3s forwards ease-in-out;
		}
		
		@keyframes pfElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1320px;
				top: 0px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1350px;
				top: -20px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1320px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.portfolio-to-faqs .elementLine4{
			animation: pfElem4 3s forwards ease-in-out;
		}
		@keyframes afElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				right: 1350px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1380px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1350px;
				border-radius: 50%;
			}
		}
	}
	@media (max-width: 1399px) {
		body.ltr.portfolio-to-faqs .globalElement2{
			animation: pfElem2 3s forwards ease-in-out;
		}
		
		@keyframes pfElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1270px;
				top: 0px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1300px;
				top: -20px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1270px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.portfolio-to-faqs .elementLine4{
			animation: pfElem4 3s forwards ease-in-out;
		}
		@keyframes pfElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				right: 1300px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1350px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1300px;
				border-radius: 50%;
			}
		}
	}

	@media (max-width: 1350px) {
		body.ltr.portfolio-to-faqs .globalElement2{
			animation: pfElem2 3s forwards ease-in-out;
		}
		
		@keyframes pfElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1220px;
				top: 0px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1250px;
				top: -20px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1220px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.portfolio-to-faqs .elementLine4{
			animation: pfElem4 3s forwards ease-in-out;
		}
		@keyframes pfElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				right: 1250px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1300px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1250px;
				border-radius: 50%;
			}
		}
	}

	@media (max-width: 1299px) {
		body.ltr.portfolio-to-faqs .globalElement2{
			animation: pfElem2 3s forwards ease-in-out;
		}
		
		@keyframes pfElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1170px;
				top: 0px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1200px;
				top: -20px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1170px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.portfolio-to-faqs .elementLine4{
			animation: pfElem4 3s forwards ease-in-out;
		}
		@keyframes pfElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				right: 1220px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1250px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1220px;
				border-radius: 50%;
			}
		}
	}
	@media (max-width: 1250px) {
		body.ltr.portfolio-to-faqs .globalElement2{
			animation: pfElem2 3s forwards ease-in-out;
		}
		
		@keyframes pfElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1120px;
				top: 0px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1150px;
				top: -20px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1120px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.portfolio-to-faqs .elementLine4{
			animation: pfElem4 3s forwards ease-in-out;
		}
		@keyframes pfElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 10px;
				right: 1170px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1200px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1170px;
				border-radius: 50%;
			}
		}
	}


	

/******************* Animate Testimonials to All **********************/

	/******************* Animate Testimonials to About **********************/

	@media (max-width: 1550px) {
		body.ltr.testimonials-to-about .globalElement2{
			animation: tesaElem2 3s forwards ease-in-out;
		}
		@keyframes tesaElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1370px;
				top: 40px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				left: 1450px;
				top: 30px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				left: 1420px;
				top: 30px;
				transform: rotate(135deg);
			}
			
		}

		body.ltr.testimonials-to-about .elementLine4{
			animation: tesaElem4 3s forwards ease-in-out;
		}
		@keyframes tesaElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1400px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1460px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1380px;
				border-radius: 50%;
			}
		}

	}
	
	@media (max-width: 1440px) {
		body.ltr.testimonials-to-about .globalElement2{
			animation: tesaElem2 3s forwards ease-in-out;
		}
		
		@keyframes tesaElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1300px;
				top: 40px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				left: 1330px;
				top: 60px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				left: 1300px;
				top: 60px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.testimonials-to-about .elementLine4{
			animation: tesaElem4 3s forwards ease-in-out;
		}
		@keyframes tesaElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1300px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1350px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1300px;
				border-radius: 50%;
			}
		}
	}
	@media (max-width: 1399px) {
		body.ltr.testimonials-to-about .globalElement2{
			animation: tesaElem2 3s forwards ease-in-out;
		}
		
		@keyframes tesaElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1250px;
				top: 40px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				left: 1330px;
				top: 60px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				left: 1300px;
				top: 60px;
				transform: rotate(135deg);
			}
			
		}

		body.ltr.testimonials-to-about .elementLine4{
			animation: tesaElem4 3s forwards ease-in-out;
		}
		@keyframes tesaElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1250px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1300px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1260px;
				border-radius: 50%;
			}
		}
	}

	@media (max-width: 1350px) {
		body.ltr.testimonials-to-about .globalElement2{
			animation: tesaElem2 3s forwards ease-in-out;
		}
		
		@keyframes tesaElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1180px;
				top: 40px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				left: 1250px;
				top: 30px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				left: 1220px;
				top: 30px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.testimonials-to-about .elementLine4{
			animation: tesaElem4 3s forwards ease-in-out;
		}
		@keyframes tesaElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1180px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1230px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1200px;
				border-radius: 50%;
			}
		}
	}

	@media (max-width: 1299px) {
		body.ltr.testimonials-to-about .globalElement2{
			animation: tesaElem2 3s forwards ease-in-out;
		}
		
		@keyframes tesaElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1130px;
				top: 40px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				left: 1250px;
				top: 30px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				left: 1220px;
				top: 30px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.testimonials-to-about .elementLine4{
			animation: tesaElem4 3s forwards ease-in-out;
		}
		@keyframes tesaElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1130px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1180px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1150px;
				border-radius: 50%;
			}
		}
	}
	@media (max-width: 1250px) {
		body.ltr.testimonials-to-about .globalElement2{
			animation: tesaElem2 3s forwards ease-in-out;
		}
		
		@keyframes tesaElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1100px;
				top: 40px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				left: 1150px;
				top: 30px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				left: 1120px;
				top: 30px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.testimonials-to-about .elementLine4{
			animation: tesaElem4 3s forwards ease-in-out;
		}
		@keyframes tesaElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1100px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1130px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1100px;
				border-radius: 50%;
			}
		}
	}

	/******************* Animate Testimonials to Services **********************/

	@media (max-width: 1550px) {
		body.ltr.testimonials-to-services .globalElement2{
			animation: tessElem2 3s forwards ease-in-out;
		}
		@keyframes tessElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1370px;
				top: 40px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1400px;
				top: 60px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1370px;
				top: 60px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.testimonials-to-services .elementLine4{
			animation: tessElem4 3s forwards ease-in-out;
		}
		@keyframes tessElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1400px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				right: -125px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				right: -95px;
				border-radius: 50%;
			}
		}



	}
	
	
	@media (max-width: 1440px) {
		body.ltr.testimonials-to-services .globalElement2{
			animation: tessElem2 3s forwards ease-in-out;
		}
		
		@keyframes tessElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1300px;
				top: 40px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1330px;
				top: 60px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1300px;
				top: 60px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.testimonials-to-services .elementLine4{
			animation: tessElem4 3s forwards ease-in-out;
		}
		@keyframes tessElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1300px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				right: -115px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				right: -95px;
				border-radius: 50%;
			}
		}
	}
	@media (max-width: 1399px) {
		body.ltr.testimonials-to-services .globalElement2{
			animation: tessElem2 3s forwards ease-in-out;
		}
		
		@keyframes tessElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1250px;
				top: 40px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1290px;
				top: 60px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1260px;
				top: 60px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.testimonials-to-services .elementLine4{
			animation: tessElem4 3s forwards ease-in-out;
		}
		@keyframes tessElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1250px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				right: -115px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				right: -95px;
				border-radius: 50%;
			}
		}
	}

	@media (max-width: 1350px) {
		body.ltr.testimonials-to-services .globalElement2{
			animation: tessElem2 3s forwards ease-in-out;
		}
		
		@keyframes tessElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1180px;
				top: 40px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1230px;
				top: 60px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1200px;
				top: 60px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.testimonials-to-services .elementLine4{
			animation: tessElem4 3s forwards ease-in-out;
		}
		@keyframes tessElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1180px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				right: -115px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				right: -95px;
				border-radius: 50%;
			}
		}
	}

	@media (max-width: 1299px) {
		body.ltr.testimonials-to-services .globalElement2{
			animation: tessElem2 3s forwards ease-in-out;
		}
		
		@keyframes tessElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1130px;
				top: 40px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1180px;
				top: 60px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1150px;
				top: 60px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.testimonials-to-services .elementLine4{
			animation: tessElem4 3s forwards ease-in-out;
		}
		@keyframes tessElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1130px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				right: -115px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				right: -95px;
				border-radius: 50%;
			}
		}
	}
	@media (max-width: 1250px) {
		body.ltr.testimonials-to-services .globalElement2{
			animation: tessElem2 3s forwards ease-in-out;
		}
		
		@keyframes tessElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1100px;
				top: 40px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1130px;
				top: 60px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1100px;
				top: 60px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.testimonials-to-services .elementLine4{
			animation: tessElem4 3s forwards ease-in-out;
		}
		@keyframes tessElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1100px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				right: -115px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				right: -95px;
				border-radius: 50%;
			}
		}
	}

	/******************* Animate Testimonials to Team **********************/

	@media (max-width: 1550px) {
		body.ltr.testimonials-to-team .globalElement2{
			animation: testElem2 3s forwards ease-in-out;
		}
		@keyframes testElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1370px;
				top: 40px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1400px;
				top: 40px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1370px;
				top: 40px;
				transform: rotate(135deg);
				
			}
			
		}
		
		body.ltr.testimonials-to-team .elementLine4{
			animation: testElem4 3s forwards ease-in-out;
		}
		@keyframes testElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1400px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 445px;
				right: 1430px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1400px;
				border-radius: 50%;
			}
		}
 		
		
	}

	@media (max-width: 1440px) {
		body.ltr.testimonials-to-team .globalElement2{
			animation: testElem2 3s forwards ease-in-out;
		}
		
		@keyframes testElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1300px;
				top: 40px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1330px;
				top: 40px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1300px;
				top: 40px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.testimonials-to-team .elementLine4{
			animation: testElem4 3s forwards ease-in-out;
		}
		@keyframes testElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1300px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 445px;
				right: 1330px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1300px;
				border-radius: 50%;
			}
		}
	}
	@media (max-width: 1399px) {
		body.ltr.testimonials-to-team .globalElement2{
			animation: testElem2 3s forwards ease-in-out;
		}
		
		@keyframes testElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1250px;
				top: 40px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1280px;
				top: 40px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1250px;
				top: 40px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.testimonials-to-team .elementLine4{
			animation: testElem4 3s forwards ease-in-out;
		}
		@keyframes testElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1250px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 445px;
				right: 1280px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1250px;
				border-radius: 50%;
			}
		}
	}

	@media (max-width: 1350px) {
		body.ltr.testimonials-to-team .globalElement2{
			animation: testElem2 3s forwards ease-in-out;
		}
		
		@keyframes testElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1180px;
				top: 40px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1230px;
				top: 40px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1180px;
				top: 40px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.testimonials-to-team .elementLine4{
			animation: testElem4 3s forwards ease-in-out;
		}
		@keyframes testElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1180px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 445px;
				right: 1230px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1180px;
				border-radius: 50%;
			}
		}
	}

	@media (max-width: 1299px) {
		body.ltr.testimonials-to-team .globalElement2{
			animation: testElem2 3s forwards ease-in-out;
		}
		
		@keyframes testElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1130px;
				top: 40px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1180px;
				top: 40px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1130px;
				top: 40px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.testimonials-to-team .elementLine4{
			animation: testElem4 3s forwards ease-in-out;
		}
		@keyframes testElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1130px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 445px;
				right: 1180px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1130px;
				border-radius: 50%;
			}
		}
	}
	@media (max-width: 1250px) {
		body.ltr.testimonials-to-team .globalElement2{
			animation: testElem2 3s forwards ease-in-out;
		}
		
		@keyframes testElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1100px;
				top: 40px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1130px;
				top: 40px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1100px;
				top: 40px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.testimonials-to-team .elementLine4{
			animation: testElem4 3s forwards ease-in-out;
		}
		@keyframes testElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1100px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 445px;
				right: 1130px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1100px;
				border-radius: 50%;
			}
		}
	}



	/******************* Animate Testimonials to Clients **********************/

	@media (max-width: 1550px) {
		
		body.ltr.testimonials-to-clients .globalElement2{
			animation: tescElem2 3s forwards ease-in-out;
		}
		@keyframes tescElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1370px;
				top: 40px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 250px;
				height: 250px;
				left: 575px;
				top: 525px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 250px;
				height: 250px;
				left: 575px;
				top: 495px;
				transform: rotate(135deg);
				
			}
			
		}
		
		body.ltr.testimonials-to-clients .elementLine4{
			animation: tescElem4 3s forwards ease-in-out;
		}
		@keyframes tescElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1400px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 500px;
				right: 610px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 470px;
				right: 600px;
				border-radius: 50%;
			}
		}
 		
		
	}

	@media (max-width: 1440px) {
		body.ltr.testimonials-to-clients .globalElement2{
			animation: tescElem2 3s forwards ease-in-out;
		}
		
		@keyframes tescElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1300px;
				top: 40px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 250px;
				height: 250px;
				left: 565px;
				top: 525px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 250px;
				height: 250px;
				left: 565px;
				top: 495px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.testimonials-to-clients .elementLine4{
			animation: tescElem4 3s forwards ease-in-out;
		}
		@keyframes tescElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1300px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 500px;
				right: 550px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 470px;
				right: 540px;
				border-radius: 50%;
			}
		}
	}
	@media (max-width: 1399px) {
		body.ltr.testimonials-to-clients .globalElement2{
			animation: tescElem2 3s forwards ease-in-out;
		}
		
		@keyframes tescElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1250px;
				top: 40px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 250px;
				height: 250px;
				left: 525px;
				top: 525px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 250px;
				height: 250px;
				left: 525px;
				top: 495px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.testimonials-to-clients .elementLine4{
			animation: tescElem4 3s forwards ease-in-out;
		}
		@keyframes tescElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1250px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 500px;
				right: 550px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 470px;
				right: 540px;
				border-radius: 50%;
			}
		}
	}

	@media (max-width: 1350px) {
		body.ltr.testimonials-to-clients .globalElement2{
			animation: tescElem2 3s forwards ease-in-out;
		}
		
		@keyframes tescElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1180px;
				top: 40px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 250px;
				height: 250px;
				left: 465px;
				top: 525px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 250px;
				height: 250px;
				left: 465px;
				top: 495px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.testimonials-to-clients .elementLine4{
			animation: tescElem4 3s forwards ease-in-out;
		}
		@keyframes tescElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1180px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 500px;
				right: 550px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 470px;
				right: 540px;
				border-radius: 50%;
			}
		}
	}

	@media (max-width: 1299px) {
		body.ltr.testimonials-to-clients .globalElement2{
			animation: tescElem2 3s forwards ease-in-out;
		}
		
		@keyframes tescElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1130px;
				top: 40px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 250px;
				height: 250px;
				left: 425px;
				top: 525px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 250px;
				height: 250px;
				left: 415px;
				top: 495px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.testimonials-to-clients .elementLine4{
			animation: tescElem4 3s forwards ease-in-out;
		}
		@keyframes tescElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1130px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 500px;
				right: 550px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 470px;
				right: 540px;
				border-radius: 50%;
			}
		}
	}
	@media (max-width: 1250px) {
		body.ltr.testimonials-to-clients .globalElement2{
			animation: tescElem2 3s forwards ease-in-out;
		}
		
		@keyframes tescElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1100px;
				top: 40px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 250px;
				height: 250px;
				left: 375px;
				top: 525px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 250px;
				height: 250px;
				left: 365px;
				top: 495px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.testimonials-to-clients .elementLine4{
			animation: tescElem4 3s forwards ease-in-out;
		}
		@keyframes tescElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1100px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 500px;
				right: 550px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 470px;
				right: 540px;
				border-radius: 50%;
			}
		}
	}

	/******************* Animate Testimonials to Contact **********************/

	@media (max-width: 1550px) {
		body.ltr.testimonials-to-contact .globalElement2{
			animation: tesCtElem2 3s forwards ease-in-out;
		}
		@keyframes tesCtElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1370px;
				top: 40px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1400px;
				top: -20px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1370px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			
		}
		
		body.ltr.testimonials-to-contact .elementLine4{
			animation: tesCtElem4 3s forwards ease-in-out;
		}
		@keyframes tesCtElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1400px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1430px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1400px;
				border-radius: 50%;
			}
		}
 		
		
	}

	@media (max-width: 1440px) {
		body.ltr.testimonials-to-contact .globalElement2{
			animation: tesCtElem2 3s forwards ease-in-out;
		}
		
		@keyframes tesCtElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1300px;
				top: 40px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1350px;
				top: -20px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1320px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.testimonials-to-contact .elementLine4{
			animation: tesCtElem4 3s forwards ease-in-out;
		}
		@keyframes tesCtElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1300px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1380px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1350px;
				border-radius: 50%;
			}
		}
	}
	@media (max-width: 1399px) {
		body.ltr.testimonials-to-contact .globalElement2{
			animation: tesCtElem2 3s forwards ease-in-out;
		}
		
		@keyframes tesCtElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1250px;
				top: 40px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1300px;
				top: -20px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1270px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.testimonials-to-contact .elementLine4{
			animation: tesCtElem4 3s forwards ease-in-out;
		}
		@keyframes tesCtElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1250px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1350px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1300px;
				border-radius: 50%;
			}
		}
	}

	@media (max-width: 1350px) {
		body.ltr.testimonials-to-contact .globalElement2{
			animation: tesCtElem2 3s forwards ease-in-out;
		}
		
		@keyframes tesCtElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1180px;
				top: 40px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1250px;
				top: -20px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1220px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.testimonials-to-contact .elementLine4{
			animation: tesCtElem4 3s forwards ease-in-out;
		}
		@keyframes tesCtElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1180px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1300px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1250px;
				border-radius: 50%;
			}
		}
	}

	@media (max-width: 1299px) {
		body.ltr.testimonials-to-contact .globalElement2{
			animation: tesCtElem2 3s forwards ease-in-out;
		}
		
		@keyframes tesCtElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1130px;
				top: 40px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1200px;
				top: -20px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1170px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.testimonials-to-contact .elementLine4{
			animation: tesCtElem4 3s forwards ease-in-out;
		}
		@keyframes tesCtElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1130px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1250px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1220px;
				border-radius: 50%;
			}
		}
	}
	@media (max-width: 1250px) {
		body.ltr.testimonials-to-contact .globalElement2{
			animation: tesCtElem2 3s forwards ease-in-out;
		}
		
		@keyframes tesCtElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1100px;
				top: 40px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1150px;
				top: -20px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1120px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.testimonials-to-contact .elementLine4{
			animation: tesCtElem4 3s forwards ease-in-out;
		}
		@keyframes tesCtElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1100px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1200px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1170px;
				border-radius: 50%;
			}
		}
	}


	/******************* Animate Testimonials to FAQs **********************/

	@media (max-width: 1550px) {
		body.ltr.testimonials-to-faqs .globalElement2{
			animation: tesfElem2 3s forwards ease-in-out;
		}
		@keyframes tesfElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1370px;
				top: 40px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1400px;
				top: -20px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1370px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			
		}
		
		body.ltr.testimonials-to-faqs .elementLine4{
			animation: tesfElem4 3s forwards ease-in-out;
		}
		@keyframes tesfElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1400px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1430px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1400px;
				border-radius: 50%;
			}
		}
 		
		
	}

	@media (max-width: 1440px) {
		body.ltr.testimonials-to-faqs .globalElement2{
			animation: tesfElem2 3s forwards ease-in-out;
		}
		
		@keyframes tesfElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1300px;
				top: 40px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1350px;
				top: -20px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1320px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.testimonials-to-faqs .elementLine4{
			animation: tesfElem4 3s forwards ease-in-out;
		}
		@keyframes tesfElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1300px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1380px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1350px;
				border-radius: 50%;
			}
		}
	}
	@media (max-width: 1399px) {
		body.ltr.testimonials-to-faqs .globalElement2{
			animation: tesfElem2 3s forwards ease-in-out;
		}
		
		@keyframes tesfElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1250px;
				top: 40px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1300px;
				top: -20px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1270px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.testimonials-to-faqs .elementLine4{
			animation: tesfElem4 3s forwards ease-in-out;
		}
		@keyframes tesfElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1250px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1350px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1300px;
				border-radius: 50%;
			}
		}
	}

	@media (max-width: 1350px) {
		body.ltr.testimonials-to-faqs .globalElement2{
			animation: tesfElem2 3s forwards ease-in-out;
		}
		
		@keyframes tesfElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1180px;
				top: 40px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1250px;
				top: -20px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1220px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.testimonials-to-faqs .elementLine4{
			animation: tesfElem4 3s forwards ease-in-out;
		}
		@keyframes tesfElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1180px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1300px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1250px;
				border-radius: 50%;
			}
		}
	}

	@media (max-width: 1299px) {
		body.ltr.testimonials-to-faqs .globalElement2{
			animation: tesfElem2 3s forwards ease-in-out;
		}
		
		@keyframes tesfElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1130px;
				top: 40px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1200px;
				top: -20px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1170px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.testimonials-to-faqs .elementLine4{
			animation: tesfElem4 3s forwards ease-in-out;
		}
		@keyframes tesfElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1130px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1250px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1220px;
				border-radius: 50%;
			}
		}
	}
	@media (max-width: 1250px) {
		body.ltr.testimonials-to-faqs .globalElement2{
			animation: tesfElem2 3s forwards ease-in-out;
		}
		
		@keyframes tesfElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1100px;
				top: 40px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1150px;
				top: -20px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1120px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.testimonials-to-faqs .elementLine4{
			animation: tesfElem4 3s forwards ease-in-out;
		}
		@keyframes tesfElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1100px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1200px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1170px;
				border-radius: 50%;
			}
		}
	}




/******************* Animate Clients to All **********************/

	/******************* Animate Clients to About **********************/

	@media (max-width: 1550px) {
		body.ltr.clients-to-about .globalElement2{
			animation: tesaElem2 3s forwards ease-in-out;
		}
		@keyframes tesaElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1370px;
				top: 40px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				left: 1450px;
				top: 30px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				left: 1420px;
				top: 30px;
				transform: rotate(135deg);
			}
			
		}

		body.ltr.clients-to-about .elementLine4{
			animation: tesaElem4 3s forwards ease-in-out;
		}
		@keyframes tesaElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1400px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1460px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1380px;
				border-radius: 50%;
			}
		}

	}
	
	@media (max-width: 1440px) {
		body.ltr.clients-to-about .globalElement2{
			animation: tesaElem2 3s forwards ease-in-out;
		}
		
		@keyframes tesaElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1300px;
				top: 40px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				left: 1330px;
				top: 60px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				left: 1300px;
				top: 60px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.clients-to-about .elementLine4{
			animation: tesaElem4 3s forwards ease-in-out;
		}
		@keyframes tesaElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1300px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1350px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1300px;
				border-radius: 50%;
			}
		}
	}
	@media (max-width: 1399px) {
		body.ltr.clients-to-about .globalElement2{
			animation: tesaElem2 3s forwards ease-in-out;
		}
		
		@keyframes tesaElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1250px;
				top: 40px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				left: 1330px;
				top: 60px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				left: 1300px;
				top: 60px;
				transform: rotate(135deg);
			}
			
		}

		body.ltr.clients-to-about .elementLine4{
			animation: tesaElem4 3s forwards ease-in-out;
		}
		@keyframes tesaElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1250px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1300px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1260px;
				border-radius: 50%;
			}
		}
	}

	@media (max-width: 1350px) {
		body.ltr.clients-to-about .globalElement2{
			animation: tesaElem2 3s forwards ease-in-out;
		}
		
		@keyframes tesaElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1180px;
				top: 40px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				left: 1250px;
				top: 30px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				left: 1220px;
				top: 30px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.clients-to-about .elementLine4{
			animation: tesaElem4 3s forwards ease-in-out;
		}
		@keyframes tesaElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1180px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1230px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1200px;
				border-radius: 50%;
			}
		}
	}

	@media (max-width: 1299px) {
		body.ltr.clients-to-about .globalElement2{
			animation: tesaElem2 3s forwards ease-in-out;
		}
		
		@keyframes tesaElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1130px;
				top: 40px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				left: 1250px;
				top: 30px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				left: 1220px;
				top: 30px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.clients-to-about .elementLine4{
			animation: tesaElem4 3s forwards ease-in-out;
		}
		@keyframes tesaElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1130px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1180px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1150px;
				border-radius: 50%;
			}
		}
	}
	@media (max-width: 1250px) {
		body.ltr.clients-to-about .globalElement2{
			animation: tesaElem2 3s forwards ease-in-out;
		}
		
		@keyframes tesaElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1100px;
				top: 40px;
				transform: rotate(135deg);
				
			}
			70% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				left: 1150px;
				top: 30px;
				transform: rotate(135deg);
			}
			100% { 
				border-radius: 55px;
				width: 150px;
				height: 150px;
				left: 1120px;
				top: 30px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.clients-to-about .elementLine4{
			animation: tesaElem4 3s forwards ease-in-out;
		}
		@keyframes tesaElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1100px;
				border-radius: 50%;
				
			}
			70% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1130px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 300px;
				height: 300px;
				top: 120px;
				right: 1100px;
				border-radius: 50%;
			}
		}
	}

	/******************* Animate Testimonials to Services **********************/

	@media (max-width: 1550px) {
		body.ltr.clients-to-services .globalElement2{
			animation: tessElem2 3s forwards ease-in-out;
		}
		@keyframes tessElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1370px;
				top: 40px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1400px;
				top: 60px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1370px;
				top: 60px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.clients-to-services .elementLine4{
			animation: tessElem4 3s forwards ease-in-out;
		}
		@keyframes tessElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1400px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				right: -125px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				right: -95px;
				border-radius: 50%;
			}
		}



	}
	
	
	@media (max-width: 1440px) {
		body.ltr.clients-to-services .globalElement2{
			animation: tessElem2 3s forwards ease-in-out;
		}
		
		@keyframes tessElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1300px;
				top: 40px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1330px;
				top: 60px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1300px;
				top: 60px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.clients-to-services .elementLine4{
			animation: tessElem4 3s forwards ease-in-out;
		}
		@keyframes tessElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1300px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				right: -115px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				right: -95px;
				border-radius: 50%;
			}
		}
	}
	@media (max-width: 1399px) {
		body.ltr.clients-to-services .globalElement2{
			animation: tessElem2 3s forwards ease-in-out;
		}
		
		@keyframes tessElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1250px;
				top: 40px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1290px;
				top: 60px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1260px;
				top: 60px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.clients-to-services .elementLine4{
			animation: tessElem4 3s forwards ease-in-out;
		}
		@keyframes tessElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1250px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				right: -115px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				right: -95px;
				border-radius: 50%;
			}
		}
	}

	@media (max-width: 1350px) {
		body.ltr.clients-to-services .globalElement2{
			animation: tessElem2 3s forwards ease-in-out;
		}
		
		@keyframes tessElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1180px;
				top: 40px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1230px;
				top: 60px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1200px;
				top: 60px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.clients-to-services .elementLine4{
			animation: tessElem4 3s forwards ease-in-out;
		}
		@keyframes tessElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1180px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				right: -115px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				right: -95px;
				border-radius: 50%;
			}
		}
	}

	@media (max-width: 1299px) {
		body.ltr.clients-to-services .globalElement2{
			animation: tessElem2 3s forwards ease-in-out;
		}
		
		@keyframes tessElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1130px;
				top: 40px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1180px;
				top: 60px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1150px;
				top: 60px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.clients-to-services .elementLine4{
			animation: tessElem4 3s forwards ease-in-out;
		}
		@keyframes tessElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1130px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				right: -115px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				right: -95px;
				border-radius: 50%;
			}
		}
	}
	@media (max-width: 1250px) {
		body.ltr.clients-to-services .globalElement2{
			animation: tessElem2 3s forwards ease-in-out;
		}
		
		@keyframes tessElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1100px;
				top: 40px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1130px;
				top: 60px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1100px;
				top: 60px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.clients-to-services .elementLine4{
			animation: tessElem4 3s forwards ease-in-out;
		}
		@keyframes tessElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1100px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				right: -115px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 36px;
				right: -95px;
				border-radius: 50%;
			}
		}
	}

	/******************* Animate Testimonials to Team **********************/

	@media (max-width: 1550px) {
		body.ltr.clients-to-team .globalElement2{
			animation: testElem2 3s forwards ease-in-out;
		}
		@keyframes testElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1370px;
				top: 40px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1400px;
				top: 40px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1370px;
				top: 40px;
				transform: rotate(135deg);
				
			}
			
		}
		
		body.ltr.clients-to-team .elementLine4{
			animation: testElem4 3s forwards ease-in-out;
		}
		@keyframes testElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1400px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 445px;
				right: 1430px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1400px;
				border-radius: 50%;
			}
		}
 		
		
	}

	@media (max-width: 1440px) {
		body.ltr.clients-to-team .globalElement2{
			animation: testElem2 3s forwards ease-in-out;
		}
		
		@keyframes testElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1300px;
				top: 40px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1330px;
				top: 40px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1300px;
				top: 40px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.clients-to-team .elementLine4{
			animation: testElem4 3s forwards ease-in-out;
		}
		@keyframes testElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1300px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 445px;
				right: 1330px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1300px;
				border-radius: 50%;
			}
		}
	}
	@media (max-width: 1399px) {
		body.ltr.clients-to-team .globalElement2{
			animation: testElem2 3s forwards ease-in-out;
		}
		
		@keyframes testElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1250px;
				top: 40px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1280px;
				top: 40px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1250px;
				top: 40px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.clients-to-team .elementLine4{
			animation: testElem4 3s forwards ease-in-out;
		}
		@keyframes testElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1250px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 445px;
				right: 1280px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1250px;
				border-radius: 50%;
			}
		}
	}

	@media (max-width: 1350px) {
		body.ltr.clients-to-team .globalElement2{
			animation: testElem2 3s forwards ease-in-out;
		}
		
		@keyframes testElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1180px;
				top: 40px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1230px;
				top: 40px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1180px;
				top: 40px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.clients-to-team .elementLine4{
			animation: testElem4 3s forwards ease-in-out;
		}
		@keyframes testElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1180px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 445px;
				right: 1230px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1180px;
				border-radius: 50%;
			}
		}
	}

	@media (max-width: 1299px) {
		body.ltr.clients-to-team .globalElement2{
			animation: testElem2 3s forwards ease-in-out;
		}
		
		@keyframes testElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1130px;
				top: 40px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1180px;
				top: 40px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1130px;
				top: 40px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.clients-to-team .elementLine4{
			animation: testElem4 3s forwards ease-in-out;
		}
		@keyframes testElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1130px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 445px;
				right: 1180px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1130px;
				border-radius: 50%;
			}
		}
	}
	@media (max-width: 1250px) {
		body.ltr.clients-to-team .globalElement2{
			animation: testElem2 3s forwards ease-in-out;
		}
		
		@keyframes testElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1100px;
				top: 40px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1130px;
				top: 40px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1100px;
				top: 40px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.clients-to-team .elementLine4{
			animation: testElem4 3s forwards ease-in-out;
		}
		@keyframes testElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1100px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 445px;
				right: 1130px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1100px;
				border-radius: 50%;
			}
		}
	}



	/******************* Animate clients to Contact **********************/

	@media (max-width: 1550px) {
		body.ltr.clients-to-contact .globalElement2{
			animation: tesCtElem2 3s forwards ease-in-out;
		}
		@keyframes tesCtElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1370px;
				top: 40px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1400px;
				top: -20px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1370px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			
		}
		
		body.ltr.clients-to-contact .elementLine4{
			animation: tesCtElem4 3s forwards ease-in-out;
		}
		@keyframes tesCtElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1400px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1430px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1400px;
				border-radius: 50%;
			}
		}
 		
		
	}

	@media (max-width: 1440px) {
		body.ltr.clients-to-contact .globalElement2{
			animation: tesCtElem2 3s forwards ease-in-out;
		}
		
		@keyframes tesCtElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1300px;
				top: 40px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1350px;
				top: -20px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1320px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.clients-to-contact .elementLine4{
			animation: tesCtElem4 3s forwards ease-in-out;
		}
		@keyframes tesCtElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1300px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1380px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1350px;
				border-radius: 50%;
			}
		}
	}
	@media (max-width: 1399px) {
		body.ltr.clients-to-contact .globalElement2{
			animation: tesCtElem2 3s forwards ease-in-out;
		}
		
		@keyframes tesCtElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1250px;
				top: 40px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1300px;
				top: -20px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1270px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.clients-to-contact .elementLine4{
			animation: tesCtElem4 3s forwards ease-in-out;
		}
		@keyframes tesCtElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1250px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1350px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1300px;
				border-radius: 50%;
			}
		}
	}

	@media (max-width: 1350px) {
		body.ltr.clients-to-contact .globalElement2{
			animation: tesCtElem2 3s forwards ease-in-out;
		}
		
		@keyframes tesCtElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1180px;
				top: 40px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1250px;
				top: -20px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1220px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.clients-to-contact .elementLine4{
			animation: tesCtElem4 3s forwards ease-in-out;
		}
		@keyframes tesCtElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1180px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1300px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1250px;
				border-radius: 50%;
			}
		}
	}

	@media (max-width: 1299px) {
		body.ltr.clients-to-contact .globalElement2{
			animation: tesCtElem2 3s forwards ease-in-out;
		}
		
		@keyframes tesCtElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1130px;
				top: 40px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1200px;
				top: -20px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1170px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.clients-to-contact .elementLine4{
			animation: tesCtElem4 3s forwards ease-in-out;
		}
		@keyframes tesCtElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1130px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1250px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1220px;
				border-radius: 50%;
			}
		}
	}
	@media (max-width: 1250px) {
		body.ltr.clients-to-contact .globalElement2{
			animation: tesCtElem2 3s forwards ease-in-out;
		}
		
		@keyframes tesCtElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1100px;
				top: 40px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1150px;
				top: -20px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1120px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.clients-to-contact .elementLine4{
			animation: tesCtElem4 3s forwards ease-in-out;
		}
		@keyframes tesCtElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1100px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1200px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1170px;
				border-radius: 50%;
			}
		}
	}


	/******************* Animate clients to FAQs **********************/

	@media (max-width: 1550px) {
		body.ltr.clients-to-faqs .globalElement2{
			animation: tesfElem2 3s forwards ease-in-out;
		}
		@keyframes tesfElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1370px;
				top: 40px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1400px;
				top: -20px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1370px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			
		}
		
		body.ltr.clients-to-faqs .elementLine4{
			animation: tesfElem4 3s forwards ease-in-out;
		}
		@keyframes tesfElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1400px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1430px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1400px;
				border-radius: 50%;
			}
		}
 		
		
	}

	@media (max-width: 1440px) {
		body.ltr.clients-to-faqs .globalElement2{
			animation: tesfElem2 3s forwards ease-in-out;
		}
		
		@keyframes tesCtElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1300px;
				top: 40px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1350px;
				top: -20px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1320px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.clients-to-faqs .elementLine4{
			animation: tesfElem4 3s forwards ease-in-out;
		}
		@keyframes tesfElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1300px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1380px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1350px;
				border-radius: 50%;
			}
		}
	}
	@media (max-width: 1399px) {
		body.ltr.clients-to-faqs .globalElement2{
			animation: tesfElem2 3s forwards ease-in-out;
		}
		
		@keyframes tesfElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1250px;
				top: 40px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1300px;
				top: -20px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1270px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.clients-to-faqs .elementLine4{
			animation: tesfElem4 3s forwards ease-in-out;
		}
		@keyframes tesfElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1250px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1350px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1300px;
				border-radius: 50%;
			}
		}
	}

	@media (max-width: 1350px) {
		body.ltr.clients-to-faqs .globalElement2{
			animation: tesfElem2 3s forwards ease-in-out;
		}
		
		@keyframes tesfElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1180px;
				top: 40px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1250px;
				top: -20px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1220px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.clients-to-faqs .elementLine4{
			animation: tesfElem4 3s forwards ease-in-out;
		}
		@keyframes tesfElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1180px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1300px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1250px;
				border-radius: 50%;
			}
		}
	}

	@media (max-width: 1299px) {
		body.ltr.clients-to-faqs .globalElement2{
			animation: tesfElem2 3s forwards ease-in-out;
		}
		
		@keyframes tesfElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1130px;
				top: 40px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1200px;
				top: -20px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1170px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.clients-to-faqs .elementLine4{
			animation: tesfElem4 3s forwards ease-in-out;
		}
		@keyframes tesfElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1130px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1250px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1220px;
				border-radius: 50%;
			}
		}
	}
	@media (max-width: 1250px) {
		body.ltr.clients-to-faqs .globalElement2{
			animation: tesfElem2 3s forwards ease-in-out;
		}
		
		@keyframes tesfElem2 {
			0% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1100px;
				top: 40px;
				transform: rotate(135deg);
			}
			70% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1150px;
				top: -20px;
				transform: rotate(140deg);
			}
			100% { 
				border-radius: 50%;
				width: 150px;
				height: 150px;
				left: 1120px;
				top: 0px;
				transform: rotate(135deg);
				
			}
			
		}

		body.ltr.clients-to-faqs .elementLine4{
			animation: tesfElem4 3s forwards ease-in-out;
		}
		@keyframes tesfElem4 {
			0% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 430px;
				right: 1100px;
				border-radius: 50%;
			}
			70% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1200px;
				border-radius: 50%;
			}
			100% { 
				z-index: -1;
				width: 200px;
				height: 200px;
				top: 180px;
				right: 1170px;
				border-radius: 50%;
			}
		}
	}
