@font-face {
  font-family: "poppinsbold";
  src: url("../fonts/Poppins-Bold.woff2") format("woff2"),
    url("../fonts/Poppins-Bold.woff") format("woff"),
    url("../fonts/Poppins-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "poppinsextrabold";
  src: url("../fonts/Poppins-ExtraBold.woff2") format("woff2"),
    url("../fonts/Poppins-ExtraBold.woff") format("woff"),
    url("../fonts/Poppins-ExtraBold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "poppinsextralight";
  src: url("../fonts/Poppins-ExtraLight.woff2") format("woff2"),
    url("../fonts/Poppins-ExtraLight.woff") format("woff"),
    url("../fonts/Poppins-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "poppinslight";
  src: url("../fonts/Poppins-Light.woff2") format("woff2"),
    url("../fonts/Poppins-Light.woff") format("woff"),
    url("../fonts/Poppins-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "poppinsmedium";
  src: url("../fonts/Poppins-Medium.woff2") format("woff2"),
    url("../fonts/Poppins-Medium.woff") format("woff"),
    url("../fonts/Poppins-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "poppinsregular";
  src: url("../fonts/Poppins-Regular.woff2") format("woff2"),
    url("../fonts/Poppins-Regular.woff") format("woff"),
    url("../fonts/Poppins-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "poppinssemibold";
  src: url("../fonts/Poppins-SemiBold.woff2") format("woff2"),
    url("../fonts/Poppins-SemiBold.woff") format("woff"),
    url("../fonts/Poppins-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "poppinsthin";
  src: url("../fonts/Poppins-Thin.woff2") format("woff2"),
    url("../fonts/Poppins-Thin.woff") format("woff"),
    url("../fonts/Poppins-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "poppinsblack";
  src: url("../fonts/Poppins-Black.woff2") format("woff2"),
    url("../fonts/Poppins-Black.woff") format("woff"),
    url("../fonts/Poppins-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

/****************Arabic Fonts***************/

@font-face {
  font-family: "Noto Kufi Arabic Thin";
  src: url("../fonts/arabic/NotoKufiArabic-Thin.woff2") format("woff2"),
    url("../fonts/arabic/NotoKufiArabic-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Noto Kufi Arabic ExtraLight";
  src: url("../fonts/arabic/NotoKufiArabic-ExtraLight.woff2") format("woff2"),
    url("../fonts/arabic/NotoKufiArabic-ExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Noto Kufi Arabic Light";
  src: url("../fonts/arabic/NotoKufiArabic-Light.woff2") format("woff2"),
    url("../fonts/arabic/NotoKufiArabic-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Noto Kufi Arabic Regular";
  src: url("../fonts/arabic/NotoKufiArabic-Regular.woff2") format("woff2"),
    url("../fonts/arabic/NotoKufiArabic-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Noto Kufi Arabic Medium";
  src: url("../fonts/arabic/NotoKufiArabic-Medium.woff2") format("woff2"),
    url("../fonts/arabic/NotoKufiArabic-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Noto Kufi Arabic SemiBold";
  src: url("../fonts/arabic/NotoKufiArabic-SemiBold.woff2") format("woff2"),
    url("../fonts/arabic/NotoKufiArabic-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Noto Kufi Arabic Bold";
  src: url("../fonts/arabic/NotoKufiArabic-Bold.woff2") format("woff2"),
    url("../fonts/arabic/NotoKufiArabic-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Noto Kufi Arabic ExtraBold";
  src: url("../fonts/arabic/NotoKufiArabic-ExtraBold.woff2") format("woff2"),
    url("../fonts/arabic/NotoKufiArabic-ExtraBold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Noto Kufi Arabic Black";
  src: url("../fonts/arabic/NotoKufiArabic-Black.woff2") format("woff2"),
    url("../fonts/arabic/NotoKufiArabic-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

:root {

  --thin: "poppinsthin";
  --extraLight: "poppinsextralight";
  --light: "poppinslight";
  --regular: "poppinsregular";
  --medium: "poppinsmedium";
  --semiBold: "poppinssemibold";
  --bold: "poppinsbold";
  --extraBold: "poppinsextrabold";
  --pBlack: "poppinsblack";
  
  --arThin: "Noto Kufi Arabic Thin";
  --arExtraLight: "Noto Kufi Arabic ExtraLight";
  --arLight: "Noto Kufi Arabic Light";
  --arRegular: "Noto Kufi Arabic Regular";
  --arMedium: "Noto Kufi Arabic Medium";
  --arSemiBold: "Noto Kufi Arabic SemiBold";
  --arBold: "Noto Kufi Arabic Bold";
  --arExtraBold: "Noto Kufi Arabic ExtraBold";
  --arBlack: "Noto Kufi Arabic Black";
}